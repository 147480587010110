@import "../../assests/scss/variables.scss";

.admin_Dashboard {
  .main_Container {
    width: calc(100%);
    float: right;
    background: $BG_LIGHT;

    .admin_Dashboard_Scroll {
      position: unset !important;

      .dashboard_Section {
        // width: calc(100% - 318px);
        // float: right;
        // padding: 16px;
        // background: $BG_LIGHT;
        padding-left: 96px;

        .page_Title {
          font-size: $FONT_32;
          color: $TXT_NAVY;
          padding-left: 6px;
        }

        .card_Section {
          .card_Width {
            width: 20%;
            max-width: 19.1% !important;
            padding: 16px 24px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
            border: none;
            margin-right: 15px;

            .details {
              .bg_Icon {
                width: 60px;
                height: 60px;
                background: $BG_ICONBG;
                border-radius: 30px;

                i {
                  color: $TXT_NAVY;
                  font-size: $FONT_20;
                }
              }

              .nos {
                font-size: $FONT_16;
                font-family: $LATO_SEMIBOLD;
                color: $TXT_NAVY;
                padding-bottom: 4px;

                display: flex;
                flex-direction: row-reverse;
              }

              .count-Text {
                font-size: $FONT_16;
                color: $LABEL_GREY;

                padding-bottom: 0;
              }
            }
          }
        }

        .main_ChartSection {
          padding-top: 40px;
          background: $BG_LIGHT;

          .chart_Part {
            .chart_Section {
              .w75 {
                width: 70%;
              }

              .w25 {
                width: 30%;
              }

              .w30 {
                width: 33.33%;
                margin-right: 15px;
              }
            }
          }

          .main_Dropdown {
            right: 0;
            z-index: 1;
            top: 16px;

            .dropdown_section {
              .customDropdown {
                margin-right: 15px !important;
                width: 110px;

                .dropdown {
                  .btn-primary {
                    border: 1px solid $TXT_NAVY !important;
                    padding: 5px 16px !important;

                    &:after {
                      position: absolute;
                      right: 18px;
                      top: 12px;
                      color: $TXT_BLUE;
                    }
                  }
                }
              }

              .btn-primary {
                color: $TXT_NAVY !important;
              }
            }

            &.post_Dropdown {
              top: 15px !important;
            }
          }

          .box_Shadow {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .admin_Dashboard {
    .main_Container {
      .admin_Dashboard_Scroll {
        .dashboard_Section {
          .main_ChartSection {
            .chart_Part {
              .chart_Section {
                flex-direction: column;

                .w75 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w25 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w30 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .admin_Dashboard {
    .main_Container {
      .admin_Dashboard_Scroll {
        .dashboard_Section {
          .main_ChartSection {
            .chart_Part {
              .chart_Section {
                flex-direction: column;

                .w75 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w25 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w30 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .admin_Dashboard {
    .main_Container {
      .admin_Dashboard_Scroll {
        .dashboard_Section {
          .card_Section {
            flex-wrap: wrap;

            .card_Width {
              width: 31%;
              margin-bottom: 3%;

              &:nth-child(3) {
                margin-right: 0 !important;
              }

              &:nth-child(5) {
                margin-right: 0 !important;
              }
            }
          }

          .main_ChartSection {
            .chart_Part {
              .chart_Section {
                flex-direction: column;

                .w75 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w25 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w30 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .admin_Dashboard {
    .main_Container {
      .admin_Dashboard_Scroll {
        .dashboard_Section {
          .card_Section {
            flex-wrap: wrap;

            .card_Width {
              width: 48%;
              margin-bottom: 3%;
              max-width: 31% !important;

              &:nth-child(2) {
                margin-right: 15px;
              }

              &:nth-child(4) {
                margin-right: 15px;
              }
            }
          }

          .main_ChartSection {
            .chart_Part {
              .chart_Section {
                flex-direction: column;

                .w75 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w25 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w30 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .admin_Dashboard {
    .headerAdmin {
      // display: none;
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .admin_Dashboard {
    .main_Container {
      .admin_Dashboard_Scroll {
        .dashboard_Section {
          width: 100%;

          .page_Title {
            padding-top: 20px;
          }

          .card_Section {
            flex-wrap: wrap;

            .card_Width {
              width: 100%;
              margin-bottom: 3%;
            }
          }

          .main_ChartSection {
            .chart_Part {
              .chart_Section {
                flex-direction: column;

                .w75 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w25 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w30 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .admin_Dashboard {
    .main_Container {
      .admin_Dashboard_Scroll {
        .dashboard_Section {
          width: 100%;
          padding: 10px;

          .page_Title {
            padding-top: 20px;
          }

          .card_Section {
            flex-wrap: wrap;

            .card_Width {
              width: 100%;
              margin-bottom: 3%;
              margin-right: 0;
            }
          }

          .main_ChartSection {
            padding-top: 0;

            .chart_Part {
              .chart_Section {
                flex-direction: column;

                .w75 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }

                  .main_Dropdown {
                    right: 0;
                    z-index: 1;
                    top: 18px;

                    .customDropdown {
                      width: 98px;

                      &:last-child {
                        margin-right: 0 !important;
                      }
                    }
                  }
                }

                .w25 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w30 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
