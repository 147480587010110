@import "../../assests/scss/variables.scss";

// .nav-link {
//     display: block;
//     padding: 0rem !important;
// }

.admin_Terms {
  .main_Container {
    width: calc(100% - 318px);
    float: right;
    background: $BG_WHITE !important;

    .admin_Terms_Scroll {
      position: unset !important;

      .dashboard_Section {
        // width: calc(100% - 318px);
        // float: right;
        // padding: 16px;
        // background: $BG_WHITE !important;
        padding-left: 96px;

        .content_Header {
          padding-bottom: 24px;

          .page_Title {
            font-size: $FONT_32;
            color: $TXT_NAVY;
            padding-bottom: 4px;
          }

          .inner_Title {
            color: $LABEL_GREY;
            font-size: $FONT_16;
            padding-bottom: 0;
          }

          .w80 {
            width: 80%;
          }

          &.policyHeader {
            display: none;
          }
        }

        .right_Button {
          .btn {
            height: 48px;
            border-radius: 8px !important;
          }

          .save {
            background-color: #ffffff !important;
            padding: 5px 46px !important;
            border: 1px solid !important;
            color: #16056b !important;
            width: auto;
            margin-right: 16px;
          }

          .publish {
            padding: 0 !important;
            width: 130px;
          }

          &.tab_Buttons {
            margin-top: 32px;
            border-radius: 4px;
          }
        }
      }

      .divider {
        border-bottom: 1px solid $BG_LIGHTBLUE;
      }

      .tabsOfTerm {
        max-width: none;

        .right_Button {
          width: auto;

          .nav-item {
            background-color: #b4d7fe;
            border-radius: 4px;
          }

          .nav-link {
            color: #16056b;
          }

          .nav-link.active {
            background-color: #16056b;
            color: white;
          }
        }
      }

      .input_Section {
        padding-top: 32px;
        padding-right: 16px;

        .center_Text {
          font-size: $FONT_24;
          color: $TXT_NAVY;
        }

        .box-border {
          padding: 32px 40px;

          .center_Section {
            width: 780px;

            &.paddTop {
              padding-top: 24px;
            }

            .textarea {
              margin: 24px 0;

              .form-control {
                height: 184px !important;
              }
            }

            .textField {
              margin-bottom: 0 !important;

              .form-control {
                height: 56px;
              }
            }

            .calenderMain {
              position: relative;
              padding-bottom: 8px;
              margin: 24px 0;

              i {
                position: absolute;
                right: 24px;
                top: 18px;
                color: $TXT_BLUE;
              }

              .react-datepicker-wrapper {
                .react-datepicker__input-container {
                  input {
                    width: 100%;
                    height: 56px;
                    border: 1px solid $TXT_NAVY !important;
                    border-radius: 16px;
                    padding: 0 16px;
                    color: $TXT_NAVY;
                    font-size: $FONT_14;
                    font-weight: 500;

                    &:focus {
                      border: 1px solid $TXT_LIGHT !important;
                    }
                  }
                }
              }
            }
          }
        }

        .addCard {
          width: 155px;
          float: right;
          height: 48px;
          margin-top: 32px;
          border-radius: 8px !important;
        }
      }

      .img_Section {
        width: 40%;
        float: left;
        padding-top: 32px;
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .admin_Terms {
    .main_Container {
      .admin_Terms_Scroll {
        .dashboard_Section {
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .admin_Terms {
    .main_Container {
      .admin_Terms_Scroll {
        .dashboard_Section {
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .admin_Terms {
    .main_Container {
      .admin_Terms_Scroll {
        .dashboard_Section {
          .input_Section {
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .admin_Terms {
    .main_Container {
      .admin_Terms_Scroll {
        .dashboard_Section {
          padding-bottom: 170px;

          .right_Button {
            margin-right: 10px;
          }

          .input_Section {
            .box-border {
              padding: 32px 19px;

              .center_Section {
                width: 620px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .admin_Terms {
    .main_Container {
      .admin_Terms_Scroll {
        .dashboard_Section {
          .input_Section {
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .admin_Terms {
    .main_Container {
      .admin_Terms_Scroll {
        .dashboard_Section {
          width: 100%;

          .page_Title {
            padding-top: 20px;
          }

          .input_Section {
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .admin_Terms {
    .main_Container {
      .admin_Terms_Scroll {
        .dashboard_Section {
          width: 100%;
          padding: 10px;

          .page_Title {
            padding-top: 20px;
          }

          .input_Section {
          }
        }
      }
    }
  }
}
