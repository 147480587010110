@import "../../assests/scss/variables.scss";

.cancelSubscription {
  background: rgba(20, 20, 43, 0.5);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .mainCard {
    width: 50%;
    background: $BG;
    border: 1px solid $BG_LIGHTBLUE;
    box-sizing: border-box;
    border-radius: 10px;
    .cardInner {
      .topSection {
        display: flex;
        justify-content: space-between;
        .cardTitle {
          padding: 32px 0px 0px 32px;
          font-size: $FONT_32;
          font-family: $LATO_SEMIBOLD;
          font-weight: 600;
          font-style: normal;
          color: $TXT_LIGHTBLACK;
        }
        .closeIcon {
          padding: 44px 40px 0px 0px;
        }
      }
      .cancelTitle {
        padding: 24px 0px 0px 32px;
        font-size: $FONT_16;
        font-family: $LATO_REGULAR;
        font-weight: 400;
        font-style: normal;
        color: $TXT_NAVY;
      }
      .button {
        width: 100%;
        display: flex;
        padding: 32px 32px 32px 275px;
        .cancelButton {
          width: 100%;
          margin-right: 16px;
          .cancelBtn {
            width: 100%;
            height: 72px;
            background: $TXT_OFFWHITE;
            border: 1px solid $BG_NAVY;
            border-radius: 10px;
            color: $TXT_NAVY;
          }
        }
        .submitBtn {
          border-radius: 10px !important;
        }
      }
    }
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1660px) {
  .cancelSubscription {
    .mainCard {
      width: 60%;
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .cancelSubscription {
    .mainCard {
      width: 65%;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .cancelSubscription {
    .mainCard {
      width: 68%;
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cancelSubscription {
    .mainCard {
      width: 80%;
      .cardInner {
        .button {
          padding: 32px 64px 32px 188px;
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cancelSubscription {
    .mainCard {
      width: 90%;
      .cardInner {
        .button {
          padding: 32px 32px 32px 156px;
        }
      }
    }
  }
}
@media only screen and (min-width: 476px) and (max-width: 575px) {
  .cancelSubscription {
    .mainCard {
      width: 90%;
      .cardInner {
        .topSection {
          .closeIcon {
            padding: 24px 24px 24px 0px;
          }
        }
        .cancelTitle {
          padding: 16px 32px 0px 32px;
        }
        .button {
          padding: 32px 32px 32px 32px;
        }
      }
    }
  }
}
@media only screen and (min-width: 300px) and (max-width: 475px) {
  .cancelSubscription {
    .mainCard {
      width: 95%;
      .cardInner {
        .topSection {
          .cardTitle {
            font-size: $FONT_16;
            font-family: $LATO_BOLD;
            font-weight: 700;
          }
          .closeIcon {
            padding: 32px 32px 0px 0px;
          }
        }
        .cancelTitle {
          padding: 16px 16px 0px 32px;
        }
        .button {
          padding: 32px 32px 32px 32px;
          .cancelButton {
            margin-right: 8px;
            .cancelBtn {
              height: 48px !important;
            }
          }
          .submitBtn {
            height: 48px !important;
          }
        }
      }
    }
  }
}
