@import "../../assests/scss/variables.scss";

.admin_Subscription {
  .dropdown {
    height: 48px;
    border-radius: 8px !important;

    #basic-nav-dropdown {
      color: $TXT_WHITE;
      padding: 11px 19px;

      &:after {
        margin-left: 8px;
      }
    }
  }

  .main_Container {
    width: calc(100% - 318px);
    float: right;
    background: $BG_WHITE !important;

    .admin_Subscription_Scroll {
      position: unset !important;

      .dashboard_Section {
        // width: calc(100% - 318px);
        // float: right;
        // padding: 16px;
        // background: $BG_LIGHT !important;
        padding-left: 96px;

        .content_Header {
          padding-bottom: 24px;

          .page_Title {
            font-size: $FONT_32;
            color: $TXT_NAVY;
            padding-bottom: 4px;
          }

          .inner_Title {
            color: $LABEL_GREY;
            font-size: $FONT_16;
            padding-bottom: 0;
          }
        }

        .right_Button {
          .btn {
            height: 48px;
            border-radius: 8px !important;
          }

          .save {
            background-color: #ffffff !important;
            padding: 5px 46px !important;
            border: 1px solid !important;
            color: #16056b !important;
            width: auto;
            margin-right: 16px;
          }

          .publish {
            padding: 0 !important;
            width: 130px;
          }

          &.tab_Buttons {
            padding-top: 32px;

            .btn {
              border-radius: 0 !important;
              height: 40px !important;
              width: 150px;
              padding: 0 !important;

              &:nth-child(2) {
                background-color: $MENUBORDER !important;
                color: #16056b !important;
              }
            }
          }
        }
      }

      .divider {
        border-bottom: 1px solid $BG_LIGHTBLUE;
      }

      .input_Section {
        padding-top: 32px;
        padding-right: 16px;

        .center_Text {
          font-size: $FONT_24;
          color: $TXT_NAVY;
        }

        .box-border {
          padding: 32px 40px;

          .center_Section {
            width: 780px;

            &.paddTop {
              padding-top: 24px;
            }

            .textarea {
              margin: 24px 0;

              .form-control {
                height: 184px !important;
              }
            }

            .textField {
              .form-control {
                height: 56px;
              }
            }

            .plans_Section {
              background: $TXT_OFFWHITE;
              padding: 52px 40px;
              border-radius: 32px;

              .toggle_Section {
                display: flex;

                .plansName {
                  color: $TXT_LIGHTBLACK;
                  font-size: 24px;
                  font-family: $LATO_REGULAR;
                  padding: 0 !important;
                }

                .switch {
                  position: relative;
                  display: inline-block;
                  width: 64px;
                  height: 36px;
                  margin: 0 16px;

                  input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                  }
                }

                .slider {
                  position: absolute;
                  cursor: pointer;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background-color: #ccc;
                  -webkit-transition: 0.4s;
                  transition: 0.4s;

                  &:before {
                    position: absolute;
                    content: "";
                    height: 36px;
                    width: 36px;
                    left: 0;
                    bottom: 0;
                    background-color: $BG_WHITE;
                    transition: 0.4s;
                    border: 1px solid $TXT_BLUELIGHT;
                  }
                }

                input:checked + .slider {
                  background-color: #2196f3;
                }

                input:focus + .slider {
                  box-shadow: 0 0 1px #2196f3;
                }

                input:checked + .slider:before {
                  -webkit-transform: translateX(26px);
                  -ms-transform: translateX(26px);
                  transform: translateX(26px);
                }

                /* Rounded sliders */
                .slider.round {
                  border-radius: 34px;

                  &:before {
                    border-radius: 50%;
                  }
                }
              }

              .menu {
                display: flex;
                flex-direction: row !important;
                flex-wrap: wrap;
                border-bottom: 1px solid $TXT_BLUELIGHT;
                font-size: $FONT_24;
                margin-top: 31px;
                position: relative;

                .active {
                  text-decoration: none;
                  border-bottom: 1px solid $BG_NAVY;
                  margin-right: 48px;
                  padding-bottom: 11px;
                  color: $BG_NAVY;
                }

                .standard {
                  text-decoration: none;
                  margin-right: 48px;
                  color: $LABEL_GREY;
                }

                .professional {
                  text-decoration: none;
                  color: $LABEL_GREY;
                  margin-right: 48px;
                }
              }

              .detail {
                padding-top: 54px;

                .inner_Width {
                  width: 100%;

                  .form {
                    .colum {
                      .w-80 {
                        width: 461px;
                        padding-right: 15px;
                      }

                      .w-20 {
                        width: 144px;
                      }
                    }
                  }

                  .icon-css {
                    font-size: $FONT_20;
                    color: $TEXT_RED;
                    top: 15px;
                    right: 27px;
                  }

                  a {
                    text-decoration: none;
                    cursor: pointer;

                    .titleText {
                      font-size: $FONT_16 !important;
                      font-family: $LATO_SEMIBOLD;
                      color: $TXT_NAVY;
                    }

                    .bg_Color {
                      background: $TXT_ORANGE;
                      width: 48px;
                      height: 48px;
                      border-radius: 24px;

                      i {
                        color: $TXT_WHITE;
                        font-weight: 600;
                      }
                    }
                  }
                }
              }
            }

            .feature_Section {
              padding-top: 40px;

              .inner_Section {
                background: $TXT_BLUELIGHT;
                border-radius: 10px 10px 0 0;
                padding: 25px;

                .w-70 {
                  width: 55%;
                }

                .w-10 {
                  width: 15%;
                  padding-right: 10px;
                }

                .titleText {
                  font-size: $FONT_16;
                  color: $TXT_LIGHTBLACK;
                }

                .textField {
                  margin-bottom: 0;
                }

                .toggle_Section {
                  display: flex;
                  padding-right: 5px;

                  .plansName {
                    color: $TXT_LIGHTBLACK;
                    font-size: 16px;
                    font-family: $LATO_REGULAR;
                    padding: 0 !important;
                  }

                  .switch {
                    position: relative;
                    display: inline-block;
                    width: 54px;
                    height: 24px;
                    margin: 0 5px;

                    input {
                      opacity: 0;
                      width: 0;
                      height: 0;
                    }
                  }

                  .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ccc;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;

                    &:before {
                      position: absolute;
                      content: "";
                      height: 24px;
                      width: 24px;
                      left: 0;
                      bottom: 0;
                      background-color: $BG_WHITE;
                      transition: 0.4s;
                      border: 1px solid $TXT_BLUELIGHT;
                    }
                  }

                  input:checked + .slider {
                    background-color: #2196f3;
                  }

                  input:focus + .slider {
                    box-shadow: 0 0 1px #2196f3;
                  }

                  input:checked + .slider:before {
                    -webkit-transform: translateX(26px);
                    -ms-transform: translateX(26px);
                    transform: translateX(26px);
                  }

                  /* Rounded sliders */
                  .slider.round {
                    border-radius: 34px;

                    &:before {
                      border-radius: 50%;
                    }
                  }
                }

                .textField {
                  .form-control {
                    height: 48px;
                  }
                }

                &.lightBg {
                  background: $BG_OFFLIGHT;

                  .inner_Section {
                    border-radius: 0 !important;
                  }
                }
              }

              a {
                text-decoration: none;
                cursor: pointer;
                padding-top: 20px;

                .titleText {
                  font-size: $FONT_16 !important;
                  font-family: $LATO_SEMIBOLD;
                  color: $TXT_NAVY;
                }

                .bg_Color {
                  background: $TXT_ORANGE;
                  width: 48px;
                  height: 48px;
                  border-radius: 24px;

                  i {
                    color: $TXT_WHITE;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }

        .addCard {
          width: 155px;
          float: right;
          height: 48px;
          margin-top: 32px;
          border-radius: 8px !important;
        }
      }

      .img_Section {
        width: 40%;
        float: left;
        padding-top: 32px;
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .admin_Subscription {
    .main_Container {
      .admin_Subscription_Scroll {
        .dashboard_Section {
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .admin_Subscription {
    .main_Container {
      .admin_Subscription_Scroll {
        .dashboard_Section {
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .admin_Subscription {
    .main_Container {
      .admin_Subscription_Scroll {
        .dashboard_Section {
          .input_Section {
            padding: 25px 0;

            .box-border {
              padding: 32px 5px;

              .center_Section {
                width: 650px;

                .plans_Section {
                  padding: 52px 15px;
                }

                .feature_Section {
                  .inner_Section {
                    padding: 25px 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .admin_Subscription {
    .main_Container {
      .admin_Subscription_Scroll {
        .dashboard_Section {
          .d-flex.justify-content-between.align-items-center {
            padding-right: 18px;
          }

          .input_Section {
            padding: 25px 0;

            .box-border {
              padding: 32px 5px;

              .center_Section {
                width: 600px;

                .plans_Section {
                  padding: 52px 15px;
                }

                .feature_Section {
                  .inner_Section {
                    padding: 25px 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .dashboard_Element {
    .main_Container {
      .dashboard_Element_Scroll {
        .dashboard_Section {
          // display: none;
        }
      }
    }
  }

  .headerAdmin {
    // display: none;
  }
}
