@import "../../assests/scss/variables.scss";

.signup_Page {
  position: relative;
  width: 100%;
  height: 100%;
  float: left;
  background: $BG_LIGHT;
  // z-index: -1;
  z-index: 1;

  .chatboat-section {
    bottom: 10px;
    right: 25px;
    width: 66px;
    height: 66px;
    background: $BG_NAVY;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
      color: $BG_WHITE;
    }
  }

  .top_Patten {
    position: absolute;
    right: 0;
    z-index: -1;
  }

  .bottom_Patten {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }

  .scroll_Section {
    height: 100%;
  }

  .center_Section {
    display: flex;
    padding: 0 174px 0 311px;
    align-items: center;
    height: 100vh;

    .img_Section {
      width: 100%;

      .main_Img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }

    .signup_Box {
      margin-left: 260px;
      margin-top: 55px;
      max-width: 525px;
      width: 100%;
      background: $BG_LIGHT;
      padding: 32px;
      display: block;
      border-radius: 8px;
      -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);

      h1 {
        font-family: $LATO_BOLD;
        font-size: 48px;
        text-align: left;
        color: $TXT_ORANGE;
        line-height: 68px;
        margin: 0;
        padding: 0;
      }

      .inner_Text {
        font-family: $LATO_LIGHT;
        font-size: 32px;
        margin: 0;
        text-align: left;
        line-height: 48px;
        color: $TXT_NAVY;
        font-weight: 300;
        padding-bottom: 24px;
      }

      .link_Section {
        display: flex;
        align-items: center;
        padding-bottom: 24px;

        .form_Name {
          font-family: $LATO_BOLD;
          font-size: 24px;
          margin: 0;
          text-align: left;
          line-height: 36px;
          color: $TXT_LIGHTBLUE;
          padding: 0;
          padding-right: 5px;
        }

        .link_Css {
          color: $TXT_BLUE;
          font-family: $LATO_BOLD;
          font-size: 24px;
          text-decoration: none;
        }
      }

      .click_Action {
        text-align: left;
        color: $TXT_NAVY;
        font-size: $FONT_14;
        font-family: $LATO_REGULAR !important;
        font-weight: 400;
        padding-bottom: 24px;
        line-height: 22px;

        .color {
          color: $TXT_ORANGE;
        }

        .link {
          color: $TXT_LIGHTBLUE;
          font-style: italic;
          text-decoration: underline;
        }
      }
    }
  }
}

@media only screen and (min-width: 1279px) and (max-width: 1700px) {
  .signup_Page {
    .center_Section {
      .signup_Box {
        margin-top: 18px;

        h1 {
          font-size: 42px;
          line-height: 52px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .signup_Page {
    .center_Section {
      padding: 0 100px;
      align-items: center;
      height: 100vh;

      .img_Section {
        .main_Img {
          max-width: 100%;
        }
      }

      .signup_Box {
        margin-left: 130px;
        margin-top: 0;

        h1 {
          font-size: $FONT_36;
          line-height: 50px;
        }

        .inner_Text {
          font-size: $FONT_22;
          padding-bottom: 15px;
        }

        .link_Section {
          .form_Name {
            font-size: $FONT_14;
          }

          .link_Css {
            font-size: $FONT_14;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .signup_Page {
    .center_Section {
      padding: 0 80px;
      align-items: center;
      height: 100vh;

      .img_Section {
        .main_Img {
          max-width: 80%;
        }
      }

      .signup_Box {
        margin-left: 100px;
        margin-top: 0;

        h1 {
          font-size: $FONT_26;
          line-height: 50px;
        }

        .inner_Text {
          font-size: $FONT_22;
          padding-bottom: 15px;
        }

        .link_Section {
          .form_Name {
            font-size: $FONT_14;
          }

          .link_Css {
            font-size: $FONT_14;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .signup_Page {
    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }

    .center_Section {
      padding: 125px 0;
      justify-content: center;
      align-items: center;

      .img_Section {
        display: none;
      }

      .signup_Box {
        margin-left: 0;
        margin-top: 200px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .signup_Page {
    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }

    .center_Section {
      padding: 10px 0;
      justify-content: center;
      align-items: flex-start;
      align-self: center;
      height: 100%;

      .img_Section {
        display: none;
      }

      .signup_Box {
        margin-left: 0;
        margin-top: 0;
        align-self: center;

        h1 {
          line-height: 72px;
        }

        .inner_Text {
          font-size: $FONT_22;
        }

        .link_Section {
          .form_Name {
            font-size: $FONT_14;
          }

          .link_Css {
            font-size: $FONT_14;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .signup_Page {
    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }

    .center_Section {
      padding: 10px 0;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .img_Section {
        display: none;
      }

      .signup_Box {
        margin-left: 0;
        margin: 0;
        margin-bottom: 20px;

        h1 {
          font-size: $FONT_36;
          line-height: 40px;
        }

        .inner_Text {
          font-size: $FONT_22;
        }

        .link_Section {
          .form_Name {
            font-size: $FONT_14;
          }

          .link_Css {
            font-size: $FONT_14;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .signup_Page {
    .top_Patten {
      height: 216px;
    }

    .bottom_Patten {
      height: 216px;
    }

    .center_Section {
      padding: 10px 24px;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .img_Section {
        display: none;
      }

      .signup_Box {
        margin: 0;
        padding: 32px 16px;
        margin-bottom: 20px;

        h1 {
          font-size: $FONT_26;
        }

        .inner_Text {
          font-size: $FONT_22;
        }

        .link_Section {
          .form_Name {
            font-size: $FONT_14;
          }

          .link_Css {
            font-size: $FONT_14;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .signup_Page {
    .top_Patten {
      height: 216px;
    }

    .bottom_Patten {
      height: 216px;
    }

    .center_Section {
      padding: 38px 8px 0 12px;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .img_Section {
        display: none;
      }

      .signup_Box {
        margin: 0;
        padding: 15px 10px;
        margin-bottom: 20px;

        h1 {
          font-size: $FONT_20;
          line-height: 25px;
        }

        .inner_Text {
          font-size: $FONT_20;
          padding-bottom: 14px;
        }

        .link_Section {
          .form_Name {
            font-size: $FONT_14;
          }

          .link_Css {
            font-size: $FONT_14;
          }
        }
      }
    }
  }
}
