@import "../../../assests/scss/variables.scss";
.titleText {
  font-size: $FONT_12;
  color: $TXT_BLUE;
  text-align: left;
  padding-bottom: 24px;
  font-family: $LATO_BOLD;
  margin: 0;
  display: table;
  width: auto;
}
