@import "../../assests/scss/variables.scss";

.terms_Page {
  .blogScroll {
    height: calc(100vh - 150px) !important;
    height: 100vh !important;
    position: relative;

    .main_Banner {
      height: 688px;
      display: flex;
      background: $TXT_NAVY;
      padding: 0 149px;
      align-items: center;
      justify-content: space-between;

      .banner_Heading {
        width: 600px;

        .banner_Title {
          font-size: $FONT_36;
          font-family: $LATO_SEMIBOLD;
          padding-bottom: 16px;
          color: $TXT_WHITE;
        }

        .banner_Title1 {
          font-size: $FONT_24;
          padding-bottom: 16px;
          color: $TXT_WHITE;
        }

        .banner_Title2 {
          font-size: $FONT_20;
          padding-bottom: 16px;
          text-decoration: underline;
          color: $TXT_WHITE;
        }
      }

      .img_Section {
        width: 37%;

        img {
          width: 100%;
        }
      }
    }

    .center_Section {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 11;
      padding: 0 148px;

      .inner_Section {
        margin-top: -85px;
        width: 100%;
        background: $BG_LIGHT;
        padding: 80px 56px;
        border-radius: 8px;
        -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
        padding-bottom: 40px;

        .inner_Scroll {
          height: calc(100vh - 200px);
          overflow-y: auto;
        }

        .heading {
          color: $TXT_ORANGE;
          font-family: $LATO_BOLD;
          font-size: $FONT_48;
        }

        .inner_Text {
          font-family: $LATO_REGULAR;
          font-size: $FONT_24;
          margin: 0;
          text-align: left;
          color: $TXT_DARKBLACK;
          font-weight: 600;
          line-height: 36px;
          padding-bottom: 32px;
          letter-spacing: 1px;

          &:last-child {
            padding-bottom: 0;
          }
        }

        p::first-letter {
          text-transform: uppercase;
          font-size: $FONT_48;
          font-family: $LATO_BOLD;
        }
      }

      .apply-Btn {
        padding-top: 40px;

        button {
          width: 230px;

          &:nth-child(2) {
            background: transparent !important;
            border: 1px solid $TXT_NAVY !important;
            color: $TXT_NAVY !important;
            margin-left: 25px;
          }
        }
      }
    }

    .image_Section {
      padding: 0 40px;
      padding-bottom: 200px !important;

      .titleText {
        display: flex;
        justify-content: center;
        font-size: $FONT_48;
        font-family: $LATO_BOLD;
        color: $TXT_NAVY;
        padding-bottom: 80px;
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .terms_Page {
    .blogScroll {
      height: calc(100vh - 150px) !important;
      height: 100vh !important;
      position: relative;

      .main_Banner {
        height: 688px;
        display: flex;
        background: $TXT_NAVY;
        padding: 0 149px;
        align-items: center;
        justify-content: space-between;

        .banner_Heading {
          width: 600px;

          .banner_Title {
            font-size: $FONT_36;
            font-family: $LATO_SEMIBOLD;
            padding-bottom: 16px;
            color: $TXT_WHITE;
          }

          .banner_Title1 {
            font-size: $FONT_24;
            padding-bottom: 16px;
            color: $TXT_WHITE;
          }

          .banner_Title2 {
            font-size: $FONT_20;
            padding-bottom: 16px;
            text-decoration: underline;
            color: $TXT_WHITE;
          }
        }

        .img_Section {
          width: 37%;

          img {
            width: 100%;
          }
        }
      }

      .center_Section {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 11;
        padding: 0 148px;

        .inner_Section {
          margin-top: -85px;
          width: 100%;
          background: $BG_LIGHT;
          padding: 80px 56px;
          border-radius: 8px;
          -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
          -moz-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);

          .heading {
            color: $TXT_ORANGE;
            font-family: $LATO_BOLD;
            font-size: $FONT_48;
          }

          .inner_Text {
            font-family: $LATO_REGULAR;
            font-size: $FONT_24;
            margin: 0;
            text-align: left;
            color: $TXT_DARKBLACK;
            font-weight: 600;
            line-height: 36px;
            padding-bottom: 32px;
            letter-spacing: 1px;

            &:last-child {
              padding-bottom: 0;
            }
          }

          p::first-letter {
            text-transform: uppercase;
            font-size: $FONT_48;
            font-family: $LATO_BOLD;
          }
        }

        .apply-Btn {
          button {
            width: 230px;

            &:nth-child(2) {
              background: transparent !important;
              border: 1px solid $TXT_NAVY !important;
              color: $TXT_NAVY !important;
              margin-left: 25px;
            }
          }
        }
      }

      .image_Section {
        padding: 0 40px;
        padding-bottom: 200px !important;

        .titleText {
          display: flex;
          justify-content: center;
          font-size: $FONT_48;
          font-family: $LATO_BOLD;
          color: $TXT_NAVY;
          padding-bottom: 80px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .terms_Page {
    .blogScroll {
      height: calc(100vh - 150px) !important;
      height: 100vh !important;
      position: relative;

      .main_Banner {
        height: 688px;
        display: flex;
        background: $TXT_NAVY;
        padding: 0 149px;
        align-items: center;
        justify-content: space-between;

        .banner_Heading {
          width: 600px;

          .banner_Title {
            font-size: $FONT_36;
            font-family: $LATO_SEMIBOLD;
            padding-bottom: 16px;
            color: $TXT_WHITE;
          }

          .banner_Title1 {
            font-size: $FONT_24;
            padding-bottom: 16px;
            color: $TXT_WHITE;
          }

          .banner_Title2 {
            font-size: $FONT_20;
            padding-bottom: 16px;
            text-decoration: underline;
            color: $TXT_WHITE;
          }
        }

        .img_Section {
          width: 37%;

          img {
            width: 100%;
          }
        }
      }

      .center_Section {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 11;
        padding: 0 148px;

        .inner_Section {
          margin-top: -85px;
          width: 100%;
          background: $BG_LIGHT;
          padding: 80px 56px;
          border-radius: 8px;
          -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
          -moz-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);

          .heading {
            color: $TXT_ORANGE;
            font-family: $LATO_BOLD;
            font-size: $FONT_48;
          }

          .inner_Text {
            font-family: $LATO_REGULAR;
            font-size: $FONT_24;
            margin: 0;
            text-align: left;
            color: $TXT_DARKBLACK;
            font-weight: 600;
            line-height: 36px;
            padding-bottom: 32px;
            letter-spacing: 1px;

            &:last-child {
              padding-bottom: 0;
            }
          }

          p::first-letter {
            text-transform: uppercase;
            font-size: $FONT_48;
            font-family: $LATO_BOLD;
          }
        }

        .apply-Btn {
          button {
            width: 230px;

            &:nth-child(2) {
              background: transparent !important;
              border: 1px solid $TXT_NAVY !important;
              color: $TXT_NAVY !important;
              margin-left: 25px;
            }
          }
        }
      }

      .image_Section {
        padding: 0 40px;
        padding-bottom: 200px !important;

        .titleText {
          display: flex;
          justify-content: center;
          font-size: $FONT_48;
          font-family: $LATO_BOLD;
          color: $TXT_NAVY;
          padding-bottom: 80px;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .terms_Page {
    .blogScroll {
      .main_Banner {
        padding: 0 50px;

        .banner_Heading {
          width: 500px;

          .banner_Title {
            font-size: $FONT_36;
            font-family: $LATO_SEMIBOLD;
            padding-bottom: 16px;
            color: $TXT_WHITE;
          }

          .banner_Title1 {
            font-size: $FONT_24;
            padding-bottom: 16px;
            color: $TXT_WHITE;
          }

          .banner_Title2 {
            font-size: $FONT_20;
            padding-bottom: 16px;
            text-decoration: underline;
            color: $TXT_WHITE;
          }
        }

        .img_Section {
          width: 37%;

          img {
            width: 100%;
          }
        }
      }
    }

    .center_Section {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 11;
      padding: 0 148px;

      .inner_Section {
        margin-top: -85px;
        width: 100%;
        background: $BG_LIGHT;
        padding: 80px 56px;
        border-radius: 8px;
        -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);

        .heading {
          color: $TXT_ORANGE;
          font-family: $LATO_BOLD;
          font-size: $FONT_48;
        }

        .inner_Text {
          font-family: $LATO_REGULAR;
          font-size: $FONT_24;
          margin: 0;
          text-align: left;
          color: $TXT_DARKBLACK;
          font-weight: 600;
          line-height: 36px;
          padding-bottom: 32px;
          letter-spacing: 1px;

          &:last-child {
            padding-bottom: 0;
          }
        }

        p::first-letter {
          text-transform: uppercase;
          font-size: $FONT_48;
          font-family: $LATO_BOLD;
        }
      }

      .apply-Btn {
        button {
          width: 230px;

          &:nth-child(2) {
            background: transparent !important;
            border: 1px solid $TXT_NAVY !important;
            color: $TXT_NAVY !important;
            margin-left: 25px;
          }
        }
      }
    }

    .image_Section {
      padding: 0 40px;
      padding-bottom: 200px !important;

      .titleText {
        display: flex;
        justify-content: center;
        font-size: $FONT_48;
        font-family: $LATO_BOLD;
        color: $TXT_NAVY;
        padding-bottom: 80px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .terms_Page {
    .blogScroll {
      .main_Banner {
        padding: 0 100px;

        .banner_Heading {
          .banner_Title1 {
            font-size: $FONT_24;
            padding-bottom: 16px;
            color: $TXT_WHITE;
          }

          .banner_Title2 {
            font-size: $FONT_20;
            padding-bottom: 16px;
            text-decoration: underline;
            color: $TXT_WHITE;
          }
        }

        .img_Section {
          width: 37%;

          img {
            width: 100%;
          }
        }
      }

      .center_Section {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 11;
        padding: 0 50px;

        .inner_Section {
          margin-top: -85px;
          width: 100%;
          background: $BG_LIGHT;
          padding: 80px 56px;
          border-radius: 8px;
          -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
          -moz-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);

          .heading {
            color: $TXT_ORANGE;
            font-family: $LATO_BOLD;
            font-size: $FONT_48;
          }

          .inner_Text {
            font-family: $LATO_REGULAR;
            font-size: $FONT_24;
            margin: 0;
            text-align: left;
            color: $TXT_DARKBLACK;
            font-weight: 600;
            line-height: 36px;
            padding-bottom: 32px;
            letter-spacing: 1px;

            &:last-child {
              padding-bottom: 0;
            }
          }

          p::first-letter {
            text-transform: uppercase;
            font-size: $FONT_48;
            font-family: $LATO_BOLD;
          }
        }

        .apply-Btn {
          button {
            width: 230px;

            &:nth-child(2) {
              background: transparent !important;
              border: 1px solid $TXT_NAVY !important;
              color: $TXT_NAVY !important;
              margin-left: 25px;
            }
          }
        }
      }

      .image_Section {
        padding: 0 40px;
        padding-bottom: 200px !important;

        .titleText {
          display: flex;
          justify-content: center;
          font-size: $FONT_48;
          font-family: $LATO_BOLD;
          color: $TXT_NAVY;
          padding-bottom: 80px;
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .terms_Page {
    .blogScroll {
      .main_Banner {
        height: 550px;
        display: flex;
        background: $TXT_NAVY;
        padding: 0 50px;
        align-items: center;
        justify-content: space-between;

        .banner_Heading {
          width: 600px;

          .banner_Title {
            font-size: $FONT_36;
            font-family: $LATO_SEMIBOLD;
            padding-bottom: 16px;
            color: $TXT_WHITE;
          }

          .banner_Title1 {
            font-size: $FONT_24;
            padding-bottom: 16px;
            color: $TXT_WHITE;
          }

          .banner_Title2 {
            font-size: $FONT_20;
            padding-bottom: 16px;
            text-decoration: underline;
            color: $TXT_WHITE;
          }
        }

        .img_Section {
          width: 37%;
          display: none;

          img {
            width: 100%;
          }
        }
      }

      .center_Section {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 11;
        padding: 0 10px;

        .inner_Section {
          margin-top: -85px;
          padding: 30px 20px;

          .heading {
            color: $TXT_ORANGE;
            font-family: $LATO_BOLD;
            font-size: $FONT_48;
          }

          .inner_Text {
            font-family: $LATO_REGULAR;
            font-size: $FONT_24;
            margin: 0;
            text-align: left;
            color: $TXT_DARKBLACK;
            font-weight: 600;
            line-height: 36px;
            padding-bottom: 32px;
            letter-spacing: 1px;

            &:last-child {
              padding-bottom: 0;
            }
          }

          p::first-letter {
            text-transform: uppercase;
            font-size: $FONT_48;
            font-family: $LATO_BOLD;
          }
        }

        .apply-Btn {
          button {
            width: 230px;

            &:nth-child(2) {
              background: transparent !important;
              border: 1px solid $TXT_NAVY !important;
              color: $TXT_NAVY !important;
              margin-left: 25px;
            }
          }
        }
      }

      .image_Section {
        padding: 0 40px;
        padding-bottom: 200px !important;

        .titleText {
          display: flex;
          justify-content: center;
          font-size: $FONT_48;
          font-family: $LATO_BOLD;
          color: $TXT_NAVY;
          padding-bottom: 80px;
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .terms_Page {
    .blogScroll {
      .main_Banner {
        height: 550px;
        display: flex;
        background: $TXT_NAVY;
        padding: 0 50px;
        align-items: center;
        justify-content: space-between;

        .banner_Heading {
          width: 600px;

          .banner_Title {
            font-size: $FONT_36;
            font-family: $LATO_SEMIBOLD;
            padding-bottom: 16px;
            color: $TXT_WHITE;
          }

          .banner_Title1 {
            font-size: $FONT_24;
            padding-bottom: 16px;
            color: $TXT_WHITE;
          }

          .banner_Title2 {
            font-size: $FONT_20;
            padding-bottom: 16px;
            text-decoration: underline;
            color: $TXT_WHITE;
          }
        }

        .img_Section {
          width: 37%;
          display: none;

          img {
            width: 100%;
          }
        }
      }

      .center_Section {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 11;
        padding: 0 10px;

        .inner_Section {
          margin-top: -85px;
          padding: 30px 20px;

          .heading {
            color: $TXT_ORANGE;
            font-family: $LATO_BOLD;
            font-size: $FONT_48;
          }

          .inner_Text {
            font-family: $LATO_REGULAR;
            font-size: $FONT_24;
            margin: 0;
            text-align: left;
            color: $TXT_DARKBLACK;
            font-weight: 600;
            line-height: 36px;
            padding-bottom: 32px;
            letter-spacing: 1px;

            &:last-child {
              padding-bottom: 0;
            }
          }

          p::first-letter {
            text-transform: uppercase;
            font-size: $FONT_48;
            font-family: $LATO_BOLD;
          }
        }

        .apply-Btn {
          button {
            width: 230px;

            &:nth-child(2) {
              background: transparent !important;
              border: 1px solid $TXT_NAVY !important;
              color: $TXT_NAVY !important;
              margin-left: 25px;
            }
          }
        }
      }

      .image_Section {
        padding: 0 40px;
        padding-bottom: 200px !important;

        .titleText {
          display: flex;
          justify-content: center;
          font-size: $FONT_48;
          font-family: $LATO_BOLD;
          color: $TXT_NAVY;
          padding-bottom: 80px;
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .terms_Page {
    .blogScroll {
      .main_Banner {
        height: 550px;
        display: flex;
        background: $TXT_NAVY;
        padding: 0 20px;
        align-items: center;
        justify-content: space-between;

        .banner_Heading {
          width: 600px;

          .banner_Title {
            font-size: $FONT_36;
            font-family: $LATO_SEMIBOLD;
            padding-bottom: 16px;
            color: $TXT_WHITE;
          }

          .banner_Title1 {
            font-size: $FONT_24;
            padding-bottom: 16px;
            color: $TXT_WHITE;
          }

          .banner_Title2 {
            font-size: $FONT_20;
            padding-bottom: 16px;
            text-decoration: underline;
            color: $TXT_WHITE;
          }
        }

        .img_Section {
          width: 37%;
          display: none;

          img {
            width: 100%;
          }
        }
      }

      .center_Section {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 11;
        padding: 0 10px;

        .inner_Section {
          margin-top: -85px;
          padding: 30px 20px;

          .heading {
            color: $TXT_ORANGE;
            font-family: $LATO_BOLD;
            font-size: $FONT_30;
          }

          .inner_Text {
            font-family: $LATO_REGULAR;
            font-size: $FONT_20;
            margin: 0;
            text-align: left;
            color: $TXT_DARKBLACK;
            font-weight: 600;
            line-height: 36px;
            padding-bottom: 32px;
            letter-spacing: 1px;

            &:last-child {
              padding-bottom: 0;
            }
          }

          p::first-letter {
            text-transform: uppercase;
            font-size: $FONT_48;
            font-family: $LATO_BOLD;
          }
        }

        .apply-Btn {
          button {
            width: 230px;

            &:nth-child(2) {
              background: transparent !important;
              border: 1px solid $TXT_NAVY !important;
              color: $TXT_NAVY !important;
              margin-left: 25px;
            }
          }
        }
      }

      .image_Section {
        padding: 0 40px;
        padding-bottom: 200px !important;

        .titleText {
          display: flex;
          justify-content: center;
          font-size: $FONT_48;
          font-family: $LATO_BOLD;
          color: $TXT_NAVY;
          padding-bottom: 80px;
        }
      }
    }

    .footer_Section {
      .bottom_Section {
        padding-bottom: 100px !important;
      }
    }
  }
}
