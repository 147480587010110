@import "../../assests/scss/variables.scss";

.CreateGroup_Page {
  overflow: hidden;

  .main_Container {
    .CreateGroupScroll {
      position: unset !important;
      text-align: left;

      .top_title {
        .titleText1 {
          margin-left: 135px;
          font-size: $FONT_32;
          font-style: normal;
          color: $TXT_NAVY;
          margin-top: 40px;
          font-family: $LATO_BOLD;
          line-height: 48px;
        }

        .titleText2 {
          margin-left: 135px;
          font-size: $FONT_16;
          font-style: normal;
          font-weight: normal;
          font-family: $LATO_REGULAR;
          color: $TXT_LIGHTGREY;
          line-height: 24px;
          margin-top: -15px;
        }

        .border {
          border-bottom: 1px solid $TXT_BLUELIGHT;
          margin-left: 96px;
        }
      }

      .middle_section {
        display: flex;
        flex-wrap: wrap;
        margin-left: 96px;
        height: 100vh;

        .middle_section_left {
          width: 50%;

          .middle_image {
            display: flex;
            justify-content: center;
            margin-top: 76px;

            .inner_Div {
              border-radius: 50%;
              position: relative;

              .Edit {
                background: white;
                position: absolute;
                right: 20px;
                cursor: pointer;
                top: 18px;
                border-radius: 50%;
                height: 25px;
                width: 25px;
              }

              .icon-edit1:before {
                padding-top: 8px;
                padding-left: 8px;
              }

              .middle_Img {
                margin: auto;
                display: block;
                border-radius: 50%;
                height: 150px;
                width: 150px;
                cursor: pointer;
              }
            }

            .middle_Img {
              margin: auto;
              display: block;
              border-radius: 50%;
              height: 150px;
              width: 150px;
              cursor: pointer;
            }
          }

          .form1 {
            .textField {
              padding: 24px;
              margin: 0;

              .form-control {
                border-radius: 10px;
                margin-bottom: -24px;
                border: 1px solid $TXT_LIGHTGREY;
              }
            }

            textarea {
              height: 175px;

              .form-control {
                border-radius: 10px;
              }
            }
            .button {
              display: flex;
              width: 50%;
              float: right;
              justify-content: space-between;
              margin-right: 30px;
  
              .btn1 {
                margin: 27px 15px 50px 0px;
                width: 50%;
                border-radius: 10px;
  
                .submitBtn {
                  background: $TXT_OFFWHITE !important;
                  color: $TXT_NAVY !important;
                  height: 56px;
                  border: 1px solid $TXT_NAVY !important;
                }
              }
  
              .btn2 {
                margin: 27px 0px 50px 0px;
                width: 50%;
  
                .submitBtn {
                  height: 56px;
                }
              }
            }
          }
        }

        .middle_section_right {
          width: 50%;
          background-color: $BG_OFFWHITE;
          height: 100vh;

          .link {
            margin-left: 24px;
            line-height: 32px;

            .nav-tabs .nav-item.show .nav-link,
            .nav-tabs .nav-link.active {
              color: $TXT_NAVY !important;
              background-color: $BG_OFFWHITE !important;
              border-color: #dee2e6 #dee2e6 #fff;
              border-bottom: 1px solid $TXT_NAVY;
            }

            .nav-link {
              font-size: $FONT_24;
              color: $TXT_NAVY !important;
            }
          }

          .middle_dropdown {
            display: flex;
            justify-content: space-between;
            margin-top: 0px;
            margin-left: 32px;

            .dropdown_Part {
              .search_Box {
                // .icon-search{
                //     position: relative !important;
                //     top: 36px !important;
                // }
                .search_Txt {
                  width: 212%;
                }
              }

              i.icon-lock {
                position: relative;
                bottom: 33px;
                left: 364px;
              }
            }

            .middle_select_all {
              display: flex;
              justify-content: space-evenly;
              margin-top: 36px;
              margin-right: 48px;

              .select {
                margin-right: 12px;
                font-size: $FONT_14;
                line-height: 24px;
                font-weight: normal;
                font-family: $LATO_REGULAR;
                font-style: normal;
                color: $TXT_LIGHTBLACK;
              }

              .checkboxall {
                margin-right: -15px;
              }
            }
          }

          .middle_main_box {
            display: flex;
            flex-wrap: wrap;
            height: calc(100vh - 450px) !important;
            overflow-y: auto;

            .main_box1 {
              margin-left: 10px;
              margin-top: 16px;
              padding: 16px;
              // background-color: $TXT_BLUELIGHT;

              .inner_box1 {
                // background-color: $BG_LIGHT;
                border-radius: 10px;
                overflow-y: scroll;
                // height: calc(100vh - 326px);
                display: flex;
                flex-wrap: wrap;

                .content1 {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding-bottom: 15px;

                  .content_inner1 {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .checkboxMain {
                      padding-right: 8px;
                    }

                    .profile_Img {
                      // padding: 33px 0px 8px 23px;
                      width: 32px;
                      height: 32px;
                    }

                    .profile_name {
                      font-size: $FONT_14;
                      font-family: $LATO_REGULAR;
                      font-weight: normal;
                      font-style: normal;
                      padding-left: 8px;
                      padding-right: 64px;
                      color: #14142b;
                      padding-bottom: 0;
                      // padding: 33px 0px 8px 15px;
                    }
                  }

                  .checkboxMain {
                    // margin: 32px 15px 0px 0px;
                  }
                }

                .box_border1 {
                  // border: 1px solid $TXT_BLUELIGHT;
                  // margin: 0px 16px -16px 16px;
                }
              }
            }
          }

        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .CreateGroup_Page {
    .main_Container {
      .CreateGroupScroll {
        .middle_section {
          .middle_section_right {
            .middle_dropdown {
              .dropdown_Part {
                .search_Box {
                  .search_Txt {
                    width: 212%;
                  }
                }

                i.icon-lock {
                  position: relative;
                  bottom: 33px;
                  left: 364px;
                }
              }

              .middle_select_all {
                margin-right: 34px;

                .checkboxall {
                  margin-right: 8px;
                }
              }
            }

            .middle_main_box {
              .main_box1 {
                width: 85%;
              }
            }

            .button {
              width: 80%;
              margin-right: 48px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .CreateGroup_Page {
    .main_Container {
      .CreateGroupScroll {
        .top_title {
          margin-left: 24px;

          .titleText1 {
            margin-left: 96px;
          }

          .titleText2 {
            margin-left: 96px;
          }
        }

        .middle_section {
          .middle_section_left {
            .middle_image {
              margin-left: 0px;
            }

            .middle_input {
              width: 97%;
              margin-left: 0px;
            }

            .text_area {
              width: 97%;
              margin-left: 0px;
            }
          }

          .middle_section_right {
            .middle_dropdown {
              .dropdown_Part {
                .search_Box {
                  // .icon-search {
                  //     position: relative !important;
                  //     top: 36px !important;
                  // }

                  .search_Txt {
                    width: 160%;
                  }
                }

                i.icon-lock {
                  position: relative;
                  bottom: 33px;
                  left: 272px;
                }
              }

              .middle_select_all {
                margin-right: 32px;
              }
            }

            .middle_main_box {
              .main_box1 {
                width: 95%;
                margin-left: 15px;
              }
            }

            .button {
              width: 85%;
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .CreateGroup_Page {
    .main_Container {
      .CreateGroupScroll {
        .top_title {
          margin-left: 24px;

          .titleText1 {
            margin-left: 96px;
          }

          .titleText2 {
            margin-left: 96px;
          }
        }

        .middle_section {
          .middle_section_left {
            width: 100%;

            .middle_image {
              margin-left: 0px;
            }

            .middle_input {
              width: 97%;
              margin-left: 0px;
            }

            .text_area {
              width: 97%;
              margin-left: 0px;
            }
          }

          .middle_section_right {
            width: 95%;
            margin-top: 30px;
            margin-right: 15px;
            margin-left: 24px;

            .middle_dropdown {
              .dropdown_Part {
                .search_Box {
                  // .icon-search {
                  //     position: relative !important;
                  //     top: 36px !important;
                  // }

                  .search_Txt {
                    width: 218%;
                  }
                }

                i.icon-lock {
                  position: relative;
                  bottom: 33px;
                  left: 382px;
                }
              }

              .middle_select_all {
                margin-right: 72px;
              }
            }

            .middle_main_box {
              .main_box1 {
                width: 85%;
              }
            }

            .button {
              width: 64%;
              margin-right: 64px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .CreateGroup_Page {
    .main_Container {
      .CreateGroupScroll {
        .top_title {
          margin-left: 24px;

          .titleText1 {
            margin-left: 96px;
          }

          .titleText2 {
            margin-left: 96px;
          }
        }

        .middle_section {
          .middle_section_left {
            width: 100%;

            .middle_image {
              margin-left: 0px;
            }

            .middle_input {
              width: 97%;
              margin-left: 0px;
            }

            .text_area {
              width: 97%;
              margin-left: 0px;
            }
          }

          .middle_section_right {
            width: 100%;
            margin-top: 30px;
            margin-right: 16px;
            margin-left: 24px;

            .middle_dropdown {
              .dropdown_Part {
              }

              .middle_select_all {
                margin-right: 48px;
              }
            }

            .middle_main_box {
              // margin-right: -25px;
              // margin-left: -15px;

              .main_box1 {
                width: 85%;
              }
            }

            .button {
              width: 80%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .CreateGroup_Page {
    .main_Container {
      .CreateGroupScroll {
        .top_title {
          margin-left: 24px;

          .titleText1 {
            margin-left: 96px;
          }

          .titleText2 {
            margin-left: 96px;
          }
        }

        .middle_section {
          .middle_section_left {
            width: 90%;

            .middle_image {
              margin-left: 0px;
            }

            .middle_input {
              width: 97%;
              margin-left: 0px;
            }

            .text_area {
              width: 97%;
              margin-left: 0px;
            }
          }

          .middle_section_right {
            width: 85%;
            margin-top: 30px;
            margin-right: 16px;
            margin-left: 24px;

            .middle_dropdown {
              .dropdown_Part {
              }

              .middle_select_all {
                //  margin-right: 48px;
              }
            }

            .middle_main_box {
              // margin-right: -25px;
              // margin-left: -15px;
              background: $BG_OFFLIGHT;

              .main_box1 {
                width: 85%;
              }
            }

            .button {
              width: 91%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .CreateGroup_Page {
    .main_Container {
      .CreateGroupScroll {
        .top_title {
          margin-left: 24px;

          .titleText1 {
            margin-left: 96px;
          }

          .titleText2 {
            margin-left: 96px;
          }
        }

        .middle_section {
          .middle_section_left {
            width: 100%;

            .middle_image {
              margin-left: 0px;
            }

            .middle_input {
              width: 97%;
              margin-left: 0px;
            }

            .text_area {
              width: 97%;
              margin-left: 0px;
            }
          }

          .middle_section_right {
            width: 100%;
            margin-top: 30px;
            margin-right: 16px;
            margin-left: 16px;

            .middle_dropdown {
              .dropdown_Part {
                .search_Box {
                  // .icon-search {
                  //     position: relative !important;
                  //     top: 36px !important;
                  // }

                  .search_Txt {
                    width: 150%;
                  }
                }

                i.icon-lock {
                  position: relative;
                  bottom: 33px;
                  left: 255px;
                }
              }

              .middle_select_all {
                margin-right: 35px;
              }
            }

            .middle_main_box {
              margin-right: -15px;
              margin-left: -15px;

              .main_box1 {
                width: 82%;
              }
            }

            .button {
              width: 80%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .CreateGroup_Page {
    .main_Container {
      .CreateGroupScroll {
        .top_title {
          margin-left: 0px;
          margin-top: 68px;

          .titleText1 {
            margin-left: 30px;
          }

          .titleText2 {
            margin-left: 30px;
          }

          .border {
            margin: 0px 10px 0px 32px;
          }
        }

        .middle_section {
          margin-left: 0px;

          .middle_section_left {
            width: 100%;

            .middle_input {
              width: 90%;
              margin-left: 25px;
            }

            .text_area {
              width: 90%;
              margin-left: 25px;
            }
          }

          .middle_section_right {
            width: 100%;
            margin-top: 30px;

            .middle_dropdown {
              .dropdown_Part {
                .search_Box {
                  // .icon-search {
                  //     position: relative !important;
                  //     top: 36px !important;
                  // }

                  .search_Txt {
                    width: 150%;
                  }
                }

                i.icon-lock {
                  position: relative;
                  bottom: 33px;
                  left: 255px;
                }
              }

              .middle_select_all {
                margin-right: 45px;
              }
            }

            .middle_main_box {
              margin-left: -25px;

              .main_box1 {
                width: 85%;
              }
            }

            .button {
              margin-right: 20px;
              width: 80%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .CreateGroup_Page {
    .main_Container {
      width: calc(100% - 76px);

      .CreateGroupScroll {
        .top_title {
          margin-left: -10px;
          margin-top: 68px;

          .titleText1 {
            margin-left: 30px;
          }

          .titleText2 {
            margin-left: 30px;
          }

          .border {
            margin: 0px 10px 0px 32px;
          }
        }

        .middle_section {
          margin-left: -10px;

          .middle_section_left {
            width: 100%;

            .middle_input {
              width: 90%;
              margin-left: 25px;
            }

            .text_area {
              width: 90%;
              margin-left: 25px;
            }
          }

          .middle_section_right {
            width: 100%;
            margin-top: 30px;

            .link .nav-link {
              font-size: $FONT_16;
            }

            .middle_dropdown {
              margin-left: 23px;

              .dropdown_Part {
                .search_Box {
                  // .icon-search {
                  //     position: relative !important;
                  //     top: 36px !important;
                  // }

                  .search_Txt {
                    font-size: $FONT_12;
                    width: 146%;
                  }
                }

                i.icon-lock {
                  position: relative;
                  bottom: 33px;
                  left: 220px;
                }
              }

              .middle_select_all {
                margin-right: 30px;

                .select {
                  margin-right: 8px;
                }
              }
            }

            .middle_main_box {
              margin-left: -30px;

              .main_box1 {
                width: 85%;

                .inner_box1 {
                  // height: calc(100vh - 336px);
                }
              }
            }

            .button {
              margin-right: 20px;
              width: 80%;
            }
          }
        }
      }
    }
  }

  .chatboat-section {
    bottom: 10px;
    right: 25px;
    width: 66px;
    height: 66px;
    background: $BG_NAVY;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
      color: $BG_WHITE;
    }
  }
}
