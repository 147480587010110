@import "../../assests/scss/variables.scss";

.social_profile_Page {
  .main_Container {
    .chatboat-section {
      bottom: 2px;
      right: 25px;
      width: 66px;
      height: 66px;
      background: $BG_NAVY;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 1;

      i {
        color: $BG_WHITE;
      }
    }

    .social_profile_Scroll {
      position: unset !important;

      .top_section {
        margin-left: 175px;
        text-align: left;

        .top_text1 {
          font-size: $FONT_32;
          line-height: 48px;
          font-family: $LATO_BOLD;
          color: $BG_NAVY;
          margin-top: 86px;
        }

        .top_text2 {
          font-size: $FONT_16;
          line-height: 24px;
          font-family: $LATO_SEMIBOLD;
          color: $TXT_GREY;
          margin-top: -10px;
        }

        .border_box {
          border-left: 3px solid $TXT_BLUE;
          width: 347px;
          height: 32px;
          background: rgba(86, 150, 250, 0.2);

          .box_text {
            font-size: $FONT_16;
            color: $TXT_BLUE;
            font-family: $LATO_REGULAR;
            line-height: 30px;
            margin-left: 15px;
          }
        }
      }

      .main_box {
        display: flex;
        flex-wrap: wrap;
        margin-left: 175px;
        margin-bottom: 40px;

        .inner_box {
          margin-right: 15px;
          padding-right: 15px;
          border-radius: 10px;
          text-align: left;
          width: 26%;
          height: 210px;
          background: #eff0f7;
          margin-top: 32px;

          .top {
            display: flex;
            margin-left: 32px;
            padding-top: 33px;
            align-items: center;
            padding-bottom: 20px;

            i {
              font-size: $FONT_50;
              color: $BG_DARKBLUE;

              &.bg_Colr {
                font-size: $FONT_24;
                background: $BG_DARKBLUE;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $TXT_WHITE;
              }
            }

            .top_text {
              color: $TXT_NAVY;
              font-size: $FONT_32;
              font-family: $LATO_REGULAR;
              margin: 0px 8px;
              font-weight: normal;
              padding-bottom: 0;
            }
          }

          .middle {
            display: flex;
            margin-left: 32px;
            justify-content: space-between;

            i {
              color: $TXT_LIGHTBLUE;
            }

            .middle_text {
              font-size: $FONT_16;
              color: $TXT_GREY;
              font-family: $LATO_REGULAR;
              font-style: normal;
            }
          }

          .top_border {
            border-bottom: 1px solid #a0a3bd;
            // width: 397px;
            // width: auto;
            margin-left: 32px;
          }

          .bottom {
            display: flex;
            margin-left: 32px;
            padding-top: 20px;
            justify-content: space-between;

            i {
              color: $TXT_LIGHTBLUE;
            }

            .bottom_text {
              font-size: $FONT_16;
              color: $TXT_GREY;
              font-family: $LATO_REGULAR;
              font-style: normal;
            }
          }
        }
      }
    }
  }

  .kep-login-facebook {
    color: transparent;
    padding: 0px;
    height: 32px;
    background-color: #4c69ba;
    border: none;
    width: 32px;
    display: none;
  }

  img.tiktok-img {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .social_profile_Page {
    .main_Container {
      .social_profile_Scroll {
        .main_box {
          .inner_box {
            width: 41%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .social_profile_Page {
    .main_Container {
      .social_profile_Scroll {
        .main_box {
          margin-bottom: 70px;

          .inner_box {
            width: 50%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .social_profile_Page {
    .main_Container {
      .social_profile_Scroll {
        .main_box {
          margin-bottom: 70px;

          .inner_box {
            width: 50%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .social_profile_Page {
    .main_Container {
      .social_profile_Scroll {
        .top_section {
          margin-left: 125px;
        }

        .main_box {
          margin-bottom: 70px;
          margin-left: 125px;

          .inner_box {
            width: 70%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .social_profile_Page {
    .main_Container {
      .social_profile_Scroll {
        .top_section {
          margin-left: 125px;

          .top_text1 {
            margin-top: 50px;
          }
        }

        .main_box {
          margin-left: 135px;
          margin-bottom: 70px;

          .inner_box {
            width: 95%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .social_profile_Page {
    .main_Container {
      .social_profile_Scroll {
        .top_section {
          margin-left: 20px;

          .top_text1 {
            font-size: $FONT_24;
          }
        }

        .main_box {
          margin-left: 20px;
          margin-bottom: 70px;
          overflow: scroll;
          height: calc(100vh - 260px);

          .inner_box {
            height: 170px;
            width: 97%;

            .top {
              margin-left: 15px;
              padding-top: 15px;

              .top_text {
                font-size: $FONT_24;
              }
            }

            .middle {
              margin-left: 15px;

              .middle_text {
                font-size: $FONT_14;
              }
            }

            .top_border {
              margin-left: 15px;
            }

            .bottom {
              margin-left: 15px;

              .bottom_text {
                font-size: $FONT_14;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .social_profile_Page {
    .main_Container {
      width: 100%;

      .social_profile_Scroll {
        .top_section {
          margin-left: 0;

          .top_text1 {
            font-size: $FONT_24;
          }

          .border_box {
            width: 100%;
            height: 70px;
          }
        }

        .main_box {
          margin-left: 0;
          margin-bottom: 70px;
          overflow: scroll;
          height: calc(100vh - 360px);

          .inner_box {
            margin-right: 0;
            height: auto;
            width: 100%;

            .top {
              margin-left: 15px;
              padding-top: 15px;

              .top_text {
                font-size: $FONT_24;
              }
            }

            .middle {
              margin-left: 15px;

              .middle_text {
                font-size: $FONT_14;
              }
            }

            .top_border {
              margin-left: 15px;
            }

            .bottom {
              margin-left: 15px;

              .bottom_text {
                font-size: $FONT_14;
              }
            }
          }
        }
      }
    }
  }
}
