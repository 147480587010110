@import "../../assests/scss/variables.scss";

.blogseven_Page {
  .headerLogin {
    .menuItem {
      .nav-link {
        &:nth-child(1) {
          display: none !important;
        }
      }

      .button_Link {
        a.nav-link {
          &:nth-child(1) {
            display: block !important;
          }
        }
      }
    }
  }

  .blogScroll {
    height: calc(100vh - 150px) !important;
    height: 100vh !important;
    // position: relative;

    .main_Banner {
      height: 600px;
      overflow: hidden;
      display: flex;

      .banner_Img {
        display: block;
        // max-width: 100%;
      }
    }

    .banner_Title {
      position: absolute;
      top: 130px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $FONT_48;
      color: $TXT_WHITE;
      padding: 0px 638px;
      text-align: center;
      line-height: 64px;

      &:before {
        content: "";
        background: $TXT_ORANGE;
        height: 4px;
        position: absolute;
        bottom: -15px;
        width: 50px;
      }
    }

    .center_Section {
      // margin-bottom: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      .center_Title {
        position: absolute;
        top: 650px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $FONT_48;
        color: $TXT_NAVY;
        padding: 0px 638px;
        text-align: center;
        line-height: 64px;

        &:before {
          content: "";
          background: $TXT_ORANGE;
          height: 4px;
          position: absolute;
          bottom: -15px;
          width: 50px;
        }
      }

      .center_text {
        max-width: 1000px;
        width: 100%;
        padding: 250px 0px;

        .inner_Text {
          font-family: $LATO_REGULAR;
          font-size: $FONT_24;
          margin: 0;
          text-align: justify;
          color: $TXT_DARKBLACK;
          font-weight: 600;
          line-height: 36px;
          padding-bottom: 32px;
          letter-spacing: 1px;
        }

        p::first-letter {
          text-transform: uppercase;
          font-size: $FONT_48;
          font-family: $LATO_BOLD;
        }
      }
    }

    .middle_section {
      margin-top: -200px;
      text-align: justify;

      .middle_image {
        .middle_Img {
          margin-right: 40px;
          width: 50%;
          float: left;
        }
      }

      .middle_text {
        padding: 0px;

        .inner_Text {
          font-family: $LATO_REGULAR;
          font-size: $FONT_24;
          margin: 0;
          text-align: justify;
          background: #eff0f7;
          color: $TXT_DARKBLACK;
          font-weight: 600;
          line-height: 36px;
          padding-bottom: 37px;
          letter-spacing: 1px;
          padding: 44px 20px;
        }
      }
    }
    .blog-feature-seven {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
    }

    .image_Section {
      margin-top: 48px;
      padding: 0 40px;
      padding-bottom: 200px !important;

      .titleText {
        display: flex;
        justify-content: center;
        font-size: $FONT_48;
        font-family: $LATO_BOLD;
        color: $TXT_NAVY;
        padding-bottom: 80px;
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .blogseven_Page {
    .blogScroll {
      .banner_Title {
        padding: 0px 300px;
      }

      .center_Section {
        .center_Title {
          padding: 0px 300px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .blogseven_Page {
    .blogScroll {
      .banner_Title {
        padding: 0px 220px;
      }

      .center_Section {
        .center_Title {
          padding: 0px 220px;
        }
      }

      .middle_section {
        .middle_text {
          .inner_Text {
            padding: 20px 20px;
          }
        }
      }

      .image_Section {
        padding: 0px 60px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .blogseven_Page {
    .blogScroll {
      .banner_Title {
        padding: 0px 200px;
      }

      .center_Section {
        .center_Title {
          padding: 0px 200px;
        }

        .center_text {
          .inner_Text {
            padding: 0px 10px;
          }
        }
      }

      .middle_section {
        .middle_text {
          .inner_Text {
            padding: 20px 20px;
          }
        }
      }

      .image_Section {
        padding: 0px 60px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blogseven_Page {
    .blogScroll {
      .main_Banner {
        .banner_Img {
          height: 400px;
        }
      }

      .banner_Title {
        padding: 0px 50px;
        font-size: $FONT_36;
      }

      .center_Section {
        .center_Title {
          top: 450px;
          font-size: $FONT_36;
          padding: 0px 50px;
        }

        .center_text {
          .inner_Text {
            margin-top: -220px;
            padding: 0px 35px;
          }
        }
      }

      .middle_section {
        .middle_image {
          .middle_Img {
            margin-bottom: 50px;
            width: 100%;
          }
        }

        .middle_text {
          .inner_Text {
            padding: 20px 20px;
          }
        }
      }

      .image_Section {
        padding: 0px 40px;

        .slider_Section {
          .slick-prev {
            left: 16px;
          }

          .slick-next {
            right: 18px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blogseven_Page {
    .blogScroll {
      .main_Banner {
        .banner_Img {
          height: 400px;
        }
      }

      .banner_Title {
        padding: 0px 50px;
        font-size: $FONT_36;
      }

      .center_Section {
        .center_Title {
          top: 450px;
          font-size: $FONT_36;
          padding: 0px 50px;
        }

        .center_text {
          .inner_Text {
            margin-top: -200px;
            padding: 0px 10px;
          }
        }
      }

      .middle_section {
        .middle_image {
          .middle_Img {
            margin-bottom: 50px;
            width: 100%;
          }
        }

        .middle_text {
          .inner_Text {
            padding: 20px 20px;
          }
        }
      }

      .image_Section {
        padding: 0px 10px;
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .blogseven_Page {
    .blogScroll {
      .main_Banner {
        .banner_Img {
          height: 350px;
        }
      }

      .banner_Title {
        padding: 0px 50px;
        font-size: $FONT_24;
      }

      .center_Section {
        .center_Title {
          top: 400px;
          font-size: $FONT_24;
          padding: 0px 50px;
        }

        .center_text {
          .inner_Text {
            margin-top: -260px;
            padding: 0px 10px;
          }
        }
      }

      .middle_section {
        .middle_image {
          .middle_Img {
            margin-bottom: 50px;
            width: 100%;
          }
        }

        .middle_text {
          .inner_Text {
            padding: 20px 20px;
          }
        }
      }

      .image_Section {
        padding: 0px 20px;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .blogseven_Page {
    .blogScroll {
      .main_Banner {
        .banner_Img {
          height: 300px;
        }
      }

      .banner_Title {
        padding: 0px 10px;
        font-size: $FONT_24;
        top: 90px;
      }

      .center_Section {
        .center_Title {
          top: 340px;
          font-size: $FONT_24;
          padding: 0px 0px;
        }

        .center_text {
          .inner_Text {
            margin-top: -320px;
            padding: 0px 15px;
          }
        }
      }

      .middle_section {
        .middle_image {
          .middle_Img {
            margin-bottom: 50px;
            width: 100%;
          }
        }

        .middle_text {
          .inner_Text {
            padding: 20px 20px;
          }
        }
      }

      .image_Section {
        padding: 0px 10px;

        .slider_Section {
          .slick-prev {
            width: 30px;
            height: 30px;
            left: 0px;
          }

          .slick-next {
            width: 30px;
            height: 30px;
            right: 0px;
          }
        }
      }
    }
  }
}
