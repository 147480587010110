@import "../../assests/scss/variables.scss";

.admin_Blog {
  .main_Container {
    width: calc(100% - 318px);
    float: right;
    background: $BG_WHITE !important;

    .admin_Dashboard_Scroll {
      position: unset !important;

      .dashboard_Section {
        // width: calc(100% - 318px);
        // float: right;
        // padding: 16px;
        // background: $BG_WHITE !important;
        padding-left: 96px;
        margin-right: 16px;

        .content_Header {
          .page_Title {
            font-size: $FONT_32;
            color: $TXT_NAVY;
            padding-bottom: 14px;
          }

          .inner_Title {
            color: $LABEL_GREY;
            font-size: $FONT_16;
            padding-bottom: 24px;
          }
        }

        .addBlogs {
          width: 144px !important;
          height: 48px !important;
          border-radius: 8px !important;
        }

        .filterBtn {
          margin-right: 16px;

          .icon-filter span {
            margin-left: 10px;
          }

          .filterIcon {
            position: relative;
            margin-left: 15px;
            background: $BG_WHITE;
            width: 117px;
            height: 48px;
            border-radius: 8px;
            border: none;
            display: flex;
            align-items: center;
            border: 1px solid $TXT_LIGHTBLACK;
            justify-content: center;

            &:focus {
              outline: none;
            }

            i {
              color: $TXT_LIGHTBLACK;
              font-size: $FONT_16;

              span {
                font-size: $FONT_16;
                font-family: $LATO_REGULAR;
              }
            }
          }
        }
      }

      .divider {
        border-bottom: 1px solid $BG_LIGHTBLUE;
      }

      .table_Section {
        .query-Section {
          padding-top: 24px;

          .header {
            background: $BG_LIGHTBLUE;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;

            li {
              color: $TXT_LIGHTBLACK;
            }
          }

          .dataList {
            padding-left: 24px !important;
            border: 1px solid $BG_LIGHTBLUE;
            border-top: none !important;

            li {
              color: $TXT_NAVY;
            }

            .dropdown_Section {
              margin-top: 2px !important;
              width: 80%;
              // padding-bottom: 48px;

              .customDropdown {
                .dropdown {
                  .btn-primary {
                    border: 1px solid $TXT_NAVY !important;
                    // padding: 17px 16px !important;
                    color: #16056b !important;

                    // &:after {
                    //   position: absolute;
                    //   right: 22px;
                    //   top: 16px;
                    //   color: $TXT_BLUE;
                    // }
                  }

                  .dropdown-menu.show {
                    position: none;
                    inset: auto !important;
                    transform: none !important;
                  }

                  // .dropdownData {
                  //   width: 93%;
                  // }
                }
              }

              .btn-primary {
                color: $TXT_NAVY !important;
              }
            }
          }

          ul {
            list-style: none;
            padding-left: 24px !important;
            min-height: 48px;
            align-items: center;

            li {
              width: 10%;
              font-size: $FONT_16;

              &:nth-child(1) {
                width: 10%;
              }

              &:nth-child(2) {
                width: 35%;
              }

              &:nth-child(3) {
                width: 12%;
              }

              &:nth-child(4) {
                width: 12%;
              }

              &:nth-child(5) {
                width: 12%;
              }

              &:nth-child(6) {
                width: 10%;
              }

              &:nth-child(7) {
                width: 10%;
              }

              &:nth-child(8) {
                width: 10%;

                .publishBtn {
                  height: 29px;
                  padding: 0 !important;
                  width: 101px;
                  font-size: $FONT_14;
                }
              }
            }
          }
        }
      }
    }
  }

  .filterDropdown {
    margin-top: 35px !important;
    margin-right: 16px;
  }

  .paginationClass {
    right: 1% !important;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .admin_Blog {
    .main_Container {
      .admin_Dashboard_Scroll {
        .dashboard_Section {
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .admin_Blog {
    .main_Container {
      .admin_Dashboard_Scroll {
        .dashboard_Section {
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .admin_Blog {
    .main_Container {
      .admin_Dashboard_Scroll {
        .dashboard_Section {
          .table_Section {
            width: 900px;

            .query-Section {
              .header {
                display: none !important;
              }

              .dataList {
                flex-wrap: wrap;
                height: auto;
                border-top: 1px solid #d9dbe9 !important;
                margin-bottom: 5px !important;
                min-height: 160px;

                li {
                  position: relative;
                  padding-top: 10px;

                  &:nth-child(1) {
                    width: 13%;

                    &::before {
                      content: "Sl.No";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(2) {
                    width: 60%;

                    &::before {
                      content: "Title";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(3) {
                    width: 20%;

                    &::before {
                      content: "Auther";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(4) {
                    width: 20%;
                    margin-top: 0;

                    &::before {
                      content: "Created On";
                      position: absolute;
                      top: -13px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(5) {
                    width: 30%;
                    padding-bottom: 10px !important;

                    &::before {
                      content: "Status";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(6) {
                    width: 20%;
                    margin-top: 0;

                    &::before {
                      content: "Edit";
                      position: absolute;
                      top: -13px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(7) {
                    width: 10%;
                    margin-top: 0;

                    &::before {
                      content: "Delete";
                      position: absolute;
                      top: -13px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .admin_Blog {
    .main_Container {
      .admin_Dashboard_Scroll {
        .dashboard_Section {
          .table_Section {
            .query-Section {
              .header {
                display: none !important;
              }

              .dataList {
                flex-wrap: wrap;
                height: auto;
                border-top: 1px solid #d9dbe9 !important;
                margin-bottom: 5px !important;
                min-height: 160px;

                li {
                  position: relative;
                  padding-top: 10px;

                  &:nth-child(1) {
                    width: 13%;

                    &::before {
                      content: "Sl.No";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(2) {
                    width: 60%;

                    &::before {
                      content: "Title";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(3) {
                    width: 20%;

                    &::before {
                      content: "Auther";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(4) {
                    width: 20%;
                    margin-top: 0;

                    &::before {
                      content: "Created On";
                      position: absolute;
                      top: -13px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(5) {
                    width: 30%;
                    padding-bottom: 10px !important;

                    &::before {
                      content: "Status";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(6) {
                    width: 20%;
                    margin-top: 0;

                    &::before {
                      content: "Edit";
                      position: absolute;
                      top: -13px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(7) {
                    width: 10%;
                    margin-top: 0;

                    &::before {
                      content: "Delete";
                      position: absolute;
                      top: -13px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
