@import "../../assests/scss/variables.scss";

.userdetail {
  .tabIcon {
    display: none;
    position: absolute;
    right: 28px;
    top: -27px;
  }

  .userdetail_scroll > div {
    overflow: auto !important;
  }

  .userdetail_scroll {
    position: unset !important;

    .change_Password {
      width: 540px;
      padding-top: 92px;

      .titleText {
        padding-bottom: 48px !important;
        font-size: $FONT_48;
        color: $TXT_NAVY;
      }

      .update_button {
        padding-top: 24px;
      }
    }

    .startdiv {
      text-align: left;
      margin-left: 100px;
      margin-top: 40px;

      .heading {
        // font-family: Lato;
        font-style: normal;
        font-weight: 600;
        color: $SOLID_BLACK;
        font-size: $FONT_32;
        line-height: 150%;
      }

      .titleText {
        padding-bottom: 4px;
      }

      .manage {
        // font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: $FONT_16;
        line-height: 150%;
        color: $LABEL_GREY;
      }

      .nav-tabs .nav-item.show .nav-link,
      .nav-tabs .nav-link.active {
        color: $TXT_NAVY;
        background-color: #fff;
        border-bottom: 2px solid $BG_NAVY;
      }

      .nav-tabs .nav-item.show .nav-link,
      .nav-tabs .nav-link {
        border-color: transparent;
        color: $TXT_LIGHT;
        padding: 0 !important;
        margin-right: 24px;
        padding-bottom: 10px !important;
      }

      .menu {
        display: flex;
        flex-direction: row !important;
        flex-wrap: wrap;
        border-bottom: 1px solid $TXT_BLUELIGHT;
        font-size: $FONT_24;
        margin-top: 32px;
        position: relative;

        .active {
          text-decoration: none;
          border-bottom: 1px solid $BG_NAVY;
          // margin-right: 48px;
          padding-bottom: 11px;
          color: $BG_NAVY;
        }

        .biling {
          text-decoration: none;
          margin-right: 48px;
          color: $LABEL_GREY;
        }

        .general {
          text-decoration: none;
          color: $LABEL_GREY;
          margin-right: 48px;
        }

        .billing {
          text-decoration: none;
          color: $LABEL_GREY;
          margin-right: 48px;
        }

        .query {
          text-decoration: none;
          color: $LABEL_GREY;
        }
      }

      .detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 60px !important;
        margin: auto;

        input[type="file"] {
          display: none;
        }

        img.profile-img {
          width: 120px;
          height: 120px;
          border-radius: 50%;
        }

        .image-text {
          background: lightsteelblue;
          width: 120px;
          height: 120px;
          border-radius: 50%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .change_img {
          margin-top: 16px;
          margin-bottom: 0px;
          text-decoration: none;
          top: 0;
          right: 0;
          cursor: pointer;
          width: 30px;
          height: 30px;
          background: $TXT_NAVY;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            color: $TXT_WHITE;
          }
        }

        .hookform {
          width: 60% !important;
        }

        .form {
          display: flex;
          flex-direction: column;
          margin-top: 43px;

          .iconCss {
            font-size: $FONT_18;
          }

          .first_line {
            display: flex;

            .first_name {
              width: 100%;
              margin-right: 15px;
            }

            .last_name {
              width: 100%;
            }
          }

          .second_line {
            display: flex;

            .email {
              width: 100%;
              margin-right: 15px;

              .textField {
                .form-control {
                  background: $BG_LIGHTBLUE;
                  color: $TXT_LIGHT !important;
                }
              }
            }

            .code_number {
              display: flex;
              width: 100%;

              .PhoneInput {
                width: 100%;

                background: $BG_WHITE;
                color: $TXT_NAVY;
                font-size: $FONT_16;
                padding: 11px 12px;
                height: 72px;
                outline: 0px;

                border-radius: 15px;
                border: 1px solid $TXT_NAVY;
                outline: none;

                width: 100%;

                .PhoneInputCountry {
                }

                .PhoneInputInput {
                  margin-left: 20px;
                  border: none;
                  outline: none;
                }
              }
            }

            .textField {
              margin-right: 6px;
            }
          }

          .button_line {
            display: flex;
            margin-top: 24px;

            .first_button {
              margin-right: 15px;
              width: 100%;

              .submitBtn {
                background: $TXT_OFFWHITE !important;
                width: 100%;
                border: 1px solid $TXT_NAVY !important;
                height: 72px;
                font-size: 16px;
                font-family: "latobold";
                border-radius: 10px;
                cursor: pointer;
                color: $TXT_NAVY;
              }
            }

            .second_button {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .billing_Section {
    padding-top: 20px;

    .main_Container {
      width: 100%;

      .billing_Inner {
        width: 100%;
        float: right;
        position: relative;

        .carddetails_Right {
          right: 0;
          top: -231px;
          position: absolute;
        }

        .title_Height {
          padding-top: 40px;

          .tabFilerIcon {
            font-size: $FONT_18;
            color: $ARROW;
            position: absolute;
            right: 15px;
            top: 45px;
            display: none;
          }

          .main_Heading {
            font-size: $FONT_32;
            font-family: $LATO_BOLD;
            font-weight: 600;
            color: $TXT_NAVY;
            padding-bottom: 14px;
          }

          .inner_Text {
            color: $TXT_LIGHTCOLOR;
            font-size: $FONT_16;
            font-family: $LATO_REGULAR;
            font-weight: 400;
            padding-bottom: 32px;
          }
        }

        .listing_Section {
          width: 70%;
          float: left;
          position: relative;

          .listing {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            position: relative;
            padding-right: 15px;
            padding-bottom: 40px;

            .left_Section {
              width: 100%;
              height: 46px;
              display: flex;
              flex-direction: column;

              .data_Listing {
                display: table;
                width: 100%;

                .scroll_Listing {
                  table {
                    thead {
                      background: #d9dbe9;
                      border-top-left-radius: 10px;
                      border-top-right-radius: 10px;
                      border: 1px solid #d9dbe9;

                      th {
                        background: transparent !important;
                        font-size: $FONT_16 !important;
                        font-family: $LATO_REGULAR !important;
                        font-weight: 400 !important;
                      }
                    }

                    td {
                      font-family: $LATO_REGULAR !important;
                    }
                  }

                  .date {
                    font-size: $FONT_24;
                    color: $BG_NAVY;
                    line-height: 36px;
                    padding-bottom: 8px;
                    border-bottom: 1px solid $TXT_BLUELIGHT;
                    width: 100%;
                  }

                  .tab_Heading {
                    margin-top: 22px;

                    .heading {
                      font-size: 16px;
                      color: $TXT_LIGHTBLACK;
                      font-family: $LATO_REGULAR;

                      &.checkBox {
                        display: none;
                      }
                    }
                  }
                }
              }

              .paginationSystemClass {
                .paginationClass {
                  right: 29% !important;
                }
              }
            }
          }
        }

        .carddetails_Right {
          float: left;
          width: 30%;
          background: $BG_OFFLIGHT;
          height: 100vh;
          max-width: 576px;
          padding-top: 40px;

          .main_Title {
            font-size: 24px;
            color: $TXT_NAVY;
            line-height: 36px;
            padding-bottom: 16px;
            padding-left: 24px;
          }

          .onClose {
            font-size: $FONT_14;
            position: absolute;
            right: 30px;
            top: 50px;
            cursor: pointer;
            display: none;
          }

          .content_Inner {
            .current_Plans {
              padding-top: 32px;

              .details {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border-bottom: 1px solid $TXT_BLUELIGHT;
                margin-bottom: 16px;
                padding: 0 24px;

                &:last-child {
                  margin: 0;
                }

                .titleText {
                  color: $LABEL_GREY;
                  font-size: $FONT_16;
                  font-family: $LATO_REGULAR;
                  padding-bottom: 16px;

                  &:nth-child(2) {
                    color: $TXT_LIGHTBLACK;
                    font-family: $LATO_SEMIBOLD;
                  }
                }
              }
            }

            .bottom_Btn {
              display: flex;
              border-bottom: 1px solid $TXT_BLUELIGHT;
              padding: 32px 24px;
              justify-content: center;

              button {
                height: 56px;
                width: 184px;
                margin-left: 16px;

                &:nth-child(1) {
                  background: transparent !important;
                  padding: 0 !important;
                  color: $TEXT_RED;
                  border: none;
                  margin-left: 0;
                }
              }
            }
          }

          .card-details {
            border-bottom: 1px solid $TXT_BLUELIGHT;
            padding: 0 24px;

            .main_Title {
              padding-left: 0;
              padding-top: 32px;
              padding-bottom: 0;
              font-size: $FONT_16;
            }

            .card_Inner {
              display: flex;
              justify-content: space-between;
              padding-top: 34px;
              padding-bottom: 26px;

              .visa_Type {
                display: flex;
                align-items: center;

                .dots {
                  width: 6px;
                  height: 6px;
                  border-radius: 5px;
                  background: $TXT_LIGHTBLACK;
                  display: inline-block;
                  margin-right: 8px;
                }

                .titleText {
                  font-size: $FONT_24;
                  font-family: $LATO_SEMIBOLD;
                  color: $TXT_LIGHTBLACK;
                  padding: 0;
                  padding-right: 24px;
                }

                .top_Patten {
                  // width: 45px;
                  // height: 27px;
                }
              }
            }
          }

          button {
            height: 56px;
            width: 184px;
            float: right;

            // margin: 30px 0;
            &.cardBtn {
              margin-right: 24px;
              margin-top: 24px;
            }
          }
        }
      }
    }
  }

  .middle_section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;

    .dropdown_box {
      width: 50%;

      .dropdown_section1 {
        display: flex;
        width: 100%;
        justify-content: center;

        .dropdown_inner1 {
          width: 50%;
          margin-right: 15px;

          .customDropdown .dropdown .btn-primary {
            border: 1px solid $TXT_NAVY !important;
            padding: 24px 16px !important;
            color: #16056b !important;

            &:after {
              position: absolute;
              right: 24px;
              top: 34px;
              color: $TXT_BLUE;
            }
          }

          .btn-primary {
            color: $TXT_NAVY !important;
          }
        }

        .dropdown_inner2 {
          width: 50%;

          .customDropdown .dropdown .btn-primary {
            border: 1px solid $TXT_NAVY !important;
            padding: 24px 16px !important;

            &:after {
              position: absolute;
              right: 24px;
              top: 34px;
              color: $TXT_BLUE;
            }
          }

          .btn-primary {
            color: $TXT_NAVY !important;
          }
        }
      }

      .dropdown_section2 {
        display: flex;
        width: 100%;
        margin-top: 24px;

        .dropdown_inner3 {
          width: 50%;
          margin-right: 15px;

          .customDropdown .dropdown .btn-primary {
            border: 1px solid $TXT_NAVY !important;
            padding: 24px 16px !important;

            &:after {
              position: absolute;
              right: 24px;
              top: 34px;
              color: $TXT_BLUE;
            }
          }

          .btn-primary {
            color: $TXT_NAVY !important;
          }
        }

        .dropdown_inner4 {
          width: 50%;

          .customDropdown .dropdown .btn-primary {
            border: 1px solid $TXT_NAVY !important;
            padding: 24px 16px !important;

            &:after {
              position: absolute;
              right: 24px;
              top: 34px;
              color: $TXT_BLUE;
            }
          }

          .btn-primary {
            color: $TXT_NAVY !important;
          }
        }
      }

      .dropdown_section3 {
        display: flex;
        width: 100%;
        margin-top: 24px;

        .dropdown_inner5 {
          width: 50%;
          margin-right: 15px;

          .customDropdown .dropdown .btn-primary {
            border: 1px solid $TXT_NAVY !important;
            padding: 24px 16px !important;

            &:after {
              position: absolute;
              right: 24px;
              top: 34px;
              color: $TXT_BLUE;
            }
          }

          .btn-primary {
            color: $TXT_NAVY !important;
          }
        }

        .dropdown_inner6 {
          width: 50%;

          .customDropdown .dropdown .btn-primary {
            border: 1px solid $TXT_NAVY !important;
            padding: 24px 16px !important;

            &:after {
              position: absolute;
              right: 24px;
              top: 34px;
              color: $TXT_BLUE;
            }
          }

          .btn-primary {
            color: $TXT_NAVY !important;
          }
        }
      }

      .button {
        display: flex;
        margin-top: 48px;

        .btn1 {
          width: 50%;
          border: 1px solid $TXT_NAVY !important;
          border-radius: 10px !important;
          opacity: 0.6 !important;

          .btn-primary {
            color: $TXT_NAVY !important;
          }

          .submitBtn {
            background: $TXT_OFFWHITE !important;
            cursor: not-allowed !important;
          }
        }

        .btn2 {
          width: 50%;
          margin-left: 15px;
        }
      }
    }
  }

  .create-QueryModal {
    width: 128px;
    height: 40px;
    border-radius: 8px !important;
    float: right;
    position: absolute;
    right: 0;
    top: 140px;
    right: 1%;

    &.downloadBtn {
      right: 32%;
      top: 90px;
    }
  }

  .Subscribed {
    width: 184px;
    height: 40px;
    border-radius: 8px !important;
    float: right;
    position: absolute;
    right: 0;
    top: 35px;
  }

  .query-Section {
    padding-top: 32px;
    width: 99%;

    .header {
      background: $BG_LIGHTBLUE;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      li {
        color: $TXT_LIGHTBLACK;
      }
    }

    .dataList {
      padding-left: 24px !important;
      border: 1px solid $BG_LIGHTBLUE;
      border-top: none !important;

      li {
        color: $TXT_NAVY;
      }
    }

    ul {
      list-style: none;
      padding-left: 24px !important;
      height: 48px;
      align-items: center;

      li {
        width: 30%;
        font-size: $FONT_16;

        &:nth-child(1) {
          width: 10%;
        }

        &:nth-child(2) {
          width: 10%;
        }

        &:nth-child(3) {
          width: 20%;
        }
      }
    }
  }

  .chatboat-section {
    bottom: 10px;
    right: 25px;
    width: 66px;
    height: 66px;
    background: $BG_NAVY;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
      color: $BG_WHITE;
    }
  }

  .paginationClass {
    right: 3% !important;
    bottom: 8% !important;
  }
}

.create_Query {
  .modal-dialog {
    float: right;
    width: 50vw;

    .modal-content {
      .modalInner {
        .modal-body {
          .query_Content {
            h1.titleText.id {
              font-family: "Lato";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 150%;
              color: #14142b;
              padding-bottom: 8px;
            }

            h1.titleText.no {
              font-family: "Lato";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              color: #6e7191;
            }

            .query_section {
              // background: red;
              .query_id {
                .textField .form-control:disabled {
                  background: none !important;
                  border: 1px solid $TXT_NAVY;
                  color: $TXT_NAVY !important;
                  opacity: 1 !important;
                }
                // background-color: red;
              }

              .query_form {
                display: flex;
                height: 75vh;
                flex-direction: column;
                // justify-content: space-between;

                .textField .form-control {
                  height: 15rem;
                  background: #f7f7fc;
                  border: 0.5px solid #16056b;
                  border-radius: 8px;
                  color: #6e7191;
                }

                .button_btm {
                  padding-bottom: 10px;
                  justify-content: center !important;
                  margin-top: 20rem;

                  .cancel_Btn {
                    width: 184px;
                    height: 57px;
                    background: #fcfcfc !important;
                    color: #16056b;
                    border: 1px solid #16056b;
                    border-radius: 10px;
                    font-family: "Lato";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 150%;
                    font-family: "latoregular" !important;
                  }

                  .add_Btn {
                    width: 184px;
                    height: 57px;
                    font-family: "Lato";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 150%;
                    font-family: "latoregular" !important;
                    border: 1px solid #16056b;
                    border-radius: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .userdetail {
    .userdetail_scroll {
      .hookform {
        .detail {
          width: 70% !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .userdetail {
    .userdetail_scroll {
      .startdiv {
        margin-left: 110px;
        margin-right: 20px;
      }
    }
  }

  .main_Container {
    .billing_Inner {
      .listing_Section {
      }

      .carddetails_Right {
        padding: 40px 24x;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .userdetail {
    .userdetail_scroll {
      .startdiv {
        margin-left: 120px;
        margin-right: 20px;

        .detail {
          .hookform {
            width: 70% !important;
          }
        }

        .query-Section {
          .header {
            display: none !important;
          }

          .dataList {
            flex-wrap: wrap;
            height: auto;
            border-top: 1px solid #d9dbe9 !important;
            margin-bottom: 5px !important;

            li {
              position: relative;
              padding-top: 10px;

              &:nth-child(1) {
                width: 20%;

                &::before {
                  content: "Query ID";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                  font-family: $LATO_REGULAR;
                }
              }

              &:nth-child(2) {
                width: 20%;

                &::before {
                  content: "Raised On";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                  font-family: $LATO_REGULAR;
                }
              }

              &:nth-child(3) {
                width: 20%;

                &::before {
                  content: "Resolved On";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }

              &:nth-child(4) {
                width: 40%;
                margin-top: 15px;

                &::before {
                  content: "Message";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }

              &:nth-child(5) {
                width: 50%;
                padding-bottom: 10px !important;

                &::before {
                  content: "Comments";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }
            }
          }
        }
      }
    }
  }

  .main_Container {
    .billing_Inner {
      .tabFilerIcon {
        display: block !important;
      }

      .listing_Section {
        width: 100%;

        .tabFilerIcon {
          font-size: $FONT_18;
          color: $ARROW;
          position: absolute;
          right: 15px;
          top: 45px;
          display: none;
        }

        .tab_Search {
          .search_Filter {
            bottom: -56px;
            left: 0;
            top: unset;
            width: 100%;

            .filterBtn {
              position: absolute;
              right: 25px;
            }
          }
        }

        .carddetails_Right {
          top: 0;
          right: 0;
          z-index: 1;
          width: 475px !important;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 30px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          // height: 100vh;
          position: absolute;
          float: none;
          border-radius: 0 !important;
          display: none !important;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }
        }
      }

      .paginationSystemClass {
        .paginationClass {
          right: 29% !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .userdetail {
    .tabIcon {
      display: block;
    }

    .downloadBtn {
      right: 0 !important;
      top: -50px;
    }

    .position-Icon {
      position: absolute;
      top: 0;
      display: block !important;
      right: 0 !important;
    }

    .userdetail_scroll {
      .startdiv {
        margin-left: 120px !important;
        margin-right: 20px !important;

        .heading {
        }

        .manage {
          color: $LABEL_GREY;
          margin-right: 99px;
          width: 400px;
        }

        .menu {
          margin-right: 0px !important;
          font-size: $FONT_24 !important;
          flex-wrap: nowrap;
          overflow: hidden;
          overflow-x: scroll;
          margin-bottom: 20px !important;

          .nav-item {
            button {
              width: 190px !important;
            }
          }
        }

        .detail {
          width: 100% !important;

          margin-top: 32px !important;

          .change_img {
            font-size: $FONT_14 !important;
          }

          .hookform {
            width: 75% !important;
          }

          .form {
            display: block;
            margin-top: 32px !important;
            margin-top: 32px !important;

            .first_line {
              display: block;
              margin-bottom: 24px !important;

              .first_name {
                width: 100%;
              }

              .last_name {
                width: 100%;
              }
            }

            .second_line {
              display: block;

              .email {
                width: 100%;
              }

              .code_number {
                display: flex;
                width: 100%;

                .textField {
                  margin-bottom: 0px !important;
                }

                .code {
                  width: 30%;
                }

                .number {
                  width: 70%;
                }
              }
            }

            .button_line {
              display: flex;
              margin-top: 33px !important;
              margin-bottom: 48px !important;

              .first_button {
                width: 100%;
                margin-right: 15px;

                .submitBtn {
                }
              }

              .second_button {
                width: 100%;
              }
            }
          }
        }

        .query-Section {
          .header {
            display: none !important;
          }

          .dataList {
            flex-wrap: wrap;
            height: auto;
            border-top: 1px solid #d9dbe9 !important;
            margin-bottom: 5px !important;

            li {
              position: relative;
              padding-top: 10px;

              &:nth-child(1) {
                width: 20%;

                &::before {
                  content: "Query ID";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                  font-family: $LATO_REGULAR;
                }
              }

              &:nth-child(2) {
                width: 20%;

                &::before {
                  content: "Raised On";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                  font-family: $LATO_REGULAR;
                }
              }

              &:nth-child(3) {
                width: 20%;

                &::before {
                  content: "Resolved On";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }

              &:nth-child(4) {
                width: 40%;
                margin-top: 15px;

                &::before {
                  content: "Message";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }

              &:nth-child(5) {
                width: 50%;
                padding-bottom: 10px !important;

                &::before {
                  content: "Comments";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }
            }
          }
        }
      }
    }

    .create-QueryModal {
      left: 80% !important;
    }

    .middle_section {
      .dropdown_box {
        width: 100%;
      }
    }
  }

  .main_Container {
    .billing_Inner {
      width: 100%;

      .tabFilerIcon {
        display: block !important;
      }

      .listing_Section {
        width: 100% !important;
      }

      .carddetails_Right {
        top: 0;
        right: 0;
        z-index: 1;
        width: 475px !important;
        box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        border-radius: 0px 30px 30px 0px;
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        position: absolute;
        float: none;
        border-radius: 0 !important;
        display: none !important;

        &.showTab {
          transform: translateX(0);
          -webkit-transform: translateX(0);
          -moz-transform: translateX(0);
          -ms-transform: translateX(0);
          -o-transform: translateX(0);
        }

        .onClose {
          display: block;
        }
      }

      .paginationSystemClass {
        .paginationClass {
          right: 6% !important;
          bottom: 9%;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .userdetail {
    .tabIcon {
      display: block;
    }

    .downloadBtn {
      right: 0 !important;
    }

    .userdetail_scroll {
      .startdiv {
        margin-left: 141px !important;
        margin-right: 45px !important;

        .position-Icon {
          position: absolute;
          top: 0;
          display: block !important;
          right: 0 !important;
        }

        .heading {
        }

        .manage {
          color: $LABEL_GREY;
          margin-right: 99px;
        }

        .menu {
          margin-right: 0px !important;
          font-size: $FONT_24 !important;
          flex-wrap: nowrap;
          overflow: hidden;
          overflow-x: scroll;
          margin-bottom: 20px !important;

          .nav-item {
            button {
              width: 190px !important;
            }
          }
        }

        .detail {
          width: 100% !important;

          margin-top: 32px !important;

          .change_img {
            font-size: $FONT_14 !important;
          }

          .form {
            display: block;
            margin-top: 32px !important;

            .first_line {
              display: block;
              margin-bottom: 24px !important;

              .first_name {
                width: 100%;
              }

              .last_name {
                width: 100%;
              }
            }

            .second_line {
              display: block;

              .email {
                width: 100%;
              }

              .code_number {
                display: flex;
                width: 100%;

                .textField {
                  margin-bottom: 0px !important;
                }

                .code {
                  width: 30%;
                }

                .number {
                  width: 70%;
                }
              }
            }

            .button_line {
              display: flex;
              margin-top: 33px !important;
              margin-bottom: 48px !important;

              .first_button {
                width: 100%;
                margin-right: 15px;

                .submitBtn {
                }
              }

              .second_button {
                width: 100%;
              }
            }
          }
        }

        .query-Section {
          .header {
            display: none !important;
          }

          .dataList {
            flex-wrap: wrap;
            height: auto;
            border-top: 1px solid #d9dbe9 !important;
            margin-bottom: 5px !important;

            li {
              position: relative;
              padding-top: 10px;

              &:nth-child(1) {
                width: 20%;

                &::before {
                  content: "Query ID";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                  font-family: $LATO_REGULAR;
                }
              }

              &:nth-child(2) {
                width: 20%;

                &::before {
                  content: "Raised On";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                  font-family: $LATO_REGULAR;
                }
              }

              &:nth-child(3) {
                width: 20%;

                &::before {
                  content: "Resolved On";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }

              &:nth-child(4) {
                width: 40%;
                margin-top: 15px;

                &::before {
                  content: "Message";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }

              &:nth-child(5) {
                width: 50%;
                padding-bottom: 10px !important;

                &::before {
                  content: "Comments";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }
            }
          }
        }
      }
    }
  }

  .main_Container {
    .billing_Inner {
      .tabFilerIcon {
        display: block !important;
      }

      .listing_Section {
        width: 100%;

        .tabFilerIcon {
          font-size: $FONT_18;
          color: $ARROW;
          position: absolute;
          right: 15px;
          top: 45px;
          display: none;
        }

        .tab_Search {
          .search_Filter {
            bottom: -56px;
            left: 0;
            top: unset;
            width: 100%;

            .filterBtn {
              position: absolute;
              right: 25px;
            }
          }
        }
      }

      .carddetails_Right {
        top: 0;
        right: 0;
        z-index: 1;
        width: 475px;
        box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        border-radius: 0px 30px 30px 0px;
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        // height: 100vh;
        position: absolute;
        float: none;
        border-radius: 0 !important;

        &.showTab {
          transform: translateX(0);
          -webkit-transform: translateX(0);
          -moz-transform: translateX(0);
          -ms-transform: translateX(0);
          -o-transform: translateX(0);
        }
      }

      .paginationSystemClass {
        .paginationClass {
          right: 6% !important;
          bottom: 9%;
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .userdetail {
    .tabIcon {
      display: block;
    }

    .dropdown_box {
      width: 100% !important;
    }

    .Subscribed {
      top: 8px;
    }

    .userdetail_scroll {
      .startdiv {
        margin-left: 111px !important;
        margin-right: 45px !important;

        .heading {
        }

        .manage {
          color: $LABEL_GREY;
          margin-right: 99px;
        }

        .menu {
          margin-right: 0px !important;
          font-size: $FONT_24 !important;
          flex-wrap: nowrap;
          overflow: hidden;
          overflow-x: scroll;

          li {
            button {
              width: 127px;
            }

            &:nth-child(2) {
              button {
                width: 200px;
              }
            }
          }
        }

        .detail {
          width: 80% !important;
          margin-bottom: 48px !important;
          margin-top: 32px !important;

          .change_img {
            font-size: $FONT_14 !important;
          }

          .hookform {
            width: 90% !important;
          }

          .form {
            margin-top: 32px !important;
            display: block;

            .first_line {
              display: block;

              .first_name {
                width: 100%;
              }

              .last_name {
                width: 100%;
              }
            }

            .second_line {
              display: block;

              .email {
                width: 100%;
              }

              .code_number {
                display: flex;
                width: 100%;

                .code {
                  width: 30%;
                }

                .number {
                  width: 70%;
                }
              }
            }

            .button_line {
              display: flex;
              margin-top: 9px !important;

              .first_button {
                width: 100%;
                margin-right: 15px;

                .submitBtn {
                }
              }

              .second_button {
                width: 100%;
              }
            }
          }
        }

        .query-Section {
          .header {
            display: none !important;
          }

          .dataList {
            flex-wrap: wrap;
            height: auto;
            border-top: 1px solid #d9dbe9 !important;
            margin-bottom: 5px !important;
            min-height: 160px;

            li {
              position: relative;
              padding-top: 10px;

              &:nth-child(1) {
                width: 33%;

                &::before {
                  content: "Query ID";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                  font-family: $LATO_REGULAR;
                }
              }

              &:nth-child(2) {
                width: 33%;

                &::before {
                  content: "Raised On";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                  font-family: $LATO_REGULAR;
                }
              }

              &:nth-child(3) {
                width: 33%;

                &::before {
                  content: "Resolved On";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }

              &:nth-child(4) {
                width: 50%;
                margin-top: 0;

                &::before {
                  content: "Message";
                  position: absolute;
                  top: -13px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }

              &:nth-child(5) {
                width: 50%;
                padding-bottom: 10px !important;

                &::before {
                  content: "Comments";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }
            }
          }
        }
      }
    }

    .create-QueryModal {
      left: 80% !important;
    }
  }

  .main_Container {
    .billing_Inner {
      width: 100%;

      .tabFilerIcon {
        display: block !important;
      }

      .listing_Section {
        width: 100% !important;
      }

      .carddetails_Right {
        top: 0;
        right: 0;
        z-index: 1;
        width: 475px !important;
        box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        border-radius: 0px 30px 30px 0px;
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        position: absolute;
        float: none;
        border-radius: 0 !important;
        display: none;

        &.showTab {
          transform: translateX(0);
          -webkit-transform: translateX(0);
          -moz-transform: translateX(0);
          -ms-transform: translateX(0);
          -o-transform: translateX(0);
        }

        .onClose {
          display: block;
        }
      }

      .paginationSystemClass {
        .paginationClass {
          right: 6% !important;
          bottom: 9%;
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .userdetail {
    .tabIcon {
      display: block;
    }

    .dropdown_box {
      width: 100% !important;
    }

    .Subscribed {
      top: 8px;
    }

    .headerAdmin {
    }

    .userdetail_scroll {
      .startdiv {
        flex-wrap: nowrap;
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 48px !important;

        .heading {
        }

        .manage {
          color: $LABEL_GREY;
        }

        .menu {
          font-size: $FONT_16 !important;
          flex-wrap: nowrap;
          overflow: hidden;
          overflow-x: scroll;

          li {
            button {
              width: 127px;
            }
          }

          .active {
            margin-right: 32px;
          }

          .biling {
            margin-right: 32px;
          }

          .general {
            margin-right: 32px;
          }

          li {
            button {
              width: 90px;
            }

            &:nth-child(2) {
              button {
                width: 130px;
              }
            }
          }
        }

        .detail {
          width: 100%;
          margin-left: 0px;
          margin-right: 24px;

          .change_img {
            font-size: $FONT_14 !important;
          }

          .hookform {
            width: 100% !important;
          }

          .form {
            display: block;
            font-size: $FONT_16 !important;

            .first_line {
              display: block;

              .first_name {
                width: 100%;
              }

              .last_name {
                width: 100%;
              }
            }

            .second_line {
              display: block;

              .email {
                width: 100%;
              }

              .code_number {
                display: flex;
                width: 100%;
              }
            }

            .button_line {
              display: block;

              .first_button {
                width: 100%;
                margin-bottom: 15px;
              }

              .second_button {
                width: 100%;
              }
            }
          }
        }

        .query-Section {
          .header {
            display: none !important;
          }

          .dataList {
            flex-wrap: wrap;
            height: auto;
            border-top: 1px solid #d9dbe9 !important;
            margin-bottom: 5px !important;
            min-height: 160px;

            li {
              position: relative;
              padding-top: 10px;

              &:nth-child(1) {
                width: 33%;

                &::before {
                  content: "Query ID";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                  font-family: $LATO_REGULAR;
                }
              }

              &:nth-child(2) {
                width: 33%;

                &::before {
                  content: "Raised On";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                  font-family: $LATO_REGULAR;
                }
              }

              &:nth-child(3) {
                width: 33%;

                &::before {
                  content: "Resolved On";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }

              &:nth-child(4) {
                width: 50%;
                margin-top: 0;

                &::before {
                  content: "Message";
                  position: absolute;
                  top: -13px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }

              &:nth-child(5) {
                width: 50%;
                padding-bottom: 10px !important;

                &::before {
                  content: "Comments";
                  position: absolute;
                  top: -10px;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }
            }
          }
        }
      }
    }

    .create-QueryModal {
      left: 68% !important;
      top: 125px;
    }
  }

  .main_Container {
    width: 100%;

    .billing_Inner {
      width: 100%;
      padding-left: 24px;

      .tabFilerIcon {
        display: block !important;
      }

      .listing_Section {
        width: 100% !important;
      }

      .carddetails_Right {
        top: 0;
        right: 0;
        z-index: 1;
        width: 365px;
        box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        border-radius: 0px 30px 30px 0px;
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        float: none;
        border-radius: 0 !important;
        display: none;

        &.showTab {
          transform: translateX(0);
          -webkit-transform: translateX(0);
          -moz-transform: translateX(0);
          -ms-transform: translateX(0);
          -o-transform: translateX(0);
        }

        .onClose {
          display: block;
        }
      }

      .paginationSystemClass {
        .paginationClass {
          right: 6% !important;
          bottom: 9%;
        }
      }
    }
  }

  .create_Query {
    .modal-dialog {
      width: 98%;

      .modal-content {
        .modalInner {
          .modal-body {
            .query_Content {
              .query_form {
                .button_btm {
                  .cancel_Btn {
                    width: 150px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .userdetail {
    .middle_section {
      margin-top: 30px;

      .dropdown_box {
        width: 100%;

        .button {
          margin-top: 15px;
        }

        .dropdown_section1 {
          flex-direction: column;

          .dropdown_inner1 {
            width: 100%;
            margin-right: 0;
            margin-bottom: 5px;
          }

          .dropdown_inner2 {
            width: 100%;
            margin-right: 0;
            margin-bottom: 5px;
          }
        }

        .dropdown_section2 {
          flex-direction: column;
          margin-top: 0;

          .dropdown_inner3 {
            width: 100%;
            margin-right: 0;
            margin-bottom: 5px;
          }

          .dropdown_inner4 {
            width: 100%;
            margin-right: 0;
            margin-bottom: 5px;
          }
        }

        .dropdown_section3 {
          flex-direction: column;
          margin-top: 0;

          .dropdown_inner5 {
            width: 100%;
            margin-right: 0;
            margin-bottom: 5px;
          }

          .dropdown_inner6 {
            width: 100%;
            margin-right: 0;
            margin-bottom: 5px;
          }
        }
      }
    }

    .tabIcon {
      display: block;
    }

    .position-Icon {
      position: absolute;
      top: -30px;
      display: block !important;
      right: 6px !important;
    }

    .create-QueryModal {
      &.downloadBtn {
        right: 3%;
        top: -50px;
      }
    }

    .headerAdmin {
    }

    .create-QueryModal {
      top: 155px;
      right: 50px !important;
      left: 62%;
    }

    .userdetail_scroll {
      .startdiv {
        margin: 68px 10px 52px 10px !important;

        .heading {
        }

        .manage {
          color: $LABEL_GREY;
        }

        .menu {
          font-size: $FONT_16 !important;
          margin-left: 0px !important;
          flex-wrap: nowrap;
          overflow: hidden;
          overflow-x: scroll;

          a {
            // margin-right: 32px;
          }

          .active {
            margin-right: 32px;
            white-space: nowrap;
          }

          .biling {
            margin-right: 32px;
          }

          .general {
            margin-right: 32px;
          }

          li {
            button {
              width: 90px;
            }

            &:nth-child(2) {
              button {
                width: 130px;
              }
            }
          }
        }

        .detail {
          width: 100%;
          margin-top: 54px !important;
          margin-bottom: 0px !important;

          .change_img {
            font-size: $FONT_14 !important;
          }

          .hookform {
            width: 100% !important;
          }

          .form {
            display: block;
            font-size: $FONT_16 !important;

            .first_line {
              display: block;

              .first_name {
                width: 100%;
              }

              .last_name {
                width: 100%;
              }
            }

            .second_line {
              display: block;
              margin-bottom: 0px !important;

              .email {
                width: 100%;
              }

              .code_number {
                display: flex;
                width: 100%;

                .textField {
                  margin-bottom: 0px !important;
                }
              }
            }

            .button_line {
              display: block;
              margin-top: 40px !important;

              .first_button {
                width: 100%;
                margin-bottom: 24px !important;
              }

              .second_button {
                width: 100%;
              }
            }
          }
        }

        .query-Section {
          .header {
            display: none !important;
          }

          .dataList {
            flex-wrap: wrap;
            height: auto;
            border-top: 1px solid #d9dbe9 !important;
            margin-bottom: 5px !important;
            min-height: 160px;

            li {
              position: relative;
              padding-top: 18px;

              &:nth-child(1) {
                width: 50%;

                &::before {
                  content: "Query ID";
                  position: absolute;
                  top: 0;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                  font-family: $LATO_REGULAR;
                }
              }

              &:nth-child(2) {
                width: 50%;

                &::before {
                  content: "Raised On";
                  position: absolute;
                  top: 0;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                  font-family: $LATO_REGULAR;
                }
              }

              &:nth-child(3) {
                width: 50%;

                &::before {
                  content: "Resolved On";
                  position: absolute;
                  top: 0;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }

              &:nth-child(4) {
                width: 50%;
                margin-top: 0;

                &::before {
                  content: "Message";
                  position: absolute;
                  top: 0;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }

              &:nth-child(5) {
                width: 50%;
                padding-bottom: 10px !important;

                &::before {
                  content: "Comments";
                  position: absolute;
                  top: 0;
                  left: 0;
                  color: $TXT_NAVY;
                  font-size: $FONT_16;
                }
              }
            }
          }
        }
      }

      .change_Password {
        width: 350px;
      }
    }

    .Subscribed {
      top: 0;
      right: 0px;
    }

    .chatboat-section {
      bottom: 5px;
    }

    .create-QueryModal {
      left: 62% !important;
      top: 164px;
    }
  }

  .billing_Section {
    padding-top: 25px !important;

    .main_Container {
      width: 100%;

      .billing_Inner {
        width: 100%;
        padding-left: 0;

        .tabFilerIcon {
          display: block !important;
        }

        .listing_Section {
          width: 100% !important;
          height: 100vh;
        }

        .carddetails_Right {
          top: 0 !important;
          right: 0 !important;
          z-index: 1;
          width: 365px !important;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 30px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          // height: 100vh;
          position: absolute;
          float: none;
          border-radius: 0 !important;
          display: none;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }

          .onClose {
            display: block;
          }
        }
      }
    }
  }

  .create_Query {
    .modal-dialog {
      width: 98%;

      .modal-content {
        .modalInner {
          .modal-body {
            .query_Content {
              .query_form {
                .button_btm {
                  .cancel_Btn {
                    width: 150px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 299px) {
  .userdetail {
    .tabIcon {
      display: block;
    }

    display: none;

    .create-QueryModal {
      &.downloadBtn {
        right: 3%;
        top: -50px;
      }
    }

    .position-Icon {
      position: absolute;
      top: -30px;
      display: block !important;
      right: 6px !important;
    }

    .query-Section {
      .header {
        display: none !important;
      }

      .dataList {
        flex-wrap: wrap;
        height: auto;
        border-top: 1px solid #d9dbe9 !important;
        margin-bottom: 5px !important;
        min-height: 160px;

        li {
          position: relative;
          padding-top: 18px;

          &:nth-child(1) {
            width: 50%;

            &::before {
              content: "Query ID";
              position: absolute;
              top: 0;
              left: 0;
              color: $TXT_NAVY;
              font-size: $FONT_16;
              font-family: $LATO_REGULAR;
            }
          }

          &:nth-child(2) {
            width: 50%;

            &::before {
              content: "Raised On";
              position: absolute;
              top: 0;
              left: 0;
              color: $TXT_NAVY;
              font-size: $FONT_16;
              font-family: $LATO_REGULAR;
            }
          }

          &:nth-child(3) {
            width: 50%;

            &::before {
              content: "Resolved On";
              position: absolute;
              top: 0;
              left: 0;
              color: $TXT_NAVY;
              font-size: $FONT_16;
            }
          }

          &:nth-child(4) {
            width: 50%;
            margin-top: 0;

            &::before {
              content: "Message";
              position: absolute;
              top: 0;
              left: 0;
              color: $TXT_NAVY;
              font-size: $FONT_16;
            }
          }

          &:nth-child(5) {
            width: 50%;
            padding-bottom: 10px !important;

            &::before {
              content: "Comments";
              position: absolute;
              top: 0;
              left: 0;
              color: $TXT_NAVY;
              font-size: $FONT_16;
            }
          }
        }
      }
    }

    .create-QueryModal {
      left: 62% !important;
      top: 164px !important;
    }
  }
}
