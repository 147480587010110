@import "../../assests/scss/variables.scss";

.supportlist_Page {
  overflow: hidden;

  .supportlist_Scroll {
    height: calc(100vh - 150px) !important;
    height: 100vh !important;
    position: relative;

    .main_supportlist {
      width: 100%;
      padding: 64px 333px;
      padding-top: 130px;

      .beadcrumb {
        display: flex;
        padding-bottom: 48px;

        .small {
          padding-bottom: 0;
          color: $LABEL_GREY;
          padding-right: 10px;
        }

        i {
          color: $LABEL_GREY;
          padding-right: 10px;
        }
      }

      .listing {
        .header {
          padding-bottom: 48px;
          border-bottom: 1px solid #000;
          font-size: $FONT_48;
          width: 100%;
          color: $TXT_ORANGE;
        }
      }

      .card-details {
        margin: 60px 0;
        // border: 1px solid;
        border-top: none !important;
        border-radius: 8px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        .header-tab {
          background: $BG_NAVY;
          height: 112px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;

          .header-title {
            font-size: $FONT_32;
            color: $TXT_WHITE;

            width: 100%;
            display: flex;
            align-items: center;
            padding-bottom: 0;
            height: 112px;
            padding-left: 48px;
          }
        }

        .details {
          padding-left: 56px;
          padding-bottom: 48px;

          .top-text {
            font-size: $FONT_48;
            color: $TXT_ORANGE;
            padding-top: 48px;
            padding-bottom: 14px;
          }

          .inner-title {
            color: $TXT_NAVY;
            font-size: $FONT_24;
            font-family: $LATO_SEMIBOLD;

            &.padTop {
              padding-top: 48px;
            }
          }

          .ul {
            li {
              color: $LABEL_GREY;
              font-size: $FONT_24;
              font-family: $LATO_REGULAR;
            }
          }
        }
      }
    }
  }

  .footerheight {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .supportlist_Page {
    .supportlist_Scroll {
      .main_supportlist {
        max-width: 100%;
        margin: 0;
        padding: 64px 100px;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .supportlist_Page {
    .supportlist_Scroll {
      .main_supportlist {
        max-width: 100%;
        padding: 64px 100px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .supportlist_Page {
    .supportlist_Scroll {
      .main_supportlist {
        max-width: 100%;
        padding: 64px 100px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .supportlist_Page {
    .supportlist_Scroll {
      .main_supportlist {
        max-width: 100%;
        padding: 64px 100px;
      }
    }
  }
}

@media only screen and (min-width: 575.5px) and (max-width: 767px) {
  .supportlist_Page {
    .supportlist_Scroll {
      .main_supportlist {
        max-width: 100%;
        padding: 64px 80px;
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575.5px) {
  .supportlist_Page {
    .supportlist_Scroll {
      .main_supportlist {
        max-width: 100%;
        padding: 64px 80px;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .supportlist_Page {
    .supportlist_Scroll {
      .main_supportlist {
        max-width: 100%;
        padding: 105px 20px;
        padding-bottom: 30px;

        .beadcrumb {
          padding-bottom: 25px;
          align-items: center;

          .small {
            font-size: 9px;
            padding-right: 5px;
          }

          i {
            padding-right: 5px;
          }
        }

        .listing {
          .header {
            padding-bottom: 28px;
            font-size: $FONT_28;
          }
        }

        .card-details {
          margin: 50px 0;

          .header-tab {
            display: flex;
            align-items: center;
            height: 52px;

            .header-title {
              font-size: $FONT_24;
              padding-left: 15px;
              height: 52px;
            }
          }

          .details {
            padding: 0 15px;
            padding-bottom: 15px;

            .top-text {
              font-size: $FONT_24;
              padding-top: 20px;
            }

            .inner-title {
              font-size: $FONT_20;

              &.padTop {
                padding-top: 20px;
              }
            }

            .ul {
              li {
                font-size: $FONT_18;
              }
            }
          }
        }
      }
    }
  }
}
