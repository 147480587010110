@import "../../assests/scss/variables.scss";

.tab_Listing {
  display: flex;
  padding: 12px 24px;
  border: 1px solid $BG_LIGHTBLUE;
  background: $BG_OFFLIGHT;
  position: relative;
  // background-color: red;

  .listing_Head {
    color: $TXT_NAVY;
    font-size: $FONT_16;
    line-height: 24px;
    padding: 0;
    text-align: left;
    // padding-right: 30px;
  // background-color: green;


    &.profile {
      width: 15%;
      cursor: pointer;
    }

    &.userName {
      width: 30%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    &.accType {
      width: 28%;
      padding-right: 8px;
    }

    &.status {
      width: 22%;
    }
    &.viwePost{
      width: 10%;
      // background-color: red;
      margin-left:4rem ;
    }

    &.checkBox {
      width: 5%;
      background: $TXT_LIGHT;
      border-radius: 50%;
      max-width: 16px;
      height: 16px;
    }

    &.date {
      color: #4e4b66;
      width: 25%;
    }

    &.icon-timer {
      margin-left: 50px;
    }
    

  }

  .edit_Dropdown {
    position: relative;

    .icon-edit {
      cursor: pointer;
    }
  }

  .dropdown_List {
    list-style: none;
    position: absolute;
    right: 60px;
    top: -30px;
    background: $BG_WHITE;
    margin: 0;
    padding: 16px 10px;
    width: 92px;
    height: 82px;
    border-radius: 8px;
    cursor: pointer;

    li {
      padding-bottom: 2px;

      a {
        font-size: $FONT_14;
        color: $TXT_LIGHTBLUE;
        text-decoration: none;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .bgColor {
    background: #d7d9e5;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-right: 20px;

    i {
      color: #5696fa;
      justify-content: center;
      display: flex;
    }
  }
  .custom-button {
    border: 0px solid #eff0f6; /* Add border */
    background-color: #eff0f6; /* Add background color */
    color: #eff0f6; /* Add text color */
    padding: 0rem 0rem; /* Add padding */
    // font-size: 1rem; /* Add font size */
    // line-height: 1.5; /* Add line height */
    // border-radius: 0.25rem; /* Add border radius */
    cursor: pointer; /* Add cursor style */
    
  }
  
  .custom-button:hover {
    background-color: #e9ecef; /* Add hover background color */
    color: #495057; /* Add hover text color */
  }
  
}

@media only screen and (min-width: 575.5px) and (max-width: 767px) {
  .tab_Listing {
    .listing_Head {
      font-family: $LATO_REGULAR;
    }
  }
}

@media only screen and (min-width: 475.5px) and (max-width: 575.5px) {
  .tab_Listing {
    .listing_Head {
      font-family: $LATO_REGULAR;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475.5px) {
  .tab_Listing {
    .listing_Head {
      font-family: $LATO_REGULAR;
      width: 25% !important;

      &.accType {
        display: none;
      }

      &.userName {
        width: 40% !important;
      }

      &.status {
        width: 30% !important;
      }

      &.checkBox {
        width: 5%;
      }
    }

    .filterDropdown {
      .dropdown_List {
        right: 40px;
      }
    }
  }
}
