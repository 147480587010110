.paginationClass {
  position: fixed;
  bottom: 8px;
  right: 122px;

  .page-link {
    color: #16056b;
    border: 0.5px solid #16056b;
  }

  .page-link:hover {
    border: 1px solid #16056b;
  }

  .page-link:focus {
    z-index: 2;
    color: white;
    text-decoration: none;
    background-color: #16056b;
    border-color: none;
    box-shadow: none;
  }

  span.visually-hidden {
    display: none;
  }
}
