@import "../../assests/scss/variables.scss";

.subscription_Scroll {
  height: calc(100vh - 0px) !important;
  position: relative;

  .plan_Section {
    padding-top: 24px;
    width: 100%;

    .plan {
      width: 32%;
      margin-right: 15px;
      float: left;
      background: white;

      &:last-child {
        margin-right: 0;
      }

      &:nth-child(1) {
        margin-left: 20px;
      }
    }
  }
}

.more-details {
  align-items: center;
  justify-content: end;
  padding-right: 40px;
  padding-top: 33px;

  .plansName {
    padding-bottom: 0;
    padding-right: 5px;
    font-size: $FONT_24;
    font-weight: 700;
    color: #14142a;
  }

  .backIcon {
    transform: rotate(180deg);
    background-color: black;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      color: $BG_WHITE;
      font-size: $FONT_12;
    }
  }
}

.subscriptiondropdown_Section {
  width: 220px;
  position: absolute;
  right: 18px;
  top: 69px;

  .customDropdown {
    .dropdown {
      .btn-primary {
        border: 1px solid $TXT_NAVY !important;
        padding: 6.5px 16px !important;
        color: #16056b !important;
        border-radius: 10px;

        &:after {
          position: absolute;
          right: 22px;
          top: 16px;
          color: $TXT_BLUE;
        }
      }
    }
  }

  .btn-primary {
    color: $TXT_NAVY !important;
  }
}

.subscription_Page {
  overflow: hidden;
  background: $BG_WHITE;

  .main_SubscriptionPage {
    width: 100%;

    .subscription_Inner {
      display: flex;
      flex-direction: column;
      width: 100%;

      .price_Section {
        // padding: 48px 13px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 0;
        background: $BG_LIGHT;

        .main_title {
          font-size: $FONT_48;
          font-family: $LATO_LIGHT;
          color: $TXT_NAVY;
          padding-top: 48px;
        }

        .decoration {
          position: absolute;
          left: 25px;
          top: 58px;

          .icon-back {
            color: $TXT_LIGHTBLACK;
            display: block;
            padding-top: 20px;
          }
        }

        .inner_title {
          font-size: $FONT_24;
          font-family: $LATO_REGULAR;
          width: 1160px;
          color: $TXT_LIGHTGREY;
          text-align: center;
          padding-bottom: 40px;
          line-height: 35px;
        }

        .sub_Section {
          width: 100%;
          display: flex;
          flex-direction: column;

          .toggle_Section {
            display: flex;
            align-items: center;

            .plansName {
              color: $TXT_LIGHTBLACK;
              font-size: 24px;
              font-family: $LATO_REGULAR;
              padding-bottom: 0;
            }

            .switch {
              position: relative;
              display: inline-block;
              width: 64px;
              height: 36px;
              margin: 0 16px;

              input {
                opacity: 0;
                width: 0;
                height: 0;
              }
            }

            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ccc;
              -webkit-transition: 0.4s;
              transition: 0.4s;

              &:before {
                position: absolute;
                content: "";
                height: 36px;
                width: 36px;
                left: 0;
                bottom: 0;
                background-color: $BG_WHITE;
                transition: 0.4s;
                border: 1px solid $TXT_BLUELIGHT;
              }
            }

            input:checked + .slider {
              background-color: #2196f3;
            }

            input:focus + .slider {
              box-shadow: 0 0 1px #2196f3;
            }

            input:checked + .slider:before {
              -webkit-transform: translateX(26px);
              -ms-transform: translateX(26px);
              transform: translateX(26px);
            }

            /* Rounded sliders */
            .slider.round {
              border-radius: 34px;

              &:before {
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }

  .feature_Section {
    margin-top: 91px;

    border: 1px solid $BG_DARKGREY;
    margin: 30px 22px !important;

    .plan_Sections {
      background-color: $TXT_BLUELIGHT;

      padding: 48px 64px;

      .plan_Items {
        width: 20%;

        .plansName {
          font-size: 32px;
          font-weight: $LATO_BOLD;
          color: $TXT_LIGHTBLACK;
          padding-bottom: 5px;

          &.blue {
            color: $TXT_DARKESTBLUE !important;
          }
        }

        .lightText {
          font-size: $FONT_20;
          font-family: $LATO_REGULAR !important;
          color: $TXT_BLACK !important;
        }

        &:nth-child(1) {
          width: 40%;
        }

        .toggle_Section {
          input:checked + .slider {
            background-color: $BG_WHITE !important;
          }

          input:focus + .slider {
            box-shadow: 0 0 1px #2196f3;
          }

          input:checked + .slider:before {
            -webkit-transform: translateX(74px) !important;
            -ms-transform: translateX(74px) !important;
            transform: translateX(74px) !important;
          }

          .switch {
            width: 110px !important;

            .slider {
              background-color: $BG_WHITE;
              border: 2px solid $TXT_DARKESTBLUE;

              &:before {
                background-color: $BG_DARKESTBLUE;
                height: 32px;
                width: 32px;
              }
            }
          }

          .titleText {
            padding: 0;
            color: $TXT_NAVY;
          }

          .bg-section {
            background: $BG;
            width: 220px;
            display: table;
            border-radius: 30px;
            margin-left: 10px;

            .smallText {
              padding-bottom: 0;
              display: flex;
              justify-content: center;
              font-size: 32px;
              font-weight: $LATO_BOLD;
              color: $TXT_DARKGREEN;
            }
          }
        }
      }
    }

    .items_Section {
      padding: 48px 64px;

      .item-listing {
        padding: 17px 0;
        border-bottom: 1px solid #979797;

        &.blueBorder {
          border-color: $BG_DARKESTBLUE !important;
        }

        &.noBorder {
          border: none !important;
          padding-top: 26px;

          .subscribed {
            width: 198px !important;
            height: 80px !important;

            &.noBg {
              background-color: $BG_WHITE !important;
              color: $TXT_DARKESTBLUE !important;
              border: 1px solid $TXT_DARKESTBLUE !important;
            }
          }

          .smallTxt {
            font-size: $FONT_16;
            padding-left: 20px;
            padding-top: 16px;
            color: $TXT_BLACK;
            font-family: $LATO_SEMIBOLD;
          }

          .subscribed {
            height: 60px !important;
          }
        }

        .monthly {
          width: 40%;
        }

        .basic {
          width: 20%;
        }

        .list {
          font-size: $FONT_24;
          font-family: $LATO_SEMIBOLD;
          color: $TXT_BLACK;

          &.bold {
            font-family: $LATO_BOLD;
          }
        }

        .titleText {
          padding-bottom: 0 !important;
        }

        .bgClr {
          width: 30px;
          height: 25px;
          border-radius: 100%;
          background-color: #d0def9;
          margin-top: 6px;
        }
      }

      .paddingLeft {
        padding-left: 45px;
        font-size: $FONT_20;

        .iconGreen {
          color: $TXT_DARKGREEN;
        }

        .iconRed {
          color: $TEXT_RED;
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .subscription_Page {
    .main_SubscriptionPage {
      .subscription_Inner {
        .price_Section {
          .inner_title {
            width: 900px;
          }
        }
      }
    }

    .feature_Section {
      display: none;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .subscription_Page {
    .main_SubscriptionPage {
      .subscription_Inner {
        .price_Section {
          .inner_title {
            width: 850px;
          }

          .sub_Section {
            .subscription_Scroll {
              .plan_Section {
                display: flex;
                flex-direction: column;
                align-items: center;

                .plan {
                  width: 60%;
                  margin-right: 0;
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
      }
    }

    .feature_Section {
      display: none;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscription_Page {
    .main_SubscriptionPage {
      .subscription_Scroll {
        .subscription_Inner {
          padding-top: 87px !important;

          .price_Section {
            .inner_title {
              width: 700px;
            }

            .main_title {
              font-size: $FONT_32;
            }

            .sub_Section {
              .plan_Section {
                display: flex;
                flex-direction: column;
                align-items: center;

                .plan {
                  width: 100%;
                  margin-right: 0;
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
      }
    }

    .feature_Section {
      .plan_Sections {
        padding: 48px 5px;
        padding-top: 10px;

        .plan_Items {
          position: relative;

          .toggle_Section {
            .switch {
              width: 91px !important;
            }
          }

          .plansName {
            font-size: 18px !important;
          }

          .bg-section {
            position: absolute;
            top: 70px !important;
            width: 165px !important;
            margin-left: 0 !important;

            .smallText {
              font-size: 24px !important;
            }
          }

          .lightText {
            display: none !important;
          }
        }
      }

      .items_Section {
        padding: 48px 4px;
        padding-top: 10px;

        .item-listing {
          .list {
            font-size: 18px;
          }

          .bgClr {
            width: 25px;
            margin-top: 0;
          }

          &.noBorder {
            .subscribed {
              width: 132px !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .subscription_Page {
    .main_SubscriptionPage {
      .subscription_Scroll {
        .subscription_Inner {
          .price_Section {
            .inner_title {
              width: 500px;
              font-size: 14px;
              line-height: 24px;
            }

            .main_title {
              font-size: $FONT_32;
            }

            .sub_Section {
              .plan_Section {
                display: flex;
                flex-direction: column;
                align-items: center;

                .plan {
                  width: 80%;
                  margin-right: 0;
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
      }
    }

    .feature_Section {
      display: none;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 575px) {
  .subscription_Page {
    .main_SubscriptionPage {
      .subscription_Scroll {
        .subscription_Inner {
          .price_Section {
            .inner_title {
              width: 300px;
              font-size: 14px;
              line-height: 24px;
            }

            a {
              top: 50px;
            }

            .main_title {
              font-size: $FONT_24;
              padding-left: 15px;
            }

            .sub_Section {
              .plan_Section {
                display: flex;
                flex-direction: column;
                align-items: center;

                .plan {
                  width: 90%;
                  margin-right: 0;
                  margin-bottom: 16px;
                }
              }
            }

            .toggle_Section {
              .plansName {
                font-size: $FONT_14;
              }

              .switch {
                width: 40px;
                height: 22px;
              }

              .slider {
                &:before {
                  width: 18px;
                  height: 18px;
                  bottom: 2px;
                }
              }

              input:checked + .slider:before {
                transform: translateX(21px);
              }
            }
          }
        }
      }
    }

    .feature_Section {
      display: none;
    }
  }
}
