@import "../../assests/scss/variables.scss";

.feature_Page {
  overflow: hidden;

  .feature_Scroll {
    height: calc(100vh - 150px) !important;
    height: 100vh !important;
    position: relative;

    .main_featurePage {
      width: 100%;

      .feature_Inner {
        display: flex;
        flex-direction: column;
        width: 100%;

        .get_Start {
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding-bottom: 100px;
          align-items: center;
          padding-left: 30px;

          .content {
            display: flex;
            flex-direction: column;
            width: 1081px;
            margin-right: 97px;

            button {
              width: 230px;
              background: $BG_ORANGE !important;
              border-radius: 100px !important;
              margin-top: 16px;

              &.outline_Btn {
                border: 1px solid $BG_ORANGE !important;
                background: transparent !important;
                color: $TXT_ORANGE !important;
              }
            }

            .titleText {
              font-size: $FONT_48;
              font-family: $LATO_BOLD;
              color: $TXT_NAVY;
              line-height: 65px;
              // width: 650px;
            }

            .inner_Text {
              font-size: $FONT_24;
              font-family: $LATO_SEMIBOLD;
              color: $TXT_LIGHTGREY;
              line-height: 30px;
            }
          }

          .img_Section {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .main_Img {
              max-width: 100%;
            }
          }
        }

        .advantage_Section {
          background: $BG_LIGHT;
          padding: 80px 60px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .header {
            font-size: $FONT_32;
            font-family: $LATO_SEMIBOLD;
            color: $TXT_NAVY;
          }

          .inner_title {
            font-size: $FONT_24;
            font-family: $LATO_REGULAR;
            width: 1160px;
            color: $TXT_LIGHTGREY;
            text-align: center;
            padding-bottom: 83px;
          }

          .adv_Section {
            width: 100%;
            display: flex;
            justify-content: center;

            .adv_Inner {
              width: 33%;
              float: left;
              margin-right: 95px;

              &:last-child {
                margin: 0;
              }

              .content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .main-Title {
                  font-size: $FONT_24;
                  font-family: $LATO_SEMIBOLD;
                  color: $TXT_NAVY;
                  padding-bottom: 4px;
                }

                .desp {
                  font-size: $FONT_16;
                  font-family: $LATO_REGULAR;
                  color: $TXT_LIGHTGREY;
                  line-height: 22px;
                  text-align: center;
                  padding-bottom: 0;
                }

                button {
                  width: 143px;
                  background: $BG_ORANGE !important;
                  border-radius: 100px !important;
                  height: 53px !important;
                  font-size: $FONT_14;
                  font-family: $LATO_SEMIBOLD;
                  margin-left: 44px;
                }
              }
            }
          }
        }

        .top_Feature {
          display: flex;
          align-items: center;
          padding: 80px 0;
          align-self: center;
          flex-direction: column;

          .header_Section {
            .header {
              font-size: $FONT_32;
              font-family: $LATO_SEMIBOLD;
              color: $TXT_NAVY;
              text-align: center;
            }

            .inner_title {
              font-size: $FONT_24;
              font-family: $LATO_REGULAR;
              width: 1160px;
              color: $TXT_LIGHTGREY;
              text-align: center;
              padding-bottom: 83px;
            }
          }

          .img_Content {
            padding-bottom: 42px;

            .img_Section {
              width: 40%;
              float: left;

              img {
                width: 100%;

                &.resp_Show {
                  display: none;
                }
              }
            }

            .content_Section {
              width: 60%;
              float: left;
              padding-left: 30px;

              .top_Title {
                font-size: $FONT_24;
                color: $TXT_NAVY;
                font-family: $LATO_SEMIBOLD;
              }

              .inner_Title {
                font-size: $FONT_16;
                color: $LABEL_GREY;
                font-family: $LATO_REGULAR;
                max-width: 670px;
                line-height: 30px;
              }
            }
          }
        }

        .video_Section {
          display: flex;
          justify-content: space-between;
          padding: 0 82px;
          background: $BG_NAVY;

          .img_Content {
            padding: 116px 0;

            button {
              width: 230px;
              background: $BG_ORANGE !important;
              border-radius: 100px !important;
              height: 68px !important;
              margin-top: 44px;
            }
          }

          .img_Section {
            width: 50%;
            float: left;

            img {
              width: 100%;

              &.img_Show {
                display: none;
              }
            }
          }

          .content_Section {
            width: 50%;
            float: left;
            padding-right: 30px;

            .top_Title {
              font-size: $FONT_40;
              font-family: $LATO_BOLD;
              color: $TXT_WHITE;
              width: 304px;
              line-height: 55px;
            }

            .main_Title {
              font-size: $FONT_48;
              color: $TXT_NAVY;
              font-family: $LATO_BOLD;
              color: $TXT_WHITE;
            }

            .inner_Title {
              font-size: $FONT_24;
              color: $TXT_LIGHTGREY;
              font-family: $LATO_REGULAR;
              max-width: 790px;
              line-height: 30px;
              padding: 0;
              color: $TXT_WHITE;
            }
          }
        }

        .imgSlider_Section {
          padding: 100px 174px;

          .main_Title {
            font-size: $FONT_32;
            font-family: $LATO_SEMIBOLD;
            padding-bottom: 100px;
            color: $TXT_NAVY;
            text-align: center;
            width: 100%;
          }

          .slider_Section {
            .slick-prev {
              z-index: 11;
            }

            .image_Slider {
              width: 461px !important;

              img {
                width: 100%;
              }
            }

            .slick-initialized {
              .slick-slide {
                display: flex;
                justify-content: center;
              }
            }
          }

          .main_Heading {
            color: $TXT_NAVY;
          }
        }
      }
    }
  }

  .footer_Section {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 1599px) and (max-width: 1800px) {
  .feature_Page {
    .feature_Scroll {
      .main_featurePage {
        .feature_Inner {
          .imgSlider_Section {
            padding: 100px 80px;

            .slider_Section {
              .image_Slider {
                width: 400px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .feature_Page {
    .feature_Scroll {
      .main_featurePage {
        .feature_Inner {
          .get_Start {
            padding-left: 80px;

            .content {
              .title_Main {
                font-size: $FONT_40 !important;
                line-height: 40px;
              }
            }
          }

          .advantage_Section {
            .inner_title {
              width: 1024px;
            }
          }

          .video_Section {
            padding: 0 82px;

            .content_Section {
              padding-top: 120px;
            }
          }

          .imgSlider_Section {
            padding: 100px 80px;

            .slider_Section {
              .image_Slider {
                width: 380px !important;
              }
            }
          }

          .input_Section {
            .content_Textbox {
              .img_Section {
                margin-right: 100px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .feature_Page {
    .feature_Scroll {
      .main_featurePage {
        .feature_Inner {
          .get_Start {
            padding-left: 80px;

            .content {
              width: 1022px;
              margin-right: 20px;

              .title_Main {
                font-size: $FONT_38 !important;
                line-height: 40px;
              }
            }
          }

          .advantage_Section {
            .inner_title {
              width: 1000px;
            }
          }

          .top_Feature {
            padding: 100px 30px;

            .content_Section {
              padding-left: 80px;
            }
          }

          .video_Section {
            padding: 0 30px;

            .content_Section {
              padding-top: 120px;
            }
          }

          .manage_Section {
            .content_Section {
              .main_Title {
                max-width: 600px;
              }

              .inner_Title {
                max-width: 600px;
              }
            }
          }

          .imgSlider_Section {
            padding: 100px 60px;

            .slider_Section {
              .image_Slider {
                width: 420px !important;
              }
            }
          }

          .input_Section {
            padding: 100px 112px 200px 112px;

            .content_Textbox {
              .img_Section {
                margin-right: 80px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .feature_Page {
    .feature_Scroll {
      .main_featurePage {
        .feature_Inner {
          padding-top: 8px;

          .get_Start {
            flex-direction: column-reverse;
            padding: 0;

            .content {
              margin: 0;
              padding: 166px 78px 128px 78px;
              width: 100%;

              .title_Main {
                font-size: $FONT_40 !important;
                line-height: 50px;
              }
            }
          }

          .advantage_Section {
            .adv_Section {
              flex-direction: column;

              .adv_Inner {
                width: 100%;
                padding-bottom: 30px;
              }
            }

            .inner_title {
              width: 100%;
              padding: 0 15px;
              padding-bottom: 40px !important;
            }

            .preview_Section {
              .tab-content {
                .adv_Section {
                  flex-direction: column;

                  .adv_Inner {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 32px;
                  }
                }
              }
            }
          }

          .top_Feature {
            padding: 60px 30px;
            flex-direction: column;

            .header_Section {
              .inner_title {
                width: 660px;
              }
            }

            .img_Content {
              display: flex;
              flex-direction: column;
            }

            .img_Section {
              width: 100% !important;
              padding-bottom: 50px;
            }

            .content_Section {
              padding-left: 0;
              width: 100% !important;

              .main_Title {
                text-align: center;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
              }
            }
          }

          .video_Section {
            padding: 0 30px;
            flex-direction: column !important;

            .img_Content {
              flex-direction: column;
              padding-top: 40px;
            }

            .img_Sections {
              width: 100%;
              // padding-bottom: 100px;
              padding-top: 48px;

              img {
                width: 100%;
              }

              .img_Hide {
                display: none;
              }

              .img_Show {
                display: block !important;
              }
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              padding-top: 25px;
              padding-right: 0 !important;
              align-items: center;
              display: flex;
              flex-direction: column;

              .top_Title {
                width: 100%;
                justify-content: center;
                display: flex;
              }

              .main_Title {
                text-align: center;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
              }
            }
          }

          .imgSlider_Section {
            padding: 60px 60px;

            .slider_Section {
              .image_Slider {
                width: 430px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.5px) {
  .feature_Page {
    .feature_Scroll {
      .main_featurePage {
        .feature_Inner {
          padding-top: 8px;

          .get_Start {
            flex-direction: column-reverse;
            padding: 0;

            .content {
              margin: 0;
              padding: 166px 78px 128px 78px;
              width: 100%;

              .title_Main {
                font-size: $FONT_40 !important;
                line-height: 50px;
              }
            }
          }

          .advantage_Section {
            .adv_Section {
              flex-direction: column;

              .adv_Inner {
                width: 100%;
                padding-bottom: 30px;
              }
            }

            .inner_title {
              width: 100%;
              padding: 0 15px;
              padding-bottom: 40px !important;
            }

            .preview_Section {
              .tab-content {
                .adv_Section {
                  flex-direction: column;

                  .adv_Inner {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 32px;
                  }
                }
              }
            }
          }

          .top_Feature {
            padding: 60px 30px;
            flex-direction: column;

            .header_Section {
              .inner_title {
                width: 660px;
              }
            }

            .img_Content {
              display: flex;
              flex-direction: column;
            }

            .img_Section {
              width: 100% !important;
              padding-bottom: 50px;
            }

            .content_Section {
              padding-left: 0;
              width: 100% !important;

              .main_Title {
                text-align: center;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
              }
            }
          }

          .video_Section {
            padding: 0 30px;
            flex-direction: column !important;

            .img_Content {
              flex-direction: column;
              padding-top: 40px;
            }

            .img_Sections {
              width: 100%;
              // padding-bottom: 100px;
              padding-top: 48px;

              img {
                width: 100%;
              }

              .img_Hide {
                display: none;
              }

              .img_Show {
                display: block !important;
              }
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              padding-top: 25px;
              padding-right: 0 !important;
              align-items: center;
              display: flex;
              flex-direction: column;

              .top_Title {
                width: 100%;
                justify-content: center;
                display: flex;
              }

              .main_Title {
                text-align: center;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
              }
            }
          }

          .imgSlider_Section {
            padding: 60px 60px;

            .slider_Section {
              .image_Slider {
                width: 660px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 575.5px) and (max-width: 767px) {
  .feature_Page {
    .feature_Scroll {
      .main_featurePage {
        .feature_Inner {
          padding-top: 8px;

          .get_Start {
            flex-direction: column-reverse;
            padding: 0;

            .content {
              margin: 0;
              padding: 166px 78px 128px 78px;
              width: 100%;

              .title_Main {
                font-size: $FONT_40 !important;
                line-height: 50px;
              }
            }
          }

          .advantage_Section {
            .adv_Section {
              flex-direction: column;

              .adv_Inner {
                width: 100%;
                padding-bottom: 30px;
              }
            }

            .inner_title {
              width: 100%;
              padding: 0 15px;
              padding-bottom: 40px !important;
            }

            .preview_Section {
              .tab-content {
                .adv_Section {
                  flex-direction: column;

                  .adv_Inner {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 32px;
                  }
                }
              }
            }
          }

          .top_Feature {
            padding: 60px 30px;
            flex-direction: column;

            .header_Section {
              .inner_title {
                width: 660px;
              }
            }

            .img_Content {
              display: flex;
              flex-direction: column;
            }

            .img_Section {
              width: 100% !important;
              padding-bottom: 50px;
            }

            .content_Section {
              padding-left: 0;
              width: 100% !important;

              .main_Title {
                text-align: center;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
              }
            }
          }

          .video_Section {
            padding: 0 30px;
            flex-direction: column !important;

            .img_Content {
              flex-direction: column;
              padding-top: 40px;
            }

            .img_Sections {
              width: 100%;
              // padding-bottom: 100px;
              padding-top: 48px;

              img {
                width: 100%;
              }

              .img_Hide {
                display: none;
              }

              .img_Show {
                display: block !important;
              }
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              padding-top: 25px;
              padding-right: 0 !important;
              align-items: center;
              display: flex;
              flex-direction: column;

              .top_Title {
                width: 100%;
                justify-content: center;
                display: flex;
              }

              .main_Title {
                text-align: center;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
              }
            }
          }

          .imgSlider_Section {
            padding: 60px 60px;

            .slider_Section {
              .image_Slider {
                width: 660px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 575.5px) {
  .feature_Page {
    .feature_Scroll {
      .main_featurePage {
        .feature_Inner {
          .get_Start {
            flex-direction: column-reverse;
            padding: 0;

            .content {
              margin: 0;
              padding: 20px;
              width: 100%;

              .title_Main {
                font-size: $FONT_24 !important;
                line-height: 30px;
              }

              button {
                margin-top: 0;
                height: 50px;
              }
            }
          }

          .advantage_Section {
            padding: 30px;

            .adv_Section {
              flex-direction: column;

              .adv_Inner {
                width: 100%;
                padding-bottom: 30px;
              }
            }

            .inner_title {
              width: 100%;
              padding: 0 15px;
              padding-bottom: 40px !important;
            }

            .preview_Section {
              .tab-content {
                .adv_Section {
                  flex-direction: column;

                  .adv_Inner {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 32px;
                  }
                }
              }
            }
          }

          .top_Feature {
            padding: 60px 30px;
            flex-direction: column;
            padding-left: 10px !important;

            .header_Section {
              .inner_title {
                width: 300px;
              }
            }

            .img_Content {
              display: flex;
              flex-direction: column;
            }

            .img_Section {
              width: 100% !important;
              padding-bottom: 50px;
            }

            .content_Section {
              padding-left: 0 !important;
              width: 100% !important;

              .main_Title {
                text-align: center;
              }

              .inner_Title {
                max-width: 100%;
                text-align: left;
              }
            }
          }

          .video_Section {
            padding: 0 15px;
            flex-direction: column !important;

            .img_Content {
              flex-direction: column;
              padding-top: 20px;
            }

            .img_Sections {
              width: 100%;
              padding-top: 48px;

              img {
                width: 100%;
              }

              .img_Hide {
                display: none;
              }

              .img_Show {
                display: block !important;
              }
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              padding-top: 0;
              padding-right: 0 !important;
              align-items: center;
              display: flex;
              flex-direction: column;

              .top_Title {
                width: 100%;
                justify-content: center;
                display: flex;
                font-size: $FONT_24;
              }

              .main_Title {
                text-align: center;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
                font-size: $FONT_16;
              }
            }
          }

          .imgSlider_Section {
            padding: 30px;

            .slider_Section {
              .slick-prev {
                left: -14px;
              }

              .slick-next {
                right: -9px;
              }
            }

            .main_Title {
              padding-bottom: 50px !important;
            }

            .slider_Section {
              .image_Slider {
                width: 315px !important;
              }
            }
          }
        }
      }
    }
  }
}
