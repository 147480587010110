@import "../../assests/scss/variables.scss";

.mainPublisher {
  .publisherAll_Page {
    .publish_Page {
      display: contents;
      overflow: hidden;
      width: 100% !important;
    }

    .schedule_Page_Parent {
      display: contents;
      overflow: hidden;
      width: 100% !important;
    }

    .published_Page {
      display: contents;
      overflow: hidden;
      width: 100% !important;
    }

    .failed_Page {
      display: contents;
      overflow: hidden;
      width: 100% !important;
    }

    .draft_Page {
      display: contents;
      overflow: hidden;
      width: 100% !important;
    }
  }
}
