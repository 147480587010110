@import "../../assests/scss/variables.scss";

.export_Form {
  max-width: 620px !important;
}

.filterDispute {
  display: table;
  width: 100%;

  .form {
    padding: 32px 24px;
    display: table;
    width: 100%;

    .filterItem {
      width: 100%;
      float: left;

      .header_Section {
        border-bottom: 1px solid $TXT_BLUELIGHT;

        .heading {
          display: flex;
          justify-content: space-between;
          padding-bottom: 24px;
        }

        .titleText {
          padding-top: 0px;
          padding-bottom: 0;
          padding-left: 0px;
          font-size: $FONT_24;
          font-family: $LATO_REGULAR;
          color: $TXT_LIGHTBLACK;
          font-weight: 600;

          &.staffTitle {
            padding-top: 16px;
          }
        }

        .icon-close {
          font-size: $FONT_16;
          cursor: pointer;
        }
      }

      .calender_Section {
        padding-top: 24px;
        padding-bottom: 0;

        .date_Filter {
          display: flex;
          justify-content: space-between;
          padding-bottom: 16px;

          .date_Title {
            font-size: $FONT_16;
            font-weight: 500;
            color: $TXT_LIGHTBLACK;
            padding: 0;
            align-self: center;
          }

          .filter_Title {
            font-size: $FONT_16;
            font-weight: 600;
            color: $TXT_BLUE;
            padding: 0;
            text-decoration: none;
          }
        }

        .date_Part {
          display: flex;

          .calenderMain {
            position: relative;
            padding-bottom: 8px;
            width: 98%;
            margin-left: 0;
            margin-right: 1%;

            &.enddate {
              margin-left: 1%;
              margin-right: 0;
            }

            i {
              position: absolute;
              right: 24px;
              top: 18px;
              color: $TXT_BLUE;
            }

            .react-datepicker-wrapper {
              .react-datepicker__input-container {
                input {
                  width: 100%;
                  height: 56px;
                  border: 1px solid $TXT_LIGHT;
                  border-radius: 8px;
                  padding: 0 16px;
                  color: $TXT_LIGHTBLUE;
                  font-size: $FONT_14;
                  font-weight: 500;

                  &:focus {
                    border: 1px solid $TXT_LIGHT !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    .custombtnfield {
      padding-top: 24px;
      width: 100%;
      margin: 0;
      display: table;
      margin: 0 auto;

      .submitBtn {
        &.btn {
          float: left;
          padding: 8px 0 8px 0 !important;
          height: 60px !important;
          background: transparent !important;
          color: $TXT_NAVY !important;
          border: 1px solid $TXT_NAVY !important;
          margin-right: 0 !important;
        }

        &:nth-child(2) {
          margin-left: 8px;
          background: $TXT_NAVY !important;
          color: $TXT_WHITE !important;
          border: 1px solid $TXT_NAVY !important;
          margin-right: 0;
        }
      }
    }
  }

  .react-datepicker-popper {
    width: 100%;
    margin-top: 1px !important;

    .react-datepicker {
      width: 100%;
      max-width: 344px;
      float: left;
      border: none;
      padding: 14px;
      -webkit-box-shadow: $BOX_SHADOW;
      -moz-box-shadow: $BOX_SHADOW;
      box-shadow: $BOX_SHADOW;

      .react-datepicker__navigation {
        outline: none;

        &.react-datepicker__navigation--previous {
          left: 10px;
          border-right-color: $TXT_BLUE;
        }

        &.react-datepicker__navigation--next {
          right: 10px;
          border-left-color: $TXT_BLUE;
        }
      }

      .react-datepicker__month-container {
        width: 100%;
        max-width: 344px;

        .react-datepicker__header {
          border-color: $BORDER;
          background-color: transparent;
          padding: 0;

          .react-datepicker__current-month {
            color: $TXT_NAVY;
            font-family: $LATO_REGULAR;
          }

          .react-datepicker__day-names {
            display: flex;
            justify-content: space-around;

            .react-datepicker__day-name {
              color: $TXT_LIGHTBLACK;
              font-family: $LATO_REGULAR;
              font-weight: 600;
            }
          }
        }

        .react-datepicker__month {
          .react-datepicker__week {
            display: flex;
            justify-content: space-around;

            .react-datepicker__day {
              color: $TXT_LIGHTBLACK;
              outline: none;

              &.react-datepicker__day--keyboard-selected {
                background-color: $TXT_ORANGE;
                color: $TXT_WHITE;
                border-radius: 50%;
              }

              &.react-datepicker__day--selected {
                background-color: $TXT_ORANGE;
                color: $TXT_WHITE;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    .react-datepicker__triangle {
      display: none;
    }
  }
}
