@import "../../../assests/scss/variables.scss";

.customDropdown {
  margin: 0 !important;
  border-radius: 45px;

  .dropdown {
    width: 100%;
    text-align: left;

    .btn-primary {
      // position: relative;
      background-color: $BG_WHITE !important;
      width: 100%;
      font-size: $FONT_14 !important;
      font-weight: normal;
      text-align: left;
      padding: 7px 16px !important;
      border-radius: 5px;
      border: 1px solid $BORDER !important;
      font-family: $LATO_REGULAR;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:focus {
        box-shadow: none !important;
        border: 1px solid;
      }

      &:active {
        color: grey;
        border: 1px solid;
      }
    }

    .dropdown-menu {
      width: 100%;
      padding: 0;
      border: none;
      border-radius: 0;

      .dropdownData {
        display: block;
        width: 100%;
        max-height: 180px;
        overflow-y: scroll;
        padding: 0;
        padding: 8px 0;
        scrollbar-color: #d2d2d2;
        border-radius: 5px;
        border: 1px solid $BORDER;
        -webkit-box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
          0px 20px 32px rgba(96, 97, 112, 0.24);
        -moz-box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
          0px 20px 32px rgba(96, 97, 112, 0.24);
        box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
          0px 20px 32px rgba(96, 97, 112, 0.24);

        &::-webkit-scrollbar {
          width: 6px;
          display: none;
        }

        .dropdown-item {
          font-size: $FONT_16;
          font-weight: normal;
          padding: 0;
          font-weight: 500 !important;
          padding: 8px 15px !important;
          color: $TXT_LIGHTBLACK;
          font-family: $LATO_REGULAR;

          &:last-child {
            border: none;
          }

          &:hover {
            background: transparent;
            // color: $TXT_WHITE;
          }

          &:active {
            color: Black;
          }

          &:first-child {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
        }
      }
    }

    &.selected {
      .btn-primary {
        color: red !important;
      }
    }

    &.show {
      .btn-primary {
        &:after {
          color: $ARROW;
        }
      }
    }
  }
}
