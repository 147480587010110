@import "../../assests/scss/variables.scss";

.landing_Page {
  overflow: hidden;

  .landing_Scroll {
    height: calc(100vh - 150px) !important;
    height: 100vh !important;
    position: relative;

    .main_LandingPage {
      width: 100%;
      padding-top: 60px;

      .landing_Inner {
        display: flex;
        flex-direction: column;
        width: 100%;

        .get_Start {
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding-bottom: 100px;
          align-items: center;
          padding-left: 174px;

          .content {
            display: flex;
            flex-direction: column;
            width: 1034px;
            margin-right: 97px;

            button {
              width: 230px;
              background: $BG_ORANGE !important;
              border-radius: 100px !important;
              margin-top: 16px;
            }

            .titleText {
              font-size: $FONT_48;
              font-family: $LATO_BOLD;
              color: $TXT_NAVY;
              line-height: 65px;
              // width: 650px;
            }

            .inner_Text {
              font-size: $FONT_24;
              font-family: $LATO_SEMIBOLD;
              color: $TXT_LIGHTGREY;
              line-height: 30px;
            }
          }

          .img_Section {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .main_Img {
              max-width: 100%;
            }
          }
        }

        .price_Section {
          background: $BG_LIGHT;
          padding: 80px 60px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .main_title {
            font-size: $FONT_32;
            font-family: $LATO_SEMIBOLD;
            color: $TXT_NAVY;
          }

          .inner_title {
            font-size: $FONT_24;
            font-family: $LATO_REGULAR;
            width: 1160px;
            color: $TXT_LIGHTGREY;
            text-align: center;
            padding-bottom: 40px;
          }

          .Monthly_yearly_tab {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .nav-tabs {
              border: none;
              padding: 0;
              border-radius: 28px !important;
              width: auto;
              background: $MENUBORDER;

              .nav-item {
                border-bottom: none !important;
                // padding: 8px 30px !important;
                width: 150px;
                display: flex;
                justify-self: center;
                margin: 0;

                .active {
                  border-bottom: none !important;
                  // padding: 8px 20px !important;
                  width: 150px;
                  display: flex;
                  justify-self: center;
                  margin: 0;
                  color: white;
                  background: #16056b;
                  height: 100%;
                  border-radius: 60px;
                }

                button {
                  padding: 8px 48px !important;
                  border: none;
                }
              }

              a {
                margin: 0;
                padding: 0 !important;
                display: flex;
                justify-content: center;
                height: 40px;
                align-items: center;
                padding-left: 45px !important;
                font-size: $FONT_16;
                font-family: $LATO_BOLD;
                color: $TXT_NAVY;

                &.active {
                  border-bottom: 0;
                  border-radius: 28px;
                  width: 150px;
                  padding-left: 0 !important;
                  background: $BG_NAVY !important;
                  color: $TXT_WHITE !important;

                  &:last-child {
                    margin-left: 39px;
                  }
                }
              }
            }

            .tab-content {
              border: none;

              .tab_Section {
                width: 100%;
                display: flex;
                justify-content: center;

                .price_TabInner {
                  width: 33%;
                  float: left;
                  margin-right: 95px;

                  &:last-child {
                    margin: 0;
                  }

                  .content {
                    display: flex;
                    flex-direction: column;

                    .label_Inline {
                      display: flex;
                      align-items: end;

                      .no {
                        padding: 0;
                        transform: rotate(270deg);
                        font-size: $FONT_24;
                        font-family: $LATO_SEMIBOLD;
                        color: $TXT_LIGHTGREY;
                        position: relative;

                        &:before {
                          content: "";
                          position: absolute;
                          top: 12px;
                          left: -30px;
                          width: 20px;
                          height: 1px;
                          background: $TXT_LIGHT;
                        }
                      }

                      .main_Text {
                        font-size: $FONT_24;
                        font-family: $LATO_SEMIBOLD;
                        color: $TXT_NAVY;
                        padding-left: 15px;
                        padding-bottom: 4px;
                      }

                      .amt {
                        display: flex;
                        width: 100%;
                        justify-content: flex-end;
                        font-size: $FONT_24;
                        font-family: $LATO_SEMIBOLD;
                        color: $TXT_NAVY;
                      }
                    }

                    .desp {
                      padding-left: 44px;
                      padding-bottom: 42px;
                      font-size: $FONT_16;
                      font-family: $LATO_REGULAR;
                      color: $TXT_LIGHTGREY;
                      line-height: 22px;
                      height: 130px;
                    }

                    button {
                      width: 143px;
                      background: $BG_ORANGE !important;
                      border-radius: 100px !important;
                      height: 53px !important;
                      font-size: $FONT_14;
                      font-family: $LATO_SEMIBOLD;
                      margin-left: 44px;
                    }
                  }

                  &.basic {
                    button {
                      margin-top: 22px;
                    }
                  }
                }
              }
            }
          }
        }

        .time_Section {
          display: flex;
          align-items: center;
          padding: 100px 82px;

          .img_Section {
            width: 40%;
            float: left;

            img {
              width: 100%;

              &.resp_Show {
                display: none;
              }
            }
          }

          .content_Section {
            width: 60%;
            float: left;
            padding-left: 117px;

            .main_Title {
              font-size: $FONT_48;
              color: $TXT_NAVY;
              font-family: $LATO_BOLD;
            }

            .inner_Title {
              font-size: $FONT_24;
              color: $TXT_LIGHTGREY;
              font-family: $LATO_SEMIBOLD;
              max-width: 780px;
              line-height: 30px;
            }
          }
        }

        .social_Section {
          display: flex;
          justify-content: space-between;
          padding: 0 82px;
          background: $BG_LIGHT;

          .img_Section {
            width: 50%;
            float: left;
            padding-top: 161px;

            img {
              width: 100%;

              &.img_Show {
                display: none;
              }
            }
          }

          .content_Section {
            width: 50%;
            float: left;
            padding-right: 30px;
            padding-top: 161px;

            .main_Title {
              font-size: $FONT_48;
              color: $TXT_NAVY;
              font-family: $LATO_BOLD;
            }

            .inner_Title {
              font-size: $FONT_24;
              color: $TXT_LIGHTGREY;
              font-family: $LATO_SEMIBOLD;
              max-width: 780px;
              line-height: 30px;
              padding: 0;
            }
          }
        }

        .manage_Section {
          display: flex;
          background: $BG_NAVY;
          justify-content: center;
          padding: 140px 0 82px 0;
          position: relative;

          .main_Top {
            position: absolute;
            right: 0;
            top: 0;
          }

          .main_Bottom {
            position: absolute;
            left: 0;
            bottom: 0;
          }

          .content_Section {
            text-align: center;

            .main_Title {
              font-size: $FONT_40;
              color: $TXT_OFFWHITE;
              font-family: $LATO_BOLD;
              text-align: center;
              width: 100%;
              padding-bottom: 10px;
            }

            .inner_Title {
              font-size: $FONT_24;
              color: $TXT_OFFWHITE;
              font-family: $LATO_SEMIBOLD;
              line-height: 30px;
              padding-bottom: 40px;
              text-align: center;
              max-width: 973px;
            }

            button {
              width: 230px;
              background: $BG_ORANGE !important;
              border-radius: 100px !important;
            }
          }
        }

        .imgSlider_Section {
          padding: 100px 174px;

          .slider_Section {
            .slick-slider {
              .slick-prev {
                left: -50px;
              }

              .slick-next {
                right: -50px;
              }
            }
          }

          .main_Title {
            font-size: $FONT_32;
            font-family: $LATO_BOLD;
            padding-bottom: 100px;
            color: $TXT_NAVY;
            text-align: center;
            width: 100%;
          }

          .slider_Section {
            .slick-prev {
              z-index: 11;
            }

            .image_Slider {
              width: 461px !important;

              img {
                width: 100%;
              }
            }

            .slick-initialized {
              .slick-slide {
                display: flex;
                justify-content: center;
              }
            }
          }
        }

        .input_Section {
          background: $BG_WHITE;
          padding: 100px 174px 200px 112px;
          position: relative;

          .main_Title {
            font-size: $FONT_40;
            color: $TXT_NAVY;
            font-family: $LATO_BOLD;
            padding-bottom: 80px;
          }

          .content_Textbox {
            display: flex;
            align-items: center;

            .img_Section {
              margin-right: 175px;
              width: 684px;

              img {
                max-width: 100%;
              }
            }

            .text_Box {
              width: 775px;

              .form1 {
                .textField {
                  padding-bottom: 32px;
                  margin: 0;

                  .form-control {
                    border-radius: 15px;
                  }
                }

                textarea {
                  height: 260px;
                  resize: none;

                  .form-control {
                    border-radius: 15px;
                  }
                }
              }

              button {
                width: 230px;
                background: $BG_ORANGE !important;
                margin-top: 16px;
                float: right;
                border-radius: 100px !important;
              }
            }
          }
        }
      }
    }
  }

  .footer_Section {
    // margin-bottom: 60px;
    padding-bottom: 60px;
  }
}

.thankyou_Modal {
  .modal-dialog {
    max-width: 525px;

    .modal-content {
      padding: 32px;

      .modal-header {
        padding: 0;
        border: none;

        .modal-title {
          font-size: $FONT_32;
          font-family: $LATO_BOLD;
          color: $TXT_ORANGE;
          text-align: center;
          padding-bottom: 24px;
        }

        i {
          display: none;
        }
      }

      .modalInner {
        .modal-body {
          padding: 0;

          .content {
            .titleText {
              font-size: $FONT_24;
              font-family: $LATO_LIGHT;
              color: $TXT_NAVY;
              text-align: center;
              line-height: 40px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1599px) and (max-width: 1800px) {
  .landing_Page {
    .landing_Scroll {
      .main_LandingPage {
        .landing_Inner {
          .imgSlider_Section {
            padding: 100px 80px;

            .slider_Section {
              .image_Slider {
                width: 400px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .landing_Page {
    .landing_Scroll {
      .main_LandingPage {
        .landing_Inner {
          .get_Start {
            padding-left: 80px;

            .content {
              .title_Main {
                font-size: $FONT_40 !important;
                line-height: 40px;
              }
            }
          }

          .price_Section {
            .inner_title {
              width: 1024px;
            }
          }

          .social_Section {
            padding: 0 82px;

            .content_Section {
              padding-top: 120px;
            }
          }

          .imgSlider_Section {
            padding: 100px 80px;

            .slider_Section {
              .image_Slider {
                width: 380px !important;
              }
            }
          }

          .input_Section {
            .content_Textbox {
              .img_Section {
                margin-right: 100px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .landing_Page {
    .landing_Scroll {
      .main_LandingPage {
        .landing_Inner {
          .get_Start {
            padding-left: 80px;

            .content {
              width: 1022px;
              margin-right: 20px;

              .title_Main {
                font-size: $FONT_38 !important;
                line-height: 40px;
              }
            }
          }

          .price_Section {
            .inner_title {
              width: 1000px;
            }
          }

          .time_Section {
            padding: 100px 30px;

            .content_Section {
              padding-left: 80px;
            }
          }

          .social_Section {
            padding: 0 30px;

            .content_Section {
              padding-top: 120px;
            }
          }

          .manage_Section {
            .content_Section {
              .main_Title {
                max-width: 600px;
              }

              .inner_Title {
                max-width: 600px;
              }
            }
          }

          .imgSlider_Section {
            padding: 100px 60px;

            .slider_Section {
              .image_Slider {
                width: 420px !important;
              }
            }
          }

          .input_Section {
            padding: 100px 112px 200px 112px;

            .content_Textbox {
              .img_Section {
                margin-right: 80px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .landing_Page {
    .landing_Scroll {
      .main_LandingPage {
        .landing_Inner {
          padding-top: 8px;

          .get_Start {
            flex-direction: column-reverse;
            padding: 0;

            .content {
              margin: 0;
              padding: 166px 78px 128px 78px;

              .title_Main {
                font-size: $FONT_40 !important;
                line-height: 40px;
              }
            }
          }

          .price_Section {
            .inner_title {
              width: 100%;
              padding: 0 15px;
              padding-bottom: 40px !important;
            }
          }

          .time_Section {
            padding: 100px 30px;

            .content_Section {
              padding-left: 25px;
            }
          }

          .social_Section {
            padding: 0 30px;

            .content_Section {
              padding-left: 0;
            }
          }

          .manage_Section {
            .content_Section {
              .main_Title {
                max-width: 600px;
              }

              .inner_Title {
                max-width: 600px;
              }
            }
          }

          .imgSlider_Section {
            padding: 100px 60px;

            .slider_Section {
              .image_Slider {
                width: 400px !important;
              }
            }
          }

          .input_Section {
            padding: 100px 112px 200px 112px;

            .content_Textbox {
              .img_Section {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.5px) {
  .landing_Page {
    .landing_Scroll {
      .main_LandingPage {
        .landing_Inner {
          padding-top: 8px;

          .get_Start {
            flex-direction: column-reverse;
            padding: 0;

            .content {
              margin: 0;
              padding: 166px 78px 128px 78px;
              width: 100%;

              .title_Main {
                font-size: $FONT_40 !important;
                line-height: 40px;
              }
            }
          }

          .price_Section {
            .inner_title {
              width: 100%;
              padding: 0 15px;
              padding-bottom: 40px !important;
            }

            .Monthly_yearly_tab {
              .tab-content {
                .tab_Section {
                  flex-direction: column;

                  .price_TabInner {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 32px;
                  }
                }
              }
            }
          }

          .time_Section {
            padding: 100px 30px;
            flex-direction: column;

            .img_Section {
              width: 60%;
              padding-bottom: 50px;
            }

            .content_Section {
              padding-left: 0;
              width: 100%;

              .main_Title {
                text-align: center;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
              }
            }
          }

          .social_Section {
            padding: 0 30px;
            flex-direction: column;

            .img_Section {
              width: 100%;
              padding-bottom: 100px;
              padding-top: 48px;

              .img_Hide {
                display: none;
              }

              .img_Show {
                display: block !important;
              }
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              padding-top: 25px;

              .main_Title {
                text-align: center;
              }

              .inner_Title {
                max-width: 100%;
                text-align: left;
              }
            }
          }

          .manage_Section {
            img {
              width: 167px;
            }

            .content_Section {
              .main_Title {
                max-width: 700px;
              }

              .inner_Title {
                max-width: 700px;
              }
            }
          }

          .imgSlider_Section {
            padding: 100px 60px;

            .slider_Section {
              .image_Slider {
                width: 660px !important;
              }
            }
          }

          .input_Section {
            padding: 100px 112px 100px 112px;

            .content_Textbox {
              .img_Section {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 575.5px) and (max-width: 767px) {
  .landing_Page {
    .landing_Scroll {
      .main_LandingPage {
        .landing_Inner {
          padding-top: 8px;

          .get_Start {
            flex-direction: column-reverse;
            padding: 0;

            .content {
              width: 612px;
              margin: 0;
              padding: 166px 78px 128px 78px;

              .title_Main {
                font-size: $FONT_40 !important;
                line-height: 45px;
              }
            }
          }

          .price_Section {
            .main_title {
              text-align: center;
            }

            .inner_title {
              text-align: center;
              width: 100%;
            }

            .Monthly_yearly_tab {
              .tab-content {
                .tab_Section {
                  flex-direction: column;

                  .price_TabInner {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 32px;
                  }
                }
              }
            }
          }

          .time_Section {
            padding: 100px 20px;
            flex-direction: column;

            .img_Section {
              width: 100%;
              padding-bottom: 50px;
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              padding-right: 0 !important;

              .main_Title {
                text-align: center;
                font-size: $FONT_24;
                font-family: $LATO_REGULAR;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
                font-size: $FONT_16;
              }
            }
          }

          .social_Section {
            padding: 0 20px;
            flex-direction: column;

            .img_Section {
              width: 100%;
              padding-bottom: 100px;
              padding-top: 48px;
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              padding-top: 25px;
              padding-right: 0;

              .main_Title {
                text-align: center;
                font-size: $FONT_24;
                font-family: $LATO_REGULAR;
              }

              .inner_Title {
                max-width: 100%;
                text-align: left;
                font-size: $FONT_16;
              }
            }
          }

          .manage_Section {
            padding-top: 90px;

            img {
              width: 167px;
            }

            .content_Section {
              .main_Title {
                max-width: 400px;
                font-size: $FONT_24;
                font-family: $LATO_REGULAR;
              }

              .inner_Title {
                max-width: 400px;
                font-size: $FONT_16;
              }
            }

            button {
              font-size: $FONT_16;
              font-family: $LATO_REGULAR;
            }
          }

          .imgSlider_Section {
            padding: 100px 60px;

            .slider_Section {
              .image_Slider {
                width: 450px !important;
              }
            }
          }

          .input_Section {
            padding: 98px 78px;

            .main_Title {
              width: 100%;
              text-align: center;
            }

            .content_Textbox {
              .img_Section {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 575.5px) {
  .landing_Page {
    .landing_Scroll {
      height: calc(100vh - 87px) !important;

      .main_LandingPage {
        padding-top: 75px;

        .landing_Inner {
          padding-top: 8px;

          .get_Start {
            flex-direction: column-reverse;
            padding: 0;

            .content {
              width: 100%;
              margin: 0;
              padding: 65px 24px;

              .title_Main {
                font-size: $FONT_20 !important;
                line-height: 40px;
                line-height: 30px;
                padding-bottom: 16px;
              }

              .inner_Text {
                font-size: 20px;
              }
            }

            button {
              font-size: $FONT_20;
              font-family: $LATO_REGULAR;
              height: 60px;
              width: 165px !important;
            }
          }

          .price_Section {
            padding: 80px 22px;

            .main_title {
              text-align: center;
            }

            .inner_title {
              text-align: center;
              width: 100%;
            }

            .Monthly_yearly_tab {
              .tab-content {
                .tab_Section {
                  flex-direction: column;

                  .price_TabInner {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 32px;
                  }
                }
              }
            }
          }

          .time_Section {
            padding: 100px 22px;
            flex-direction: column;

            .img_Section {
              width: 100%;
              padding-bottom: 50px;

              .resp_Hide {
                display: none;
              }

              .resp_Show {
                display: block !important;
              }
            }

            .content_Section {
              padding-left: 0;
              width: 100%;

              .main_Title {
                text-align: center;
                font-size: $FONT_24;
                font-family: $LATO_REGULAR;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
                font-size: $FONT_16;
              }
            }
          }

          .social_Section {
            padding: 0 10px;
            flex-direction: column;

            .img_Section {
              width: 100%;
              padding-bottom: 100px;
              padding-top: 48px;

              .img_Hide {
                display: none;
              }

              .img_Show {
                display: block !important;
              }
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              padding-top: 25px;
              padding-right: 0;

              .main_Title {
                text-align: center;
                font-size: $FONT_24;
                font-family: $LATO_REGULAR;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
                font-size: $FONT_16;

                &:last-child {
                  text-align: left !important;
                }
              }
            }
          }

          .manage_Section {
            padding-top: 90px;

            img {
              width: 112px;
            }

            .content_Section {
              .main_Title {
                max-width: 350px;
                font-size: $FONT_24;
                font-family: $LATO_REGULAR;
                padding-bottom: 24px;
              }

              .inner_Title {
                max-width: 350px;
                font-size: $FONT_16;
              }
            }

            button {
              font-size: $FONT_16;
              font-family: $LATO_REGULAR;
            }
          }

          .imgSlider_Section {
            padding: 50px 59px;

            .slider_Section {
              .image_Slider {
                width: 270px !important;
              }

              .slick-slider {
                .slick-next {
                  right: -50px;
                }

                .slick-prev {
                  left: -50px;
                }
              }
            }
          }

          .input_Section {
            padding: 50px 32px;

            .main_Title {
              font-size: $FONT_24;
            }

            .main_Title {
              width: 100%;
              text-align: center;
            }

            .content_Textbox {
              .img_Section {
                display: none;
              }
            }
          }
        }
      }
    }

    .footer_Section {
      margin-bottom: 0;
    }
  }
}
