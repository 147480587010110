@import "../../assests/scss/variables.scss";

.bloglist_Page {
  overflow: hidden;

  .bloglist_Scroll {
    height: calc(100vh - 150px) !important;
    height: 100vh !important;
    position: relative;

    .main_BlogList {
      width: 100%;
      max-width: 1572px;
      margin: 0 auto;
      padding-top: 150px;

      .blog_Inner {
        display: table;

        .boxlising_Section {
          width: 33.33%;
          float: left;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .footerheight {
    margin-bottom: 65px;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .bloglist_Page {
    .bloglist_Scroll {
      .main_BlogList {
        max-width: 100%;
        margin: 0;

        .blog_Inner {
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .bloglist_Page {
    .bloglist_Scroll {
      .main_BlogList {
        max-width: 100%;

        .blog_Inner {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .boxlising_Section {
            width: 30%;
            margin-right: 20px;

            &:nth-child(3n + 3) {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .bloglist_Page {
    .bloglist_Scroll {
      .main_BlogList {
        max-width: 100%;

        .blog_Inner {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .boxlising_Section {
            width: 45%;
            margin-right: 20px;

            &:nth-child(2n + 2) {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bloglist_Page {
    .bloglist_Scroll {
      .main_BlogList {
        max-width: 100%;
        padding-top: 80px;

        .blog_Inner {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .boxlising_Section {
            width: 45%;
            margin-right: 20px;

            &:nth-child(2n + 2) {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 575.5px) and (max-width: 767px) {
  .bloglist_Page {
    .bloglist_Scroll {
      .main_BlogList {
        max-width: 100%;
        padding-top: 80px;

        .blog_Inner {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .boxlising_Section {
            width: 98%;
            margin-bottom: 80px;

            .image_Slider {
              width: 461px !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575.5px) {
  .bloglist_Page {
    .bloglist_Scroll {
      .main_BlogList {
        max-width: 100%;
        padding-top: 80px;

        .blog_Inner {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .boxlising_Section {
            width: 98%;
            margin-bottom: 80px;

            .image_Slider {
              width: 461px !important;

              .content_Section {
                .main_Heading {
                  font-size: $FONT_24 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .bloglist_Page {
    .bloglist_Scroll {
      .main_BlogList {
        max-width: 100%;
        padding-top: 80px;

        .blog_Inner {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .boxlising_Section {
            width: 98%;
            margin-bottom: 80px;

            .image_Slider {
              width: 380px !important;

              .content_Section {
                .main_Heading {
                  font-size: $FONT_24 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
