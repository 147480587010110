@import "../../assests/scss/variables.scss";

.support_Page {
  .support_Scroll {
    height: calc(100vh - -65px) !important;
    position: relative;
  }

  .support_img {
    .support_content {
      img {
        padding-top: 60px;
        width: 100%;
        height: auto;
        max-width: 100%;
      }

      .content-Top {
        top: 18%;
        left: 10%;
        width: 779px;

        .top-Head {
          font-size: $FONT_48;
          color: $TXT_WHITE;
          padding-bottom: 14px;
          font-family: $LATO_BOLD;
          line-height: 55px;
        }

        .top-Inner {
          color: $TXT_WHITE;
          font-size: $FONT_32;
          font-family: $LATO_BOLD;
          margin: 0 !important;
          padding: 0;
          text-align: left;
          padding-top: 24px;
          padding-bottom: 48px;
        }
      }

      .search_Box {
        .search_Txt {
          width: 100%;
          height: 78px;
          border-radius: 15px;
          font-size: $FONT_20;
          color: $BG_NAVY;

          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $BG_NAVY;
            opacity: 1;
            /* Firefox */
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $BG_NAVY;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $BG_NAVY;
          }
        }

        .icon-search {
          left: unset;
          top: 26px;
          right: 12px;
          font-size: $FONT_28;
        }
      }
    }

    .center_card {
      padding: 55px 174px;

      .titleText {
        display: flex;
        justify-content: center;
        color: $TXT_ORANGE;
        font-size: $FONT_48;
        font-family: $LATO_BOLD;
        padding-bottom: 56px;
      }

      .card_section {
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;

        .card {
          width: 49%;
          height: 140px;
          margin-bottom: 56px;
          border-radius: 8px;

          .inner {
            width: 90%;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;

            .title {
              font-size: $FONT_24;
              padding-bottom: 0;
              font-family: $LATO_SEMIBOLD;
              line-height: 36px;
              color: $LABEL_GREY;
              justify-content: flex-start;
            }

            .article {
              font-size: $FONT_16;
              font-family: $LATO_SEMIBOLD;
              padding-bottom: 0;
              color: $TXT_NAVY;
              justify-content: flex-start;
            }
          }

          &.cards {
            max-width: 100%;
            display: flex;
            flex-direction: row;
            padding: 0;

            .bg-Color {
              height: auto;
              width: 88px;
              background-color: #16056b;
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;

              i {
                color: white;
                font-size: $FONT_36;
              }
            }
          }
        }
      }
    }

    .footerheight {
      margin-bottom: 65px;
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .support_Page {
    .support_Scroll {
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .support_Page {
    .support_img {
      .support_content {
        .content-Top {
          top: 7%;
          left: 5%;
          width: 779px;

          .top-Head {
            font-size: $FONT_32;
            line-height: 40px;
          }

          .top-Inner {
            font-size: 24px;
            padding-top: 21px;
            padding-bottom: 42px;
          }
        }
      }

      .center_card {
        padding: 55px 44px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .support_Page {
    .support_img {
      .support_content {
        .content-Top {
          top: 7%;
          left: 5%;
          width: 779px;

          .top-Head {
            font-size: $FONT_32;
            line-height: 40px;
          }

          .top-Inner {
            font-size: 24px;
            padding-top: 21px;
            padding-bottom: 30px;
          }
        }
      }

      .center_card {
        padding: 55px 44px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .support_Page {
    .support_img {
      .support_content {
        .content-Top {
          top: 7%;
          left: 5%;
          width: 779px;

          .top-Head {
            font-size: $FONT_32;
            line-height: 35px;
          }

          .top-Inner {
            font-size: 24px;
            padding-top: 21px;
            padding-bottom: 30px;
          }
        }
      }

      .center_card {
        padding: 55px 44px;
      }

      .center_card {
        .card_section {
          .card {
            &.cards {
              width: 100%;
              margin-bottom: 35px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 575.5px) and (max-width: 767px) {
  .support_Page {
    .support_img {
      .support_content {
        .main_Img {
          padding-top: 85px;
          width: 120%;
          max-width: initial;
        }

        .content-Top {
          top: 8%;
          left: 5%;
          width: 479px;

          .top-Head {
            font-size: $FONT_32;
            line-height: 30px;
          }

          .top-Inner {
            font-size: 24px;
            padding-top: 21px;
            padding-bottom: 20px;
          }

          .search_Section {
            .search_Box {
              input {
                height: 48px;
              }

              .icon-search {
                top: 10px;
                font-size: $FONT_18;
              }
            }
          }
        }
      }

      .center_card {
        padding: 55px 44px;
      }

      .center_card {
        .card_section {
          .card {
            &.cards {
              width: 100%;
              margin-bottom: 35px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .support_Page {
    .support_img {
      .support_content {
        .main_Img {
          padding-top: 85px;
          width: 120%;
          height: auto;
          max-width: inherit;
        }

        .content-Top {
          top: 8%;
          left: 5%;
          width: 479px;

          .top-Head {
            font-size: $FONT_24;
            line-height: 25px;
          }

          .top-Inner {
            font-size: 24px;
            padding-top: 10px;
            padding-bottom: 20px;
          }

          .search_Section {
            .search_Box {
              input {
                height: 48px;
              }

              .icon-search {
                top: 10px;
                font-size: $FONT_18;
              }
            }
          }
        }
      }

      .center_card {
        padding: 55px 44px;
      }

      .center_card {
        .card_section {
          .card {
            &.cards {
              width: 100%;
              margin-bottom: 35px;
            }
          }

          .titleText {
            font-size: $FONT_32;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .support_Page {
    .support_img {
      .support_content {
        .main_Img {
          padding-top: 85px;
          width: 150%;
          height: auto;
          max-width: inherit;
        }

        .content-Top {
          top: 12%;
          left: 3%;
          width: 329px;

          .top-Head {
            font-size: $FONT_20;
            line-height: 20px;
          }

          .top-Inner {
            font-size: $FONT_20;
            padding-top: 0px;
            padding-bottom: 20px;
          }

          .search_Section {
            .search_Box {
              input {
                height: 48px;
              }

              .icon-search {
                top: 10px;
                font-size: $FONT_18;
              }
            }
          }
        }
      }

      .center_card {
        padding: 20px 15px;
      }

      .center_card {
        .card_section {
          .card {
            &.cards {
              width: 100%;
              margin-bottom: 35px;
              height: 80px;

              .bg-Color {
                width: 40px;

                i {
                  top: 15px;
                  font-size: $FONT_18;
                }
              }
            }

            .inner {
              padding: 0 12px;

              .title {
                font-size: $FONT_18;
              }
            }
          }
        }

        .titleText {
          font-size: $FONT_32;
          padding-bottom: 25px;
        }
      }
    }
  }
}
