@import "../../assests//scss/variables.scss";

.button-tooltip > .tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 1px solid #062e56;
  border-radius: 5px 5px 5px 0px;
}

.headerAdmin {
  padding: 0;
  width: 83px;
  height: 100vh;
  float: left;
  position: relative;
  background: $BG_WHITE;
  z-index: 999 !important;

  .profile_Section {
    padding: 0 16px;
    margin-bottom: 77px;

    .main_Img {
      border-radius: 50%;
      height: 50px;
      width: 50px;
    }

    .border-bottom {
      padding-bottom: 24px;
      border-bottom: 1px solid $TXT_OFFWHITE;
    }
  }

  .navbar {
    padding: 0px;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    &.navbar-expand {
      .navbar-toggler {
        display: none;

        &:focus {
          outline: none;
          color: transparent;
        }
      }

      .navbar-collapse {
        width: 100%;
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;
        background: $BG_NAVY !important;
        padding-top: 24px !important;
        height: 100vh !important;
        -webkit-box-shadow: $BOX_SHADOW;
        -moz-box-shadow: $BOX_SHADOW;
        box-shadow: $BOX_SHADOW;

        .responsive_title {
          display: none;
          font-size: $FONT_16;
          font-family: $LATO_LIGHT;
          color: $TXT_NAVY;
          line-height: 26px;
          position: fixed;
          left: 160px;
          top: 10px;
          z-index: 1;
        }
      }
    }

    .navbar-brand {
      padding: 0px;
      margin: 0;
      padding-right: 55px;

      &:focus {
        outline: none;
      }
    }

    .headerLogo {
      width: 28px;
    }

    .navbar-nav {
      align-items: center;

      .dropdown-menu {
        position: absolute;
      }

      &.menuItem {
        display: flex;
        flex-direction: column;
        width: 100%;

        .nav-link {
          color: $BG_WHITE;
          font-size: $FONT_20 !important;
          font-weight: normal;
          line-height: 24px;
          padding: 11px 0px !important;
          color: $TXT_BLUE;
          position: relative;
          font-size: $FONT_16;
          text-decoration: none;
          margin-bottom: 23px;
          text-align: center;

          &.active {
            width: 100%;
            background: -moz-linear-gradient(
              90deg,
              rgba(86, 150, 250, 0.51) 0%,
              rgba(86, 150, 250, 0) 100%
            ) !important;
            background: -webkit-linear-gradient(
              90deg,
              rgba(86, 150, 250, 0.51) 0%,
              rgba(86, 150, 250, 0) 100%
            ) !important;
            background: linear-gradient(
              90deg,
              rgba(86, 150, 250, 0.51) 0%,
              rgba(86, 150, 250, 0) 100%
            ) !important;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              background: $MENUBORDER;
              width: 3px;
            }
          }
        }
      }

      .nav-item {
        position: relative;
      }
    }
  }

  .sub_Menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 222px;
    background: $BG_WHITE;
    box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
    border-radius: 0px 30px 30px 0px;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    height: 100vh;

    &.show {
      transform: translateX(44%);
      -webkit-transform: translateX(44%);
      -moz-transform: translateX(44%);
      -ms-transform: translateX(44%);
      -o-transform: translateX(44%);
    }

    .inner_SabMenu {
      padding-top: 86px;
      padding-left: 24px;

      .titleText {
        padding-bottom: 36px;
        font-size: $FONT_32;
        color: $TXT_NAVY;
        font-family: $LATO_LIGHT;
        line-height: 48px;
      }

      //added badal for list design--start
      .list-group-item.active {
        z-index: 2;
        color: #fff;
        border: 32px;
        border-left: 2px solid $BG_NAVY;
        color: $TXT_NAVY;
      }

      .list-group-item:first-child {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
      }

      .list-group {
        border-left: 1px solid $TXT_BLUELIGHT;
      }

      .list-group-item {
        cursor: pointer;
        color: $TXT_LIGHTCOLOR;
        position: relative;
        display: block;
        padding: 0.75rem 0.65rem;
        background-color: #fff;
        font-family: $LATO_BOLD;
        font-weight: 600;
        font-size: $FONT_16;
        // margin-bottom: 24px;
        border: none;
      }

      //--end
      .subMenu_Ul {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: left;
        border-left: 1px solid $TXT_BLUELIGHT;

        .subMenu_Li {
          padding: 0;
          padding: 12px 16px;
          font-family: $LATO_BOLD;
          font-weight: 600;
          font-size: $FONT_16;
          margin-bottom: 24px;
          cursor: pointer;
          color: $TXT_LIGHTCOLOR;

          &.active {
            border-left: 1px solid $BG_NAVY;
            color: $TXT_NAVY;
          }
        }
      }
    }
  }

  .colorIcon {
    button {
      // color: red;
    }

    .icon-logout {
      // color: red;
    }

    .nav-link {
      color: $BG_WHITE;
      font-size: $FONT_20 !important;
      font-weight: normal;
      line-height: 24px;
      padding: 11px 0px !important;
      color: $TXT_BLUE;
      position: relative;
      font-size: $FONT_16;
      text-decoration: none;
      margin-bottom: 23px;
    }
  }
}
