@import "../../assests/scss/variables.scss";

.admin_DashboardBlog {
  .main_Container {
    width: calc(100% - 318px);
    float: right;
    background: $BG_LIGHT;

    .admin_DashboardBlog_Scroll {
      position: unset !important;

      .dashboard_Section {
        width: calc(100% - 75px);
        float: right;
        padding: 16px;
        background: $BG_LIGHT;
        height: 100vh;

        .page_Title {
          font-size: $FONT_32;
          color: $TXT_NAVY;
        }

        .id_Title {
          font-size: $FONT_16;
          color: $LABEL_GREY;
        }

        .card_Section {
          .card_Width {
            width: 20%;
            padding: 16px 24px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
            border: none;
            margin-right: 15px;

            .details {
              .bg_Icon {
                width: 60px;
                height: 60px;
                background: $BG_ICONBG;
                border-radius: 30px;

                i {
                  color: $TXT_NAVY;
                  font-size: $FONT_20;
                }
              }

              .nos {
                font-size: $FONT_16;
                font-family: $LATO_SEMIBOLD;
                color: $TXT_NAVY;
                padding-bottom: 4px;
              }

              .count-Text {
                font-size: $FONT_12;
                color: $LABEL_GREY;
                padding-bottom: 0;
              }
            }
          }
        }

        .main_ChartSection {
          padding-top: 24px;
          border-top: 1px solid $TXT_BLUELIGHT;

          .chart_Part {
            .chart_Section {
              margin-bottom: 32px !important;

              .w75 {
                width: 70%;
              }

              .w25 {
                width: 33%;
              }

              .w30 {
                width: 33.33%;
                margin-right: 15px;
              }
            }
          }

          .main_Dropdown {
            right: 0;
            z-index: 1;
            top: 16px;

            .dropdown_section {
              .customDropdown {
                margin-right: 15px !important;
                width: 110px;

                .dropdown {
                  .btn-primary {
                    border: 1px solid $TXT_NAVY !important;
                    padding: 5px 16px !important;

                    &:after {
                      position: absolute;
                      right: 18px;
                      top: 12px;
                      color: $TXT_BLUE;
                    }
                  }
                }
              }

              .btn-primary {
                color: $TXT_NAVY !important;
              }
            }

            &.post_Dropdown {
              top: 15px !important;
            }
          }

          .box_Shadow {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
          }

          .header_Filter {
            display: flex;

            .table_Title {
              font-size: $FONT_24;
              color: $TXT_NAVY;
            }

            .icon_Filter {
              .filterBtn {
                position: relative;
                margin-right: 24px;

                .filterIcon {
                  position: relative;
                  margin-left: 15px;
                  background: $BG_WHITE;
                  width: 117px;
                  height: 40px;
                  border-radius: 8px;
                  border: none;
                  display: flex;
                  align-items: center;
                  border: 1px solid $TXT_LIGHTBLACK;
                  justify-content: center;

                  &:focus {
                    outline: none;
                  }

                  i {
                    color: $TXT_LIGHTBLACK;
                    font-size: $FONT_16;

                    span {
                      font-size: $FONT_16;
                      font-family: $LATO_REGULAR;
                    }
                  }
                }
              }

              .submitBtn {
                height: 48px !important;
              }
            }
          }
        }

        .table_Sections {
          .query-Section {
            padding-top: 24px;
            padding-bottom: 15px;

            .header {
              background: $BG_LIGHTBLUE;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;

              li {
                color: $TXT_LIGHTBLACK;
              }
            }

            .dataList {
              padding-left: 24px !important;
              border: 1px solid $BG_LIGHTBLUE;
              border-top: none !important;

              li {
                color: $TXT_NAVY;
              }
            }

            ul {
              list-style: none;
              padding-left: 24px !important;
              min-height: 48px;
              align-items: center;

              li {
                width: 16.66%;
                font-size: $FONT_16;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .admin_DashboardBlog {
    .main_Container {
      .admin_DashboardBlog_Scroll {
        .dashboard_Section {
          .main_ChartSection {
            .chart_Part {
              .chart_Section {
                flex-direction: column;

                .w75 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w25 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w30 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .admin_DashboardBlog {
    .main_Container {
      .admin_DashboardBlog_Scroll {
        .dashboard_Section {
          .main_ChartSection {
            .chart_Part {
              .chart_Section {
                flex-direction: column;

                .w75 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w25 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w30 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .admin_DashboardBlog {
    .main_Container {
      .admin_DashboardBlog_Scroll {
        .dashboard_Section {
          .card_Section {
            flex-wrap: wrap;

            .card_Width {
              width: 31%;
              margin-bottom: 3%;

              &:nth-child(3) {
                margin-right: 0 !important;
              }

              &:nth-child(5) {
                margin-right: 0 !important;
              }
            }
          }

          .main_ChartSection {
            .chart_Part {
              .chart_Section {
                flex-direction: column;

                .w75 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w25 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w30 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .admin_DashboardBlog {
    .main_Container {
      .admin_DashboardBlog_Scroll {
        .dashboard_Section {
          .card_Section {
            flex-wrap: wrap;

            .card_Width {
              width: 48%;
              margin-bottom: 3%;

              &:nth-child(2) {
                margin-right: 0 !important;
              }

              &:nth-child(4) {
                margin-right: 0 !important;
              }
            }
          }

          .main_ChartSection {
            .chart_Part {
              .chart_Section {
                flex-direction: column;

                .w75 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w25 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w30 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .admin_DashboardBlog {
    .main_Container {
      .admin_DashboardBlog_Scroll {
        .dashboard_Section {
          .card_Section {
            flex-wrap: wrap;

            .card_Width {
              width: 48%;
              margin-bottom: 3%;

              &:nth-child(2) {
                margin-right: 0 !important;
              }

              &:nth-child(4) {
                margin-right: 0 !important;
              }
            }
          }

          .main_ChartSection {
            .chart_Part {
              .chart_Section {
                flex-direction: column;

                .w75 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w25 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w30 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .admin_DashboardBlog {
    .main_Container {
      .admin_DashboardBlog_Scroll {
        .dashboard_Section {
          width: 100%;

          .page_Title {
            padding-top: 20px;
          }

          .card_Section {
            flex-wrap: wrap;

            .card_Width {
              width: 100%;
              margin-bottom: 3%;
            }
          }

          .main_ChartSection {
            .chart_Part {
              .chart_Section {
                flex-direction: column;

                .w75 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w25 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w30 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .admin_DashboardBlog {
    .main_Container {
      .admin_DashboardBlog_Scroll {
        .dashboard_Section {
          width: 100%;
          padding: 10px;

          .page_Title {
            padding-top: 20px;
          }

          .card_Section {
            flex-wrap: wrap;

            .card_Width {
              width: 100%;
              margin-bottom: 3%;
              margin-right: 0;
            }
          }

          .main_ChartSection {
            padding-top: 0;

            .chart_Part {
              .chart_Section {
                flex-direction: column;

                .w75 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }

                  .main_Dropdown {
                    right: 0;
                    z-index: 1;
                    top: 18px;

                    .customDropdown {
                      width: 98px;

                      &:last-child {
                        margin-right: 0 !important;
                      }
                    }
                  }
                }

                .w25 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }

                .w30 {
                  width: 100%;
                  margin-bottom: 10px;

                  .highcharts-container {
                    width: 100% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
