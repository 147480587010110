@import "../../assests/scss/variables.scss";

.dashboard_Element {
  .dropdown {
    height: 48px;
    border-radius: 8px !important;

    #basic-nav-dropdown {
      color: $TXT_WHITE;
      padding: 11px 19px;

      &:after {
        margin-left: 8px;
      }
    }
  }

  .img_Section {
    .submitBtn {
      width: 97px;
      background: $BG_ORANGE !important;
      font-size: 12px;
      padding: 6px 0 !important;
      height: auto;
    }
  }

  .main_Container {
    width: calc(100% - 318px);
    float: right;
    background: $BG_LIGHT !important;

    .dashboard_Element_Scroll {
      position: unset !important;

      .dashboard_Section {
        padding-left: 96px;

        .content_Header {
          padding-bottom: 24px;
          padding: 16px;

          .page_Title {
            font-size: $FONT_32;
            color: $TXT_NAVY;
            padding-bottom: 4px;
          }

          .inner_Title {
            color: $LABEL_GREY;
            font-size: $FONT_16;
            padding-bottom: 0;
          }

          .w80 {
            width: 80%;
          }
        }

        .right_Button {
          padding: 16px;

          .btn {
            height: 48px;
            border-radius: 8px !important;
          }

          .save {
            background-color: #ffffff !important;
            padding: 5px 46px !important;
            border: 1px solid !important;
            color: #16056b !important;
            width: auto;
            margin-right: 16px;
          }

          .publish {
            padding: 0 !important;
            width: 130px;
          }

          &.tab_Buttons {
            padding-top: 32px;

            .btn {
              border-radius: 0 !important;
              height: 40px !important;
              width: 150px;
              padding: 0 !important;

              &:nth-child(2) {
                background-color: $MENUBORDER !important;
                color: #16056b !important;
              }
            }
          }
        }

        .addCard {
          width: 155px;
          float: right;
          height: 48px;
          border-radius: 8px !important;
          background-color: #16056b;
          color: $BG_WHITE;
        }

        .closeIcon {
          right: 4px;
          top: 2px;
        }

        .bgColor {
          background: $BG_WHITE;
          width: 27px;
          height: 27px;
        }

        .form_Img {
          padding: 15px;
          display: flex;
          background: $BG_LIGHT !important;
          padding: 0;

          .inner_Section {
            border-top: 2px solid $BG_LIGHTBLUE;
            margin: 0 15px;
            background: $BG_WHITE !important;

            .form_Contact {
              width: 55%;
              float: left;
              padding: 52px 70px 51px 46px;

              .search_Box {
                position: relative;

                .search_Txt {
                  float: right;
                }

                .icon-search {
                  left: unset;
                  right: 268px;
                }
              }
            }

            .img_Sections {
              width: 45% !important;
              float: left;
              padding: 52px 21px 46px 0;

              .about_Img {
                width: 160px;
              }

              .main-Text {
                font-size: $FONT_18;
                color: $TXT_NAVY;
                padding-bottom: 10px;
                text-align: center;
              }

              .inner-Text {
                font-size: $FONT_14;
                color: $LABEL_GREY;
                padding-bottom: 5px;
              }

              .submitBtn {
                width: 76px;
                font-size: $FONT_12;
                padding: 0;
                height: 22px;
                background: $BG_ORANGE !important;
              }

              .text-section {
                width: 240px;

                &.width {
                  width: 477px;

                  ul {
                    padding-left: 16px;
                  }
                }
              }

              .laptop-img {
                padding-left: 50px;
              }

              .paddingLeft {
                padding-left: 132px;
              }
            }
          }

          .select-Section {
            width: 162px;
            background: $BG_WHITE;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;

            .titleText {
              padding-bottom: 0;
              font-size: $FONT_16;
            }

            .closeCss {
              font-size: $FONT_10;
              padding-left: 12px;
            }
          }

          .bgBlue {
            background: $BG_NAVY !important;

            .dataList {
              background: $BG_OFFLIGHT;
            }
          }

          .img_Section {
            img {
              width: 40%;
            }
          }

          .bgWhite {
            background: transparent !important;
          }

          .manage_Section {
            display: flex;
            background: $BG_NAVY;
            justify-content: center;
            padding: 140px 0 82px 0;
            position: relative;

            .main_Title {
              font-size: $FONT_20;
              color: $TXT_OFFWHITE;
              font-family: $LATO_BOLD;
              text-align: center;
              width: 100%;
              padding-bottom: 10px;
            }

            .inner_Title {
              font-size: $FONT_14;
              color: $TXT_OFFWHITE;
              font-family: $LATO_SEMIBOLD;
              line-height: 30px;
              padding-bottom: 40px;
              text-align: center;
              max-width: 973px;
            }

            .main_Top {
              position: absolute;
              right: 0;
              top: 0;
              width: 88px;
            }

            .main_Bottom {
              position: absolute;
              left: 0;
              bottom: 0;
              width: 88px;
            }

            button {
              width: 85px !important;
              background: $BG_ORANGE !important;
              border-radius: 100px !important;
            }
          }

          &.image-section {
            .img_Section {
              padding: 52px 70px 51px 46px;

              img {
                width: 411px;
                height: 411px;
              }
            }
          }
        }

        .form_Toogle {
          padding: 15px;
          display: flex;
          background: $BG_LIGHT !important;
          padding: 0;

          .inner_Section {
            border-top: none;

            .form_Contact {
              width: 55%;
              float: left;
              padding: 52px 70px 51px 46px;

              .toggle_Section {
                width: 100%;
                display: flex;
                flex-direction: column;

                .nav-tabs {
                  border: none;
                  border-radius: 28px !important;
                  width: 138px;
                  background: $MENUBORDER;
                  display: flex;
                  justify-content: space-between;
                  padding: 5px 10px;

                  a {
                    margin: 0;
                    padding: 0 !important;
                    display: flex;
                    justify-content: center;
                    height: 40px;
                    align-items: center;
                    padding-left: 45px !important;
                    font-size: $FONT_16;
                    font-family: $LATO_BOLD;
                    color: $TXT_NAVY;

                    &.active {
                      border-bottom: 0;
                      border-radius: 28px;
                      width: 150px;
                      padding-left: 0 !important;
                      background: $BG_NAVY !important;
                      color: $TXT_WHITE !important;

                      &:last-child {
                        margin-left: 39px;
                      }
                    }
                  }

                  .nav-item {
                    margin-right: 0;
                    height: 21px;

                    .nav-link {
                      &.active {
                        background-color: inherit;
                        color: #16056b;
                        font-weight: 600;
                      }
                    }
                  }

                  .nav-link {
                    padding: 0 !important;

                    &.active {
                      border: none !important;
                    }
                  }
                }

                .tab-content {
                  border: none;

                  .tab_Section {
                    width: 100%;
                    display: flex;

                    .price_TabInner {
                      width: 33%;
                      float: left;
                      margin-right: 13px;

                      &:last-child {
                        margin: 0;
                      }

                      .content {
                        display: flex;
                        flex-direction: column;

                        .label_Inline {
                          display: flex;
                          align-items: end;

                          .no {
                            padding: 0;
                            transform: rotate(270deg);
                            font-size: $FONT_24;
                            font-family: $LATO_SEMIBOLD;
                            color: $TXT_LIGHTGREY;
                            position: relative;

                            &:before {
                              content: "";
                              position: absolute;
                              top: 12px;
                              left: -30px;
                              width: 20px;
                              height: 1px;
                              background: $TXT_LIGHT;
                            }
                          }

                          .main_Text {
                            font-size: $FONT_14;
                            font-family: $LATO_SEMIBOLD;
                            color: $TXT_NAVY;
                            padding-left: 15px;
                            padding-bottom: 4px;
                          }

                          .amt {
                            display: flex;
                            width: 100%;
                            justify-content: flex-end;
                            font-size: $FONT_16;
                            font-family: $LATO_SEMIBOLD;
                            color: $TXT_NAVY;
                          }
                        }

                        .desp {
                          padding-left: 44px;
                          padding-bottom: 42px;
                          font-size: $FONT_12;
                          font-family: $LATO_REGULAR;
                          color: $TXT_LIGHTGREY;
                          line-height: 22px;
                        }

                        button {
                          width: 143px;
                          background: $BG_ORANGE !important;
                          border-radius: 100px !important;
                          height: 53px !important;
                          font-size: $FONT_14;
                          font-family: $LATO_SEMIBOLD;
                          margin-left: 44px;
                        }
                      }
                    }
                  }
                }
              }
            }

            .payment_Plans {
              width: 45%;
              float: left;
              padding: 52px 21px 46px 0;

              .main_title {
                font-size: $FONT_30;
                color: $TXT_NAVY;
                padding-bottom: 15px;
              }

              .inner_title {
                font-size: $FONT_14;
                color: $LABEL_GREY;
              }

              .preview_Section {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .nav-tabs {
                  border: none;
                  border-radius: 28px !important;
                  width: 138px;
                  background: $MENUBORDER;
                  display: flex;
                  justify-content: space-between;
                  padding: 5px 10px;

                  a {
                    margin: 0;
                    padding: 0 !important;
                    display: flex;
                    justify-content: center;
                    height: 40px;
                    align-items: center;
                    padding-left: 45px !important;
                    font-size: $FONT_16;
                    font-family: $LATO_BOLD;
                    color: $TXT_NAVY;

                    &.active {
                      border-bottom: 0;
                      border-radius: 28px;
                      width: 150px;
                      padding-left: 0 !important;
                      background: $BG_NAVY !important;
                      color: $TXT_WHITE !important;

                      &:last-child {
                        margin-left: 39px;
                      }
                    }
                  }

                  .nav-item {
                    margin-right: 0;
                    height: 21px;
                  }

                  .nav-link {
                    padding: 0 !important;

                    &.active {
                      border: none !important;
                    }
                  }
                }

                .tab-content {
                  border: none;

                  .tab_Section {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .price_TabInner {
                      width: 33%;
                      float: left;
                      margin-right: 13px;

                      &:last-child {
                        margin: 0;
                      }

                      .content {
                        display: flex;
                        flex-direction: column;

                        .label_Inline {
                          display: flex;
                          align-items: end;

                          .no {
                            padding: 0;
                            transform: rotate(270deg);
                            font-size: $FONT_24;
                            font-family: $LATO_SEMIBOLD;
                            color: $TXT_LIGHTGREY;
                            position: relative;

                            &:before {
                              content: "";
                              position: absolute;
                              top: 12px;
                              left: -30px;
                              width: 20px;
                              height: 1px;
                              background: $TXT_LIGHT;
                            }
                          }

                          .main_Text {
                            font-size: $FONT_14;
                            font-family: $LATO_SEMIBOLD;
                            color: $TXT_NAVY;
                            padding-left: 15px;
                            padding-bottom: 4px;
                          }

                          .amt {
                            display: flex;
                            width: 100%;
                            justify-content: flex-end;
                            font-size: $FONT_16;
                            font-family: $LATO_SEMIBOLD;
                            color: $TXT_NAVY;
                          }
                        }

                        .desp {
                          padding-left: 44px;
                          padding-bottom: 42px;
                          font-size: $FONT_12;
                          font-family: $LATO_REGULAR;
                          color: $TXT_LIGHTGREY;
                          line-height: 22px;
                        }

                        button {
                          width: 143px;
                          background: $BG_ORANGE !important;
                          border-radius: 100px !important;
                          height: 53px !important;
                          font-size: $FONT_14;
                          font-family: $LATO_SEMIBOLD;
                          margin-left: 44px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .form-control {
      height: 56px;
    }

    textarea {
      &.form-control {
        height: 152px;
      }
    }
  }

  .imgSlider_Section {
    width: 40%;
    padding-top: 52px;

    .main_Title {
      font-size: $FONT_32;
      font-family: $LATO_BOLD;
      padding-bottom: 36px;
      color: $TXT_NAVY;
      text-align: center;
      width: 100%;
      padding-bottom: 20px;
    }

    .slider_Section {
      .slick-prev {
        z-index: 11;
        width: 20px;
        height: 20px;

        &:before {
          font-size: 16px;
        }
      }

      .slick-next {
        width: 20px;
        height: 20px;

        &:before {
          font-size: 16px;
        }
      }

      .image_Slider {
        width: 203px !important;

        img {
          width: 100%;
          height: 148px;
        }
      }

      .slick-initialized {
        .slick-slide {
          display: flex;
          justify-content: center;
        }
      }
    }

    .content_Section {
      .main_Heading {
        font-size: $FONT_16 !important;
      }

      .inner_Txt {
        font-size: $FONT_14 !important;
        line-height: inherit;
      }

      .read_More {
        font-size: $FONT_14 !important;
      }
    }
  }

  .table_Section {
    .query-Section {
      padding-top: 24px;

      .header {
        background: $BG_LIGHTBLUE;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        li {
          color: $TXT_LIGHTBLACK;
        }
      }

      .dataList {
        padding-left: 24px !important;
        border: 1px solid $BG_LIGHTBLUE;
        border-top: none !important;

        li {
          color: $TXT_NAVY;

          .checkboxMain {
            .nutritionProducts {
              &:after {
                top: 6px;
                left: 5px;
              }
            }
          }
        }
      }

      ul {
        list-style: none;
        padding-left: 24px !important;
        min-height: 48px;
        align-items: center;

        li {
          font-size: $FONT_16;
          width: 10%;

          &:nth-child(2) {
            width: 46%;
          }

          &:nth-child(3) {
            width: 14%;
          }

          &:nth-child(4) {
            width: 20%;
            text-align: right;
          }
        }
      }
    }
  }

  .text_Box {
    width: 490px;
    display: table !important;
    padding-left: 60px;
  }

  .bottom_Section {
    .main_Title {
      font-size: 20px;
      color: $BG_NAVY;
    }

    .submitBtn {
      float: right;
    }

    .form1 {
      .textField {
        .form-control {
          height: 45px !important;
        }

        i {
          top: 12px;
        }

        textarea.form-control {
          height: 152px !important;
        }
      }
    }
  }

  .paddingBtn {
    padding-bottom: 24px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dashboard_Element {
    .main_Container {
      .dashboard_Element_Scroll {
        .dashboard_Section {
          .form_Img {
            .inner_Section {
              flex-direction: column;
              display: flex;
              margin: 0;

              .form_Contact {
                width: 100%;
                padding: 13px 13px;
              }

              .img_Section {
                padding: 13px 13px;
              }

              .img_Sections {
                width: 100% !important;
              }
            }
          }

          .form_Toogle {
            .inner_Section {
              .form_Contact {
                width: 100%;
                padding: 13px 13px;
              }

              .payment_Plans {
                width: 100%;
                padding: 13px 13px;
              }
            }
          }
        }
      }
    }

    .imgSlider_Section {
      width: 100%;

      .slider_Section {
        .image_Slider {
          width: 300px !important;

          img {
            height: auto;
          }
        }

        .slick-prev {
          left: 8px;
        }

        .slick-next {
          right: 15px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .dashboard_Element {
    .main_Container {
      .dashboard_Element_Scroll {
        .dashboard_Section {
          display: none;
        }
      }
    }
  }

  .headerAdmin {
    // display: none;
  }
}
