@import "../../assests//scss/variables.scss";

.blog-comment-list-parent {
  border-top: 1px solid $BG_LIGHTBLUE;
  .top-section {
    display: flex;
    justify-content: space-between;
    padding: 14px 24px;
    align-items: center;
    .left-section {
      display: flex;
      .profile-img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
      }
      .profile-name-time {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 10px;
        .profile-name {
          padding-bottom: 0px;
          margin-top: 6px;
          color: $TXT_BLACK;
        }
        .profile-time {
          padding-bottom: 0px;
          margin-bottom: 4px;
          color: $TXT_LIGHT;
        }
      }
    }
    .icon-edit {
      cursor: pointer;
    }
  }
  .center-section {
    padding: 0px 24px 0px 24px;
    .center-text {
      color: $TXT_BLACKIST;
      font-family: $LATO_REGULAR !important;
      line-height: 18px;
      text-align: justify;
    }
  }
  .bottom-section {
    padding: 0px 24px 0px 24px;
    .icons-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icons-left {
        .like-reply-main {
          display: flex;
          align-items: center;
          .icon-like-like {
            margin-right: 4px;
            cursor: pointer;
          }
          .like-text {
            padding-bottom: 0px;
            color: $TXT_LIGHT;
            font-size: $FONT_14;
          }
          .icon-comment {
            margin-left: 8px;
            margin-right: 4px;
            cursor: pointer;
          }
        }
      }
      .icon-right {
        .reply {
          padding-bottom: 0px;
          color: $TXT_LIGHT;
          font-size: $FONT_14;
          padding-top: 4px;
          cursor: pointer;
          font-family: $LATO_BOLD;
        }
      }
    }
  }

  .border-bottom-line {
    margin: 20px 24px 0px 24px;
    border-bottom: 1px solid $BG_LIGHTBLUE;
  }
}
