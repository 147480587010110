@import "../../../assests/scss/variables.scss";

.modalPopup {
  .modal-content {
    border: 0;

    .closeBtn {
      float: right;
      text-decoration: none;

      i {
        color: $TXT_NAVY;
        font-size: $FONT_16;
      }

      &:focus {
        outline: none;
      }
    }

    .modalInner {
      display: block;

      .modal-body {
        padding: 24px 24px;
        display: table;
        width: 100%;
      }
    }
  }
}
