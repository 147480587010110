@import "../../assests/scss/variables.scss";

.admin_Support {
  .dropdown {
    height: 48px;
    border-radius: 8px !important;

    #basic-nav-dropdown {
      color: $TXT_WHITE;
      padding: 11px 19px;

      &:after {
        margin-left: 8px;
      }
    }
  }

  .main_Container {
    width: calc(100% - 318px);
    float: right;
    background: $BG_WHITE !important;

    .admin_Dashboard_Scroll {
      position: unset !important;

      .dashboard_Section {
        // width: calc(100% - 318px);
        // float: right;
        // padding: 16px;
        // background: $BG_WHITE !important;
        padding-left: 96px;

        .content_Header {
          padding-bottom: 24px;

          .backIcon {
            padding-bottom: 14px;

            .icon_Bg {
              width: 24px;
              height: 24px;
              background: $TXT_LIGHTBLACK;
              border-radius: 12px;

              i {
                color: $BG_WHITE;
                font-size: $FONT_12;
              }
            }
          }

          .page_Title {
            font-size: $FONT_32;
            color: $TXT_NAVY;
            padding-bottom: 0;
            padding-left: 0;
            padding-bottom: 5px;
          }

          .inner_Title {
            color: $LABEL_GREY;
            font-size: $FONT_16;
            padding-bottom: 0;
            padding-left: 0;
          }

          .w80 {
            width: 80%;
          }
        }

        .right_Button {
          .btn {
            height: 48px;
            border-radius: 8px !important;
          }

          .save {
            background-color: #ffffff !important;
            padding: 5px 46px !important;
            border: 1px solid !important;
            color: #16056b !important;
            width: auto;
            margin-right: 16px;
          }

          .publish {
            padding: 0 !important;
            width: 130px;
          }
        }
      }

      .divider {
        border-bottom: 1px solid $BG_LIGHTBLUE;
      }

      .input_Section {
        width: 60%;
        padding-top: 32px;
        padding-right: 16px;
        float: left;

        .box-border {
          padding: 32px 40px;
          background: $BG_WHITE;
          -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);

          .textField {
            margin-bottom: 0 !important;

            .form-control {
              height: 56px;
            }
          }

          &.marginTop {
            margin-top: 32px;
            padding: 0;

            .textField {
              margin-bottom: 24px !important;
            }

            .inner_Section {
              padding: 40px 42px;

              .textField {
                &:last-child {
                  margin-bottom: 0 !important;
                }
              }
            }
          }

          .bg_Textfield {
            background: $BG_ICONBG;
            padding: 24px 24px;
            display: table;

            a {
              text-decoration: none;
              cursor: pointer;

              .titleText {
                font-size: $FONT_16 !important;
                font-family: $LATO_SEMIBOLD;
                color: $TXT_NAVY;
              }

              .bg_Color {
                background: $TXT_ORANGE;
                width: 48px;
                height: 48px;
                border-radius: 24px;

                i {
                  color: $TXT_WHITE;
                  font-weight: 600;
                }
              }
            }
          }
        }

        .addCard {
          width: 155px;
          float: right;
          height: 48px;
          margin-top: 32px;
          border-radius: 8px !important;
        }
      }

      .img_Section {
        width: 40%;
        float: left;
        padding-top: 32px;
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .admin_Dashboard {
    .main_Container {
      .admin_Dashboard_Scroll {
        .dashboard_Section {
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .admin_Dashboard {
    .main_Container {
      .admin_Dashboard_Scroll {
        .dashboard_Section {
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .admin_Support {
    .main_Container {
      .admin_Dashboard_Scroll {
        .dashboard_Section {
          .content_Header {
            margin-right: 10px;
          }

          .input_Section {
            width: 99%;
            float: none;
          }

          .img_Section {
            width: 99%;
            float: none;
            padding-top: 115px;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .admin_Support {
    .main_Container {
      .admin_Dashboard_Scroll {
        .dashboard_Section {
          .content_Header {
            margin-right: 10px;
          }

          .input_Section {
            width: 99%;
            float: none;
          }

          .img_Section {
            width: 99%;
            float: none;
            padding-top: 115px;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
