@import "../../assests//scss/variables.scss";
.blog-modal-grand-parent {
  height: 100vh;
  width: 25%;
  position: fixed;
  z-index: 2;
  right: 0;
  background-color: $BG_WHITE;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25);
  .blog-modal-small-card {
    .top-section {
      display: flex;
      justify-content: flex-end;
      padding: 24px 24px 0px 0px;
      color: $TXT_BLACK;
      cursor: pointer;
    }
    .comment-card-before {
      box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25);
      margin: 24px;
      border-radius: 8px;
      background-color: $BG_WHITE;
      padding: 16px;
      cursor: text;
      .profile-name {
        color: $TXT_LIGHTGREY;
        padding-bottom: 0px;
      }
    }
  }

  .comment-reply-box {
    .blog-modal-parent .comment-card .profile-image-name {
      display: none;
    }
    .blog-modal-parent .comment-card .form {
      margin-top: 0px;
    }
  }
  .blog-commnent-section-parent {
    height: 70vh;
    overflow-y: scroll;
    .child-comment-box {
      overflow-y: scroll;
      margin: 16px 16px 0px 0px;
      border-left: 2px solid $BG_ORANGE;
      margin-left: 50px;
    }
  }
  .icon-hover {
    fill: $TXT_LIGHT !important;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .blog-modal-grand-parent {
    width: 35%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .blog-modal-grand-parent {
    width: 35%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .blog-modal-grand-parent {
    width: 38%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-modal-grand-parent {
    width: 48%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-modal-grand-parent {
    width: 65%;
  }
}
@media only screen and (min-width: 476px) and (max-width: 575px) {
  .blog-modal-grand-parent {
    width: 80%;
  }
}
@media only screen and (min-width: 300px) and (max-width: 475px) {
  .blog-modal-grand-parent {
    width: 100%;
  }
}
