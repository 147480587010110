@import "../../../assests/scss/variables.scss";

.submitBtn {
  background: $TXT_NAVY !important;
  width: 100%;
  border: none !important;
  height: 72px;
  font-size: 16px;
  font-family: $LATO_BOLD;
  border-radius: 15px !important;
  cursor: pointer;
  color: $TXT_WHITE;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &.active {
    box-shadow: none !important;
  }
}
