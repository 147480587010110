@import "../../assests/scss/variables.scss";

.admin_UserProfile {
  .main_Container {
    width: calc(100% - 318px);
    float: right;
    background: $BG_WHITE !important;

    .admin_UserProfile_Scroll {
      position: unset !important;

      .dashboard_Section {
        // width: calc(100% - 318px);
        // float: right;
        // padding: 16px;
        // background: $BG_WHITE !important;
        padding-left: 96px;

        .content_Header {
          padding-bottom: 24px;

          .page_Title {
            font-size: $FONT_32;
            font-family: $LATO_BOLD;
            font-weight: 600;
            color: $TXT_NAVY;
            padding-bottom: 14px;
            padding-bottom: 4px;
          }

          .inner_Title {
            color: $TXT_LIGHTCOLOR;
            font-size: $FONT_16;
            font-family: $LATO_REGULAR;
            font-weight: 400;
            padding-bottom: 0;
          }
        }
      }

      .divider {
        border-bottom: 1px solid $BG_LIGHTBLUE;
      }

      .serach_Filter {
        padding-top: 32px;

        .search_Section {
          width: 100%;
          height: 52px;

          .search_Box {
            width: 25% !important;

            .search_Txt {
              width: 100% !important;
            }

            .icon-search {
              top: 15px !important;
            }
          }

          .icon_Filter {
            .filterBtn {
              position: relative;
              margin-right: 24px;

              .filterIcon {
                position: relative;
                margin-left: 15px;
                background: $BG_WHITE;
                width: 117px;
                height: 40px;
                border-radius: 8px;
                border: none;
                display: flex;
                align-items: center;
                border: 1px solid $TXT_LIGHTBLACK;
                justify-content: center;

                &:focus {
                  outline: none;
                }

                i {
                  color: $TXT_LIGHTBLACK;
                  font-size: $FONT_16;

                  span {
                    font-size: $FONT_16;
                    font-family: $LATO_REGULAR;
                  }
                }
              }
            }

            .submitBtn {
              height: 48px !important;

              &:nth-child(1) {
                margin-bottom: 10px !important;
              }
            }
          }
        }
      }

      .table_Sections {
        .query-Section {
          padding-top: 24px;

          .header {
            background: $BG_LIGHTBLUE;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;

            li {
              color: $TXT_LIGHTBLACK;
            }
          }

          .dataList {
            padding-left: 24px !important;
            border: 1px solid $BG_LIGHTBLUE;
            border-top: none !important;

            li {
              color: $TXT_NAVY;
            }
          }

          ul {
            list-style: none;
            padding-left: 24px !important;
            min-height: 48px;
            align-items: center;

            li {
              width: 16.66%;
              font-size: $FONT_16;
            }
          }
        }
      }
    }
  }

  .paginationClass {
    right: 1% !important;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .admin_UserProfile {
    .main_Container {
      .admin_UserProfile_Scroll {
        .dashboard_Section {
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .admin_UserProfile {
    .main_Container {
      .admin_UserProfile_Scroll {
        .dashboard_Section {
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .admin_UserProfile {
    .main_Container {
      .admin_UserProfile_Scroll {
        .dashboard_Section {
          .serach_Filter {
            .search_Section {
              .search_Box {
                width: 60% !important;
              }
            }
          }

          .table_Sections {
            width: 900px;

            .query-Section {
              .header {
                display: none !important;
              }

              .dataList {
                flex-wrap: wrap;
                height: auto;
                border-top: 1px solid #d9dbe9 !important;
                margin-bottom: 5px !important;
                padding: 13px 13px !important;

                li {
                  position: relative;
                  padding-top: 10px;

                  &:nth-child(1) {
                    width: 15%;

                    &::before {
                      content: "User Id";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(2) {
                    width: 25%;

                    &::before {
                      content: "User Name";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(3) {
                    width: 20%;

                    &::before {
                      content: "Last Active";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(4) {
                    width: 20%;

                    &::before {
                      content: "Joining Date";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(5) {
                    width: 20%;

                    &::before {
                      content: "Renewal Date";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(6) {
                    width: 90%;
                    margin-top: 15px;

                    &::before {
                      content: "Subscription Type";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .admin_UserProfile {
    .main_Container {
      .admin_UserProfile_Scroll {
        .dashboard_Section {
          .serach_Filter {
            .search_Section {
              .search_Box {
                width: 60% !important;
              }
            }
          }

          .table_Sections {
            width: 660px;

            .query-Section {
              .header {
                display: none !important;
              }

              .dataList {
                flex-wrap: wrap;
                height: auto;
                border-top: 1px solid #d9dbe9 !important;
                margin-bottom: 5px !important;
                padding: 13px 13px !important;

                li {
                  position: relative;
                  padding-top: 10px;

                  &:nth-child(1) {
                    width: 15%;

                    &::before {
                      content: "User Id";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(2) {
                    width: 25%;

                    &::before {
                      content: "User Name";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(3) {
                    width: 20%;

                    &::before {
                      content: "Last Active";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(4) {
                    width: 20%;

                    &::before {
                      content: "Joining Date";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(5) {
                    width: 20%;

                    &::before {
                      content: "Renewal Date";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }

                  &:nth-child(6) {
                    width: 90%;
                    margin-top: 15px;

                    &::before {
                      content: "Subscription Type";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_BOLD;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .dashboard_Section {
    // display: none;
  }
}
