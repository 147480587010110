@import "../../assests/scss/variables.scss";

.group_Heading {
  display: flex;
  // justify-content: space-between;
  background: $BG_LIGHTBLUE;
  padding: 12px 24px;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .heading {
    color: $TXT_LIGHTBLACK;
    font-size: $FONT_16;
    line-height: 24px;
    padding: 0;

    &.profile {
      width: 15%;
    }

    &.userName {
      width: 15%;
    }

    &.Description {
      width: 30%;
    }

    &.Status {
      width: 20%;
    }

    &.CreatedDate {
      width: 10%;
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .group_Heading {
    .heading {
      font-family: $LATO_REGULAR;
      font-size: $FONT_14;
    }
  }
}

@media only screen and (min-width: 475.5px) and (max-width: 575.5px) {
  .group_Heading {
    display: none;
    .heading {
      font-family: $LATO_REGULAR;
      // display: none;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475.5px) {
  .group_Heading {
    display: none;
    .heading {
      font-family: $LATO_REGULAR;
      width: 25% !important;

      &.accType {
        display: none;
      }

      &.userName {
        width: 40% !important;
      }

      &.Description {
        width: 28%;
      }

      &.Created Date {
        width: 22%;
      }
    }
  }
}
