@import "../../assests/scss/variables.scss";
.popuptwo_container {
  background: rgba(20, 20, 43, 0.5);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;

  align-items: center;
  position: relative;
  .popup_box {
    font-family: Lato;
    font-style: normal;
    line-height: 150%;

    width: 30%;
    height: auto;
    background: $BG;
    border: 1px solid $BG_LIGHTBLUE;
    box-sizing: border-box;
    border-radius: 10px;
    position: absolute;
    .heading {
      display: flex;
      justify-content: space-between;
      margin: 32px 45px 42px 40px;
      .upgrade {
        font-weight: 600;
        font-size: $FONT_32;
        color: $TXT_LIGHTBLACK;
        padding-bottom: 0px;
      }
    }
    .text {
      font-weight: 500;
      font-size: $FONT_16;
      color: $TXT_LIGHTBLACK;
      padding-bottom: 0px;
      font-family: $LATO_SEMIBOLD;
      margin: 0px 45px 40px 36px;
    }
    .button {
      margin: 0px 80px 32px 79px;
    }
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .popuptwo_container {
    .popup_box {
      width: 45% !important;
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .popuptwo_container {
    .popup_box {
      width: 44% !important;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .popuptwo_container {
    .popup_box {
      width: 50% !important;
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .popuptwo_container {
    .popup_box {
      width: 70% !important;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .popuptwo_container {
    .popup_box {
      width: 80% !important;
    }
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .popuptwo_container {
    .popup_box {
      width: 71% !important;
    }
  }
}
@media only screen and (min-width: 476px) and (max-width: 575px) {
  .popuptwo_container {
    .popup_box {
      width: 87% !important;
      .heading {
        margin: 24px 35px 16px 24px !important;
        .upgrade {
          font-size: $FONT_24 !important;
        }
      }
      .text {
        margin: 0px 25px 40px 24px !important;
      }
      .button {
        margin: 0px 24px 32px 24px !important;
      }
    }
  }
}
@media only screen and (min-width: 300px) and (max-width: 475px) {
  .popuptwo_container {
    .popup_box {
      width: 87% !important;
      .heading {
        margin: 24px 35px 16px 24px !important;
        .upgrade {
          font-size: $FONT_24 !important;
        }
      }
      .text {
        margin: 0px 25px 40px 24px !important;
      }
      .button {
        margin: 0px 24px 32px 24px !important;
      }
    }
  }
}
@media only screen and (min-width: 0px) and (max-width: 299px) {
  .popuptwo_container {
    display: none;
  }
}
