@import "../../assests/scss/variables.scss";

.BlogTwo_Page {
  .headerLogin {
    .menuItem {
      .nav-link {
        &:nth-child(1) {
          display: none !important;
        }
      }

      .button_Link {
        a.nav-link {
          &:nth-child(1) {
            display: block !important;
          }
        }
      }
    }
  }

  .blogScroll {
    height: calc(100vh - 150px) !important;
    height: 100vh !important;

    .top_section {
      .top_image {
        .top_Img {
          float: left;
          width: 50%;
          margin-right: 40px;
        }
      }

      .top_Title {
        font-size: $FONT_48;
        color: $TXT_NAVY;
        text-align: left;
        line-height: 60px;
        padding: 20px 100px;
      }

      .top_text {
        padding: 20px;
        text-align: justify;

        .inner_Text {
          font-family: $LATO_REGULAR;
          font-size: $FONT_24;
          margin: 0;
          text-align: justify;
          color: $TXT_DARKBLACK;
          font-weight: 600;
          line-height: 36px;
          padding-bottom: 37px;
          letter-spacing: 1px;
          padding: 0px;
        }

        p::first-letter {
          text-transform: uppercase;
          font-size: $FONT_48;
          font-family: $LATO_BOLD;
        }
      }
    }

    .middle_section {
      margin-top: 30px;
      text-align: justify;

      .middle_text {
        .inner_Text {
          font-family: $LATO_REGULAR;
          font-size: $FONT_24;
          margin: 0;
          text-align: justify;
          color: $TXT_DARKBLACK;
          font-weight: 600;
          line-height: 36px;
          padding-bottom: 37px;
          letter-spacing: 1px;
          padding: 10px;
        }
      }
    }
    .blog-feature-two {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .image_Section {
      padding: 100px 40px;
      margin-bottom: 100px;

      .titleText {
        display: flex;
        justify-content: center;
        font-size: $FONT_48;
        font-family: $LATO_BOLD;
        color: $TXT_NAVY;
        padding-bottom: 80px;
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .BlogTwo_Page {
    .blogScroll {
      .top_section {
        .top_image {
        }

        .top_Title {
          padding: 40px 0px;
          font-size: 35px;
        }

        .top_text {
          padding: 0px 0px;

          .inner_Text {
            padding: 15px;
          }
        }
      }

      .middle_section {
        margin-top: 50px;

        .middle_text {
          .inner_Text {
            padding: 14px;
          }
        }
      }

      .image_Section {
        margin-bottom: 100px;
        padding: 0px 60px;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .BlogTwo_Page {
    .blogScroll {
      .top_section {
        .top_Title {
          padding: 20px 0px;
          font-size: 35px;
        }

        .top_text {
          padding: 0px 0px;

          .inner_Text {
            padding: 15px;
          }
        }
      }

      .middle_section {
        margin-top: 20px;

        .middle_text {
          .inner_Text {
            padding: 14px;
          }
        }
      }

      .image_Section {
        margin-bottom: 100px;
        margin-top: 50px;
        padding: 50px 54px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .BlogTwo_Page {
    .blogScroll {
      .top_section {
        .top_Title {
          padding: 30px 0px;
          font-size: 35px;
        }

        .top_text {
          padding: 0px 10px;
        }
      }

      .middle_section {
        .middle_text {
          .inner_Text {
          }
        }
      }

      .image_Section {
        margin-top: 0px;
        padding: 50px 54px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .BlogTwo_Page {
    .blogScroll {
      .top_section {
        .top_image {
          .top_Img {
            width: 100%;
          }
        }

        .top_Title {
          font-size: $FONT_24;
          padding: 30px 150px;
        }

        .top_text {
          padding: 0px 30px;
        }
      }

      .middle_section {
        .middle_text {
          .inner_Text {
          }
        }
      }

      .image_Section {
        padding: 0px 40px;
        margin-top: 50px;
        margin-bottom: 100px;

        .slider_Section {
          .slick-prev {
            left: 16px;
          }

          .slick-next {
            right: 18px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .BlogTwo_Page {
    .blogScroll {
      .top_section {
        .top_image {
          .top_Img {
            width: 100%;
          }
        }

        .top_Title {
          padding: 40px 70px;
          font-size: $FONT_24;
          line-height: 60px;
        }

        .top_text {
          padding: 0px 10px;
        }
      }

      .middle_section {
        .middle_text {
          .inner_Text {
          }
        }
      }

      .image_Section {
        padding: 0px 10px;
        margin-top: 50px;
        margin-bottom: 100px;
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .BlogTwo_Page {
    .blogScroll {
      .top_section {
        .top_image {
          .top_Img {
            width: 100%;
          }
        }

        .top_Title {
          padding: 20px 60px;
          font-size: $FONT_24;
          line-height: 60px;
        }

        .top_text {
          padding: 0px 5px;
        }
      }

      .middle_section {
        .middle_text {
          .inner_Text {
          }
        }
      }

      .image_Section {
        padding: 0px 20px;
        margin-top: 50px;
        margin-bottom: 100px;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .BlogTwo_Page {
    .blogScroll {
      .top_section {
        .top_image {
          .top_Img {
            width: 100%;
          }
        }

        .top_Title {
          padding: 20px 10px;
          font-size: $FONT_24;
          line-height: 60px;
        }

        .top_text {
          padding: 0px 5px;
        }
      }

      .middle_section {
        .middle_text {
          .inner_Text {
          }
        }
      }

      .image_Section {
        padding: 0px 15px;
        margin-top: 50px;
        margin-bottom: 100px;
      }
    }
  }
}
