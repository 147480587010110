@import "../../assests/scss/variables.scss";

rect {
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
span.sc-jTQDnj.bGSUxb {
  display: none !important;
}

.sc-eDLJxc {
  padding: 24px 48px !important;
}
