@import "../../../assests/scss/variables.scss";

.account-listing-parent {
  .account-listing-wrapper {
    display: flex;
    align-items: center;
    .accImage img {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin-left: 8px;
    }
    .accName {
      padding-bottom: 0px;
      margin-left: 8px;
      font-size: $FONT_14;
      color: $TXT_LIGHTBLACK;
      font-family: $LATO_REGULAR;
    }
  }
}
