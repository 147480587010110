@import "../../../assests/scss/variables.scss";

.planCard {
  padding: 32px 43px;
  border: 1px solid $TXT_BLUELIGHT;
  border-radius: 30px;
  width: 100%;

  .card_Head {
    display: flex;
    justify-content: space-between;

    .titleText {
      font-size: 32px;
      font-family: $LATO_BOLD;
      color: $TXT_LIGHTBLACK;
    }
  }

  .plans_Details {
    padding-top: 8px;

    .icon_Text {
      display: flex;
    }

    .titleText {
      color: $TXT_LIGHTBLACK;
      font-size: 16px;
      font-family: $LATO_SEMIBOLD;
      padding-left: 13px;
      padding-bottom: 32px;
    }
  }

  button {
    margin-top: 15px;

    &.outlinBtn {
      border: 1px solid $TXT_BLACK !important;
      background: transparent !important;
      color: $TXT_BLACK !important;
      margin-bottom: 24px;
    }
  }

  .bottonText {
    color: $TXT_ORANGE !important;
    font-family: $LATO_BOLD;
    font-size: $FONT_16;
    font-weight: 700;
  }
}
