@import "../../../assests/scss/variables.scss";

.super-admin-parent {
  width: 100%;

  .divide-section {
    display: flex;

    .left-section {
      width: 70%;
      padding: 0px 24px 0px 16px;

      .top-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;

        .top-title {
          padding-bottom: 0px !important;
          color: $TXT_NAVY;
          font-size: $FONT_32;
          line-height: 24px;
        }

        .invite-link {
          .customDropdown .dropdown .btn-primary {
            background: #fcfcfc;
            border: 1px solid #d9dbe9 !important;
            border-radius: 8px;
            color: #5696fa !important;
            padding: 12px 24px !important;
          }

          .dropdown-toggle::after {
            margin-left: 5.255em !important;
            color: #2e3a59;
          }
        }

        .icon-tab {
          display: none;
          cursor: pointer;
        }
      }

      .top-subTitle {
        color: #6e7191;
        padding-bottom: 0px !important;
        font-weight: 400 !important;
        font-family: $LATO_REGULAR;
        line-height: 24px;
        margin-top: 10px;
        font-size: $FONT_16;
      }

      .search-filter-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 32px;
        border-bottom: 1px solid #d9dbe9;
        padding-bottom: 12px;

        .search-section {
        }

        .filter-section {
          .filterIcon {
            position: relative;
            margin-left: 15px;
            background: $BG_WHITE;
            width: 117px;
            height: 40px;
            border-radius: 8px;
            border: none;
            display: flex;
            align-items: center;
            border: 1px solid $TXT_LIGHTBLACK;
            justify-content: center;

            &:focus {
              outline: none;
            }

            i {
              color: $TXT_LIGHTBLACK;
              font-size: $FONT_16;

              span {
                font-size: $FONT_16;
                font-family: $LATO_REGULAR;
                margin-left: 8px;
              }
            }
          }

          .filter-modal {
            position: absolute;
            margin-left: -180px;
            margin-top: 8px;
            z-index: 1;

            .filterDropdown {
              position: unset;

              .custombtnfield {
                .submitBtn {
                  &:nth-child(2) {
                    margin-left: 0 !important;
                    margin-top: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .right-section {
      background: #eff0f7;
      width: 30%;
      height: 100vh;
      padding: 0px 32px;

      .right-center-section {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        align-items: center;

        .center-text {
          color: #a0a3bd;
          text-align: center;
          font-size: $FONT_16;
          line-height: 24px;
        }

        .btn-invite {
          width: 200px;
        }
      }

      .icon-close {
        display: none;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .super-admin-parent {
    .divide-section {
      .left-section {
        width: 100%;

        .top-section {
          .top-title {
          }

          .invite-link {
            .customDropdown .dropdown .btn-primary {
            }

            .dropdown-toggle::after {
            }
          }

          .icon-tab {
            display: block;
          }
        }

        .top-subTitle {
        }

        .search-filter-main {
          .search-section {
          }

          .filter-section {
            .filterIcon {
              &:focus {
              }

              i {
                span {
                }
              }
            }

            .filter-modal {
              .filterDropdown {
              }
            }
          }
        }
      }

      .right-section {
        .right-center-section {
          .center-text {
          }

          .btn-invite {
          }
        }
      }

      .right-section {
        display: none;

        &.showTab {
          display: block;
        }
      }

      .right-section {
        top: 0;
        right: 0;
        z-index: 1;
        width: 40%;
        box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 30px 0px;
        position: absolute;

        // &.showTab {
        //   transform: translateX(0);
        //   -webkit-transform: translateX(0);
        //   -moz-transform: translateX(0);
        //   -ms-transform: translateX(0);
        //   -o-transform: translateX(0);
        // }

        .icon-close {
          display: block;
          position: absolute;
          right: 32px;
          top: 48px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .super-admin-parent {
    .divide-section {
      .left-section {
        width: 100%;

        .top-section {
          .top-title {
          }

          .invite-link {
            .customDropdown .dropdown .btn-primary {
            }

            .dropdown-toggle::after {
            }
          }

          .icon-tab {
            display: block;
          }
        }

        .top-subTitle {
        }

        .search-filter-main {
          .search-section {
          }

          .filter-section {
            .filterIcon {
              &:focus {
              }

              i {
                span {
                }
              }
            }

            .filter-modal {
              .filterDropdown {
              }
            }
          }
        }
      }

      .right-section {
        // display: none;
        // width: 35%;

        .right-center-section {
          .center-text {
          }

          .btn-invite {
          }
        }
      }

      .right-section {
        display: none;

        &.showTab {
          display: block;
        }
      }

      .right-section {
        top: 0;
        right: 0;
        z-index: 1;
        width: 50%;
        box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 30px 0px;
        position: absolute;

        // &.showTab {
        //   transform: translateX(0);
        //   -webkit-transform: translateX(0);
        //   -moz-transform: translateX(0);
        //   -ms-transform: translateX(0);
        //   -o-transform: translateX(0);
        // }

        .icon-close {
          display: block;
          position: absolute;
          right: 32px;
          top: 48px;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .super-admin-parent {
    .divide-section {
      .left-section {
        width: 100%;

        .top-section {
          .top-title {
          }

          .invite-link {
            .customDropdown .dropdown .btn-primary {
            }

            .dropdown-toggle::after {
            }
          }

          .icon-tab {
            display: block;
          }
        }

        .top-subTitle {
        }

        .search-filter-main {
          .search-section {
          }

          .filter-section {
            .filterIcon {
              &:focus {
              }

              i {
                span {
                }
              }
            }

            .filter-modal {
              .filterDropdown {
              }
            }
          }
        }
      }

      .right-section {
        // display: none;
        // width: 35%;

        .right-center-section {
          .center-text {
          }

          .btn-invite {
          }
        }
      }

      .right-section {
        display: none;

        &.showTab {
          display: block;
        }
      }

      .right-section {
        top: 0;
        right: 0;
        z-index: 1;
        width: 50%;
        box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 30px 0px;
        position: absolute;

        // &.showTab {
        //   transform: translateX(0);
        //   -webkit-transform: translateX(0);
        //   -moz-transform: translateX(0);
        //   -ms-transform: translateX(0);
        //   -o-transform: translateX(0);
        // }

        .icon-close {
          display: block;
          position: absolute;
          right: 32px;
          top: 48px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .super-admin-parent {
    .divide-section {
      .left-section {
        width: 100%;

        .top-section {
          .top-title {
          }

          .invite-link {
            .customDropdown .dropdown .btn-primary {
            }

            .dropdown-toggle::after {
            }
          }

          .icon-tab {
            display: block;
          }
        }

        .top-subTitle {
        }

        .search-filter-main {
          .search-section {
          }

          .filter-section {
            .filterIcon {
              &:focus {
              }

              i {
                span {
                }
              }
            }

            .filter-modal {
              .filterDropdown {
              }
            }
          }
        }
      }

      .right-section {
        // display: none;
        // width: 35%;

        .right-center-section {
          .center-text {
          }

          .btn-invite {
          }
        }
      }

      .right-section {
        display: none;

        &.showTab {
          display: block;
        }
      }

      .right-section {
        top: 0;
        right: 0;
        z-index: 1;
        width: 70%;
        box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 30px 0px;
        position: absolute;

        // &.showTab {
        //   transform: translateX(0);
        //   -webkit-transform: translateX(0);
        //   -moz-transform: translateX(0);
        //   -ms-transform: translateX(0);
        //   -o-transform: translateX(0);
        // }

        .icon-close {
          display: block;
          position: absolute;
          right: 32px;
          top: 48px;
        }
      }
    }
  }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
  .super-admin-parent {
    .divide-section {
      .left-section {
        width: 100%;

        .top-section {
          .top-title {
            font-size: $FONT_24;
          }

          .invite-link {
            .customDropdown .dropdown .btn-primary {
            }

            .dropdown-toggle::after {
            }
          }

          .icon-tab {
            display: block;
          }
        }

        .top-subTitle {
          font-size: $FONT_14;
        }

        .search-filter-main {
          .search-section {
          }

          .filter-section {
            .filterIcon {
              &:focus {
              }

              i {
                span {
                }
              }
            }

            .filter-modal {
              .filterDropdown {
              }
            }
          }
        }
      }

      .right-section {
        // display: none;
        // width: 35%;

        .right-center-section {
          .center-text {
          }

          .btn-invite {
          }
        }
      }

      .right-section {
        display: none;

        &.showTab {
          display: block;
        }
      }

      .right-section {
        top: 0;
        right: 0;
        z-index: 1;
        width: 85%;
        box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 30px 0px;
        position: absolute;

        // &.showTab {
        //   transform: translateX(0);
        //   -webkit-transform: translateX(0);
        //   -moz-transform: translateX(0);
        //   -ms-transform: translateX(0);
        //   -o-transform: translateX(0);
        // }

        .icon-close {
          display: block;
          position: absolute;
          right: 32px;
          top: 48px;
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 574px) {
  .super-admin-parent {
    .divide-section {
      .left-section {
        width: 100%;

        .top-section {
          .top-title {
            font-size: $FONT_24;
          }

          .invite-link {
            .customDropdown .dropdown .btn-primary {
            }

            .dropdown-toggle::after {
            }
          }

          .icon-tab {
            display: block;
          }
        }

        .top-subTitle {
          font-size: $FONT_14;
        }

        .search-filter-main {
          .search-section {
          }

          .filter-section {
            .filterIcon {
              &:focus {
              }

              i {
                span {
                }
              }
            }

            .filter-modal {
              .filterDropdown {
              }
            }
          }
        }
      }

      .right-section {
        // display: none;
        // width: 35%;

        .right-center-section {
          .center-text {
          }

          .btn-invite {
          }
        }
      }

      .right-section {
        display: none;

        &.showTab {
          display: block;
        }
      }

      .right-section {
        top: 0;
        right: 0;
        z-index: 1;
        width: 100%;
        box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 30px 0px;
        position: absolute;

        // &.showTab {
        //   transform: translateX(0);
        //   -webkit-transform: translateX(0);
        //   -moz-transform: translateX(0);
        //   -ms-transform: translateX(0);
        //   -o-transform: translateX(0);
        // }

        .icon-close {
          display: block;
          position: absolute;
          right: 32px;
          top: 48px;
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .super-admin-parent {
    .divide-section {
      .left-section {
        width: 100%;

        .top-section {
          .top-title {
            font-size: $FONT_16;
          }

          .invite-link {
            .customDropdown .dropdown .btn-primary {
            }

            .dropdown-toggle::after {
            }
          }

          .icon-tab {
            display: block;
          }
        }

        .top-subTitle {
          font-size: $FONT_12;
        }

        .search-filter-main {
          .search-section {
          }

          .filter-section {
            .filterIcon {
              &:focus {
              }

              i {
                span {
                }
              }
            }

            .filter-modal {
              .filterDropdown {
              }
            }
          }
        }
      }

      .right-section {
        // display: none;
        // width: 35%;

        .right-center-section {
          .center-text {
          }

          .btn-invite {
          }
        }
      }

      .right-section {
        display: none;

        &.showTab {
          display: block;
        }
      }

      .right-section {
        top: 0;
        right: 0;
        z-index: 1;
        width: 100%;
        box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 30px 0px;
        position: absolute;

        // &.showTab {
        //   transform: translateX(0);
        //   -webkit-transform: translateX(0);
        //   -moz-transform: translateX(0);
        //   -ms-transform: translateX(0);
        //   -o-transform: translateX(0);
        // }

        .icon-close {
          display: block;
          position: absolute;
          right: 32px;
          top: 48px;
        }
      }
    }
  }
}
