@import "./assests/scss/variables.scss";

body {
  margin: 0px;
  padding: 0px;
  font-family: $LATO_REGULAR !important;
  background-color: $BG_WHITE !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "latoregular";
  src: url("./assests/fonts/lato-regular-webfont.woff2") format("woff2"),
    url("./assests/fonts/lato-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "latolight";
  src: url("./assests/fonts/lato-light-webfont.woff2") format("woff2"),
    url("./assests/fonts/lato-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "latosemibold";
  src: url("./assests/fonts/lato-semibold-webfont.woff2") format("woff2"),
    url("./assests/fonts/lato-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "latobold";
  src: url("./assests/fonts/lato-bold-webfont.woff2") format("woff2"),
    url("./assests/fonts/lato-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Custom Fonts

@font-face {
  font-family: "icomoon";
  src: url("./assests/fonts/icomoon.eot?km5yr0");
  src: url("./assests/fonts/icomoon.eot?km5yr0#iefix")
      format("embedded-opentype"),
    url("./assests/fonts/icomoon.ttf?km5yr0") format("truetype"),
    url("./assests/fonts/icomoon.woff?km5yr0") format("woff"),
    url("./assests/fonts/icomoon.svg?km5yr0#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.icon-lock:before {
  content: "\e900";
}

.icon-mail:before {
  content: "\e901";
}

.icon-lock1:before {
  content: "\e902";
}

.icon-password:before {
  content: "\e903";
}

.icon-youtube:before {
  content: "\e904";
}

.icon-insta:before {
  content: "\e905";
}

.icon-twitter:before {
  content: "\e906";
}

.icon-fb:before {
  content: "\e907";
}

.icon-linkedin:before {
  content: "\e908";
}

.icon-more:before {
  content: "\e909";
}

.icon-leftarrow:before {
  content: "\e90a";
}

.icon-rightarrow:before {
  content: "\e90b";
}

.icon-home:before {
  content: "\e90c";
}

.icon-profile:before {
  content: "\e90d";
}

.icon-manage:before {
  content: "\e90e";
}

.icon-network:before {
  content: "\e90f";
}

.icon-event:before {
  content: "\e910";
}

.icon-graph:before {
  content: "\e911";
}

.icon-setting:before {
  content: "\e912";
}

.icon-search:before {
  content: "\e913";
}

.icon-tab:before {
  content: "\e914";
}

.icon-filter:before {
  content: "\e915";
}

.icon-close:before {
  content: "\e916";
}

.icon-calendar:before {
  content: "\e917";
}

.icon-downarrow:before {
  content: "\e918";
}

.icon-like:before {
  content: "\e919";
}

.icon-comment:before {
  content: "\e91a";
}

.icon-share:before {
  content: "\e91b";
}

.icon-timer:before {
  content: "\e91c";
}

.icon-gif:before {
  content: "\e91d";
}

.icon-image:before {
  content: "\e91e";
}

.icon-location:before {
  content: "\e91f";
}

.icon-smile:before {
  content: "\e920";
}

.icon-tag:before {
  content: "\e921";
}

.icon-menubar:before {
  content: "\e922";
}

.icon-user:before {
  content: "\e923";
}

.icon-chat:before {
  content: "\e924";
}

.icon-send1:before {
  content: "\e925";
}

.icon-share1:before {
  content: "\e926";
}

.icon-like1:before {
  content: "\e927";
}

.icon-repeat:before {
  content: "\e928";
}

.icon-chat1:before {
  content: "\e929";
}

.icon-comment1:before {
  content: "\e92a";
}

.icon-download:before {
  content: "\e92b";
}

.icon-edit:before {
  content: "\e92c";
}

.icon-likeright:before {
  content: "\e92d";
}

.icon-world:before {
  content: "\e92e";
}

.icon-edit1:before {
  content: "\e92f";
}

.icon-rotate:before {
  content: "\e930";
}

.icon-play:before {
  content: "\e931";
}

.icon-delete:before {
  content: "\e932";
}

.icon-pause:before {
  content: "\e933";
}

.icon-phone:before {
  content: "\e934";
}

.icon-dropdown:before {
  content: "\e935";
}

.icon-editr1:before {
  content: "\e936";
}

.icon-back:before {
  content: "\e937";
}

.icon-tick:before {
  content: "\e938";
}

.icon-reverse:before {
  content: "\e939";
}

.icon-plus:before {
  content: "\e93a";
}

.icon-circle-download:before {
  content: "\e93b";
}

.icon-blog:before {
  content: "\e93c";
}

.icon-cart:before {
  content: "\e93d";
}

.icon-element:before {
  content: "\e93e";
}

.icon-inr:before {
  content: "\e93f";
}

.icon-policies:before {
  content: "\e940";
}

.icon-query:before {
  content: "\e941";
}

.icon-question:before {
  content: "\e942";
}

.icon-user-double:before {
  content: "\e943";
}

.icon-user-filled:before {
  content: "\e944";
}

.icon-users:before {
  content: "\e945";
}

.icon-video:before {
  content: "\e946";
}

.icon-edit3:before {
  content: "\e947";
}

.icon-adds:before {
  content: "\e948";
}

.icon-logout:before {
  content: "\e949";
}

.icon-logout1:before {
  content: "\e94a";
}

.icon-bookmark:before {
  content: "\e94b";
}

.icon-eye:before {
  content: "\e94c";
}

.icon-hide:before {
  content: "\e94d";
}

.icon-eye1:before {
  content: "\e94e";
}

.icon-hide1:before {
  content: "\e94f";
}

.icon-sidemenu:before {
  content: "\e950";
}

.icon-chatboat:before {
  content: "\e951";
}

.icon-teams:before {
  content: "\e952";
}

.icon-file:before {
  content: "\e953";
}

p {
  margin: 0;
}

.overLay {
  background: transparent;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}

.overlay_Chat {
  background: transparent;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}

a {
  &:focus-visible {
    outline: none;
  }
}

.cursor {
  cursor: pointer;
}

.decoration {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

#uncontrolled-tab-example-tab-Publish:hover {
  border: none;
  padding-bottom: 8px !important;
}

#uncontrolled-tab-example-tab-Reoccurring:hover {
  border: none;
  padding-bottom: 8px !important;
}

.no-design-section {
  display: none;

  .no-design {
    font-size: $FONT_28;
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .no-design-section {
    display: flex !important;
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .demo_ModalPopup {
    .modal-dialog {
      margin: 7px;
    }

    .footer_Modal {
      padding-top: 20px !important;
      padding-bottom: 30px !important;

      button {
        height: 55px !important;
      }
    }
  }
}
