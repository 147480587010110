@import "../../../assests/scss/variables.scss";
.team-tab-parent {
  margin-top: 32px;
  .team-table-wrapper {
    .table-header {
      background-color: $BG_LIGHTBLUE;
    }
  }
  .listing-main {
    .profile-image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
  thead th:first-child {
    border-radius: 10px 0px 0px 0px;
  }
  thead th:last-child {
    border-radius: 0px 10px 0px 0px;
  }

  table th {
    border: none !important;
    font-weight: 400;
    font-size: 16px;
    color: #14142b;
  }
  tbody {
    border-bottom: 1px solid #d9dbe9;
    background: #eff0f7;
  }
  tbody td {
    color: $TXT_NAVY;
  }
  tbody .icons i {
    cursor: pointer;
  }
  .icons i {
    color: #a0a3bd;
  }
  .icons i:hover {
    color: #5696fa;
  }
  tbody .email:hover {
    color: #5696fa;
    cursor: pointer;
  }
  .hover-text {
    display: none;
  }
  .icon-mail:hover + .hover-text {
    display: block;
    color: $TXT_OFFWHITE;
    position: absolute;
    display: inline-block;
    background-color: $TXT_LIGHTBLACK;
    padding: 8px;
    border-radius: 4px;
  }
  .icon-edit1:hover + .hover-text {
    display: block;
    color: $TXT_OFFWHITE;
    position: absolute;
    display: inline-block;
    background-color: $TXT_LIGHTBLACK;
    padding: 8px;
    border-radius: 4px;
  }
}
