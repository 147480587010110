@import "../../assests/scss/variables.scss";

.admin_SubscriberPage {
  .main_Container {
    width: calc(100% - 318px);
    float: right;
    background: $BG_WHITE !important;

    .admin_SubscriberPage_Scroll {
      position: unset !important;

      .dashboard_Section {
        // width: calc(100% - 318px);
        // float: right;
        // padding: 16px;
        // background: $BG_WHITE !important;
        padding-left: 96px;

        .content_Header {
          .page_Title {
            font-size: $FONT_32;
            color: $TXT_NAVY;
            padding-bottom: 14px;
          }

          .inner_Title {
            color: $LABEL_GREY;
            font-size: $FONT_16;
            padding-bottom: 24px;
          }
        }
      }

      .divider {
        border-bottom: 1px solid $BG_LIGHTBLUE;
      }

      .table_Section {
        .query-Section {
          padding-top: 24px;

          .header {
            background: $BG_LIGHTBLUE;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;

            li {
              color: $TXT_LIGHTBLACK;
            }
          }

          .dataList {
            padding-left: 24px !important;
            border: 1px solid $BG_LIGHTBLUE;
            border-top: none !important;

            li {
              color: $TXT_NAVY;
            }
          }

          ul {
            list-style: none;
            padding-left: 24px !important;
            min-height: 48px;
            align-items: center;

            li {
              width: 10%;
              font-size: $FONT_16;
              position: relative;

              &:nth-child(1) {
                width: 5%;
              }

              &:nth-child(2) {
                width: 6%;
              }

              &:nth-child(3) {
                width: 10%;
              }

              &:nth-child(4) {
                width: 35%;
              }

              &:nth-child(5) {
                width: 30%;
              }

              &:nth-child(6) {
                width: 10%;

                .customDropdown {
                  margin-right: 15px !important;
                  width: 110px;

                  .dropdown {
                    .btn-primary {
                      padding: 5px 16px !important;
                      padding-left: 0 !important;
                      color: $TXT_NAVY;

                      &:after {
                        position: absolute;
                        right: 18px;
                        top: 12px;
                        color: $TXT_BLUE;
                      }
                    }
                  }
                }
              }

              &:nth-child(7) {
                width: 6%;
              }

              &:nth-child(8) {
                width: 6%;

                .publishBtn {
                  height: 29px;
                  padding: 0 !important;
                  width: 101px;
                  font-size: $FONT_14;
                }
              }

              .customDropdown {
                .dropdown {
                  .dropdown-menu {
                    top: auto !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .paginationClass {
    right: 1% !important;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .admin_SubscriberPage {
    .main_Container {
      .admin_SubscriberPage_Scroll {
        .dashboard_Section {
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .admin_SubscriberPage {
    .main_Container {
      .admin_SubscriberPage_Scroll {
        .dashboard_Section {
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .admin_SubscriberPage {
    .main_Container {
      .admin_SubscriberPage_Scroll {
        .dashboard_Section {
          .table_Section {
            .query-Section {
              .header {
                display: none !important;
              }

              .dataList {
                flex-wrap: wrap;
                height: auto;
                border-top: 1px solid #d9dbe9 !important;
                margin-bottom: 5px !important;

                li {
                  position: relative;
                  padding-top: 10px;

                  &:nth-child(1) {
                    width: 20%;

                    &::before {
                      content: "Query ID";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_REGULAR;
                    }
                  }

                  &:nth-child(2) {
                    width: 20%;

                    &::before {
                      content: "Raised On";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_REGULAR;
                    }
                  }

                  &:nth-child(3) {
                    width: 20%;

                    &::before {
                      content: "Resolved On";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(4) {
                    width: 40%;
                    margin-top: 15px;

                    &::before {
                      content: "Message";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(5) {
                    width: 50%;
                    padding-bottom: 10px !important;

                    &::before {
                      content: "Comments";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .admin_SubscriberPage {
    .main_Container {
      .admin_SubscriberPage_Scroll {
        .dashboard_Section {
          .table_Section {
            .query-Section {
              .header {
                display: none !important;
              }

              .dataList {
                flex-wrap: wrap;
                height: auto;
                border-top: 1px solid #d9dbe9 !important;
                margin-bottom: 5px !important;

                li {
                  position: relative;
                  padding-top: 10px;

                  &:nth-child(1) {
                    width: 20%;

                    &::before {
                      content: "Query ID";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_REGULAR;
                    }
                  }

                  &:nth-child(2) {
                    width: 20%;

                    &::before {
                      content: "Raised On";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_REGULAR;
                    }
                  }

                  &:nth-child(3) {
                    width: 20%;

                    &::before {
                      content: "Resolved On";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(4) {
                    width: 40%;
                    margin-top: 15px;

                    &::before {
                      content: "Message";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(5) {
                    width: 50%;
                    padding-bottom: 10px !important;

                    &::before {
                      content: "Comments";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(6) {
                    width: 20%;
                  }

                  .customDropdown {
                    .dropdown {
                      .dropdown-menu {
                        top: unset !important;
                        bottom: 0 !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .admin_SubscriberPage {
    .main_Container {
      .admin_SubscriberPage_Scroll {
        .dashboard_Section {
          .table_Section {
            .query-Section {
              .header {
                display: none !important;
              }

              .dataList {
                flex-wrap: wrap;
                height: auto;
                border-top: 1px solid #d9dbe9 !important;
                margin-bottom: 5px !important;
                min-height: 160px;

                li {
                  position: relative;
                  padding-top: 10px;

                  &:nth-child(1) {
                    width: 33%;

                    &::before {
                      content: "Query ID";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_REGULAR;
                    }
                  }

                  &:nth-child(2) {
                    width: 33%;

                    &::before {
                      content: "Raised On";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_REGULAR;
                    }
                  }

                  &:nth-child(3) {
                    width: 33%;

                    &::before {
                      content: "Resolved On";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(4) {
                    width: 50%;
                    margin-top: 0;

                    &::before {
                      content: "Message";
                      position: absolute;
                      top: -13px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(5) {
                    width: 50%;
                    padding-bottom: 10px !important;

                    &::before {
                      content: "Comments";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .admin_SubscriberPage {
    .main_Container {
      .admin_SubscriberPage_Scroll {
        .dashboard_Section {
          width: 100%;

          .page_Title {
            padding-top: 20px;
          }

          .table_Section {
            .query-Section {
              .header {
                display: none !important;
              }

              .dataList {
                flex-wrap: wrap;
                height: auto;
                border-top: 1px solid #d9dbe9 !important;
                margin-bottom: 5px !important;
                min-height: 160px;

                li {
                  position: relative;
                  padding-top: 10px;

                  &:nth-child(1) {
                    width: 33%;

                    &::before {
                      content: "Query ID";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_REGULAR;
                    }
                  }

                  &:nth-child(2) {
                    width: 33%;

                    &::before {
                      content: "Raised On";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_REGULAR;
                    }
                  }

                  &:nth-child(3) {
                    width: 33%;

                    &::before {
                      content: "Resolved On";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(4) {
                    width: 50%;
                    margin-top: 0;

                    &::before {
                      content: "Message";
                      position: absolute;
                      top: -13px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(5) {
                    width: 50%;
                    padding-bottom: 10px !important;

                    &::before {
                      content: "Comments";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
