@import "../../../assests/scss/variables.scss";

.textField {
  padding: 0;
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 24px;

  .inputBox {
    position: relative;

    .icon-css {
      color: $TXT_NAVY;
      position: absolute;
      top: 22px;
      right: 15px;
      font-size: $FONT_24;
      background: transparent;
      font-style: inherit;
      font-family: icomoon !important;
      line-height: normal !important;
    }

    .errorMsg {
      display: block;
      font-size: $FONT_14;
      text-align: left;
      padding: 0;
      margin: 0;
      color: $TXT_WHITE;
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.3s ease-out;
      font-family: $LATO_REGULAR;
    }

    .errorMsg.show {
      color: red;
      padding: 8px 0 0;
      max-height: 50px;
      transition: max-height 0.3s ease-in;
      display: flex;
      align-items: flex-start;

      p {
        margin: 0px;
        padding: 0px 0 0 0;
        font-size: $FONT_14;
        color: red;
        font-family: $LATO_REGULAR;
      }
    }
  }

  .form-control {
    background: $BG_WHITE;
    color: $TXT_NAVY;
    font-size: $FONT_16;
    padding: 11px 12px;
    height: 72px;
    outline: 0px;
    width: 100%;
    border-radius: 15px;
    border: 1px solid $TXT_NAVY;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &::-webkit-input-placeholder {
      color: $TXT_NAVY;
    }

    &::-moz-placeholder {
      color: $TXT_NAVY;
    }

    &:-ms-input-placeholder {
      color: $TXT_NAVY;
    }

    &:-moz-placeholder {
      color: $TXT_NAVY;
    }

    &:focus {
      outline: 0;
      box-shadow: none;
      border-color: $TXT_NAVY;
    }

    &.error {
      border-color: $TXT_NAVY;
    }

    &:disabled {
      background: $TXT_NAVY !important;
      border: 1px solid $TXT_NAVY;
      color: $TXT_NAVY !important;
      opacity: 1 !important;
    }
  }

  .form-group {
    margin-bottom: 0px;
  }
}
