@import "../../assests/scss/variables.scss";

.Forgot_Page {
  position: relative;
  width: 100%;
  height: 100vh;
  float: left;
  background: $BG_LIGHT;
  // z-index: -1;
  z-index: 1;

  .chatboat-section {
    bottom: 10px;
    right: 25px;
    width: 66px;
    height: 66px;
    background: $BG_NAVY;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
      color: $BG_WHITE;
    }
  }

  .chatboat-section {
    bottom: 10px;
    right: 25px;
    width: 66px;
    height: 66px;
    background: $BG_NAVY;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
      color: $BG_WHITE;
    }
  }

  .top_Patten {
    position: absolute;
    right: 0;
    z-index: -1;
  }

  .bottom_Patten {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }

  .scroll_Section {
    height: 100%;
  }

  .center_Section {
    display: flex;
    padding: 117px 174px 0 311px;
    justify-content: center;
    align-items: center;

    .img_Section {
      height: auto;
      width: 100%;

      .main_Img {
        max-width: 120%;
        max-height: 100%;
        object-fit: cover;
      }
    }

    .Forgot_Box {
      .Forgot_BoxInner {
        margin-left: 260px;
        max-width: 525px;
        width: 100%;
        background: $BG_LIGHT;
        padding: 32px;
        display: block;
        font-style: normal;
        line-height: 150%;
        border-radius: 15px;
        -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);

        h1 {
          font-family: $LATO_BOLD;
          font-size: $FONT_48;
          text-align: left;
          color: $TXT_ORANGE;
          margin-bottom: 24px;
          padding: 0;
        }

        .inner_Text {
          font-family: $LATO_LIGHT;
          font-size: $FONT_32;
          margin: 0;
          text-align: left;
          line-height: 48px;
          color: $TXT_NAVY;
          font-weight: 300;
          padding-bottom: 24px;
        }

        .form_Name {
          font-family: $LATO_REGULAR;
          font-size: $FONT_24;
          margin: 0;
          text-align: left;
          line-height: 36px;
          color: $TXT_LIGHTBLUE;
          padding: 0;
          padding-right: 5px;
        }

        .link_Section {
          display: flex;
          align-items: center;
          padding-bottom: 24px;
        }

        .form {
          .textField {
            .form-control {
              font-family: $LATO_REGULAR !important;
              box-sizing: border-box;
              border-radius: 15px;
              padding: 24px;
              order: 2;
            }
          }

          .submitBtn {
            border-radius: 15px !important;
            padding: 24px 205px;
            font-family: $LATO_REGULAR !important;
          }

          .back {
            display: flex;
            text-decoration: none;
            align-items: center;
            font-size: $FONT_16;
            justify-content: center;
            padding-top: 24px;

            i {
              color: $TXT_LIGHTBLACK;
              font-size: $FONT_24;
              padding-right: 7px;
            }

            .btl {
              font-family: $LATO_REGULAR !important;
              color: $TXT_LIGHTBLACK;
              font-size: $FONT_16;
              // margin-left: 10.05px;
              margin-bottom: 0px !important;
            }
          }

          .link_Css {
            color: $TXT_BLUE;

            font-size: $FONT_24;
            text-decoration: none;
          }

          .click_Action {
            text-align: left;
            color: $TXT_NAVY;
            font-size: $FONT_14;
            font-weight: 400;
            padding-bottom: 24px;
            line-height: 22px;

            .color {
              color: $TXT_ORANGE;
            }

            .link {
              color: $TXT_LIGHTBLUE;
              font-style: italic;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .Forgot_Page {
    .center_Section {
      padding: 0px 70px !important;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .img_Section {
        height: auto;

        .main_Img {
          max-width: 180% !important;
          // margin-left: 245px !important;
        }
      }

      .Forgot_Box {
        margin-left: 200px;
        margin-top: 100px;
        margin-bottom: 45px;

        h1 {
          font-size: $FONT_36;
          line-height: 48px;
        }

        .inner_Text {
          font-size: $FONT_22;
          padding-bottom: 15px;
        }

        .form_Name {
          font-size: $FONT_22;
        }

        .link_Section {
        }

        .form {
          .textField {
            .form-control {
            }
          }

          .submitBtn {
            font-family: $LATO_BOLD !important;
          }

          .back {
          }

          .link_Css {
            font-size: $FONT_14;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .Forgot_Page {
    .top_Patten {
    }

    .bottom_Patten {
    }

    .center_Section {
      padding: 0px 80px !important;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .img_Section {
        width: 100%;

        .main_Img {
          margin-top: 177px;
          max-width: 80% !important;
        }
      }

      .Forgot_Box {
        margin-left: 100px !important;
        margin-top: 0 !important;
        margin-bottom: 50px !important;

        h1 {
          font-size: $FONT_26;
          line-height: 50px;
        }

        .inner_Text {
          font-size: $FONT_22;
          padding-bottom: 15px;
        }

        .form {
          .submitBtn {
            font-family: $LATO_BOLD !important;
          }
        }

        .link_Section {
          .form_Name {
            font-size: $FONT_22;
          }

          .link_Css {
            font-size: $FONT_14;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .Forgot_Page {
    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }

    .center_Section {
      padding: 0px 0 !important;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .img_Section {
        display: none;
      }

      .Forgot_Box {
        margin: 0px !important;

        .Forgot_BoxInner {
          margin: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .Forgot_Page {
    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }

    .center_Section {
      padding: 0px 0 !important;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .img_Section {
        display: none;
      }

      .Forgot_Box {
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 0px !important;

        h1 {
        }

        .inner_Text {
        }

        .link_Section {
          .form_Name {
          }

          .link_Css {
          }
        }

        .Forgot_BoxInner {
          margin: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .Forgot_Page {
    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }

    .center_Section {
      padding: 0px 0 !important;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .img_Section {
        display: none;
      }

      .Forgot_Box {
        margin-left: 0;
        margin: 0;
        margin-bottom: 20px;

        .Forgot_BoxInner {
          margin-left: 0;
        }

        h1 {
          font-size: $FONT_36;
        }

        .inner_Text {
          font-size: $FONT_22;
        }

        .link_Section {
          .form_Name {
            font-size: $FONT_14;
          }

          .link_Css {
            font-size: $FONT_14;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .Forgot_Page {
    .top_Patten {
      height: 216px;
    }

    .bottom_Patten {
      height: 216px;
    }

    .center_Section {
      padding: 130px 24px;
      justify-content: center;
      align-items: center;

      .img_Section {
        display: none;
      }

      .Forgot_Box {
        margin: 0;
        padding: 32px 16px;
        margin-bottom: 20px;

        h1 {
          font-size: $FONT_26;
        }

        .inner_Text {
          font-size: $FONT_22;
        }

        .link_Section {
          .form_Name {
            font-size: $FONT_14;
          }

          .link_Css {
            font-size: $FONT_14;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .Forgot_Page {
    .top_Patten {
      height: 216px;
    }

    .bottom_Patten {
      height: 216px;
    }

    .center_Section {
      justify-content: center;
      align-items: center;
      padding: 0px 10px;
      height: 100vh;

      .img_Section {
        display: none;
      }

      .Forgot_Box {
        margin: 0;
        padding: 32px 16px 36px 16px !important;

        .Forgot_BoxInner {
          max-width: 366px;
          margin-left: 0;
          padding: 22px;

          h1 {
            font-size: $FONT_36;
          }
        }

        h1 {
          font-size: $FONT_26;
        }

        .inner_Text {
          font-size: $FONT_22;
        }

        .form_Name {
          line-height: 24px !important;
        }

        .link_Section {
          .form_Name {
            font-size: $FONT_16;
          }

          .link_Css {
            font-size: $FONT_14;
          }
        }

        .form {
          .submitBtn {
            padding: 24px 0px !important;
          }

          .back {
            margin: 28px 52px 0px 62px !important;
            padding-top: 0 !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 299px) {
  .Forgot_Page {
    display: none;
  }
}
