@import "../../../assests/scss/variables.scss";

.message_Box {
  // position: fixed;
  // right: 30px;
  // top: 20px;
  // z-index: 1;
  // cursor: pointer;
  margin: 40px 0;
  position: relative;

  .message_Inner {
    // border: 1px solid $BG_OFFLIGHT;
    width: 100%;
    position: relative;

    .close_Box {
      position: absolute;
      right: 10px;
      top: -20px;
      cursor: pointer;
      background: $BG_ORANGE;
      width: 48px;
      height: 48px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // z-index: 11;

      .icon-downarrow {
        font-size: $FONT_16;
        color: $TXT_WHITE;
      }
    }

    .form {
      border-radius: 20px 20px 0px 0px;
      border: 1px solid $BG_LIGHTBLUE;
      background: $BG;

      .link-container {
        display: flex;
        align-items: center;

        .link-label {
          color: $TXT_LIGHTBLUE;
          font-size: $FONT_20;
          cursor: pointer;
        }
        .close {
          background-color: WHITE;
          cursor: pointer;
          margin-left: 5px; // Adjust the margin as needed
        }
      }

      .textField {
        margin-bottom: 0;

        .form-control {
          border: 1px solid $BG_LIGHTBLUE;
          border-radius: 20px 20px 0px 0px;
          background: $BG;
        }

        .inputBox {
          textarea {
            height: 197px;
            padding: 32px;
            resize: none;
            width: 100%;
            // padding-right: 6em;
          }
        }
      }

      .bottom_Bar {
        background: $BG_LIGHTBLUE;
        height: 80px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0px;
        align-items: center;
        padding: 0 32px;

        a {
          text-decoration: none;
          color: $TXT_LIGHTBLUE;
          font-size: $FONT_20;
          padding-right: 18px;
        }

        .bottom_button {
          display: flex;

          button {
            height: 40px;
            width: 170px;
            position: relative;
            padding-left: 0px;
            border-radius: 8px !important;

            &:nth-child(1) {
              margin-right: 16px;
              padding: 0;
              text-align: left;
              padding-left: 20px;
            }

            &:nth-child(2) {
              margin-right: 16px;
              padding: 0;
              text-align: center;
            }

            i {
              position: absolute;
              right: 15px;
              top: 12px;
              font-size: $FONT_18;
            }
          }
        }

        label {
          cursor: pointer;
          text-decoration: none;
          color: #4e4b66;
          font-size: 20px;
          padding-right: 18px;
        }
      }
    }
  }

  .emoj {
    position: absolute;
    z-index: 1;
    top: -420px;
    left: 0;

    .picker {
      width: 10px;
    }
  }

  .scroll {
    position: absolute;
    z-index: 1;
    top: 30px;
    right: 0;

    margin: 4px, 4px;
    padding: 4px;

    width: 6em;
    height: 10em;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
  }

  .textArea {
    background: #eff0f7;
    border: none;
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    padding: 25px;
  }

  .close {
    background-color: WHITE;
    position: absolute;
    // width: 20px;
    // height: 20px;
    left: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .message_Box {
    width: 100%;

    .message_Inner {
      width: 100%;

      .form .bottom_Bar {
        .bottom_button {
          button {
            width: 100px !important;
            font-size: $FONT_14;
            font-family: $LATO_REGULAR;

            &:nth-child(1) {
              font-size: 0;
              width: 48px !important;
              margin-right: 0px;
            }

            &:nth-child(2) {
              font-size: 0;
              width: 48px !important;
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .message_Box {
    // width: 80%;

    .message_Inner {
      .form .bottom_Bar {
        .bottom_button {
          button {
            width: 100px !important;
            font-size: $FONT_14;
            font-family: $LATO_REGULAR;

            &:nth-child(1) {
              font-size: 0;
              width: 48px !important;
              margin-right: 0px;
            }

            &:nth-child(2) {
              font-size: 0;
              width: 48px !important;
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .message_Box {
    // width: 92%;

    .message_Inner {
      .form .bottom_Bar {
        .bottom_button {
          button {
            width: 100px !important;
            font-size: $FONT_14;
            font-family: $LATO_REGULAR;

            &:nth-child(1) {
              font-size: 0;
              width: 48px !important;
              margin-right: 0px;
            }

            &:nth-child(2) {
              font-size: 0;
              width: 48px !important;
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .message_Box {
    // width: 92%;

    .message_Inner {
      .form {
        .bottom_Bar {
          padding: 0 20px;

          .bottom_button {
            button {
              width: 100px !important;
              font-size: $FONT_13;
              font-family: $LATO_REGULAR;
              margin-right: 0px;

              &:nth-child(1) {
                font-size: 0;
                width: 48px !important;
                margin-right: 10px;
              }

              &:nth-child(2) {
                font-size: 0;
                width: 48px !important;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}
