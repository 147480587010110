@import "../../assests/scss/variables.scss";

.reset_Page {
  position: relative;
  width: 100%;
  height: 100%;
  float: left;
  background: $BG_LIGHT;
  // z-index: -1;
  z-index: 1;

  .top_Patten {
    position: absolute;
    right: 0;
    z-index: -1;
  }

  .bottom_Patten {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }

  .scroll_Section {
    height: 100%;
  }

  .center_Section {
    display: flex;
    padding: 117px 174px 0 311px;
    justify-content: center;
    align-items: center;

    .img_Section {
      height: 729px;
      width: 100%;

      .main_Img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }

    .reset_Box {
      .reset_BoxInner {
        width: 100%;
        background: $BG_LIGHT;
        padding: 32px;
        display: block;
        border-radius: 15px;
        -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);

        h1 {
          font-family: $LATO_BOLD;
          font-size: $FONT_44;
          text-align: left;
          color: $TXT_ORANGE;
          line-height: 150%;
        }

        .inner_Text {
          font-family: $LATO_REGULAR;
          font-size: $FONT_24;
          margin: 0;
          text-align: left;
          line-height: 150%;
          color: $TXT_LIGHTBLUE;
          font-weight: 300;
          padding-bottom: 24px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .reset_Page {
    .center_Section {
      padding: 80px 100px 0 100px;

      .reset_Box {
        margin-left: 200px;
      }

      .img_Section {
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .reset_Page {
    .center_Section {
      padding: 80px 80px 0 80px;

      .reset_Box {
        margin-left: 70px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .reset_Page {
    .center_Section {
      padding: 0px !important;
      height: 100vh !important;
      justify-content: center;
      align-items: center;

      .img_Section {
        display: none;
      }

      .reset_Box {
        margin: 0;

        .reset_BoxInner {
          max-width: 525px;
          width: 100%;
          background: #f7f7fc;
          padding: 32px;
          display: block;
          font-style: normal;
          line-height: 150%;
          border-radius: 15px;
          box-shadow: 0px 8px 20px rgb(0 0 0 / 25%);
        }
      }
    }

    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .reset_Page {
    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }

    .center_Section {
      padding: 0px !important;
      height: 90% !important;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      .img_Section {
        display: none;
      }

      .reset_Box {
        margin: 0px;

        .reset_BoxInner {
          max-width: 525px;
          width: 100%;
          background: #f7f7fc;
          padding: 32px;
          display: block;
          font-style: normal;
          line-height: 150%;
          border-radius: 15px;
          box-shadow: 0px 8px 20px rgb(0 0 0 / 25%);
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .reset_Page {
    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }

    .center_Section {
      padding: 0px !important;
      height: 100vh !important;
      justify-content: center;
      align-items: center;

      .img_Section {
        display: none;
      }

      .reset_Box {
        margin: 0px;
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .reset_Page {
    .top_Patten {
      height: 216px;
    }

    .bottom_Patten {
      height: 216px;
    }

    .center_Section {
      padding: 0px 24px 0 24px;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .img_Section {
        display: none;
      }

      .reset_Box {
        margin: 0;
        padding: 32px 16px;

        h1 {
          font-size: $FONT_26;
        }

        .inner_Text {
          font-size: $FONT_16;
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .reset_Page {
    .top_Patten {
      height: 216px;
    }

    .bottom_Patten {
      height: 216px;
    }

    .center_Section {
      padding: 0px 10px;
      justify-content: center;
      align-items: center;
      height: 98%;

      .img_Section {
        display: none;
      }

      .reset_Box {
        margin: 0;
        padding: 32px 0;

        .reset_BoxInner {
          padding: 10px;

          h1 {
            font-size: $FONT_26;
            line-height: 40px;
            padding-bottom: 10px;
          }

          .inner_Text {
            font-size: $FONT_16;
          }
        }
      }
    }
  }
}
