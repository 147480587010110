@import "../../../assests/scss/variables.scss";

.slider_Blog {
  .image_Slider {
    width: 461px !important;

    img {
      max-width: 100%;
      height: auto;
    }

    .content_Section {
      background: $BG_LIGHT;
      padding: 32px 18px;
      border-radius: 0 0 10px 10px;

      .name {
        font-size: $FONT_32 !important;
        color: $TXT_NAVY;
        font-family: $LATO_BOLD;
        padding-bottom: 8px !important;
        width: 100%;
      }

      .designation {
        font-size: $FONT_24 !important;
        color: $TXT_ORANGE;
        font-family: $LATO_SEMIBOLD;
        padding-bottom: 16px !important;

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 33px;
          height: 1px;
          background: $BG_NAVY;
        }
      }

      .inner_Txt {
        font-size: $FONT_24 !important;
        color: $TXT_LIGHT !important;
        line-height: 38px;
        font-family: $LATO_SEMIBOLD;
        padding-top: 16px !important;
        padding-bottom: 0 !important;
      }
    }
  }

  .slick-prev {
    background: $TXT_BLUELIGHT;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    left: -50px !important;

    &:before {
      color: $TXT_NAVY;
      font-size: $FONT_24;
      content: "\e90a";
      font-family: "icomoon" !important;
    }
  }

  .slick-next {
    background: $TXT_BLUELIGHT;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    right: -50px !important;

    &:before {
      color: $TXT_NAVY;
      font-size: $FONT_24;
      content: "\e90b";
      font-family: "icomoon" !important;
    }
  }

  .slick-slide {
    text-align: center;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1600px) {
  .slider_Blog {
    .image_Slider {
      width: 380px !important;

      .content_Section {
        .name {
          font-size: 30px !important;
        }

        .inner_Txt {
          font-size: 22px !important;
        }
      }
    }

    .slick-prev {
      left: -30px;
      z-index: 1;
      width: 40px;
      height: 40px;
    }

    .slick-next {
      width: 40px;
      height: 40px;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1299px) {
  .slider_Blog {
    .image_Slider {
      width: 381px !important;

      .content_Section {
        .name {
          font-size: 30px !important;
        }

        .inner_Txt {
          font-size: 22px !important;
        }
      }
    }

    .slick-prev {
      left: -50px;
      z-index: 1;
      width: 40px;
      height: 40px;
    }

    .slick-next {
      right: -50px;
      width: 40px;
      height: 40px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider_Blog {
    .image_Slider {
      width: 461px !important;

      img {
        height: auto;
      }

      .content_Section {
        .name {
          font-size: 30px !important;
        }

        .inner_Txt {
          font-size: 22px !important;
        }
      }
    }

    .slick-prev {
      top: 33%;
      left: -20px;
      z-index: 1;
      width: 40px;
      height: 40px;
    }

    .slick-next {
      top: 33%;
      right: -15px;
      width: 40px;
      height: 40px;
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider_Blog {
    .image_Slider {
      // width: 500px !important;

      img {
        height: auto;
      }

      .content_Section {
        .name {
          font-size: 30px !important;
        }

        .inner_Txt {
          font-size: 22px !important;
        }
      }
    }

    .slick-prev {
      top: 33%;
      left: -20px;
      z-index: 1;
      width: 40px;
      height: 40px;
    }

    .slick-next {
      top: 33%;
      right: -15px;
      width: 40px;
      height: 40px;
    }
  }
}

@media only screen and (min-width: 400px) and (max-width: 575px) {
  .slider_Blog {
    .image_Slider {
      width: 350px !important;

      img {
        height: auto;
      }

      .content_Section {
        .name {
          font-size: 30px !important;
        }

        .inner_Txt {
          font-size: 22px !important;
        }
      }
    }

    .slick-prev {
      top: 33%;
      left: -20px !important;
      z-index: 1;
      width: 40px;
      height: 40px;
    }

    .slick-next {
      top: 33%;
      right: -15px !important;
      width: 40px;
      height: 40px;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 399px) {
  .slider_Blog {
    .image_Slider {
      width: 300px !important;

      img {
        height: auto;
      }

      .content_Section {
        .name {
          font-size: 30px !important;
        }

        .inner_Txt {
          font-size: 22px !important;
        }
      }
    }

    .slick-prev {
      top: 33%;
      left: -20px;
      z-index: 1;
      width: 40px;
      height: 40px;
    }

    .slick-next {
      top: 33%;
      right: -15px;
      width: 40px;
      height: 40px;
    }
  }
}
