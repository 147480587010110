@import "../../assests/scss/variables.scss";

.AdminLogin_Page {
  position: relative;
  width: 100%;
  height: 100%;
  float: left;
  background: $BG_LIGHT;
  // z-index: -1;
  z-index: 1;

  .top_Patten {
    position: absolute;
    right: 0;
    z-index: -1;
  }

  .bottom_Patten {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }

  .scroll_Section {
    height: 100%;
  }

  .center_Section {
    display: flex;
    padding: 117px 174px 0 311px;

    .img_Section {
      height: 729px;
      width: 100%;

      .main_Img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }

    .Login_Box {
      margin-left: 260px;
      margin-top: 25px;
      margin-bottom: 100px;
      max-width: 525px;
      width: 100%;
      background: $BG_LIGHT;
      padding: 32px;
      display: block;
      border-radius: 8px;
      -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);

      h1 {
        font-family: $LATO_BOLD;
        font-size: 48px;
        text-align: left;
        color: $TXT_ORANGE;
        line-height: 72px;
        margin: 0;
        padding: 0;
      }

      .inner_Text {
        font-family: $LATO_LIGHT;
        font-size: 32px;
        margin: 0;
        text-align: left;
        line-height: 48px;
        color: $TXT_NAVY;
        font-weight: 300;
        padding-bottom: 24px;
      }

      .link_Section {
        display: flex;
        align-items: center;
        padding-bottom: 24px;

        .form_Name {
          font-family: $LATO_BOLD;
          font-size: 24px;
          margin: 0;
          text-align: left;
          line-height: 36px;
          color: $TXT_LIGHTBLUE;
          padding: 0;
          padding-right: 5px;
        }

        .link_Css {
          color: $TXT_BLUE;
          font-family: $LATO_BOLD;
          font-size: 24px;
          text-decoration: none;
        }
      }

      .form1 {
        .forgot {
          text-align: right;
          color: $TXT_NAVY !important;
          font-size: $FONT_16;
          font-family: $LATO_REGULAR !important;
          font-weight: 400;
          width: 100% !important;
          padding-bottom: 28px;
          line-height: 22px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .AdminLogin_Page {
    .center_Section {
      padding: 0px 24px 0px 24px;
      justify-content: center;
      align-items: center;
      height: 100%;

      .Login_Box {
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
      }

      .img_Section {
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .AdminLogin_Page {
    .center_Section {
      padding: 0px 24px 0px 24px;
      justify-content: center;
      align-items: center;
      height: 100%;

      .Login_Box {
        margin-left: 0px;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .AdminLogin_Page {
    .center_Section {
      padding: 0px 24px 0px 24px;
      height: 100%;
      justify-content: center;
      align-items: center;

      .img_Section {
        display: none;
      }

      .Login_Box {
        margin-left: 0;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .AdminLogin_Page {
    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }

    .center_Section {
      padding: 0px 24px 0px 24px;
      justify-content: center;
      align-items: center;
      height: 91%;

      .img_Section {
        display: none;
      }

      .Login_Box {
        margin-left: 0;
        margin-top: 0px;
        margin-bottom: 0px !important;
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .AdminLogin_Page {
    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }

    .center_Section {
      padding: 0px 24px 0 24px;
      justify-content: center;
      align-items: center;
      height: 100%;

      .img_Section {
        display: none;
      }

      .Login_Box {
        margin-left: 0;
        margin: 0;
        margin-bottom: 0px;

        h1 {
          font-size: $FONT_36;
        }

        .inner_Text {
          font-size: $FONT_22;
        }

        .link_Section {
          .form_Name {
            font-size: $FONT_14;
          }

          .link_Css {
            font-size: $FONT_14;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .AdminLogin_Page {
    .top_Patten {
      height: 216px;
    }

    .bottom_Patten {
      height: 216px;
    }

    .center_Section {
      padding: 0px 24px 0 24px;
      justify-content: center;
      align-items: center;
      height: 100%;

      .img_Section {
        display: none;
      }

      .Login_Box {
        margin: 0px;
        padding: 32px 16px;
        margin-bottom: 0px;
        margin-top: 0px;

        h1 {
          font-size: $FONT_26;
          line-height: 40px;
        }

        .inner_Text {
          font-size: $FONT_22;
          line-height: 50px;
        }

        .link_Section {
          padding-bottom: 20px;

          .form_Name {
            font-size: $FONT_14;
          }

          .link_Css {
            font-size: $FONT_14;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .AdminLogin_Page {
    .top_Patten {
      height: 216px;
    }

    .bottom_Patten {
      height: 216px;
    }

    .center_Section {
      height: 100%;
      padding: 0px 24px 0 24px;
      justify-content: center;
      align-items: center;

      .img_Section {
        display: none;
      }

      .Login_Box {
        margin: 0px;
        padding: 0px 24px;
        margin-top: 0px;

        h1 {
          font-size: $FONT_26;
        }

        .inner_Text {
          font-size: $FONT_22;
          line-height: 0 !important;
        }

        .link_Section {
          .form_Name {
            font-size: $FONT_14;
          }

          .link_Css {
            font-size: $FONT_14;
          }
        }

        button.submitBtn.btn.btn-primary {
          margin-bottom: 32px !important;
        }
      }
    }
  }
}
