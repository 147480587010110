@import "../../assests/scss/variables.scss";

.first_container {
  background: rgba(20, 20, 43, 0.5);
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  //   position:relative;
  .popup_box {
    font-family: Lato;
    font-style: normal;
    line-height: 150%;
    width: 100%;
    height: 100%;
    background: $BG;
    box-sizing: border-box;

    // position: absolute;
    .first_heading {
      border-bottom: 2px solid $BG_LIGHTBLUE;
      // margin:25px;

      display: flex;

      padding: 24px 20px 20px 24px;

      justify-content: space-between;

      .logo_heading {
        display: flex;

        .logo {
          height: 60px;
          width: 60px;
        }

        .title {
          margin-left: 10px;

          .heading1 {
            font-weight: 600;
            font-size: $FONT_24;
            color: $BG_NAVY;
          }

          .titleText {
            padding-bottom: 5px;

            font-family: $LATO_REGULAR;
          }

          .pauseclass {
            margin-left: 15px;
            font-weight: 400;

            .row {
              .status {
                .heading2 {
                  font-size: $FONT_16;

                  color: $TXT_DARKBLACK;
                }
              }

              .pause {
                .pause1 {
                  font-size: $FONT_16;

                  color: orange;
                }
              }
            }
          }
        }
      }
    }

    .scroll_box {
      overflow-y: scroll;
      height: 75.9%;

      .normaltext {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $BG_LIGHTBLUE;
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 33px;
        padding-bottom: 16px;

        .titleText {
          font-weight: normal;
          font-size: $FONT_14;
          font-family: $LATO_REGULAR;
          color: $TXT_LIGHTBLUE;
          padding-bottom: 0px;
        }
      }

      .normaltext1 {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $BG_LIGHTBLUE;
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 24px;
        padding-bottom: 16px;

        .titleText {
          font-weight: normal;
          font-size: $FONT_14;
          color: $TXT_LIGHTBLUE;
          padding-bottom: 0px;
          font-family: $LATO_REGULAR;
        }
      }

      .general {
        font-weight: 600;
        font-size: $FONT_16;

        margin: 32px 0px 0px 24px;

        .titleText {
          padding-bottom: 0px !important;
          color: $TXT_LIGHTBLUE;
          font-size: $FONT_16;
          font-family: $LATO_SEMIBOLD;
        }
      }

      .normaltext2 {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $BG_LIGHTBLUE;
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 24px;
        padding-bottom: 16px;

        .titleText {
          font-weight: normal;
          font-size: $FONT_14;
          color: $TXT_LIGHTBLUE;
          padding-bottom: 0px;
          font-family: $LATO_REGULAR;
        }
      }

      .normaltext3 {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $BG_LIGHTBLUE;
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 24px;
        padding-bottom: 24px;

        .titleText {
          font-weight: normal;
          font-size: $FONT_14;
          color: $TXT_LIGHTBLUE;
          padding-bottom: 0px;
          font-family: $LATO_REGULAR;
        }
      }

      .about {
        padding: 16px 24px 40px 24px;

        .heading {
          font-weight: 600;
          font-size: $FONT_16;
          line-height: 150%;
          padding-bottom: 12px !important;
          color: $TXT_LIGHTBLUE;
        }

        .text {
          font-weight: normal;
          font-size: $FONT_14;
          color: $TXT_LIGHTBLUE;
          padding-bottom: 0px !important;
          font-family: $LATO_REGULAR;
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1660px) {
  .first_container {
    .popup_box {
      // width: 50% !important;
      // height: 50% !important;
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .first_container {
    .popup_box {
      // width: 60% !important;
      // height: 60% !important;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .first_container {
    .popup_box {
      // width: 50% !important;
      // height: 50% !important;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .first_container {
    .popup_box {
      // width: 65% !important;
      // height: 70% !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .first_container {
    .popup_box {
      // width: 70% !important;
      // height: 70% !important;

      .scroll_box {
        height: 80% !important;
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .first_container {
    .popup_box {
      // width: 70% !important;
      // height: 70% !important;

      .scroll_box {
        height: 80% !important;
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .first_container {
    .popup_box {
      // width: 75% !important;
      // height: 70% !important;

      .first_heading {
        padding: 24px 21px 33px 23px !important;

        .logo_heading {
          .logo {
            height: 52px !important;
            width: 52px !important;
          }

          .title {
            .heading1 {
              font-size: $FONT_16 !important;
            }

            .heading2 {
              font-size: $FONT_14 !important;
            }
          }
        }
      }
    }

    .about {
      padding: 16px 24px 19px 24px !important;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .first_container {
    .popup_box {
      // width: 80% !important;
      // height: 81% !important;

      .first_heading {
        padding: 24px 21px 33px 23px !important;

        .logo_heading {
          .logo {
            height: 52px !important;
            width: 52px !important;
          }

          .title {
            .heading1 {
              font-size: $FONT_16 !important;
            }

            .heading2 {
              font-size: $FONT_14 !important;
            }
          }
        }
      }

      .scroll_box {
      }

      .about {
        padding: 16px 24px 19px 24px !important;
      }
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 299px) {
  .first_container {
    display: none;
  }
}
