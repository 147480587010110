@import "../../../assests/scss/variables.scss";

.preview_Section {
  width: 100%;
  // display: table;

  .nav-tabs {
    border-bottom: 1px solid $TXT_BLUELIGHT;
    margin-bottom: 48px !important;
    padding: 0px 15px;

    .nav-link {
      font-size: $FONT_16;
      font-weight: 600;
      color: $TXT_LIGHTCOLOR;
      padding: 0 !important;
      background: transparent;
      border-radius: 0;
      padding-top: 2px;

      &:focus {
        outline: none;
        border-color: transparent;
        border: none;
      }

      &.active {
        border: none;
        border-bottom: 1px solid $BG_NAVY;
        padding: 0;
        padding-bottom: 15px !important;
        background: transparent !important;
        color: $TXT_NAVY;
        font-weight: 600;
      }
    }

    .nav-item {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .tab-content {
    border: 1px solid $TXT_BLUELIGHT;

    .tab_Section {
      .preview_Details {
        display: flex;
        justify-content: space-between;
        padding: 16px 16px 0px 16px;

        .with_Logo {
          display: flex;
          align-items: center;

          .details {
            padding-left: 8px;

            .titleText {
              padding-bottom: 0;
            }

            .main_Heading {
              font-size: $FONT_16;
              font-weight: 400;
              color: $TXT_NAVY;
              line-height: 24px;
            }

            .sub-heading-main {
              display: flex;
              align-items: center;

              .sub-heading-title {
                color: $TXT_LIGHT;
              }

              .sub-heading-icon {
                margin-left: 8px;
                align-self: baseline;
                color: $TXT_LIGHTGREY;
              }
            }
          }
        }

        .time_Update {
          display: flex;
          align-items: center;

          .titleText {
            padding: 0;
          }
        }
      }
      .textWrap{
        text-overflow: ellipsis;
        overflow: hidden;
        height: 45px;
      }
      .new_Txt {
        font-size: $FONT_14;
        font-weight: 400;
        color: $TXT_LIGHT;
        line-height: 21px;
        padding: 0px 16px 8px 16px;
      }

      .img_Desp {
        color: $TXT_BLACK;
        padding-left: 24px;
        font-size: $FONT_14;
        padding-bottom: 16px;
        padding-right: 10px;

        &.hashTag {
          color: $TXT_SKYBLUE;
          padding-bottom: 7px;
        }
      }

      .main_Img {
        display: table;
        width: 100%;
      }

      .like_Section {
        margin: 0 8px;
        padding: 8px 0;
        border-bottom: 1px solid $TXT_BLUELIGHT;

        .bg_Icon {
          width: 16px;
          height: 16px;
          background: $TXT_SKYBLUE;
          border-radius: 100%;
          position: relative;
          margin-left: 24px;

          i {
            position: absolute;
            left: 3px;
            top: 3px;
            color: $TXT_WHITE;
            font-size: $FONT_10;
          }
        }
      }

      .url-main {
        background-color: #e3e6eb;
        display: flex;
        justify-content: space-between;
        padding: 16px 16px 16px 16px;
        align-items: center;
        border-bottom: 1px solid $TXT_LIGHT;
        border-top: 1px solid black;

        .left-section {
          overflow: hidden;
          height: 19px;
          .url-text {
            color: $TXT_LIGHTBLACK;
            font-size: $FONT_14;
            padding-bottom: 0px !important;
          }
        }

        .right-section {
          .learn-btn {
            background-color: #d9dbe9;
            color: $TXT_BLACK;
            border: none;
          }
        }
      }

      .icons-comments-share-main {
        display: flex;
        justify-content: space-between;

        .left-icons {
          display: flex;
          padding: 12px 0px 16px 12px;

          .left-text {
            margin-left: 4px;
            font-size: $FONT_16;
            font-weight: 600;
            color: $TXT_LIGHT;
            padding-bottom: 0px !important;
          }

          img.fb-like-icon {
            width: 16px;
            height: 16px;
          }
        }

        .right-comment-share-text {
          display: flex;
          padding: 12px 16px 16px 12px;

          .comment-text {
            display: flex;
            margin-right: 8px;

            .text-colors {
              color: $TXT_LIGHT;
              font-size: $FONT_14;
              margin-left: 4px;
              font-weight: 400;
              padding-bottom: 0px !important;
            }
          }

          .share-text {
            display: flex;

            .text-colors {
              color: $TXT_LIGHT;
              font-size: $FONT_14;
              margin-left: 4px;
              font-weight: 400;
              padding-bottom: 0px !important;
            }
          }
        }
      }

      .border-bottom {
        margin: 0px 16px;
      }

      .share_Comment {
        display: flex;
        // padding: 16px;
        padding: 8px 0px 16px 8px;
        justify-content: space-between;
        align-items: center;

        .link {
          display: flex;
          align-items: center;
          width: 33.33%;
          cursor: pointer;
          text-decoration: none;
          justify-content: center;

          span {
            color: $TXT_LIGHTBLACK;
            font-size: $FONT_14;
            font-family: $LATO_REGULAR;
          }
        }

        i {
          font-size: $FONT_16;
          color: $TXT_LIGHTGREY !important;
          padding-right: 8px;

          span {
            font-size: $FONT_12;
            color: $TXT_LIGHTGREY !important;
          }
        }

        img.profile-icon {
          height: 24px !important;
          width: 24px !important;
          margin-right: 6px;
          border-radius: 50%;
        }
      }

      //insta-card-scss

      &.insta_Section {
        padding: 16px;

        .insta-top-section {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .insta-profile {
          display: flex;

          .insta-profile-text {
            padding-bottom: 0px !important;
            justify-content: center;
            align-items: center;
            display: flex;
            margin-left: 10px;
            color: $TXT_BLACKIST;
          }

          .insta-time {
            color: $TXT_LIGHT;
          }
        }

        .insta-main-image {
          margin-top: 16px;
        }

        .insta-icons-main {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 8px;

          .insta-left-icons {
            .comment-icon {
              padding-left: 10px;
            }

            .share-icon {
              padding-left: 10px;
            }
          }
        }

        .total-likes {
          margin-top: 4px;

          .likes-text {
            padding-bottom: 0px !important;
          }
        }

        .isnta-about-post-text {
          margin-top: 10px;
          text-overflow: ellipsis;
          overflow: hidden;
          height: 45px;
          text-overflow: ellipsis;
          overflow: hidden;
          height: 45px;
          .post-text {
            padding-bottom: 0px !important;
            color: $TXT_LIGHTBLACK;
          }
        }

        .view-comments {
          margin-top: 10px;

          .comments-text {
            padding-bottom: 0px !important;
            color: $TXT_LIGHTGREY;
          }
        }
      }

      //TWITTER SCSS
      &.twitter_Section {
        .top-section {
          display: flex;
          padding: 16px 16px 16px 16px;

          .twitter-profile {
            img.twiter-profile {
              border-radius: 50%;
            }
          }

          .divide {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left-section {
              .left-title {
                display: flex;
                align-items: center;
                // justify-content: center;

                .all-twitter-text {
                  margin-left: 8px;
                  padding-bottom: 0px !important;
                }

                .profile-name {
                  color: $TXT_BLACK;
                }

                .profile-tag {
                  color: $TXT_LIGHTGREY;
                }
              }

              .timeline {
                text-overflow: ellipsis;
                overflow: hidden;
                height: 45px;
                .timeline-text {
                  margin-top: 4px;
                  margin-left: 8px;
                  padding-bottom: 0px !important;
                }

                .timeline-title {
                  color: $TXT_LIGHT;
                  font-weight: bold;
                }
              }
            }
          }
        }

        .image-icons-main {
          margin: 0px 30px;

          .twitter-main_Img {
            padding: 0px 48px 0px 48px;

            img.main_Img {
              border-radius: 16px;
            }
          }

          .twitter-share_Comment {
            margin: 16px 40px;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .icon-text {
              margin-left: 8px;
              font-size: $FONT_12;
              color: $TXT_LIGHTGREY;
              font-weight: 700;
            }

            .twitter-all-icon {
              color: $TXT_LIGHTGREY;
            }

            .align-icon-text {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }

      //linkedIn css
      .new_Txt linkedin_about_post{
        text-overflow: ellipsis;
        overflow: hidden;
        height: 45px;
     }
      &.linked_section {
        .linkedin_about_post {
          color: $TXT_BLACKIST;
          margin-top: 8px;
          font-weight: lighter;
          padding-bottom: 0px !important;
        }
        .linkedin_hastag {
          color: #0072b1;
        }
        .linkedin_url_main {
          background-color: #f0f1f5 !important;
        }
        .linkedIn_btn {
          background-color: #f0f1f5 !important;
          border-radius: 32px;
          border: 1px solid #0072b1 !important;
          color: #0072b1 !important;
        }
        .linkedin_border {
          margin: 0px !important;
        }
      }
    }
  }

  // ul.for-scroll.nav.nav-tabs {
  //   flex-wrap: nowrap;
  //   overflow-y: scroll;
  // }
  ul.for-scroll.nav.nav-tabs {
    flex-wrap: nowrap;
    overflow-y: scroll;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1600px) {
  .preview_Section {
    .nav-tabs {
      .nav-item {
        margin-right: 12px;
      }
    }

    .tab-content {
      .tab_Section {
        .share_Comment {
          i {
            padding-right: 5px;
          }
        }
      }
    }
  }
}
