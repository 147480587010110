@import "../../assests//scss/variables.scss";

.blog-modal-parent {
  .top-section {
    display: flex;
    justify-content: flex-end;
    padding: 24px 24px 0px 0px;
    color: $TXT_BLACK;
    cursor: pointer;
  }
  .comment-card {
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25);
    margin: 24px;
    border-radius: 8px;
    background-color: $BG_WHITE;
    padding: 16px 16px 16px 16px;
    .profile-image-name {
      display: flex;
      align-items: center;
      .profile-name {
        font-size: 16px;
        color: $TXT_LIGHTGREY;
        padding-bottom: 0px;
        margin-left: 16px;
      }
      .profile-img {
        border-radius: 50%;
        height: 40px;
        width: 40px;
      }
    }
    .form {
      margin-top: 16px;
      .textField .form-control {
        border: none;
        color: $TXT_LIGHTCOLOR;
        // height: 0px !important;
        width: 100%;
        padding: 16px 0px 0px 0px;
      }
      .card-btn {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        .cancel-btn {
          background-color: $BG_WHITE !important;
          border: 1px solid $TXT_NAVY;
          color: $TXT_NAVY;
          margin-right: 8px;
          border-radius: 50px;
          border: none;
        }
        .submit-btn {
          background-color: $BG_NAVY !important;
          border: 1px solid $TXT_NAVY;
          color: $TXT_WHITE;
          border-radius: 50px;
        }
      }
    }
  }
}
