@import "../../assests/scss/variables.scss";

.about_Page {
  overflow: hidden;

  .about_Scroll {
    height: calc(100vh - 150px) !important;
    height: 100vh !important;
    position: relative;

    .main_aboutPage {
      width: 100%;

      .about_Inner {
        display: flex;
        flex-direction: column;
        width: 100%;

        .who_Section {
          display: flex;
          align-items: center;
          padding: 88px;

          .img_Section {
            width: 60%;
            float: left;
            padding-left: 92px;

            img {
              width: 100%;

              &.resp_Show {
                display: none;
              }
            }
          }

          .content_Section {
            width: 40%;
            float: left;

            .main_Title {
              font-size: $FONT_48;
              color: $TXT_NAVY;
              font-family: $LATO_BOLD;
              padding-bottom: 35px;
            }

            .inner_Title {
              font-size: $FONT_24;
              color: $TXT_LIGHTGREY;
              font-family: $LATO_SEMIBOLD;
              max-width: 780px;
              line-height: 30px;
              padding-bottom: 40px;
            }

            .expoloreBtn {
              background: $BG_ORANGE !important;
              border-color: $BG_ORANGE;
              border-radius: 100px;
              width: 230px;
              height: 68px;
              font-size: $FONT_24;
              font-family: $LATO_SEMIBOLD;
            }
          }
        }

        .wedo_Section {
          justify-content: space-between;
          padding: 121px 88px;
          background: $BG_LIGHT;

          .img_Section {
            width: 40%;
            float: left;

            img {
              width: 100%;

              &.img_Show {
                display: none;
              }
            }
          }

          .content_Section {
            width: 30%;
            float: left;

            .main_Title {
              font-size: $FONT_48;
              color: $TXT_NAVY;
              font-family: $LATO_BOLD;
              padding-bottom: 35px;
            }

            .inner_Title {
              font-size: $FONT_24;
              color: $TXT_LIGHTGREY;
              font-family: $LATO_SEMIBOLD;
              max-width: 780px;
              line-height: 30px;
              padding-bottom: 40px;
            }

            .expoloreBtn {
              background: $BG_ORANGE !important;
              border-color: $BG_ORANGE;
              border-radius: 100px;
              width: 230px;
              height: 68px;
              font-size: $FONT_24;
              font-family: $LATO_SEMIBOLD;
            }
          }
        }

        .purpose_Section {
          display: flex;
          align-items: center;
          padding: 88px;

          .img_Section {
            width: 60%;
            float: left;
            padding-left: 92px;

            img {
              width: 100%;

              &.resp_Show {
                display: none;
              }
            }
          }

          .content_Section {
            width: 40%;
            float: left;

            .main_Title {
              font-size: $FONT_48;
              color: $TXT_NAVY;
              font-family: $LATO_BOLD;
              padding-bottom: 35px;
            }

            .inner_Title {
              font-size: $FONT_24;
              color: $TXT_LIGHTGREY;
              font-family: $LATO_SEMIBOLD;
              max-width: 780px;
              line-height: 30px;
              padding-bottom: 40px;
            }

            .expoloreBtn {
              background: $BG_ORANGE !important;
              border-color: $BG_ORANGE;
              border-radius: 100px;
              width: 230px;
              height: 68px;
              font-size: $FONT_24;
              font-family: $LATO_SEMIBOLD;
            }
          }
        }

        .imgSlider_Section {
          padding: 100px 174px;

          .main_Title {
            font-size: $FONT_32;
            font-family: $LATO_BOLD;
            padding-bottom: 100px;
            color: $TXT_NAVY;
            text-align: center;
            width: 100%;
          }

          .slider_Section {
            .slick-prev {
              z-index: 11;
            }

            .image_Slider {
              width: 461px !important;

              img {
                width: 100%;
              }
            }

            .slick-initialized {
              .slick-slide {
                display: flex;
                justify-content: center;
              }
            }
          }

          &.bgColor {
            background: $BG_NAVY;

            .main_Title {
              color: $TXT_WHITE;
            }
          }
        }

        .input_Section {
          background: $BG_WHITE;
          padding: 100px 174px 200px 112px;
          position: relative;

          .main_Title {
            font-size: $FONT_40;
            color: $TXT_NAVY;
            font-family: $LATO_BOLD;
            padding-bottom: 80px;
          }

          .content_Textbox {
            display: flex;
            align-items: center;

            .img_Section {
              margin-right: 175px;
              width: 684px;

              img {
                max-width: 100%;
              }
            }

            .text_Box {
              width: 775px;

              .form1 {
                .textField {
                  padding-bottom: 32px;
                  margin: 0;

                  .form-control {
                    border-radius: 15px;
                  }
                }

                textarea {
                  height: 260px;
                  resize: none;

                  .form-control {
                    border-radius: 15px;
                  }
                }
              }

              button {
                width: 230px;
                background: $BG_ORANGE !important;
                margin-top: 16px;
                float: right;
                border-radius: 100px !important;
              }
            }
          }
        }
      }
    }
  }

  .footer_Section {
    // margin-bottom: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 1599px) and (max-width: 1800px) {
  .about_Page {
    .about_Scroll {
      .main_aboutPage {
        .about_Inner {
          .imgSlider_Section {
            padding: 100px 80px;

            .slider_Section {
              .image_Slider {
                width: 400px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .about_Page {
    .about_Scroll {
      .main_aboutPage {
        .about_Inner {
          .wedo_Section {
            padding: 100px 82px;

            .content_Section {
              width: 50%;
            }
          }

          .imgSlider_Section {
            padding: 100px 80px;

            .slider_Section {
              .image_Slider {
                width: 380px !important;
              }
            }
          }

          .input_Section {
            .content_Textbox {
              .img_Section {
                margin-right: 100px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .about_Page {
    .about_Scroll {
      .main_aboutPage {
        .about_Inner {
          .who_Section {
            padding: 100px 30px;

            .content_Section {
              padding-left: 80px;
            }
          }

          .wedo_Section {
            padding: 100px 30px;

            .content_Section {
              width: 50%;
            }
          }

          .manage_Section {
            .content_Section {
              .main_Title {
                max-width: 600px;
              }

              .inner_Title {
                max-width: 600px;
              }
            }
          }

          .imgSlider_Section {
            padding: 100px 60px;

            .slider_Section {
              .image_Slider {
                width: 420px !important;
              }
            }
          }

          .input_Section {
            padding: 100px 112px 200px 112px;

            .content_Textbox {
              .img_Section {
                margin-right: 80px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .about_Page {
    .about_Scroll {
      .main_aboutPage {
        .about_Inner {
          padding-top: 8px;

          .who_Section {
            padding: 100px 30px;

            .content_Section {
              padding-left: 25px;
            }
          }

          .wedo_Section {
            padding: 60px 30px;

            .content_Section {
              padding-left: 0;
              width: 50%;
            }
          }

          .imgSlider_Section {
            padding: 40px 60px;

            .slider_Section {
              .image_Slider {
                width: 400px !important;
              }
            }
          }

          .input_Section {
            padding: 100px 112px 200px 112px;

            .content_Textbox {
              .img_Section {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.5px) {
  .about_Page {
    .about_Scroll {
      .main_aboutPage {
        .about_Inner {
          padding-top: 8px;

          .who_Section {
            padding: 30px;
            flex-direction: column;

            .img_Section {
              width: 100%;
              padding-bottom: 50px;
              padding-left: 0;
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              text-align: center;
              padding-bottom: 40px;

              .main_Title {
                text-align: center;
                width: 100%;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
              }
            }
          }

          .wedo_Section {
            padding: 0 30px;
            flex-direction: column;

            .img_Section {
              width: 80%;
              padding-bottom: 100px;
              padding-top: 48px;

              .img_Hide {
                display: none;
              }

              .img_Show {
                display: block !important;
              }
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              padding-top: 25px;
              text-align: center;
              padding-bottom: 40px;

              .main_Title {
                text-align: center;
                width: 100%;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
              }
            }
          }

          .purpose_Section {
            padding: 0 30px;
            flex-direction: column;

            .img_Section {
              width: 100%;
              padding-bottom: 100px;
              padding-top: 48px;
              padding-left: 0;

              .img_Hide {
                display: none;
              }

              .img_Show {
                display: block !important;
              }
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              padding-top: 25px;
              text-align: center;

              .main_Title {
                text-align: center;
                width: 100%;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
              }
            }
          }

          .imgSlider_Section {
            padding: 100px 60px;

            .slider_Section {
              .slider_Blog {
                .slick-prev {
                  left: -10px !important;
                }

                .slick-right {
                  right: 0px !important;
                }
              }

              .image_Slider {
                width: 660px !important;
              }
            }
          }

          .input_Section {
            padding: 100px 112px 100px 112px;

            .content_Textbox {
              .img_Section {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 575.5px) and (max-width: 767px) {
  .about_Page {
    .about_Scroll {
      .main_aboutPage {
        .about_Inner {
          padding-top: 8px;

          .who_Section {
            padding: 20px;
            flex-direction: column;

            .img_Section {
              width: 100%;
              padding-bottom: 50px;
              padding-left: 0;
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              text-align: center;

              .main_Title {
                text-align: center;
                font-size: $FONT_24;
                font-family: $LATO_REGULAR;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
                font-size: $FONT_16;
              }
            }
          }

          .wedo_Section {
            padding: 30px;
            flex-direction: column;

            .img_Section {
              width: 80%;
              padding-bottom: 100px;
              padding-top: 48px;
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              padding-top: 25px;
              padding-left: 0;
              text-align: center;

              .main_Title {
                width: 100%;
                text-align: center;
                font-size: $FONT_24;
                font-family: $LATO_REGULAR;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
                font-size: $FONT_16;
              }
            }
          }

          .purpose_Section {
            padding: 20px;
            flex-direction: column;

            .img_Section {
              width: 100%;
              padding-bottom: 50px;
              padding-left: 0;
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              text-align: center;

              .main_Title {
                text-align: center;
                font-size: $FONT_24;
                font-family: $LATO_REGULAR;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
                font-size: $FONT_16;
              }
            }
          }

          .imgSlider_Section {
            padding: 30px;

            .slider_Section {
              .image_Slider {
                width: 450px !important;
              }
            }
          }

          .input_Section {
            padding: 98px 78px;

            .main_Title {
              width: 100%;
              text-align: center;
            }

            .content_Textbox {
              .img_Section {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 575.5px) {
  .about_Page {
    .about_Scroll {
      .main_aboutPage {
        .about_Inner {
          padding-top: 8px;

          .who_Section {
            padding: 20px;
            flex-direction: column;

            .img_Section {
              width: 100%;
              padding-bottom: 50px;
              padding-left: 0;
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              text-align: center;

              .main_Title {
                text-align: center;
                font-size: $FONT_24;
                font-family: $LATO_REGULAR;
                width: 100%;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
                font-size: $FONT_16;
              }
            }
          }

          .wedo_Section {
            padding: 30px;
            flex-direction: column;

            .img_Section {
              width: 80%;
              padding-bottom: 30px;
              padding-top: 48px;
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              padding-top: 25px;
              padding-left: 0;
              text-align: center;

              .main_Title {
                width: 100%;
                text-align: center;
                font-size: $FONT_24;
                font-family: $LATO_REGULAR;
                padding-bottom: 10px;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
                font-size: $FONT_16;
              }
            }
          }

          .purpose_Section {
            padding: 20px;
            flex-direction: column;

            .img_Section {
              width: 100%;
              padding-bottom: 50px;
              padding-left: 0;
            }

            .content_Section {
              padding-left: 0;
              width: 100%;
              text-align: center;

              .main_Title {
                text-align: center;
                font-size: $FONT_24;
                font-family: $LATO_REGULAR;
                width: 100%;
              }

              .inner_Title {
                max-width: 100%;
                text-align: center;
                font-size: $FONT_16;
              }
            }
          }

          .imgSlider_Section {
            padding: 30px;

            .slider_Section {
              .image_Slider {
                width: 350px !important;
              }
            }
          }

          .input_Section {
            padding: 30px;

            .main_Title {
              width: 100%;
              text-align: center;
              padding-bottom: 40px;
            }

            .content_Textbox {
              .img_Section {
                display: none;
              }
            }
          }

          .slider_Blog {
            .slick-next {
              right: -20px !important;
            }

            .slick-prev {
              left: -20px !important;
            }
          }
        }
      }
    }
  }
}
