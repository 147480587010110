@import "../scss/variables.scss";

.edit_Post {
  .modal-dialog {
    max-width: 650px;

    .modal-content {
      padding: 40px 32px;

      .modal-header {
        padding: 0;
        border: none;
        padding-bottom: 32px;

        .modal-title {
          font-size: $FONT_32;
          font-family: $LATO_SEMIBOLD;
          color: $TXT_LIGHTBLACK;
        }
      }

      .modalInner {
        .modal-body {
          padding: 0;

          .preview_Section {
            &.modal_Tabsection {
              .nav-tabs {
                margin-bottom: 64px !important;

                .nav-link {
                  padding-bottom: 8px !important;
                }
              }

              .tab-content {
                width: 100%;
                border: none;

                .inner_Tab {
                  .dropdown-Calendar {
                    .filter_Title {
                      font-size: $FONT_16;
                      font-weight: 500;
                      color: $TXT_LIGHTBLACK;
                      padding-bottom: 16px;
                    }

                    .inner_dropSection {
                      display: flex;
                      justify-content: space-around;
                      margin-bottom: 40px;

                      .dropdown_Part {
                        width: 50%;
                        padding-right: 7px;

                        .customDropdown {
                          width: 100%;

                          .dropdown button {
                            padding: 17px 24px !important;
                          }

                          .dropdown {
                            button {
                              padding: 16px 24px !important;
                              border: 1px solid !important;
                              border-radius: 10px;
                              position: relative;
                              background-color: $TXT_OFFWHITE !important;
                              font-size: $FONT_16 !important;
                              color: $TXT_LIGHT !important;

                              &:after {
                                position: absolute;
                                right: 24px;
                                top: 22px;
                              }
                            }
                          }
                        }
                      }

                      .calendar_Section {
                        width: 100%;
                        padding-left: 7px;

                        .calenderMain {
                          position: relative;
                          padding-bottom: 8px;

                          .customCalender {
                            // .react-datepicker__tab-loop {
                            //     .react-datepicker-popper {
                            //         width: 160%;
                            //         left: -130px !important;

                            //     }
                            // }

                            // i {
                            //     position: absolute;
                            //     right: 24px;
                            //     top: 18px;
                            //     color: $TXT_BLUE;
                            // }

                            // .react-datepicker-wrapper {
                            //     .react-datepicker__input-container {
                            //         input {
                            //             width: 100%;
                            //             height: 56px;
                            //             border: 1px solid $TXT_LIGHT;
                            //             border-radius: 8px;
                            //             padding: 0 16px;
                            //             color: $TXT_LIGHTBLUE;
                            //             font-size: $FONT_14;
                            //             font-weight: 500;

                            //             &:after {
                            //                 line-height: 56px;
                            //                 right: 20px;

                            //             }

                            //             &:focus {
                            //                 border: 1px solid $TXT_LIGHT !important;
                            //             }
                            //         }
                            //     }
                            // }
                            .react-datepicker-wrapper {
                              .react-datepicker__input-container {
                                input {
                                  width: 100%;
                                  height: 56px;
                                  border: 1px solid $TXT_LIGHT;
                                  border-radius: 8px;
                                  padding: 0 16px;
                                  color: $TXT_LIGHTBLUE;
                                  font-size: $FONT_14;
                                  font-weight: 500;

                                  &:focus {
                                    border: 1px solid $TXT_LIGHT !important;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  .timer_Section {
                    margin-bottom: 40px;

                    .calenderMain {
                      position: relative;

                      .react-datepicker-wrapper {
                        .react-datepicker__input-container {
                          input {
                            width: 100%;
                            padding: 16px 24px !important;
                            border: 1px solid $TXT_LIGHT !important;
                            border-radius: 10px;

                            &:focus {
                              outline: none;
                            }
                          }
                        }
                      }
                    }

                    i {
                      position: absolute;
                      right: 25px;
                      top: 20px;
                      color: $TXT_BLUE;
                    }
                  }

                  .label_Section {
                    .time_Zone {
                      padding-bottom: 8px;
                      color: $TXT_LIGHTBLUE;
                      font-size: $FONT_16;
                      font-family: $LATO_SEMIBOLD;
                      line-height: 24px;
                    }

                    .time_Inner {
                      padding-bottom: 0;
                      color: $TXT_LIGHTBLUE;
                      font-size: $FONT_16;
                      font-family: $LATO_SEMIBOLD;
                      line-height: 24px;
                    }
                  }

                  .bottom_Button {
                    display: flex;
                    margin-top: 40px;

                    button {
                      width: 220px;
                      margin-right: 10px;
                      height: 60px;

                      &:nth-child(2) {
                        background: transparent !important;
                        color: $TXT_NAVY !important;
                        border: 1px solid $BG_NAVY !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.editpost_Review {
  .modal-dialog {
    max-width: 938px;

    .modal-content {
      padding: 40px 0px;

      .modal-header {
        border: none;
        padding: 0 32px;
      }

      .modalInner {
        padding-top: 40px;

        .modal-body {
          width: 412px;
          margin: 0 auto;
          padding: 0;

          .tab_Section {
            border: 1px solid $TXT_BLUELIGHT;
            margin-bottom: 40px;

            .preview_Details {
              display: flex;
              justify-content: space-between;
              padding: 16px;

              .with_Logo {
                display: flex;
                align-items: center;

                .details {
                  padding-left: 8px;

                  .titleText {
                    padding-bottom: 0;
                  }

                  .main_Heading {
                    font-size: $FONT_16;
                    font-weight: 400;
                    color: $TXT_NAVY;
                    line-height: 24px;
                  }

                  .new_Txt {
                    font-size: $FONT_14;
                    font-weight: 400;
                    color: $TXT_LIGHT;
                    line-height: 21px;
                  }
                }
              }

              .time_Update {
                display: flex;
                align-items: center;

                .titleText {
                  padding: 0;
                }
              }
            }

            .img_Desp {
              color: $TXT_BLACK;
              padding-left: 24px;
              font-size: $FONT_14;
              padding-bottom: 16px;
              padding-right: 10px;

              &.hashTag {
                color: $TXT_SKYBLUE;
                padding-bottom: 7px;
              }
            }

            .main_Img {
              display: table;
              width: 100%;
            }

            .like_Section {
              margin: 0 8px;
              padding: 8px 0;
              border-bottom: 1px solid $TXT_BLUELIGHT;

              .bg_Icon {
                width: 16px;
                height: 16px;
                background: $TXT_SKYBLUE;
                border-radius: 100%;
                position: relative;
                margin-left: 24px;

                i {
                  position: absolute;
                  left: 3px;
                  top: 3px;
                  color: $TXT_WHITE;
                  font-size: $FONT_10;
                }
              }
            }

            .share_Comment {
              display: flex;
              padding: 25px;

              .link {
                display: flex;
                align-items: center;
                width: 33.33%;
                cursor: pointer;
                text-decoration: none;
                justify-content: center;

                span {
                  color: $TXT_LIGHTBLACK;
                  font-size: $FONT_14;
                  font-family: $LATO_REGULAR;
                }
              }

              i {
                font-size: $FONT_16;
                color: $TXT_LIGHTGREY !important;
                padding-right: 8px;

                span {
                  font-size: $FONT_12;
                  color: $TXT_LIGHTGREY !important;
                }
              }
            }

            &.insta_Section {
              .preview_Details {
                position: relative;

                .with_Logo {
                  align-items: flex-start;

                  .logo {
                    width: 118px;
                  }

                  .details {
                    .inline_Section {
                      display: flex;
                      align-items: center;

                      .iconCss {
                        position: absolute;
                        right: 15px;
                        top: 16px;
                        color: $TXT_LIGHT;
                      }

                      .main_Heading {
                        color: $TXT_BLACKIST;
                        padding-right: 4px;
                      }

                      .mail {
                        color: $TXT_LIGHTGREY;
                        padding-right: 10px;
                        position: relative;

                        &:before {
                          content: "";
                          position: absolute;
                          width: 4px;
                          height: 4px;
                          background: $TXT_LIGHTGREY;
                          border-radius: 100%;
                          right: 0;
                          top: 5px;
                        }
                      }

                      .time {
                        color: $TXT_LIGHTGREY;
                        padding-left: 4px;
                      }
                    }

                    .new_Txt {
                      color: $TXT_BLACK !important;
                      padding-bottom: 16px;
                    }

                    .share_Comment {
                      padding: 0;
                      padding-top: 16px;

                      i {
                        font-size: $FONT_16;
                        color: $TXT_LIGHTGREY !important;
                        padding-right: 8px;

                        span {
                          font-size: $FONT_12;
                          color: $TXT_LIGHTGREY !important;
                        }
                      }
                    }
                  }
                }
              }
            }

            &.twitter_Section {
              .preview_Details {
                .with_Logo {
                  .details {
                    .main_Heading {
                      padding-bottom: 5px;
                    }

                    .inner_Text {
                      color: $TXT_LIGHT !important;
                    }

                    .text_Icon {
                      display: flex;

                      .new_Txt {
                        position: relative;
                        padding-right: 8px;

                        &:before {
                          content: "";
                          position: absolute;
                          right: 2px;
                          top: 6px;
                          width: 4px;
                          height: 4px;
                          background: $TXT_LIGHT;
                          border-radius: 100%;
                        }
                      }

                      i {
                        color: $TXT_LIGHT;
                        align-items: center;
                      }
                    }
                  }
                }
              }

              .share_Comment {
                .link {
                  width: auto !important;
                  padding-right: 10px;
                }
              }
            }
          }

          button {
            width: 184px;
            float: right;
            height: 56px;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .editpost_Review {
    .modal-dialog {
      max-width: 650px;
    }
  }
}

@media only screen and (min-width: 676px) and (max-width: 767px) {
  .editpost_Review {
    .modal-dialog {
      max-width: 450px;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 675px) {
  .edit_Post {
    .modal-dialog {
      max-width: 363px;
      display: table;
      margin: 0 auto;

      .modal-content {
        .modalInner {
          .modal-body {
            .preview_Section {
              &.modal_Tabsection {
                .tab-content {
                  .inner_Tab {
                    .dropdown-Calendar {
                      .inner_dropSection {
                        flex-direction: column;
                        margin-bottom: 10px;

                        .dropdown_Part {
                          width: 100%;
                          padding: 0;
                          padding-bottom: 15px;
                        }

                        .calendar_Section {
                          width: 100%;
                          padding: 0;
                        }
                      }
                    }

                    .bottom_Button {
                      flex-direction: column;

                      button {
                        width: 100%;

                        &:first-child {
                          margin-bottom: 20px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .editpost_Review {
    .modal-dialog {
      max-width: 350px;
      margin: 0 auto;

      .modalInner {
        .modal-body {
          width: 300px !important;
        }
      }
    }
  }
}
