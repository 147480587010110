@import "../../assests/scss/variables.scss";

.statistics_Page {
  width: 100%;
  // display: table;
  display: inline;
  position: relative;
  background: $BG_LIGHT;
  overflow: hidden;

  .main_Container {
    width: calc(100% - 96px);
    float: left;

    .statistics_Inner {
      // width: calc(100% - 225px);
      width: 100%;
      float: right;
      padding-left: 32px;

      .tabSearch_section {
        width: 100%;
        float: left;
        padding-top: 40px;
        position: relative;

        .label_Filter {
          display: flex;
          justify-content: space-between;
          padding-right: 15px;

          .label {
            display: flex;
            flex-direction: column;
          }

          .search_Filter {
            display: flex;

            .filterBtn {
              position: relative;
              margin-right: 24px;
              margin-top: 5px !important;

              .filterIcon {
                position: relative;
                margin-left: 15px;
                background: $BG_WHITE;
                width: 117px;
                height: 40px;
                border-radius: 8px;
                border: none;
                display: flex;
                align-items: center;
                border: 1px solid $TXT_LIGHTBLACK;
                justify-content: center;

                &:focus {
                  outline: none;
                }

                i {
                  color: $TXT_LIGHTBLACK;
                  font-size: $FONT_16;

                  span {
                    font-size: $FONT_16;
                    font-family: $LATO_REGULAR;
                  }
                }
              }
            }

            .submitBtn {
              height: 48px;
              width: 128px;
              border-radius: 8px !important;
            }
          }
        }

        .tabFilerIcon {
          font-size: $FONT_18;
          color: $ARROW;
          position: absolute;
          right: 15px;
          top: 15px;
          display: none;
        }

        .main_Heading {
          font-size: $FONT_32;
          font-family: $LATO_BOLD;
          font-weight: 600;
          color: $TXT_NAVY;
          padding-bottom: 14px;
        }

        .inner_Text {
          color: $TXT_LIGHTCOLOR;
          font-size: $FONT_16;
          font-family: $LATO_REGULAR;
          font-weight: 400;
          padding-bottom: 21px;
        }

        .tab_Search {
          align-items: baseline;
          position: relative;
          padding-right: 15px;

          .dropdown_Calendar {
            width: 100%;
            height: 52px;
            display: flex;
            margin-bottom: 28px;

            .dropdown_Option {
              width: 50%;

              .customDropdown {
                .dropdown {
                  .dropdown-menu {
                    transform: translate3d(0, 50px, 0px) !important;
                  }
                }
              }

              .customDropdown .dropdown .btn-primary {
                border: 1px solid $TXT_BLUELIGHT !important;
                padding: 13px 16px !important;
                border-radius: 10px;

                &:after {
                  position: absolute;
                  right: 24px;
                  top: 22px;
                  color: $TXT_DIMBLUE;
                }
              }

              .btn-primary {
                color: $TXT_NAVY !important;
              }
            }

            .calendar {
              display: flex;
              justify-content: flex-end;
              width: 100%;

              .date {
                &:last-child {
                  margin-left: 32px;
                }

                .customCalender {
                  .react-datepicker__tab-loop {
                    .react-datepicker-popper {
                      width: 160%;
                      left: -113px !important;
                    }
                  }

                  .react-datepicker-wrapper {
                    .react-datepicker__input-container {
                      input {
                        padding: 10px 16px 8px;
                      }

                      &:after {
                        top: 0;
                        right: 15px;
                        line-height: 40px;
                      }
                    }
                  }
                }
              }
            }
          }

          .main_ChartSection {
            .chart_Part {
              .chart_Section {
                padding-bottom: 30px;

                &.inline_Chart {
                  display: flex;
                  flex-direction: row;

                  .w30 {
                    width: 30%;
                    padding-right: 15px;
                  }

                  .w60 {
                    width: 40%;
                  }
                }
              }
            }

            .tab-content {
              width: 100%;
              border: none;
            }
          }

          .tab_Listing .listing_Head.userName {
            width: 15% !important;
          }

          .tab_Listing .listing_Head.accType {
            width: 41% !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .statistics_Page {
    .main_Container {
      .statistics_Inner {
        .tabSearch_section {
          .tabFilerIcon {
          }

          .main_Heading {
          }

          .inner_Text {
          }

          .tab_Search {
            .dropdown_Calendar {
              .search_Box {
                width: 33% !important;
              }
            }

            .main_ChartSection {
              .tab_Listing .listing_Head.userName {
                width: 15% !important;
              }

              .tab_Listing .listing_Head.accType {
                width: 33% !important;
              }

              .group_Heading .heading.Description {
                width: 33% !important;
              }

              .tab_Listing .listing_Head.date {
                width: 27% !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .statistics_Page {
    .main_Container {
      .statistics_Inner {
        .tabSearch_section {
          .tabFilerIcon {
          }

          .main_Heading {
          }

          .inner_Text {
          }

          .tab_Search {
            .dropdown_Calendar {
              .search_Box {
                width: 43% !important;
              }
            }

            .main_ChartSection {
              &.main_TabEvent {
                .tab-content {
                }
              }

              .tab_Listing .listing_Head.userName {
                width: 18% !important;
              }

              .group_Heading .heading.userName {
                width: 18% !important;
              }

              .tab_Listing .listing_Head.date {
                width: 13% !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 991.5px) and (max-width: 1024px) {
  .statistics_Page {
    .main_Container {
      .statistics_Inner {
        .tabSearch_section {
          .tab_Search {
            .dropdown_Calendar {
              .search_Box {
                width: 43% !important;
              }
            }

            .main_ChartSection {
              &.main_TabEvent {
                .tab-content {
                }
              }

              .tab_Listing .listing_Head.userName {
                width: 18% !important;
              }

              .group_Heading .heading.userName {
                width: 18% !important;
              }

              .tab_Listing .listing_Head.date {
                width: 13% !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.5px) {
  .statistics_Page {
    .main_Container {
      .statistics_Inner {
        width: 100%;

        .tabSearch_section {
          .tab_Search {
            .dropdown_Calendar {
              .search_Box {
                width: 60% !important;
              }

              .search_Filter {
                right: 12px !important;
                top: -62px !important;

                .filterDropdown {
                  margin-top: 50px !important;
                }

                .filterBtn {
                  left: 172px !important;
                  margin-top: 56px !important;
                }

                .submitBtn {
                  height: 42px !important;
                  margin-top: 4px !important;
                  margin-bottom: 20px !important;
                }
              }
            }

            .main_ChartSection {
              .chart_Part {
                .chart_Section {
                  &.inline_Chart {
                    flex-direction: column;

                    .w30 {
                      width: 100%;
                      padding-right: 0;
                      padding-bottom: 48px;
                    }

                    .w60 {
                      width: 100%;
                      padding-bottom: 48px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .statistics_Page {
    .main_Container {
      .statistics_Inner {
        padding-left: 0;
        width: 100%;

        .tabSearch_section {
          .inner_Text {
            font-size: $FONT_14;
            width: 200px !important;
          }

          .tab_Search {
            .dropdown_Calendar {
              .calendar {
                margin-left: 18px;

                .date {
                  .customCalender {
                    .react-datepicker-wrapper {
                      .react-datepicker__input-container {
                        width: 110%;

                        input {
                          padding: 10px 4px 8px;
                        }
                      }
                    }
                  }
                }
              }

              .dropdown_Option {
                width: 90%;
              }

              .search_Box {
                width: 68% !important;
              }

              .search_Filter {
                right: 8px !important;
                top: -62px !important;

                .filterDropdown {
                  top: 95px !important;
                }

                .filterBtn {
                  top: 52px !important;
                  left: 172px !important;
                }

                .submitBtn {
                  height: 42px !important;
                  margin-top: 0px !important;
                  margin-left: 0px;
                  margin-bottom: 20px !important;
                }
              }
            }

            .main_ChartSection {
              .chart_Part {
                .chart_Section {
                  &.inline_Chart {
                    flex-direction: column;

                    .w30 {
                      width: 100%;
                      padding-right: 0;
                      padding-bottom: 48px;
                    }

                    .w60 {
                      width: 100%;
                      padding-bottom: 48px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .statistics_Page {
    .main_Container {
      .statistics_Inner {
        width: 100%;
        padding-left: 10px;

        .tabSearch_section {
          width: 130% !important;

          .label_Filter {
            justify-content: unset;
          }

          .inner_Text {
            font-size: $FONT_14;
            width: 225px !important;
          }

          .search_Filter {
            .filterBtn {
              .filterIcon {
                margin-left: 0;
              }
            }
          }

          .tab_Search {
            .dropdown_Calendar {
              flex-direction: column;
              margin-bottom: 68px;

              .dropdown_Option {
                width: 100%;

                .customDropdown {
                  margin-bottom: 10px !important;
                }
              }

              .calendar {
                margin-left: 0;
                justify-content: flex-start;

                .date {
                  .customCalender {
                    .react-datepicker-wrapper {
                      .react-datepicker__input-container {
                        width: 110%;

                        input {
                          // padding: 10px 4px 8px;
                        }
                      }
                    }
                  }
                }
              }

              .search_Box {
                width: 87% !important;
              }

              .search_Filter {
                top: -64px !important;

                .filterDropdown {
                  top: 95px !important;
                  left: -85px;
                }

                .filterBtn {
                  top: 52px;
                  left: 172px !important;

                  .filterIcon {
                    width: 40px;
                    height: 40px;

                    i {
                      span {
                        display: none;
                      }
                    }
                  }
                }

                .submitBtn {
                  height: 42px !important;
                  margin-bottom: 4px;
                  margin-right: 8px;
                }
              }
            }

            .main_ChartSection {
              .chart_Part {
                .chart_Section {
                  &.inline_Chart {
                    flex-direction: column;

                    .w30 {
                      width: 100%;
                      padding-right: 0;
                      padding-bottom: 48px;
                    }

                    .w60 {
                      width: 100%;
                      padding-bottom: 48px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .statistics_Page {
    .main_Container {
      .statistics_Inner {
        width: 100%;
        padding-left: 15px;

        .tabSearch_section {
          width: 137% !important;

          .search_Filter {
            flex-direction: column;

            .filterIcon {
              margin-left: 0 !important;
              margin-bottom: 10px;
            }
          }

          .inner_Text {
            font-size: $FONT_14;
            width: 200px !important;
          }

          .tab_Search {
            .dropdown_Calendar {
              flex-direction: column;
              margin-bottom: 68px;

              .dropdown_Option {
                width: 100%;

                .customDropdown {
                  margin-bottom: 10px !important;
                }
              }

              .calendar {
                margin-left: 0;
                justify-content: flex-start;

                .date {
                  .customCalender {
                    .react-datepicker-wrapper {
                      .react-datepicker__input-container {
                        width: 100%;
                      }
                    }

                    &:last-child {
                      .react-datepicker__tab-loop {
                        .react-datepicker-popper {
                          left: unset !important;
                          right: 0px !important;
                        }
                      }
                    }
                  }

                  &.firstCal {
                    .customCalender {
                      .react-datepicker__tab-loop {
                        .react-datepicker-popper {
                          left: 0px !important;
                        }
                      }
                    }
                  }
                }
              }

              .search_Box {
                width: 87% !important;
              }

              .search_Filter {
                top: -64px !important;

                .filterDropdown {
                  top: 95px !important;
                  left: -85px;
                }

                .filterBtn {
                  top: 52px;
                  left: 172px !important;

                  .filterIcon {
                    width: 40px;
                    height: 40px;

                    i {
                      span {
                        display: none;
                      }
                    }
                  }
                }

                .submitBtn {
                  height: 42px !important;
                  margin-bottom: 4px;
                  margin-right: 8px;
                }
              }
            }

            .dropdown_Calendar {
              .search_Box {
                width: 85% !important;
              }

              .search_Filter {
                top: -64px !important;

                .filterDropdown {
                  top: 95px !important;
                  left: -85px;
                }

                .filterBtn {
                  top: 52px;
                  left: 172px !important;

                  .filterIcon {
                    width: 40px;
                    height: 40px;

                    i {
                      span {
                        display: none;
                      }
                    }
                  }
                }

                .submitBtn {
                  height: 42px !important;
                  margin-bottom: 4px;
                  margin-right: 8px;
                }
              }
            }

            .main_ChartSection {
              .chart_Part {
                .chart_Section {
                  &.inline_Chart {
                    flex-direction: column;

                    .w30 {
                      width: 100%;
                      padding-right: 0;
                      padding-bottom: 48px;
                    }

                    .w60 {
                      width: 100%;
                      padding-bottom: 48px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
