@import "../../assests/scss/variables.scss";

.admin_Marketing {
  .main_Container {
    width: calc(100% - 318px);
    float: right;
    background: $BG_WHITE !important;

    .admin_Marketing_Scroll {
      position: unset !important;

      .dashboard_Section {
        // width: calc(100% - 318px);
        // float: right;
        // padding: 16px;
        // background: $BG_WHITE !important;
        padding-left: 96px;

        .content_Header {
          .page_Title {
            font-size: $FONT_32;
            color: $TXT_NAVY;
            padding: 0;
            padding-bottom: 14px;
          }

          .inner_Title {
            color: $LABEL_GREY;
            font-size: $FONT_16;
            padding-bottom: 24px;
          }
        }
      }

      .divider {
        border-bottom: 1px solid $BG_LIGHTBLUE;
      }

      .table_Section {
        .query-Section {
          padding-top: 24px;

          .header {
            background: $BG_LIGHTBLUE;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;

            li {
              color: $TXT_LIGHTBLACK;
            }
          }

          .dataList {
            padding-left: 24px !important;
            border: 1px solid $BG_LIGHTBLUE;
            border-top: none !important;

            li {
              color: $TXT_NAVY;
            }
          }

          ul {
            list-style: none;
            padding-left: 24px !important;
            min-height: 48px;
            align-items: center;

            li {
              font-size: $FONT_16;

              &:nth-child(1) {
                width: 5%;
              }

              &:nth-child(2) {
                width: 10%;
              }

              &:nth-child(3) {
                width: 12%;
              }

              &:nth-child(4) {
                width: 12%;
              }

              &:nth-child(5) {
                width: 22%;
              }

              &:nth-child(6) {
                width: 22%;
              }

              &:nth-child(7) {
                width: 5%;
              }

              &:nth-child(8) {
                width: 10%;
              }
            }
          }
        }
      }
    }
  }

  .paginationClass {
    right: 1% !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .admin_Marketing {
    .main_Container {
      .admin_Marketing_Scroll {
        .dashboard_Section {
          .table_Section {
            .query-Section {
              padding-right: 10px;

              .header {
                display: none !important;
              }

              .dataList {
                flex-wrap: wrap;
                height: auto;
                border-top: 1px solid #d9dbe9 !important;
                margin-bottom: 5px !important;
                padding: 18px 10px !important;

                li {
                  position: relative;
                  padding-top: 10px;

                  &:nth-child(1) {
                    width: 20%;
                    margin-bottom: 10px;

                    &::before {
                      content: "Sl.No.";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_REGULAR;
                    }
                  }

                  &:nth-child(2) {
                    width: 20%;
                    margin-bottom: 10px;

                    &::before {
                      content: "Request Id";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_REGULAR;
                    }
                  }

                  &:nth-child(3) {
                    width: 20%;
                    margin-bottom: 10px;

                    &::before {
                      content: "Requested On";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(4) {
                    width: 40%;
                    margin-bottom: 10px;

                    &::before {
                      content: "Delivered On";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(5) {
                    width: 50%;
                    padding-bottom: 10px !important;

                    &::before {
                      content: "Purpose";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(6) {
                    width: 50%;
                    padding-bottom: 10px !important;

                    &::before {
                      content: "Discription";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(7) {
                    width: 20%;
                    padding-bottom: 10px !important;

                    &::before {
                      content: "Status";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(8) {
                    width: 20%;
                    padding-bottom: 10px !important;

                    &::before {
                      content: "";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .admin_Marketing {
    .main_Container {
      .admin_Marketing_Scroll {
        .dashboard_Section {
          .table_Section {
            .query-Section {
              padding-right: 10px;

              .header {
                display: none !important;
              }

              .dataList {
                flex-wrap: wrap;
                height: auto;
                border-top: 1px solid #d9dbe9 !important;
                margin-bottom: 5px !important;
                padding: 18px 10px !important;

                li {
                  position: relative;
                  padding-top: 10px;

                  &:nth-child(1) {
                    width: 20%;
                    margin-bottom: 10px;

                    &::before {
                      content: "Sl.No.";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_REGULAR;
                    }
                  }

                  &:nth-child(2) {
                    width: 20%;
                    margin-bottom: 10px;

                    &::before {
                      content: "Request Id";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                      font-family: $LATO_REGULAR;
                    }
                  }

                  &:nth-child(3) {
                    width: 20%;
                    margin-bottom: 10px;

                    &::before {
                      content: "Requested On";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(4) {
                    width: 40%;
                    margin-bottom: 10px;

                    &::before {
                      content: "Delivered On";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(5) {
                    width: 50%;
                    padding-bottom: 10px !important;

                    &::before {
                      content: "Purpose";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(6) {
                    width: 50%;
                    padding-bottom: 10px !important;

                    &::before {
                      content: "Discription";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(7) {
                    width: 20%;
                    padding-bottom: 10px !important;

                    &::before {
                      content: "Status";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }

                  &:nth-child(8) {
                    width: 20%;
                    padding-bottom: 10px !important;

                    &::before {
                      content: "";
                      position: absolute;
                      top: -10px;
                      left: 0;
                      color: $TXT_NAVY;
                      font-size: $FONT_16;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
