@import "../../assests//scss/variables.scss";

.button-tooltip > .tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 1px solid #062e56;
  border-radius: 5px 5px 5px 0px;
}

.userHeader {
  padding: 0;
  width: 96px;
  height: 100vh;
  float: left;
  position: relative;
  background: $BG_WHITE;
  z-index: 999 !important;

  .profile_Section {
    padding: 0 16px;
    margin-bottom: 77px;

    .main_Img {
      border-radius: 50%;
      height: 50px;
      width: 50px;
    }

    .border-bottom {
      padding-bottom: 24px;
      border-bottom: 1px solid $TXT_OFFWHITE;
    }
  }

  .navbar {
    padding: 0px;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    &.navbar-expand {
      .navbar-toggler {
        display: none;

        &:focus {
          outline: none;
          color: transparent;
        }
      }

      .navbar-collapse {
        width: 100%;
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;
        background: $BG_NAVY !important;
        padding-top: 24px !important;
        height: 100vh !important;
        -webkit-box-shadow: $BOX_SHADOW;
        -moz-box-shadow: $BOX_SHADOW;
        box-shadow: $BOX_SHADOW;

        .responsive_title {
          display: none;
          font-size: $FONT_16;
          font-family: $LATO_LIGHT;
          color: $TXT_NAVY;
          line-height: 26px;
          position: fixed;
          left: 160px;
          top: 10px;
          z-index: 1;
        }
      }
    }

    .navbar-brand {
      padding: 0px;
      margin: 0;
      padding-right: 55px;

      &:focus {
        outline: none;
      }
    }

    .headerLogo {
      width: 28px;
    }

    .navbar-nav {
      align-items: center;

      .dropdown-menu {
        position: absolute;
      }

      &.menuItem {
        display: flex;
        flex-direction: column;
        width: 100%;

        .nav-link {
          color: $BG_WHITE;
          font-size: $FONT_20 !important;
          font-weight: normal;
          line-height: 24px;
          padding: 11px 0px !important;
          color: $TXT_BLUE;
          position: relative;
          font-size: $FONT_16;
          text-decoration: none;
          margin-bottom: 23px;
          text-align: center;

          &.active {
            width: 100%;
            background: -moz-linear-gradient(
              90deg,
              rgba(86, 150, 250, 0.51) 0%,
              rgba(86, 150, 250, 0) 100%
            ) !important;
            background: -webkit-linear-gradient(
              90deg,
              rgba(86, 150, 250, 0.51) 0%,
              rgba(86, 150, 250, 0) 100%
            ) !important;
            background: linear-gradient(
              90deg,
              rgba(86, 150, 250, 0.51) 0%,
              rgba(86, 150, 250, 0) 100%
            ) !important;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              background: $MENUBORDER;
              width: 3px;
            }
          }
        }
      }

      .nav-item {
        position: relative;
      }
    }
  }

  .sub_Menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 222px;
    background: $BG_WHITE;
    box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
    border-radius: 0px 30px 30px 0px;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    height: 100vh;

    &.show {
      transform: translateX(44%);
      -webkit-transform: translateX(44%);
      -moz-transform: translateX(44%);
      -ms-transform: translateX(44%);
      -o-transform: translateX(44%);
    }

    .inner_SabMenu {
      padding-top: 86px;
      padding-left: 24px;

      .titleText {
        padding-bottom: 36px;
        font-size: $FONT_32;
        color: $TXT_NAVY;
        font-family: $LATO_LIGHT;
        line-height: 48px;
      }

      //added badal for list design--start
      .list-group-item.active {
        z-index: 2;
        color: #fff;
        border: 32px;
        border-left: 2px solid $BG_NAVY;
        color: $TXT_NAVY;
      }

      .list-group-item:first-child {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
      }

      .list-group {
        border-left: 1px solid $TXT_BLUELIGHT;
      }

      .list-group-item {
        cursor: pointer;
        color: $TXT_LIGHTCOLOR;
        position: relative;
        display: block;
        padding: 0.75rem 1.25rem;
        background-color: #fff;
        font-family: $LATO_BOLD;
        font-weight: 600;
        font-size: $FONT_16;
        // margin-bottom: 24px;
        border: none;
      }

      //--end
      .subMenu_Ul {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: left;
        border-left: 1px solid $TXT_BLUELIGHT;

        .subMenu_Li {
          padding: 0;
          padding: 12px 16px;
          font-family: $LATO_BOLD;
          font-weight: 600;
          font-size: $FONT_16;
          margin-bottom: 24px;
          cursor: pointer;
          color: $TXT_LIGHTCOLOR;

          &.active {
            border-left: 1px solid $BG_NAVY;
            color: $TXT_NAVY;
          }
        }
      }
    }
  }

  .colorIcon {
    button {
      // color: red;
    }

    .icon-logout {
      // color: red;
    }

    .nav-link {
      color: $BG_WHITE;
      font-size: $FONT_20 !important;
      font-weight: normal;
      line-height: 24px;
      padding: 11px 0px !important;
      color: $TXT_BLUE;
      position: relative;
      font-size: $FONT_16;
      text-decoration: none;
      margin-bottom: 23px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1920px !important;
    width: 100% !important;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .userHeader {
    .container {
      max-width: 1920px !important;

      .navbar {
        .navbar-brand {
          padding-right: 40px;
        }

        .navbar-nav {
          &.menuItem {
            .nav-link {
              padding: 18px 15px !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 575px) and (max-width: 991px) {
  .userHeader {
  }
}

@media only screen and (min-width: 300px) and (max-width: 574px) {
  .userHeader {
    padding-right: 0px !important;
    padding-left: 0px !important;
    position: absolute;
    background: $BG_WHITE;
    width: 0;

    .container {
      max-width: 1920px !important;

      .navbar {
        padding: 0;
        display: block;

        &.navbar-expand {
          .navbar-toggler {
            display: flex;
            float: left;
            padding: 0px;
            border: none;
            outline: none;
            margin-right: 31px;
            width: 33px;
            height: 33px;
            justify-content: center;
            align-items: center;

            &.collapsed {
              margin-right: 0;

              &::before {
                position: relative;
                content: "\e922";
                font-family: "icomoon" !important;
                color: $TXT_BLUE;
                font-size: $FONT_16;
                top: 5px;
                left: 20px;
                z-index: 1;
              }
            }

            &::before {
              position: relative;
              content: "\00d7";
              font-family: "icomoon" !important;
              color: $TXT_BLUE;
              font-size: $FONT_32;
              top: 5px;
              left: 100px;
              z-index: 1;
            }

            .navbar-toggler-icon {
              display: none;
            }
          }

          .responsive_title {
            display: block;
          }
        }

        .navbar-brand {
          float: left;
        }

        .navbar-collapse {
          position: absolute;
          top: 0;
          height: calc(100vh - 58px);
          height: -webkit-calc(100vh - 58px);
          height: -ms-calc(100vh - 58px);
          height: -moz-calc(100vh - 58px);
          height: -o-calc(100vh - 58px);
          background: $BG_WHITE;
          left: 0;
          z-index: 1;
          padding: 0;
          width: 96px;
          transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          -moz-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          -o-transform: translateX(-100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          -webkit-box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.08);
          -moz-box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.08);
          -ms-box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.08);
          -o-box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.08);
          display: flex !important;

          &.collapsing {
            height: 100vh !important;
          }

          &.show {
            transform: translateX(0%);
            -webkit-transform: translateX(0%);
            -moz-transform: translateX(0%);
            -ms-transform: translateX(0%);
            -o-transform: translateX(0%);
            transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
          }

          .navbar-nav {
            flex-direction: column !important;
            align-items: flex-start;

            .nav-link {
              width: 100%;
              text-align: center;
              font-size: 24px !important;

              &:nth-child(1) {
                padding-top: 0;
              }
            }
          }
        }
      }
    }
  }

  .sub_Menu {
    left: -100px !important;
  }
}
