@import "../../assests/scss/variables.scss";

.blog_Page {
  .headerLogin {
    .menuItem {
      .nav-link {
        &:nth-child(1) {
          display: none !important;
        }
      }

      .button_Link {
        a.nav-link {
          &:nth-child(1) {
            display: block !important;
          }
        }
      }
    }
  }

  .blogScroll {
    height: calc(100vh - 150px) !important;
    height: 100vh !important;
    position: relative;

    .main_Banner {
      height: 600px;
      overflow: hidden;
      display: flex;

      .banner_Img {
        display: block;
      }
    }

    .banner_Title {
      position: absolute;
      top: 130px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $FONT_48;
      color: $TXT_WHITE;
      padding: 0px 638px;
      text-align: center;
      line-height: 64px;

      &:before {
        content: "";
        background: $TXT_ORANGE;
        height: 4px;
        position: absolute;
        bottom: -15px;
        width: 50px;
      }
    }

    .center_Section {
      margin-bottom: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 11;

      .inner_Section {
        margin-top: -174px;
        max-width: 938px;
        width: 100%;
        background: $BG_LIGHT;
        padding: 80px 56px;
        border-radius: 8px;
        -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);

        .inner_Text {
          font-family: $LATO_REGULAR;
          font-size: $FONT_24;
          margin: 0;
          text-align: left;
          color: $TXT_DARKBLACK;
          font-weight: 600;
          line-height: 36px;
          padding-bottom: 32px;
          letter-spacing: 1px;

          &:last-child {
            padding-bottom: 0;
          }
        }

        p::first-letter {
          text-transform: uppercase;
          font-size: $FONT_48;
          font-family: $LATO_BOLD;
        }

        .inner_Img {
          img {
            width: 100%;
            padding-bottom: 34px;
          }
        }
      }
    }
    .blog-feature {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
    }

    .image_Section {
      padding: 0 40px;
      padding-bottom: 200px !important;

      .titleText {
        display: flex;
        justify-content: center;
        font-size: $FONT_48;
        font-family: $LATO_BOLD;
        color: $TXT_NAVY;
        padding-bottom: 80px;
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .blog_Page {
    .blogScroll {
      .banner_Title {
        padding: 0px 440px;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .blog_Page {
    .blogScroll {
      .banner_Title {
        padding: 0px 295px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .blog_Page {
    .blogScroll {
      .banner_Title {
        padding: 0px 230px;
      }

      .center_Section {
        .inner_Section {
          max-width: 800px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog_Page {
    .blogScroll {
      .banner_Title {
        padding: 0px 110px;
      }

      .center_Section {
        .inner_Section {
          max-width: 700px;
        }
      }

      .image_Section {
        .slider_Section {
          .slick-prev {
            left: 16px;
          }

          .slick-next {
            right: 18px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog_Page {
    .blogScroll {
      .banner_Title {
        padding: 0px 103px;
        font-size: $FONT_36;
      }

      .center_Section {
        .inner_Section {
          max-width: 500px;

          .inner_Text {
            font-size: $FONT_22;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .blog_Page {
    .blogScroll {
      .banner_Title {
        padding: 0px 103px;
        font-size: $FONT_36;
      }

      .center_Section {
        .inner_Section {
          max-width: 400px;

          .inner_Text {
            font-size: $FONT_22;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .blog_Page {
    .blogScroll {
      .banner_Title {
        padding: 0;
        font-size: $FONT_30;
        top: 140px;
        width: 95%;
        line-height: 45px;
      }

      .center_Section {
        margin-bottom: 55px;

        .inner_Section {
          max-width: 370px;
          margin-top: -174px;
          padding: 40px 24px;

          .inner_Text {
            font-size: $FONT_18;
          }
        }
      }

      .image_Section {
        padding: 0 24px;

        .titleText {
          font-size: 35px;
        }

        .slider_Section {
          .slick-prev {
            width: 30px;
            height: 30px;
          }

          .slick-next {
            width: 30px;
            height: 30px;
            right: -12px;
          }
        }
      }
    }
  }

  .image_Section {
    padding: 0 24px;

    .slider_Section {
      .slick-prev {
        width: 30px;
        height: 30px;
      }

      .slick-next {
        width: 30px;
        height: 30px;
        right: -8px;
      }
    }
  }
}
