@import "../../../../assests/scss/variables.scss";
.whiteContainer {
  padding: 25px 220px 248px 220px;
  .logoSection {
    display: flex;
    justify-content: center;

    .inner_Div {
      border-radius: 50%;
      position: relative;

      .Edit {
        background: white;
        position: absolute;
        right: 20px;
        cursor: pointer;
        top: 18px;
        border-radius: 50%;
        height: 25px;
        width: 25px;
      }

      .icon-edit1:before {
        padding-top: 8px;
        padding-left: 8px;
      }

      .middle_Img {
        margin: auto;
        display: block;
        border-radius: 50%;
        height: 150px;
        width: 150px;
        cursor: pointer;
      }
    }
    .changeLogoContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 14px;
      .changeLogo {
        font-size: $FONT_14;
        font-weight: 600;
        line-height: 14px;
        font-family: $LATO_SEMIBOLD;
        color: $TXT_NAVY;
        cursor: pointer;
      }
    }
  }
  .CredentialLink {
    display: flex;
    align-items: baseline;
    .adminText {
      font-size: $FONT_24;
      font-weight: 400;
      line-height: 36px;
      font-family: $LATO_REGULAR;
      color: $TXT_NAVY;
    }
    .clickHere {
      font-size: $FONT_16;
      font-weight: 400;
      line-height: 36px;
      font-family: $LATO_SEMIBOLD;
    }
  }
  .dropdown_inner6 {
    width: 100%;

    .customDropdown .dropdown .btn-primary {
      border: 1px solid $TXT_NAVY !important;
      padding: 24px 16px !important;
      border-radius: 10px;
      &:after {
        position: absolute;
        right: 24px;
        top: 34px;
        color: $TXT_BLUE;
      }
    }
    .customDropdown .dropdown.selected .btn-primary {
      color: $TXT_NAVY !important;
    }

    .btn-primary {
      color: $TXT_NAVY !important;
    }
  }
  .button_line {
    .whiteButton {
      background-color: $BG_WHITE;
      color: $SOLID_BLACK;
      width: 100%;
      padding-top: 24px;
      padding-bottom: 24px;
      border-radius: 15px;
      border-color: $TXT_BLACK;
    }
    .saveButton {
      background-color: $BG_NAVY;
      color: $TXT_WHITE;
      width: 100%;
      padding-top: 24px;
      padding-bottom: 24px;
      border-radius: 15px;
    }
  }
  .form {
    display: flex;
    flex-direction: column;

    .iconCss {
      font-size: $FONT_18;
    }

    .first_line {
      display: flex;

      .first_name {
        width: 100%;
        margin-right: 15px;
        .textField {
          .inputBox {
            .form-control {
              border-radius: 10px;
            }
          }
        }
      }

      .last_name {
        width: 100%;
        .textField {
          .inputBox {
            .form-control {
              border-radius: 10px;
            }
          }
        }
      }
    }

    .second_line {
      display: flex;

      .email {
        width: 100%;
        margin-right: 15px;

        .textField {
          .inputBox {
            .form-control {
              color: $TXT_LIGHT !important;
              border-radius: 10px;
            }
          }
        }
      }

      .textField {
        margin-right: 6px;
      }
    }

    .button_line {
      display: flex;
      margin-top: 24px;

      .first_button {
        margin-right: 15px;
        width: 100%;

        .submitBtn {
          background: $TXT_OFFWHITE !important;
          width: 100%;
          border: 1px solid $TXT_NAVY !important;
          height: 72px;
          font-size: 16px;
          font-family: "latobold";
          border-radius: 10px;
          cursor: pointer;
          color: $TXT_NAVY;
        }
      }

      .second_button {
        width: 100%;
      }
    }
  }
}
@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .whiteContainer {
    padding: 10px;
    .CredentialLink {
      .adminText {
        font-size: $FONT_20;
        font-weight: 400;
        line-height: 20px;
      }
      .clickHere {
        font-size: $FONT_16;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
}
