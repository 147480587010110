@import "../../assests/scss/variables.scss";

.calendar_Page {
  width: 100%;
  // display: table;
  display: inline;
  position: relative;
  background: $BG_LIGHT;
  overflow: hidden;

  .main_Container {
    width: calc(100% - 96px);
    float: left;

    .calendar_Inner {
      // width: calc(100% - 225px);
      width: 100%;
      float: right;
      padding-left: 32px;

      .title_Height {
        padding-top: 40px;

        .tabFilerIcon {
          font-size: $FONT_18;
          color: $ARROW;
          position: absolute;
          right: 15px;
          top: 45px;
          display: none;
        }

        .main_Heading {
          font-size: $FONT_32;
          font-family: $LATO_BOLD;
          font-weight: 600;
          color: $TXT_NAVY;
          padding-bottom: 14px;
        }

        .inner_Text {
          color: $TXT_LIGHTCOLOR;
          font-size: $FONT_16;
          font-family: $LATO_REGULAR;
          font-weight: 400;
          padding-bottom: 32px;
        }
      }

      .listing_Section {
        width: 75%;
        float: left;
        position: relative;
        height: 100vh;
        overflow-y: scroll;

        .listing {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          position: relative;
          padding-right: 15px;
          padding-bottom: 40px;

          .left_Section {
            width: 100%;
            height: 46px;
            display: flex;

            .data_Listing {
              display: table;
              width: 100%;

              .scroll_Listing {
                .tab_Heading {
                  margin-top: 22px;

                  .heading {
                    font-size: 16px;
                    color: $TXT_LIGHTBLACK;
                    font-family: $LATO_REGULAR;

                    &.checkBox {
                      display: none;
                    }
                  }
                }
              }
            }

            // .top-section-text-tab {
            //   display: flex;
            //   justify-content: end;
            // }
          }

          .search_Filter {
            display: flex;
            position: absolute;
            right: 40px;
            top: -14px;
            align-items: center;

            .filterBtn {
              position: relative;

              .filterIcon {
                position: relative;
                margin-left: 15px;
                background: $BG_WHITE;
                width: 117px;
                height: 40px;
                border-radius: 8px;
                border: none;
                display: flex;
                align-items: center;
                border: 1px solid $TXT_LIGHTBLACK;
                justify-content: center;

                &:focus {
                  outline: none;
                }

                i {
                  color: $TXT_LIGHTBLACK;
                  font-size: $FONT_16;

                  span {
                    font-size: $FONT_16;
                    font-family: $LATO_REGULAR;
                  }
                }
              }
            }
          }
        }

        .calenderChat {
          display: none;
        }
      }

      .calendar_Right {
        float: left;
        width: 25%;
        background: $BG_OFFLIGHT;
        padding-top: 40px;
        height: 100vh;
        // max-width: 476px;
        padding: 40px 40px 0 40px;

        .main_Title {
          font-size: 24px;
          color: $TXT_NAVY;
          line-height: 36px;
          padding-bottom: 16px;
          padding-left: 15px;
        }

        .onClose {
          // font-size: $FONT_14;
          // position: absolute;
          // right: 30px;
          // top: 50px;
          // cursor: pointer;
          display: none;
        }
      }
    }
    .acccordionForListing {
      width: 100%;
      .accordion-button{
        width: 100%;
      }
      .headerAccordion {
         border-bottom: 2px solid #D9DBE9;
        button{
          border: none;
        }
        width: 100%;
        font-size: 20px;
        background-color: #EFF0F6;
        // border: 2px solid #D9DBE9;
        padding:5px;
        .expandBlock {
          display: flex;
          justify-content: space-between;
          width: 100%;
          .category{
        width: 8%;
          }
          .date{
            width: 30%;
          }
          .deleted{
            width: 15%;
          }
          .expand{
            width: 47%;
          }
        }
      }
      .bodyAccordion{
       .postDetailBlock{
        display: flex;
          justify-content: space-between;
          background-color: #EFF0F6;
          border: 1px solid #D9DBE9;
          padding:5px;
          .category{
            width: 8%;
            text-align: center;
           
              }
              .date{
                width: 30%;
                text-align: center;
              }
              .deleted{
                width: 15%;
               
                text-align: center;
              }
              .expand{
                width: 47%;
                text-align: center;
                text-decoration: underline;
                cursor: pointer;
              }
       }
      }
    }
  }

  .top-section-text-tab {
    display: flex;
    justify-content: space-between;
    width: 100%;

    // border-bottom: 1px solid $TXT_LIGHTCOLOR;
    // border-spacing: 5px;
    .list-calendar-tab {
      .btn-list {
        color: $TXT_NAVY !important;
        background-color: #b4d7fe;
        width: 110px;
        border-color: transparent !important;
      }

      .btn-list-active {
        background-color: $TXT_NAVY;
        color: white;
        width: 110px;
        border-color: transparent !important;
        border-radius: 0px !important;
      }
    }

    .date {
      font-size: $FONT_24;
      color: $BG_NAVY;
      padding-bottom: 8px;
    }
  }
  .parentBox {
    // background-color: red;
    display: flex;
    justify-content: space-around;
    .dropdown_Section {
      // padding-top: 24px;
      // background-color: green;
      margin-right: 2rem;

      .filter_Title {
        font-size: $FONT_16;
        font-weight: 500;
        color: $TXT_LIGHTBLACK;
        padding-bottom: 16px;
        // display: flex;
        // justify-content: flex-end;
        // margin-right: 6em;
      }

      .customDropdown {
        .dropdown {
          button {
            padding: 16px 24px !important;
            border: 1px solid !important;
            border-radius: 10px;
            position: relative;
            background-color: $TXT_OFFWHITE !important;
            font-size: $FONT_16 !important;
            color: $TXT_LIGHT !important;
            width: 200px;

            &:after {
              position: absolute;
              right: 24px;
              top: 22px;
            }
          }

          .dropdown-menu {
            width: 200px;
          }
        }
      }
    }
  }

  .big-calendar {
    margin: 32px 32px 90px 0px;
    height: 100vh;
  }

  .border-class {
    border: 1px solid lightgrey;
    margin-top: 16px;
  }

  .chatboat-section {
    bottom: 10px;
    right: 25px;
    width: 66px;
    height: 66px;
    background: $BG_NAVY;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
      color: $BG_WHITE;
    }

    &.calenderChat {
      bottom: 100px;
      z-index: 1;
      display: flex;
    }
  }
}
@media only screen and (min-width: 1921px) {
  .calendar_Page {
    .main_Container {
      .calendar_Inner {
        .calendar_Right {
          display: flex;
          justify-content: center;
          .content_Btn {
            width: 380px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .calendar_Page {
    .main_Container {
      .calendar_Inner {
        .listing_Section {
          // .tab_Search {
          //     .search_Filter {
          //         bottom: -56px;
          //         left: 0;
          //         top: unset;
          //         width: 100%;

          //         .filterBtn {
          //             position: absolute;
          //             right: 25px
          //         }

          //     }
          // }
        }

        .calendar_Right {
          padding: 40px 10px 0 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .calendar_Page {
    .main_Container {
      .calendar_Inner {
        .listing_Section {
          width: 65%;
          // .tab_Search {
          //     .search_Filter {
          //         bottom: -56px;
          //         left: 0;
          //         top: unset;
          //         width: 100%;

          //         .filterBtn {
          //             position: absolute;
          //             right: 25px
          //         }
          //     }
          // }
        }

        .calendar_Right {
          padding: 40px 8px 0 8px;
          width: 35%;
        }
      }
    }
  }
}

@media only screen and (min-width: 991.5px) and (max-width: 1024px) {
  .calendar_Page {
    .main_Container {
      .calendar_Inner {
        .tabFilerIcon {
          display: block !important;
        }

        .listing_Section {
          width: 100%;

          .tabFilerIcon {
            font-size: $FONT_18;
            color: $ARROW;
            position: absolute;
            right: 15px;
            top: 45px;
            display: none;
          }

          .tab_Search {
            .search_Filter {
              bottom: -56px;
              left: 0;
              top: unset;
              width: 100%;

              .filterBtn {
                position: absolute;
                right: 25px;
              }
            }
          }
        }

        .calendar_Right {
          display: none;

          &.showTab {
            display: block;
          }
        }

        .calendar_Right {
          top: 0;
          right: 0;
          z-index: 1;
          width: 475px;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 30px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          // height: 100vh;
          position: absolute;
          float: none;
          border-radius: 0 !important;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }

          .onClose {
            display: block;
            float: right;
            position: relative;
            bottom: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.5px) {
  .calendar_Page {
    .main_Container {
      .calendar_Inner {
        width: 100%;

        .tabFilerIcon {
          display: block !important;
        }

        .listing_Section {
          width: 100%;

          // .tab_Search {
          //     .search_Filter {
          //         bottom: -56px;
          //         left: 0;
          //         top: unset;
          //         width: 100%;

          //         .filterBtn {
          //             position: absolute;
          //             right: 25px;

          //             .filterIcon {
          //                 width: 40px;
          //                 height: 40px;
          //                 padding: 0 4px 0 9px;

          //                 i {
          //                     span {
          //                         font-size: 0;
          //                     }
          //                 }

          //             }
          //         }
          //     }

          // }
        }

        .calendar_Right {
          display: none;

          &.showTab {
            display: block;
          }
        }

        .calendar_Right {
          top: 0;
          right: 0;
          z-index: 1;
          width: 400px;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 30px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          // height: 100vh;
          position: absolute;
          float: none;
          border-radius: 0 !important;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }

          .onClose {
            display: block;
            float: right;
            position: relative;
            bottom: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 575.5px) and (max-width: 767px) {
  .calendar_Page {
    .main_Container {
      .calendar_Inner {
        width: 100%;

        .tabFilerIcon {
          display: block !important;
        }

        .listing_Section {
          width: 100%;

          // .tab_Search {
          //     .search_Filter {
          //         bottom: -56px;
          //         left: 0;
          //         top: unset;
          //         width: 100%;

          //         .filterBtn {
          //             position: absolute;
          //             right: 35px;

          //             .filterIcon {
          //                 width: 40px;
          //                 height: 40px;
          //                 padding: 0 4px 0 9px;

          //                 i {
          //                     span {
          //                         font-size: 0;
          //                     }
          //                 }

          //             }
          //         }
          //     }
          // }
        }

        .calendar_Right {
          display: none;

          &.showTab {
            display: block;
          }
        }

        .calendar_Right {
          top: 0;
          right: 0;
          z-index: 1;
          width: 475px;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 30px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          position: absolute;
          float: none;
          border-radius: 0 !important;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }

          .onClose {
            display: block;
            float: right;
            position: relative;
            bottom: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 475.5px) and (max-width: 575.5px) {
  .calendar_Page {
    .main_Container {
      width: 100%;

      .calendar_Inner {
        width: 100%;
        padding-left: 24px;

        .tabFilerIcon {
          display: block !important;
        }

        .listing_Section {
          width: 100%;

          // .tab_Search {
          //     .search_Filter {
          //         bottom: -56px;
          //         left: 0;
          //         top: unset;
          //         width: 100%;

          //         .filterBtn {
          //             position: absolute;
          //             right: 10px;

          //             .filterIcon {
          //                 width: 40px;
          //                 height: 40px;
          //                 padding: 0 4px 0 9px;

          //                 i {
          //                     span {
          //                         font-size: 0;
          //                     }
          //                 }

          //             }
          //         }
          //     }
          // }
        }

        .calendar_Right {
          display: none;

          &.showTab {
            display: block;
          }
        }

        .calendar_Right {
          top: 0;
          right: 0;
          z-index: 1;
          width: 400px;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 30px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          // height: 100vh;
          position: absolute;
          float: none;
          border-radius: 0 !important;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }

          .onClose {
            display: block;
            float: right;
            position: relative;
            bottom: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475.5px) {
  .calendar_Page {
    .big-calendar {
      margin: 20px 8px 0px 0px !important;

      .rbc-btn-group {
        padding-bottom: 15px;
      }

      .rbc-toolbar .rbc-toolbar-label {
        text-align: left;
        padding: 0;
        padding-bottom: 10px;
      }

      .rbc-toolbar {
        justify-content: left;
      }
    }

    .main_Container {
      width: 100%;

      .calendar_Inner {
        width: 100%;
        padding-left: 12px;

        .tabFilerIcon {
          display: block !important;
        }

        .listing_Section {
          width: 100%;
          height: 100vh;

          // .tab_Search {
          //     .search_Filter {
          //         bottom: -56px;
          //         left: 0;
          //         top: unset;
          //         width: 100%;

          //         .search_Box {
          //             width: 260px;

          //             .search_Txt {
          //                 width: 100%;
          //             }
          //         }

          //         .filterBtn {
          //             position: absolute;
          //             right: 10px;

          //             .filterIcon {
          //                 width: 40px;
          //                 height: 40px;
          //                 padding: 0 4px 0 9px;

          //                 i {
          //                     span {
          //                         font-size: 0;
          //                     }
          //                 }

          //             }
          //         }
          //     }
          // }
        }

        .calendar_Right {
          display: none;

          &.showTab {
            display: block;
          }
        }

        .calendar_Right {
          top: 0;
          right: 0;
          z-index: 1;
          width: 365px;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 30px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          // height: 100vh;
          position: absolute;
          float: none;
          border-radius: 0 !important;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }

          .onClose {
            display: block;
            float: right;
            position: relative;
            bottom: 16px;
          }
        }
      }
    }
  }
}
