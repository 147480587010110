@import "../../assests/scss/variables.scss";

.socialprolist_Page {
  width: 100%;
  display: table;
  position: relative;
  background: $BG_LIGHT;
  overflow: hidden;
  height: 100vh;

  .chatboat-section {
    bottom: 10px;
    right: 25px;
    width: 66px;
    height: 66px;
    background: $BG_NAVY;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
      color: $BG_WHITE;
    }
  }

  .search_Box {
    width: 360px;

    input {
      width: 100%;
    }
  }

  .main_Container {
    width: calc(100% - 96px);
    float: left;

    .socialprolist_Inner {
      // width: calc(100% - 225px);
      width: 100%;
      float: right;
      padding-left: 32px;

      .tabSearch_section {
        width: 70%;
        float: left;
        padding-top: 40px;
        position: relative;
        overflow-y: scroll;
        height: 100vh;

        .main_Heading {
          font-size: $FONT_32;
          font-family: $LATO_BOLD;
          font-weight: 600;
          color: $TXT_NAVY;
          padding-bottom: 14px;
        }

        .profileList_container {
          margin-top: 38px;

          .profile_Listing {
            margin-right: 15px;
          }
        }

        .search_Filter {
          display: flex;
          justify-content: space-between;
          margin-top: 50px;
          margin-right: 15px;

          .filterBtn {
            position: relative;

            .filterIcon {
              position: relative;
              margin-left: 15px;
              background: $BG_WHITE;
              width: 117px;
              height: 40px;
              border-radius: 8px;
              border: none;
              display: flex;
              align-items: center;
              border: 1px solid $TXT_LIGHTBLACK;
              justify-content: center;

              &:focus {
                outline: none;
              }

              i {
                color: $TXT_LIGHTBLACK;
                font-size: $FONT_16;

                span {
                  font-size: $FONT_16;
                  font-family: $LATO_REGULAR;
                }
              }
            }
          }
        }

        .tabProfileIcon {
          font-size: $FONT_18;
          color: $ARROW;
          position: absolute;
          right: 15px;
          top: 15px;
          display: none;
        }
      }

      .social_Section {
        float: right;
        width: 30%;
        background: $BG_OFFLIGHT;
        padding-top: 40px;
        height: 100vh;
        // max-width: 476px;
        padding: 40px 40px 0 40px;

        .icon-close {
          float: right;
          display: none;
        }

        .main_Title {
          font-size: 24px;
          color: $TXT_NAVY;
          line-height: 36px;
          padding-bottom: 16px;
          // padding-left: 1px;
          margin-top: 54px;
        }

        .add_profile_link {
          .line {
            display: block;
            border: 1px solid $TXT_BLUELIGHT;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .socialprolist_Page {
    .main_Container {
      .socialprolist_Inner {
        .tabSearch_section {
          .search_Filter {
            bottom: -56px;
            left: 0;
            top: unset;
            width: 100%;

            .filterBtn {
              position: absolute;
              right: 10px;
            }
          }
        }

        .social_Section {
          padding: 40px 15px 0 15px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .socialprolist_Page {
    .main_Container {
      .socialprolist_Inner {
        .tabSearch_section {
          .search_Filter {
            bottom: -56px;
            left: 0;
            top: unset;
            width: 100%;

            .filterBtn {
              position: absolute;
              right: 10px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .socialprolist_Page {
    .main_Container {
      .socialprolist_Inner {
        width: 100%;

        .tabSearch_section {
          width: 100%;

          .tabProfileIcon {
            display: block;
          }

          .search_Filter {
            bottom: -56px;
            left: 0;
            top: unset;
            width: 100%;

            .filterBtn {
              position: absolute;
              right: 10px;

              .filterIcon {
                width: 40px;
                height: 40px;
                padding: 0 4px 0 9px;

                i {
                  span {
                    font-size: 0;
                  }
                }
              }
            }
          }
        }

        .social_Section {
          top: 0;
          right: 0;
          z-index: 1;
          width: 475px;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 0px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          // height: 100vh;
          position: absolute;
          float: none;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }

          .icon-close {
            display: block;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .socialprolist_Page {
    .main_Container {
      .socialprolist_Inner {
        width: 100%;

        .tabSearch_section {
          width: 100%;

          .tabProfileIcon {
            display: block;
            right: 20px;
          }

          .search_Filter {
            bottom: -56px;
            left: 0;
            top: unset;
            width: 100%;
            margin-right: 0px;

            .filterBtn {
              position: absolute;
              right: 20px;

              .filterIcon {
                width: 40px;
                height: 40px;
                padding: 0 4px 0 9px;

                i {
                  span {
                    font-size: 0;
                  }
                }
              }
            }
          }
        }

        .social_Section {
          top: 0;
          right: 0;
          z-index: 1;
          width: 475px;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 0px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          // height: 100vh;
          position: absolute;
          float: none;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }

          .icon-close {
            display: block;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 575.5px) and (max-width: 767.5px) {
  .socialprolist_Page {
    .main_Container {
      .socialprolist_Inner {
        width: 100%;

        .tabSearch_section {
          width: 100%;

          .tabProfileIcon {
            display: block;
          }

          .search_Filter {
            bottom: -56px;
            left: 0;
            top: unset;
            width: 100%;

            .filterBtn {
              position: absolute;
              right: 10px;

              .filterIcon {
                width: 40px;
                height: 40px;
                padding: 0 4px 0 9px;

                i {
                  span {
                    font-size: 0;
                  }
                }
              }
            }
          }
        }

        .social_Section {
          top: 0;
          right: 0;
          z-index: 1;
          width: 475px;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 0px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          // height: 100vh;
          position: absolute;
          float: none;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }

          .icon-close {
            display: block;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 475.5px) and (max-width: 575.5px) {
  .socialprolist_Page {
    .main_Container {
      width: 100%;

      .socialprolist_Inner {
        width: 100%;
        padding-left: 24px;

        .tabSearch_section {
          width: 100%;

          .tabProfileIcon {
            display: block;
          }

          .search_Filter {
            bottom: -56px;
            left: 0;
            top: unset;
            width: 100%;

            .filterBtn {
              position: absolute;
              right: 10px;

              .filterIcon {
                width: 40px;
                height: 40px;
                padding: 0 4px 0 9px;

                i {
                  span {
                    font-size: 0;
                  }
                }
              }
            }
          }
        }

        .social_Section {
          top: 0;
          right: 0;
          z-index: 1;
          width: 475px;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 0px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          // height: 100vh;
          position: absolute;
          float: none;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }

          .icon-close {
            display: block;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475.5px) {
  .socialprolist_Page {
    .main_Container {
      width: 100%;

      .socialprolist_Inner {
        width: 100%;
        padding-left: 24px;

        .tabSearch_section {
          width: 100%;
          height: 100vh;

          .tabProfileIcon {
            display: block;
            right: 30px;
          }

          .search_Filter {
            bottom: -56px;
            left: 0;
            top: unset;
            width: 100%;
            margin: 0;

            .search_Box {
              width: 260px;

              input {
                width: 100%;
              }
            }

            .filterBtn {
              position: absolute;
              right: 28px;

              .filterIcon {
                width: 40px;
                height: 40px;
                padding: 0 4px 0 9px;

                i {
                  span {
                    font-size: 0;
                  }
                }
              }
            }
          }

          .profileList_container {
            margin-top: 24px;
          }
        }

        .social_Section {
          top: 0;
          right: 0;
          z-index: 1;
          width: 320px;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 0px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          // height: 100vh;
          position: absolute;
          float: none;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }

          .icon-close {
            display: block;
          }
        }
      }
    }
  }
}
