@import "../../assests/scss/variables.scss";

.dashboard_Page {
  width: 100%;
  display: table;
  // height: 100vh;
  // overflow: hidden;
  position: relative;

  .main_Container {
    width: calc(100% - 96px);
    float: left;

    .chatboat-section {
      bottom: 10px;
      right: 25px;
      width: 66px;
      height: 66px;
      background: $BG_NAVY;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      i {
        color: $BG_WHITE;
      }
    }

    .dashboardScroll {
      height: 100vh !important;

      .get_Started {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .image_Section {
          width: 613px;
          height: 512px;
          margin-bottom: 32px;

          img {
            max-width: 100%;
            height: auto;
          }
        }

        .main_title {
          font-size: $FONT_48;
          font-family: $LATO_BOLD;
          color: $TXT_ORANGE;
          line-height: 72px;
          font-weight: 400;
          padding-bottom: 40px;
          text-align: center;
        }

        .inner_Txt {
          width: 682px;
          font-size: $FONT_16;
          font-weight: 500;
          color: $TXT_LIGHT;
          text-align: center;
          line-height: 24px;
          padding-bottom: 40px;
        }

        .started_Btn {
          width: 236px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .dashboard_Page {
    .main_Container {
      .dashboardScroll {
        .get_Started {
          .image_Section {
            width: 487px;
            height: 406px;
          }

          .main_title {
            padding-bottom: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .dashboard_Page {
    .main_Container {
      .dashboardScroll {
        .get_Started {
          .image_Section {
            width: 487px;
            height: 406px;
          }

          .main_title {
            padding-bottom: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .dashboard_Page {
    .main_Container {
      .dashboardScroll {
        .get_Started {
          .image_Section {
            width: 487px;
            height: 406px;
          }

          .main_title {
            padding-bottom: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dashboard_Page {
    .main_Container {
      .dashboardScroll {
        .get_Started {
          .image_Section {
            width: 487px;
            height: 406px;
          }

          .main_title {
            padding-bottom: 30px;
            font-size: $FONT_24;
          }

          .inner_Txt {
            width: 487px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .dashboard_Page {
    .main_Container {
      .dashboardScroll {
        .get_Started {
          .image_Section {
            width: 261px;
            height: 218px;
          }

          .main_title {
            padding-bottom: 25px;
            font-size: $FONT_24;
          }

          .inner_Txt {
            width: 487px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .dashboard_Page {
    .headerAdmin {
      background: $BG_WHITE;
    }

    .main_Container {
      width: 100%;

      .dashboardScroll {
        .get_Started {
          padding: 0;

          .image_Section {
            width: 261px;
            height: 218px;
          }

          .main_title {
            padding-bottom: 25px;
            font-size: $FONT_24;
            line-height: 30px;
          }

          .inner_Txt {
            width: 400px;
          }
        }
      }

      .social_profile_Scroll > div {
        position: unset !important;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .dashboard_Page {
    .main_Container {
      width: calc(100% - 76px);
      width: 100%;
      padding: 0 15px;

      .dashboardScroll {
        .get_Started {
          .image_Section {
            width: 225px;
            height: 218px;
          }

          .main_title {
            padding-bottom: 25px;
            font-size: $FONT_24;
            line-height: 30px;
          }

          .inner_Txt {
            width: 280px;
          }
        }
      }

      .social_profile_Scroll > div {
        position: unset !important;
      }
    }
  }
}
