@import "../../assests/scss/variables.scss";

#fantasyTable {
  width: 100%;
  border: 1px solid $TXT_BLUELIGHT;
  border-radius: 6px;

  thead {
    // background: $BG_LIGHTBLUE;
    // border-radius: 6px;
    // border-bottom-left-radius: 0;
    // border-bottom-right-radius: 0;

    th {
      background: $BG_LIGHTBLUE;
      color: $TXT_LIGHTBLACK;
      font-size: $FONT_16;
      line-height: 24px;
      padding: 10px 18px;
    }

    // .heading {
    // 	color: $TXT_LIGHTBLACK;
    // 	font-size: $FONT_16;
    // 	line-height: 24px;
    // 	padding: 0;
    // 	padding-right: 15px;

    // 	&:first-child {
    // 		padding-left: 10px;
    // 	}
    // }
  }

  td {
    &.listing_Head {
      color: $TXT_LIGHTBLACK;
      font-size: $FONT_16;
      line-height: 24px;
      padding: 0;
      padding: 10px 18px;
      color: $TXT_NAVY;
      font-family: $LATO_SEMIBOLD;

      &:first-child {
        padding-left: 10px;
      }
    }
  }

  .edit_Dropdown {
    position: relative;

    .icon-edit {
      cursor: pointer;
    }
  }

  .dropdown_List {
    list-style: none;
    position: absolute;
    right: 60px;
    top: -30px;
    background: $BG_WHITE;
    margin: 0;
    padding: 16px 10px;
    width: 92px;
    height: 82px;
    border-radius: 8px;

    li {
      padding-bottom: 2px;

      a {
        font-size: $FONT_14;
        color: $TXT_LIGHTBLUE;
        text-decoration: none;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

// .tab_Heading {
// 	// display: block;
// 	background: $BG_LIGHTBLUE;
// 	// padding: 12px 24px;
// 	border-radius: 6px;
// 	border-bottom-left-radius: 0;
// 	border-bottom-right-radius: 0;
// 	width: 100%;

// 	.heading {
// 		color: $TXT_LIGHTBLACK;
// 		font-size: $FONT_16;
// 		line-height: 24px;
// 		padding: 0;
// 		padding-right: 15px;

// 		&.srno {
// 			width: 10%;
// 		}

// 		&.dateInput {
// 			width: 15%;
// 		}

// 		&.accType {
// 			width: 33%;
// 		}

// 		&.status {
// 			width: 25%;
// 		}

// 		&.price {
// 			width: 15%;
// 		}

// 		&.action {
// 			width: 2%;
// 		}
// 	}
// }

// @media only screen and (min-width: 575.5px) and (max-width: 767px) {
// 	.tab_Heading {
// 		.heading {
// 			font-family: $LATO_REGULAR;
// 		}
// 	}
// }

// @media only screen and (min-width: 475.5px) and (max-width: 575.5px) {
// 	.tab_Heading {
// 		.heading {
// 			font-family: $LATO_REGULAR;
// 		}
// 	}
// }

// @media only screen and (min-width: 300px) and (max-width: 475.5px) {
// 	.tab_Heading {
// 		.heading {
// 			font-family: $LATO_REGULAR;
// 			width: 25% !important;

// 			&.accType {
// 				display: none;
// 			}

// 			&.userName {
// 				width: 40% !important;
// 			}

// 			&.status {
// 				width: 30% !important;
// 			}

// 			&.checkBox {
// 				width: 5%;
// 			}
// 		}
// 	}
// }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #fantasyTable {
    position: relative;

    thead {
      display: none;
    }

    td {
      width: 48%;
      float: left;

      &:before {
        content: attr(data-title);
        width: 100%;
        display: table;
      }

      &.srNo {
        display: none;
      }

      &.subsType {
        text-align: right;
      }

      &.price {
        text-align: right;
      }

      &.actionBtn {
        width: auto;
        position: absolute;
        right: 10px;
        top: 10px;
      }

      .filterDropdown {
        right: -32px;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
  #fantasyTable {
    position: relative;

    thead {
      display: none;
    }

    td {
      width: 48%;
      float: left;

      &:before {
        content: attr(data-title);
        width: 100%;
        display: table;
      }

      &.srNo {
        display: none;
      }

      &.subsType {
        text-align: right;
      }

      &.price {
        text-align: right;
      }

      &.actionBtn {
        width: auto;
        position: absolute;
        right: 10px;
        top: 10px;
      }

      .filterDropdown {
        right: -32px;
      }
    }
  }
}
