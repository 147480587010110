@import "../../assests/scss/variables.scss";

.filterForm {
  max-width: 546px;
  top: 92px;
  right: 10px;

  .filterDispute {
    display: table;
    width: 100%;

    .form {
      padding: 0;
      display: table;
      width: 100%;
      padding-bottom: 24px !important;

      .filterItem {
        width: 100%;
        float: left;

        .header_Section {
          border-bottom: 1px solid $TXT_BLUELIGHT;
          padding: 40px 24px 0px;

          .heading {
            display: flex;
            justify-content: space-between;
            padding-bottom: 24px;
          }

          .titleText {
            padding-top: 0px;
            padding-bottom: 0;
            padding-left: 0px;
            font-size: $FONT_24;
            font-family: $LATO_REGULAR;
            color: $TXT_LIGHTBLACK;
            font-weight: 600;

            &.staffTitle {
              padding-top: 16px;
            }
          }

          .icon-close {
            font-size: $FONT_16;
            cursor: pointer;
          }
        }

        .calender_Section {
          padding: 24px 32px 0 32px;

          .date_Filter {
            display: flex;
            justify-content: space-between;
            padding-bottom: 16px;

            .date_Title {
              font-size: $FONT_16;
              font-weight: 500;
              color: $TXT_LIGHTBLACK;
              padding: 0;
              align-self: center;
            }

            .filter_Title {
              font-size: $FONT_16;
              font-weight: 600;
              color: $TXT_BLUE;
              padding: 0;
              text-decoration: none;
            }
          }

          .calenderMain {
            position: relative;
            padding-bottom: 8px;

            i {
              position: absolute;
              right: 24px;
              top: 18px;
              color: $TXT_BLUE;
            }

            .react-datepicker-wrapper {
              .react-datepicker__input-container {
                input {
                  width: 100%;
                  height: 56px;
                  border: 1px solid $TXT_LIGHT;
                  border-radius: 8px;
                  padding: 0 16px;
                  color: $TXT_LIGHTBLUE;
                  font-size: $FONT_14;
                  font-weight: 500;

                  &:focus {
                    border: 1px solid $TXT_LIGHT !important;
                  }
                }
              }
            }
          }

          .customDropdown {
            .dropdown {
              .dropdown-menu {
                .dropdownData {
                  .dropdown-item {
                    font-size: $FONT_14;
                  }
                }
              }
            }
          }
        }

        .dropdown_Sections {
          padding-top: 0;

          &.paddingT {
            padding-top: 24px;
          }

          .filter_Title {
            font-size: $FONT_16;
            font-weight: 500;
            color: $TXT_LIGHTBLACK;
            padding-bottom: 16px;
          }

          .customDropdown {
            .dropdown {
              button {
                padding: 16px 24px !important;
                border: 1px solid !important;
                border-radius: 10px;
                position: relative;
                background-color: $TXT_OFFWHITE !important;
                font-size: $FONT_16 !important;
                color: $TXT_LIGHT !important;

                &:after {
                  position: absolute;
                  right: 24px;
                  top: 22px;
                }
              }
            }
          }
        }

        .calendar_Section {
          .date_Title {
            font-size: $FONT_16;
            font-weight: 500;
            color: $TXT_LIGHTBLACK;
            padding-bottom: 16px;
          }
        }
      }

      .custombtnfield {
        padding-top: 24px;
        width: 100%;
        margin: 0;
        display: table;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        .submitBtn {
          float: left;
          height: 56px;
          padding: 8px 0 8px 0 !important;

          &.btn {
            width: 228px !important;

            &:nth-child(2) {
              margin-left: 0px !important;
            }
          }

          &:nth-child(2) {
            margin-left: 0px !important;
            background: transparent !important;
            color: $TXT_NAVY !important;
            border: 1px solid $TXT_NAVY !important;
            margin-bottom: 24px;
            margin-top: 16px;
            margin-left: 0;
          }
        }
      }
    }

    .react-datepicker-popper {
      width: 100%;
      margin-top: 1px !important;

      .react-datepicker {
        width: 100%;
        max-width: 344px;
        float: left;
        border: none;
        padding: 14px;
        -webkit-box-shadow: $BOX_SHADOW;
        -moz-box-shadow: $BOX_SHADOW;
        box-shadow: $BOX_SHADOW;

        .react-datepicker__navigation {
          outline: none;

          &.react-datepicker__navigation--previous {
            left: 10px;
            border-right-color: $TXT_BLUE;
          }

          &.react-datepicker__navigation--next {
            right: 10px;
            border-left-color: $TXT_BLUE;
          }
        }

        .react-datepicker__month-container {
          width: 100%;
          max-width: 344px;

          .react-datepicker__header {
            border-color: $BORDER;
            background-color: transparent;
            padding: 0;

            .react-datepicker__current-month {
              color: $TXT_NAVY;
              font-family: $LATO_REGULAR;
            }

            .react-datepicker__day-names {
              display: flex;
              justify-content: space-around;

              .react-datepicker__day-name {
                color: $TXT_LIGHTBLACK;
                font-family: $LATO_REGULAR;
                font-weight: 600;
              }
            }
          }

          .react-datepicker__month {
            .react-datepicker__week {
              display: flex;
              justify-content: space-around;

              .react-datepicker__day {
                color: $TXT_LIGHTBLACK;
                outline: none;

                &.react-datepicker__day--keyboard-selected {
                  background-color: $TXT_ORANGE;
                  color: $TXT_WHITE;
                  border-radius: 50%;
                }

                &.react-datepicker__day--selected {
                  background-color: $TXT_ORANGE;
                  color: $TXT_WHITE;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }

      .react-datepicker__triangle {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475.5px) {
  .filterForm {
    .filterDispute {
      .form {
        .custombtnfield {
          .submitBtn.btn {
            width: 186px !important;
            margin-top: 0;
          }
        }
      }
    }
  }

  .filterDispute {
    .form {
      .custombtnfield {
        .submitBtn {
          &:nth-child(2) {
            margin-bottom: 0;
            margin-top: 0;
            margin-left: 29px;
          }
        }
      }
    }
  }
}
