@import "../../assests/scss/variables.scss";

.verify_Page {
  position: relative;
  width: 100%;
  height: 100%;
  float: left;
  background: $BG_LIGHT;
  // z-index: -1;
  z-index: 1;

  .chatboat-section {
    bottom: 10px;
    right: 25px;
    width: 66px;
    height: 66px;
    background: $BG_NAVY;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
      color: $BG_WHITE;
    }
  }

  .top_Patten {
    position: absolute;
    right: 0;
    z-index: -1;
  }

  .bottom_Patten {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }

  .scroll_Section {
    height: 100%;
  }

  .center_Section {
    display: flex;
    padding: 80px 174px 0 311px;

    .img_Section {
      height: auto;
      width: 100%;

      .main_Img {
        max-width: 120%;
        max-height: 100%;
        object-fit: cover;
      }
    }

    .verify_Box {
      margin-left: 260px;
      margin-top: 150px;
      margin-bottom: 248px;
      max-width: 525px;
      width: 100%;
      background: $BG_LIGHT;
      padding: 32px;
      display: block;
      border-radius: 15px;
      -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);

      .form {
        .otp_box {
          margin-bottom: 24px;

          div[style] {
            justify-content: space-between;

            input[style] {
              width: 84px !important;
              height: 72px !important;
              border: 1px solid $TXT_NAVY;
              border-radius: 15px;
              margin: 0px !important;
            }
          }
        }
      }

      h1 {
        font-family: $LATO_BOLD;
        font-size: $FONT_48;
        text-align: left;
        color: $TXT_ORANGE;
        line-height: 150%;
        margin: 0;
        padding: 0;
      }

      .inner_text_wrap {
        padding: 24px 0 24px 0;

        .text_color {
          color: $TXT_ORANGE;
        }
      }

      .inner_Text {
        font-family: $LATO_REGULAR;
        font-size: $FONT_24;
        margin: 0;
        text-align: left;
        color: $TXT_LIGHTBLUE;
        font-weight: 300;
        line-height: 150%;
        // padding-bottom: 24px;
      }

      .link_Section {
        display: flex;
        align-items: center;
        padding-bottom: 24px;

        .form_Name {
          font-family: $LATO_BOLD;
          font-size: $FONT_24;
          margin: 0;
          text-align: left;
          line-height: 36px;
          color: $TXT_LIGHTBLUE;
          padding: 0;
          padding-right: 5px;
        }

        .link_Css {
          color: $TXT_BLUE;
          font-family: $LATO_BOLD;
          font-size: $FONT_24;
          text-decoration: none;
        }
      }

      .click_Action {
        text-align: left;
        color: $TXT_NAVY;
        font-size: $FONT_14;
        font-family: $LATO_REGULAR !important;
        font-weight: 400;
        padding-bottom: 24px;
        line-height: 22px;

        .color {
          color: $TXT_ORANGE;
        }

        .link {
          color: $TXT_LIGHTBLUE;
          font-style: italic;
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .verify_Page {
    .center_Section {
      padding: 0 100px;
      align-items: center;
      height: 100vh;

      .verify_Box {
        margin-left: 200px;
      }

      .img_Section {
        .main_Img {
          max-width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .verify_Page {
    .center_Section {
      padding: 80px 80px 0 80px;

      .verify_Box {
        margin-left: 70px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .verify_Page {
    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }

    .center_Section {
      padding: 0px;
      height: 100%;
      justify-content: center;
      align-items: center;

      .img_Section {
        display: none;
      }

      .verify_Box {
        margin: 0px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .verify_Page {
    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }

    .center_Section {
      padding: 0px;
      height: 100%;
      justify-content: center;
      align-items: center;

      .img_Section {
        display: none;
      }

      .verify_Box {
        margin: 0px;
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .verify_Page {
    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }

    .center_Section {
      padding: 0px;
      height: 100%;
      justify-content: center;
      align-items: center;

      .img_Section {
        display: none;
      }

      .verify_Box {
        margin: 0px;
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .verify_Page {
    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }

    .center_Section {
      padding: 0px !important;
      height: 100% !important;
      justify-content: center;
      align-items: center;

      .img_Section {
        display: none;
      }

      .verify_Box {
        margin: 24px;
      }
    }
  }
}

@media only screen and (min-width: 390px) and (max-width: 475px) {
  .verify_Page {
    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }

    .center_Section {
      padding: 0px !important;
      height: 100% !important;
      justify-content: center;
      align-items: center;

      .img_Section {
        display: none;
      }

      .verify_Box {
        padding: 32px 16px 32px 16px !important;
        margin: 0px 24px 0px 24px !important;

        .form {
          .otp_box {
            margin-bottom: 24px;

            div[style] {
              justify-content: space-between;

              input[style] {
                width: 70px !important;
                height: 72px !important;
                border-radius: 15px;
                margin: 0px !important;
              }
            }
          }
        }

        h1 {
          font-family: $LATO_BOLD;
          font-size: $FONT_26;
          line-height: 39px;
        }

        .inner_Text {
          font-size: $FONT_16;
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 380px) {
  .verify_Page {
    .top_Patten {
      height: 297px;
    }

    .bottom_Patten {
      height: 297px;
    }

    .center_Section {
      padding: 0px !important;
      height: 100% !important;
      justify-content: center;
      align-items: center;

      .img_Section {
        display: none;
      }

      .verify_Box {
        padding: 32px 16px 32px 16px !important;
        margin: 0px 24px 0px 24px !important;

        .form {
          .otp_box {
            margin-bottom: 24px;

            div[style] {
              justify-content: space-between;

              input[style] {
                width: 80% !important;
                height: 52px !important;
                border-radius: 10px !important;
                margin: 0px !important;
              }
            }
          }
        }

        h1 {
          font-family: $LATO_BOLD;
          font-size: $FONT_26;
          line-height: 39px;
        }

        .inner_Text {
          font-size: $FONT_16;
        }
      }
    }
  }
}
