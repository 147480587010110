@import "../../assests/scss/variables.scss";

.footer_Section {
  background: $TXT_NAVY;
  // position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;

  .inner_Footer {
    // display: flex;
    padding: 80px 101px 68px 174px;
    border-bottom: 1px solid $BORDER;
    display: table;
    width: 100%;

    .logo_Content {
      width: 620px;
      text-align: left;
      float: left;

      img {
        padding-bottom: 16px;
      }

      .titleText {
        font-size: 24px;
        font-family: $LATO_BOLD;
        color: $TXT_OFFWHITE;
        padding: 0;
        padding-bottom: 24px;
        font-weight: 600;
      }

      .inner_Text {
        font-size: $FONT_16;
        font-family: $LATO_REGULAR;
        font-weight: 500;
        line-height: 24px;
        padding-bottom: 0;
      }
    }

    // .footer_Item {
    // 	display: flex;

    ul {
      list-style: none;
      padding: 0;
      padding-left: 174px;
      margin: 0;
      // float: left;

      li {
        text-align: left;
        color: $TXT_OFFWHITE;
        font-size: 16px;
        font-family: $LATO_REGULAR;
        padding-bottom: 16px;
      }

      .titleText {
        font-size: 24px;
        font-family: $LATO_BOLD;
        color: $TXT_OFFWHITE;
        padding: 0;
        padding-bottom: 24px;
      }
    }

    // }

    .publisher {
      float: left;

      li {
        color: $TXT_OFFWHITE;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    .legal {
      float: left;
      width: 380px;

      li {
        color: $TXT_OFFWHITE;
      }
    }

    .social_Link {
      display: flex;
      padding: 0;

      .titleText {
        font-size: 24px;
        font-family: $LATO_BOLD;
        color: $TXT_OFFWHITE;
        padding: 0;
        padding-bottom: 24px;
      }

      ul {
        padding: 0;
        padding: 0;
        display: flex;
        margin: 0;
      }

      li {
        font-size: $FONT_24;
        color: $TXT_WHITE;
        padding-right: 24px;
      }
    }

    .logo_Content {
      width: 620px;
      text-align: left;
      float: left;
      padding-right: 100px;
    }

    .ul_Listing {
      float: left;
      width: 22%;

      .titleText {
        font-size: 24px;
        font-family: $LATO_BOLD;
        color: $TXT_OFFWHITE;
        padding: 0;
        padding-bottom: 24px;
      }

      a {
        display: table;
        font-size: $FONT_16;
        color: $TXT_WHITE;
        text-decoration: none;
        padding-bottom: 16px;

        &:last-child {
          padding-bottom: 0;
        }
      }

      &.contact {
        width: 20%;
      }

      &.policy {
        width: 18%;
      }

      &.social {
        width: 15%;

        ul {
          padding: 0;
          display: flex;

          a {
            padding-right: 18px;
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .bottom_Section {
    padding-left: 174px;
    padding-top: 24px;
    padding-bottom: 85px;

    .titleText {
      font-size: $FONT_16;
      font-family: $LATO_REGULAR;
      font-weight: 500;
      line-height: 24px;
      padding-bottom: 0;
      color: $TXT_WHITE;
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1570px) {
  .footer_Section {
    .inner_Footer {
      padding: 80px 101px 68px 84px;

      .logo_Content {
        width: 500px;
      }
    }

    .bottom_Section {
      padding-left: 84px;
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .footer_Section {
    .inner_Footer {
      padding: 80px 101px 68px 84px;

      .logo_Content {
        width: 100%;
        padding-bottom: 95px;
      }

      .ul_Listing {
        width: 33% !important;
      }
    }

    .bottom_Section {
      padding-left: 84px;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .footer_Section {
    .inner_Footer {
      padding: 80px 101px 68px 84px;

      .logo_Content {
        width: 100%;
        padding-bottom: 95px;
      }

      .ul_Listing {
        width: 33% !important;
      }
    }

    .bottom_Section {
      padding-left: 84px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer_Section {
    padding-bottom: 10px;

    .inner_Footer {
      padding: 80px 40px 68px 40px;

      .logo_Content {
        width: 100%;
        padding: 0;
        display: table;
        padding-bottom: 60px;
      }

      .ul_Listing {
        width: 50% !important;

        &.contact {
          padding-bottom: 48px;
        }

        &.social {
          clear: both;
        }
      }
    }

    .bottom_Section {
      padding-left: 32px;
      padding-bottom: 10px;

      .titleText {
        // margin: 0 auto;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .footer_Section {
    position: relative;

    .inner_Footer {
      padding: 80px 40px 50px 40px;

      .logo_Content {
        width: 100%;
        padding: 0;
        display: table;
        padding-bottom: 60px;
      }

      .ul_Listing {
        width: 50% !important;

        &.contact {
          padding-bottom: 48px;
          width: 70% !important;
        }

        &.policy {
          width: 30% !important;
        }

        &.social {
          clear: both;
        }
      }
    }

    .bottom_Section {
      padding-left: 40px;
      padding-bottom: 100px;

      .titleText {
        margin: 0 auto;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 575.5px) {
  .footer_Section {
    .bottom_Section {
      padding-bottom: 60px !important;
    }
  }
}
