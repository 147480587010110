@import "../../../assests/scss/variables.scss";

.invite-modal-parent {
  width: 100%;
  // float: right;
  background: #eff0f7;
  height: 100vh;
  .invite-modal-inner {
    // padding: 0px 0px 0px 0px;
    .divide-invite-role-and-btn-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100vh;
      .top-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #d9dbe9;
        margin-top: 40px;
        padding-bottom: 16px;
        .top-title {
          padding-bottom: 0px;
          font-size: $FONT_24;
          color: $TXT_LIGHTBLACK;
          font-weight: 600;
        }
        .icon-close {
          cursor: pointer;
        }
      }
      .input-section {
        margin-top: 40px;
        .mail-id {
          padding-bottom: 0px;
          font-size: $FONT_16;
          color: $TXT_LIGHTBLACK;
          font-family: $LATO_REGULAR !important;
        }
        .input-field {
          margin-top: 16px;
        }

        .textField .form-control {
          color: $TXT_LIGHT !important;
          border: 1px solid $TXT_LIGHT;
        }
      }
      .roles-section {
        margin-top: 40px;
        .select-role-text {
          font-size: $FONT_16;
          color: $TXT_LIGHTBLACK;
          font-family: $LATO_REGULAR !important;
        }
        .roles-listing {
          .checkboxMain input:checked + label:before {
            margin-right: 12px;
          }
          .checkboxMain label:before {
            margin-right: 12px;
          }
          .role-text {
            color: $TXT_LIGHT;
            font-size: $FONT_16;
            margin-top: 8px;
            padding-bottom: 0px;
            font-family: $LATO_REGULAR;
            margin-left: 27px;
          }
        }
      }
      .btn-wrapper {
        margin-bottom: 40px;
        .btn-section {
          display: flex;
          bottom: 0;
          .cancel-btn {
            background: #ffffff !important;
            width: 100%;
            border: none !important;
            height: 56px;
            font-size: 16px;
            font-family: "latobold";
            border-radius: 10px !important;
            color: #16056b;
            border: 1px solid #16056b !important;
            margin-right: 12px;
          }
          .next-btn {
            background: $TXT_NAVY !important;
            width: 100%;
            border: none !important;
            height: 56px;
            font-size: 16px;
            font-family: "latobold";
            border-radius: 10px !important;
            color: #ffffff;
          }
        }
      }
    }
  }
  .show-acc-listing {
    // position: absolute;
    // right: 0;
    // bottom: 0;
    // top: 0;
    // z-index: 999;
    // left: 0;
    // width: 100%;
  }
}
