// Background Color

$BG: #eff0f7;
$BG_LIGHT: #f7f7fc;
$BG_LIGHTBLUE: #d9dbe9;
$BG_OFFLIGHT: #eff0f6;
$BG_WHITE: #ffffff;
$BG_NAVY: #16056b;
$BG_ORANGE: #f45b0f;
$BG_OFFWHITE: #eff0f6;
$BG_BLUE: #1877f2;
$BG_DARKBLUE: #0077b5;
$BG_GREEN: #4596a2;
$BG_DARKGREY: #979797;
$BG_GREY: #eaeaea;
$BG_DARKESTBLUE: #2161e3;
$BG_YELLOW: #ffca63;
$BG_ICONBG: #e3ebff;

// Color
$TXT_WHITE: #ffffff;
$TXT_BLACK: #000000;
$TXT_DARKBLACK: #26252c;
$TXT_BLACKIST: #1e1e1e;
$TXT_LIGHTBLACK: #14142b;
$TXT_OFFWHITE: #fcfcfc;
$TXT_ORANGE: #f45b0f;
$TXT_NAVY: #16056b;
$TXT_BLUE: #5696fa;
$TXT_SKYBLUE: #0544e9;
$TXT_LIGHTBLUE: #4e4b66;
$TXT_LIGHT: #a0a3bd;
$TXT_BLUELIGHT: #d9dbe9;
$LABEL_GREY: #6e7191;
$TXT_GREY: #6e7191;
$TXT_GREEN: #0fd9a2;
$TXT_DARKGREY: #7e7e7e;
$TXT_LIGHTGREY: #6e7191;
$TXT_DIMBLUE: #2e3a59;
$TXT_LIGHTCOLOR: #6e7191;
$SOLID_BLACK: #000000;
$TEXT_RED: #fc3333;
$TXT_GREEN: #4596a2;
$TXT_LIGHTNAVY: #293688;
$TXT_VIOLENT: #0000ff;
$TXT_DARKESTBLUE: #2161e3;
$TXT_DARKGREEN: #22bc6c;

// Border Color
$BORDER: rgba(255, 255, 255, 0.3);
$BORDERLIGHT: #d8d8d8;
$MENUBORDER: #b4d7fe;

$ARROW: #2e3a59;

// Box-Shadow

$BOX_SHADOWHEADER: 0px 4px 15px rgba(0, 0, 0, 0.15);
$BOX_SHADOW: 0px 0px 1px rgba(40, 41, 61, 0.08),
  0px 0.5px 2px rgba(96, 97, 112, 0.16);

// Font Size
$FONT_10: 10px;
$FONT_12: 12px;
$FONT_13: 13px;
$FONT_14: 14px;
$FONT_16: 16px;
$FONT_18: 18px;
$FONT_20: 20px;
$FONT_22: 22px;
$FONT_24: 24px;
$FONT_26: 26px;
$FONT_28: 28px;
$FONT_30: 30px;
$FONT_32: 32px;
$FONT_34: 34px;
$FONT_38: 38px;
$FONT_36: 36px;
$FONT_40: 40px;
$FONT_44: 44px;
$FONT_48: 48px;
$FONT_50: 50px;
$FONT_64: 64px;

// Font Family

$LATO_REGULAR: "latoregular";
$LATO_LIGHT: "latolight";
$LATO_SEMIBOLD: "latosemibold";
$LATO_BOLD: "latobold";
