@import "../../../assests/scss/variables.scss";

.search_Box {
  position: relative;
  display: table;
  width: 100%;

  .search_Txt {
    font-size: $FONT_14;
    color: $TXT_LIGHT;
    font-family: $LATO_REGULAR;
    margin: 0;
    font-weight: 400;
    display: flex;
    align-self: center;
    border: 1px solid $TXT_LIGHT;
    border-radius: 8px;
    padding: 11px 10px;
    padding-left: 30px;
    width: 302px;
    position: relative;

    &:focus {
      outline: none;
    }
  }

  .icon-search {
    font-size: $FONT_16;
    color: $TXT_LIGHT;
    padding-right: 10px;
    position: absolute;
    left: 10px;
    top: 14px;
  }
}
