@import "../../../assests/scss/variables.scss";

.selectAcc-modal-parent {
  width: 100%;
  background: #eff0f7;
  height: 100vh;

  .selectAcc-modal-inner {
    .divide-selectAcc-role-and-btn-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100vh;
      .top-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #d9dbe9;
        margin-top: 40px;
        padding-bottom: 16px;
        .top-title {
          padding-bottom: 0px;
          font-size: $FONT_24;
          color: $TXT_LIGHTBLACK;
          font-weight: 600;
        }
        .icon-close {
          cursor: pointer;
        }
      }
      .change-role-dropdown {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
        .customDropdown .dropdown .btn-primary {
          background: #fcfcfc;
          border: 1px solid #d9dbe9 !important;
          border-radius: 8px;
          color: #5696fa !important;
        }
        .dropdown-toggle::after {
          margin-left: 1.255em !important;
          color: #2e3a59;
        }
        .customDropdown
          .dropdown
          .dropdown-menu
          .dropdownData
          .dropdown-item:hover {
          color: #5696fa;
        }
        .customDropdown
          .dropdown
          .dropdown-menu
          .dropdownData
          .dropdown-item:nth-child(1):hover::after {
          color: $TXT_OFFWHITE !important;
          position: absolute;
          top: 15px;
          right: 150px;
          background-color: $TXT_LIGHTBLACK;
          padding: 4px 8px;
          border-radius: 4px;
          content: "Can add new users and has all permissions on all accounts.";
        }
        .customDropdown
          .dropdown
          .dropdown-menu
          .dropdownData
          .dropdown-item:nth-child(2):hover::after {
          color: $TXT_OFFWHITE !important;
          position: absolute;
          display: inline-block;
          top: 55px;
          right: 150px;
          background-color: $TXT_LIGHTBLACK;
          padding: 4px 8px;
          border-radius: 4px;
          content: "Can Create, Edit, Boost & Comment on posts and connect new accounts.";
        }
        .customDropdown
          .dropdown
          .dropdown-menu
          .dropdownData
          .dropdown-item:nth-child(3):hover::after {
          color: $TXT_OFFWHITE !important;
          position: absolute;
          display: inline-block;
          top: 95px;
          right: 150px;
          background-color: $TXT_LIGHTBLACK;
          padding: 4px 8px;
          border-radius: 4px;
          content: "Posts created will go for manager approval. Can Comment on posts.";
        }
        .customDropdown
          .dropdown
          .dropdown-menu
          .dropdownData
          .dropdown-item:nth-child(4):hover::after {
          color: $TXT_OFFWHITE !important;
          position: absolute;
          display: inline-block;
          top: 135px;
          right: 150px;
          background-color: $TXT_LIGHTBLACK;
          padding: 4px 8px;
          border-radius: 4px;
          content: "Can View & Comment on posts and connect new accounts.";
        }
      }

      .tab-section {
        margin-top: 24px;
        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
          color: $TXT_NAVY;
          background-color: transparent !important;
          border-bottom: 2px solid $BG_NAVY !important;
        }
        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link {
          border-color: transparent !important;
          font-weight: bold;
          color: $TXT_LIGHT;
          padding: 0 !important;
          // margin-right: 0px;

          padding-bottom: 10px !important;
        }
        .nav {
          flex-wrap: nowrap !important;
          display: flex;
          justify-content: space-between;
        }

        .tab-wrapper {
          margin-top: 24px;
          background-color: $BG_WHITE;
          border-radius: 4px;
          .list-heading {
            padding: 16px 0px 8px 32px;
            font-size: $FONT_14;
            color: $TXT_LIGHTBLACK;
            border-bottom: 1px solid #d9dbe9;
            width: 100%;
          }
          .tab-inner {
            padding: 24px 16px 0px 16px;
            .tab-top-section {
              .acc-listing-wrapper {
                width: "100%";
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                padding: 0px 16px;
                .acc-listing {
                  margin-bottom: 24px;
                }
              }
              .nav {
                display: flex;
                justify-content: flex-start;
              }
              .nav-tabs .nav-item.show .nav-link,
              .nav-tabs .nav-link {
                margin-right: 16px;
              }
            }
          }
        }
      }

      .btn-wrapper {
        margin-bottom: 40px;
        .btn-section {
          display: flex;
          bottom: 0;
          .cancel-btn {
            background: #ffffff !important;
            width: 100%;
            border: none !important;
            height: 56px;
            font-size: 16px;
            font-family: "latobold";
            border-radius: 10px !important;
            color: #16056b;
            border: 1px solid #16056b !important;
            margin-right: 12px;
          }
          .next-btn {
            background: $TXT_NAVY !important;
            width: 100%;
            border: none !important;
            height: 56px;
            font-size: 16px;
            font-family: "latobold";
            border-radius: 10px !important;
            color: #ffffff;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 300px) and (max-width: 475px) {
  .selectAcc-modal-parent {
    .nav {
      flex-wrap: wrap !important;
      overflow-y: scroll;
    }
    .nav .nav-link {
      margin-left: 16px;
    }
  }
}
