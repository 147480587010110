@import "../../assests/scss/variables.scss";

.group_Page {
  width: 100%;
  display: table;
  position: relative;
  background: $BG_LIGHT;

  .main_Container {
    width: calc(100% - 96px);
    float: left;

    .group_Inner {
      padding-left: 32px;

      .tabSearch_section {
        width: 100%;
        float: left;
        padding-top: 40px;
        position: relative;

        .tabFilerIcon {
          font-size: $FONT_18;
          color: $ARROW;
          position: absolute;
          right: 15px;
          top: 15px;
          display: none;
        }

        .main_Heading {
          font-size: $FONT_32;
          font-family: $LATO_BOLD;
          font-weight: 600;
          color: $TXT_NAVY;
          padding-bottom: 14px;
        }

        .inner_Text {
          color: $TXT_LIGHTCOLOR;
          font-size: $FONT_16;
          font-family: $LATO_REGULAR;
          font-weight: 400;
          padding-bottom: 32px;
        }

        .tab_Search {
          align-items: baseline;
          position: relative;
          padding-right: 15px;

          .tabs_Section {
            width: 100%;
            height: 52px;
            display: flex;

            .search_Box {
              margin-top: -10px !important;
              width: 25% !important;

              .search_Txt {
                width: 100% !important;
              }

              .icon-search {
                top: 15px !important;
              }
            }

            .search_Filter {
              display: flex;
              position: absolute;
              right: 8px;
              top: -14px;

              .filterBtn {
                position: relative;
                margin-right: 24px;
                margin-top: 5px !important;

                .filterIcon {
                  position: relative;
                  margin-left: 15px;
                  background: $BG_WHITE;
                  width: 117px;
                  height: 40px;
                  border-radius: 8px;
                  border: none;
                  display: flex;
                  align-items: center;
                  border: 1px solid $TXT_LIGHTBLACK;
                  justify-content: center;

                  &:focus {
                    outline: none;
                  }

                  i {
                    color: $TXT_LIGHTBLACK;
                    font-size: $FONT_16;

                    span {
                      font-size: $FONT_16;
                      font-family: $LATO_REGULAR;
                    }
                  }
                }
              }

              .submitBtn {
                height: 48px !important;
              }
            }
          }

          .preview_Section {
            &.main_TabEvent {
              .tab-content {
                width: 100%;
                border: none;
              }
            }

            .tab_Section {
              margin-top: -24px !important;
              margin-bottom: 50px !important;
            }

            .tab_Listing .listing_Head.userName {
              width: 15% !important;
            }

            .tab_Listing .listing_Head.accType {
              width: 41% !important;
            }
          }
        }
      }
    }
  }
}

.profilePopup {
  .modal-header {
    padding: 1rem !important;
    background: $BG;
    border-radius: 10px;
  }

  .modal-body {
    display: none !important;
  }

  .modalPopup .modal-content .closeBtn i {
    padding-top: 20px;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .group_Page {
    .main_Container {
      .group_Inner {
        .tabSearch_section {
          .tabFilerIcon {
          }

          .main_Heading {
          }

          .inner_Text {
          }

          .tab_Search {
            .tabs_Section {
              .search_Box {
                width: 33% !important;
              }

              .search_Filter {
                .filterBtn {
                  .filterIcon {
                    &:focus {
                    }

                    i {
                      span {
                      }
                    }
                  }
                }

                .submitBtn {
                }
              }
            }

            .preview_Section {
              &.main_TabEvent {
                .tab-content {
                }
              }

              .tab_Listing .listing_Head.userName {
                width: 15% !important;
              }

              .tab_Listing .listing_Head.accType {
                width: 33% !important;
              }

              .group_Heading .heading.Description {
                width: 33% !important;
              }

              .tab_Listing .listing_Head.date {
                width: 27% !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .group_Page {
    .main_Container {
      .group_Inner {
        .tabSearch_section {
          .tabFilerIcon {
          }

          .main_Heading {
          }

          .inner_Text {
          }

          .tab_Search {
            .tabs_Section {
              .search_Box {
                width: 43% !important;
              }

              .search_Filter {
                .filterBtn {
                  .filterIcon {
                    &:focus {
                    }

                    i {
                      span {
                      }
                    }
                  }
                }

                .submitBtn {
                }
              }
            }

            .preview_Section {
              &.main_TabEvent {
                .tab-content {
                }
              }

              .tab_Listing .listing_Head.userName {
                width: 18% !important;
              }

              .group_Heading .heading.userName {
                width: 18% !important;
              }

              .tab_Listing .listing_Head.date {
                width: 18% !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 991.5px) and (max-width: 1024px) {
  .group_Page {
    .main_Container {
      .group_Inner {
        .tabSearch_section {
          .tabFilerIcon {
          }

          .main_Heading {
          }

          .inner_Text {
          }

          .tab_Search {
            .tabs_Section {
              .search_Box {
                width: 43% !important;
              }

              .search_Filter {
                .filterBtn {
                  .filterIcon {
                    &:focus {
                    }

                    i {
                      span {
                      }
                    }
                  }
                }

                .submitBtn {
                }
              }
            }

            .preview_Section {
              &.main_TabEvent {
                .tab-content {
                }
              }

              .tab_Listing .listing_Head.userName {
                width: 18% !important;
              }

              .group_Heading .heading.userName {
                width: 18% !important;
              }

              .tab_Listing .listing_Head.date {
                width: 13% !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 767.5px) and (max-width: 991.5px) {
  .group_Page {
    .main_Container {
      .group_Inner {
        .tabSearch_section {
          .tabFilerIcon {
          }

          .main_Heading {
          }

          .inner_Text {
          }

          .tab_Search {
            .tabs_Section {
              .search_Box {
                width: 60% !important;
              }

              .search_Filter {
                right: 12px !important;
                top: -62px !important;

                .filterDropdown {
                  margin-top: 50px !important;
                }

                .filterBtn {
                  left: 172px !important;
                  margin-top: 56px !important;

                  .filterIcon {
                    &:focus {
                    }

                    i {
                      span {
                      }
                    }
                  }
                }

                .submitBtn {
                  height: 42px !important;
                  margin-top: 4px !important;
                  margin-bottom: 20px !important;
                }
              }
            }

            .preview_Section {
              &.main_TabEvent {
                .tab-content {
                }
              }

              .tab_Listing .listing_Head.profile {
                width: 21% !important;
              }

              .tab_Listing .listing_Head.userName {
                width: 33% !important;
              }

              .tab_Listing .listing_Head.accType {
                width: 40% !important;
              }

              .group_Heading .heading.userName {
                width: 22% !important;
              }

              .group_Heading .heading.heading.Description {
                width: 27% !important;
              }

              .tab_Listing .listing_Head.icon-timer {
                margin-top: 95px !important;
              }

              .tab_Listing .listing_Head.icon-setting {
                margin-top: 95px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 575.5px) and (max-width: 767.5px) {
  .group_Page {
    .main_Container {
      .group_Inner {
        padding-left: 10px;

        .tabSearch_section {
          .tabFilerIcon {
          }

          .main_Heading {
          }

          .inner_Text {
            font-size: $FONT_14;
            width: 300px !important;
          }

          .tab_Search {
            .tabs_Section {
              .search_Box {
                width: 68% !important;
              }

              .search_Filter {
                right: 8px !important;
                top: -62px !important;

                .filterDropdown {
                  top: 95px !important;
                }

                .filterBtn {
                  top: 52px !important;
                  left: 172px !important;

                  .filterIcon {
                    &:focus {
                    }

                    i {
                      span {
                      }
                    }
                  }
                }

                .submitBtn {
                  height: 42px !important;
                  margin-top: 0px !important;
                  margin-left: 0px;
                  margin-bottom: 20px !important;
                }
              }
            }

            .preview_Section {
              &.main_TabEvent {
                .tab-content {
                  width: 100%;
                }
              }

              .nav-tabs {
                margin-bottom: 32px !important;
              }

              .tab_Listing .listing_Head.icon-timer {
                margin-left: 0px !important;
                margin-top: 138px;
              }

              .tab_Listing .listing_Head.icon-setting {
                margin-top: 138px;
              }

              .tab_Listing .listing_Head {
                font-size: $FONT_14 !important;
              }

              .tab_Listing .listing_Head.profile {
                width: 26% !important;
              }

              .tab_Listing .listing_Head.userName {
                width: 52% !important;
              }

              .tab_Listing .listing_Head.accType {
              }

              .group_Heading .heading.profile {
                width: 16% !important;
              }

              .group_Heading .heading.userName {
                width: 27% !important;
              }

              .group_Heading .heading.heading.Description {
                width: 23% !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 475.5px) and (max-width: 575.5px) {
  .group_Page {
    .main_Container {
      .group_Inner {
        .tabSearch_section {
          width: 130% !important;

          .tabFilerIcon {
          }

          .main_Heading {
          }

          .inner_Text {
            font-size: $FONT_14;
            width: 285px !important;
          }

          .tab_Search {
            .tabs_Section {
              .search_Box {
                width: 87% !important;
              }

              .search_Filter {
                top: -64px !important;

                .filterDropdown {
                  top: 95px !important;
                  left: -85px;
                }

                .filterBtn {
                  top: 52px;
                  left: 172px !important;

                  .filterIcon {
                    width: 40px;
                    height: 40px;

                    &:focus {
                    }

                    i {
                      span {
                        display: none;
                      }
                    }
                  }
                }

                .submitBtn {
                  height: 42px !important;
                  margin-bottom: 4px;
                  margin-right: 8px;
                }
              }
            }

            .preview_Section {
              &.main_TabEvent {
                .tab-content {
                  width: 100%;
                }
              }

              .tab_Listing {
                padding: 12px 14px !important;
                display: inline-block !important;
              }

              .tab_Listing .listing_Head.profile::before {
                content: "Profile";
                color: $TXT_LIGHTBLACK;
                font-family: $LATO_BOLD;
              }

              .tab_Listing .listing_Head.userName::before {
                content: "UserName";
                color: $TXT_LIGHTBLACK;
                font-family: $LATO_BOLD;
              }

              .tab_Listing .listing_Head.accType::before {
                content: "Description";
                color: $TXT_LIGHTBLACK;
                font-family: $LATO_BOLD;
              }

              .tab_Listing .listing_Head.date::before {
                content: "Created Date";
                color: $TXT_LIGHTBLACK;
                font-family: $LATO_BOLD;
              }

              .nav-tabs {
                margin-bottom: 32px !important;
              }

              .tab_Listing .listing_Head.icon-timer {
                margin-left: 0px !important;
                float: right !important;
                margin-top: -25px !important;
                margin-right: 20px !important;
              }

              .tab_Listing .listing_Head {
                font-size: $FONT_14 !important;
              }

              .tab_Listing .listing_Head.profile {
                width: 26% !important;
                display: grid !important;
                float: right !important;
                margin-top: 140px !important;
                margin-right: 32px !important;
              }

              .tab_Listing .listing_Head.userName {
                overflow: unset !important;
                display: flex !important;
                flex-direction: column !important;
              }

              .tab_Listing .listing_Head.accType {
                display: flex !important;
                flex-direction: column !important;
                margin-left: 270px !important;
                margin-top: -46px !important;
              }

              .tab_Listing .listing_Head.date {
                display: flex !important;
                flex-direction: column !important;
              }

              .tab_Listing .listing_Head.icon-setting {
                float: right !important;
                margin-top: -25px !important;
                margin-right: -12px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475.5px) {
  .group_Page {
    .main_Container {
      .group_Inner {
        .tabSearch_section {
          width: 137% !important;

          .inner_Text {
            font-size: $FONT_14;
            width: 200px !important;
          }

          .tab_Search {
            .tabs_Section {
              .search_Box {
                width: 85% !important;
              }

              .search_Filter {
                top: -64px !important;

                .filterDropdown {
                  top: 95px !important;
                  left: -85px;
                }

                .filterBtn {
                  top: 52px;
                  left: 172px !important;

                  .filterIcon {
                    width: 40px;
                    height: 40px;

                    i {
                      span {
                        display: none;
                      }
                    }
                  }
                }

                .submitBtn {
                  height: 42px !important;
                  margin-bottom: 4px;
                  margin-right: 8px;
                }
              }
            }

            .preview_Section {
              &.main_TabEvent {
                .tab-content {
                  width: 100%;
                }
              }

              .tab_Listing {
                padding: 12px 14px !important;
                display: inline-block !important;
              }

              .tab_Listing .listing_Head.profile::before {
                content: "Profile";
                color: $TXT_LIGHTBLACK;
                font-family: $LATO_BOLD;
              }

              .tab_Listing .listing_Head.userName::before {
                content: "UserName";
                color: $TXT_LIGHTBLACK;
                font-family: $LATO_BOLD;
              }

              .tab_Listing .listing_Head.accType::before {
                content: "Description";
                color: $TXT_LIGHTBLACK;
                font-family: $LATO_BOLD;
              }

              .tab_Listing .listing_Head.date::before {
                content: "Created Date";
                color: $TXT_LIGHTBLACK;
                font-family: $LATO_BOLD;
              }

              .nav-tabs {
                margin-bottom: 32px !important;
              }

              .tab_Listing .listing_Head.icon-timer {
                margin-left: 0px !important;
                float: right;
                margin-top: -25px;
                // margin-right: -25px;
                padding-left: 32px;
              }

              .tab_Listing .listing_Head {
                font-size: $FONT_14 !important;
              }

              .tab_Listing .listing_Head.profile {
                width: 26% !important;
                display: grid !important;
                float: right;
                // margin-top: 164px;
                // margin-right: 32px;
              }

              .tab_Listing .listing_Head.userName {
                overflow: unset;
                display: flex;
                flex-direction: column;
              }

              .tab_Listing .listing_Head.accType {
                display: flex;
                flex-direction: column;
                // margin-left: 200px;
                // margin-top: -46px;
                width: 100% !important;
              }

              .tab_Listing .listing_Head.date {
                display: flex;
                flex-direction: column;
                width: 46% !important;
              }

              .tab_Listing .listing_Head.icon-setting {
                float: right;
                margin-top: -25px;
                // margin-right: -55px;
                padding-left: 60px;
              }
            }
          }
        }
      }
    }
  }

  .paginationClass {
    right: 4% !important;
  }
}
