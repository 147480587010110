@import "../../../assests/scss/variables.scss";

.card {
  padding: 16px;
  max-width: 332px;
  border-radius: 1rem !important;
  height: 400px;
  overflow: hidden;

  .card_Head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;

    .image_Text {
      display: flex;
    }

    .text {
      padding-left: 8px;

      .card-title {
        margin-bottom: 0;
        font-size: $FONT_16;
        color: $TXT_LIGHTBLACK;
        padding-bottom: 5px;
      }

      .sub_Text {
        font-size: $FONT_14;
        color: $TXT_LIGHT;
      }
    }

    .image {
      width: 40px;

      .card-img {
        width: auto;
        border-radius: 0;
        max-width: 100%;
        display: block;
      }
    }

    i {
      color: $TXT_LIGHTGREY;
    }
  }

  .card-body {
    padding: 0;

    .img_Text {
      font-size: $FONT_14;
      color: $TXT_LIGHTGREY;
      padding-bottom: 8px;
    }

    .main_Image {
      width: 100%;
      height: 200px; /* Set a fixed height for the image or video preview */
      overflow: hidden;
      border-radius: 8px;

      .card-img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
      }

      video {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }

    .time {
      font-size: $FONT_14;
      color: $TXT_BLACK;
      line-height: 20px;
      padding-bottom: 16px;
    }
  }

  .titleText {
    padding-bottom: 0;
    font-family: $LATO_REGULAR;
  }

  .footer_Icon {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 20%; /* Adjust width as needed */

      i {
        color: $TXT_BLUE;
        font-size: 18px;

        &:first-child {
          color: $TEXT_RED;
        }
      }

      &.iconsTwo {
        width: 100%;
      }
    }

    .icon_Bg {
      background: $BG_ORANGE;
      width: 35px;
      height: 35px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: $TXT_WHITE;
      }

      .icon-reverse {
        color: $TXT_WHITE !important;
      }

      .icon-send1 {
        color: $TXT_WHITE !important;
        padding-top: 2px;
        padding-right: 3px;
      }
    }
  }
}
