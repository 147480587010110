@import "../../assests/scss/variables.scss";

.tab_Heading {
  display: flex;
  // justify-content: space-between;
  background: $BG_LIGHTBLUE;
  padding: 12px 24px;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .heading {
    color: $TXT_LIGHTBLACK;
    font-size: $FONT_16;
    line-height: 24px;
    padding: 0;

    &.profile {
      width: 15%;
    }

    &.userName {
      width: 30%;
    }

    &.accType {
      width: 28%;
    }

    &.status {
      width: 22%;
    }

    &.checkBox {
      width: 5%;
      background: $TXT_LIGHT;
      border-radius: 50%;
      max-width: 16px;
      height: 16px;
    }
  }
}

@media only screen and (min-width: 575.5px) and (max-width: 767px) {
  .tab_Heading {
    .heading {
      font-family: $LATO_REGULAR;
    }
  }
}

@media only screen and (min-width: 475.5px) and (max-width: 575.5px) {
  .tab_Heading {
    .heading {
      font-family: $LATO_REGULAR;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475.5px) {
  .tab_Heading {
    .heading {
      font-family: $LATO_REGULAR;
      width: 25% !important;

      &.accType {
        display: none;
      }

      &.userName {
        width: 40% !important;
      }

      &.status {
        width: 30% !important;
      }

      &.checkBox {
        width: 5%;
      }
    }
  }
}
