@import "../../../assests/scss/variables.scss";

.checkboxMain {
  display: block;
  margin: 0;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;
    font-family: $LATO_REGULAR;
    font-size: $FONT_16;
    color: $TXT_LIGHTBLACK;
    margin: 0;

    &:before {
      content: "";
      -webkit-appearance: none;
      background-color: $TXT_LIGHT;
      border: 2px solid $TXT_LIGHT;
      padding: 6px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      border-radius: 50%;
      top: -1px;
    }
  }

  input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 7px;
    left: 6px;
    width: 5px;
    height: 9px;
    border: solid $TXT_WHITE;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  input:checked + label:before {
    background-color: $TXT_ORANGE;
    border: 2px solid $TXT_ORANGE;
  }
}
