@import "../../assests//scss/variables.scss";

.blog-feature-parent {
  width: 394px;
  display: inline-block;
  .card-wrapper {
    background: #ffffff;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    .card-inner {
      display: flex;
      justify-content: space-between;
      padding: 20px 32px;
      .card-left-section {
        display: flex;
        align-items: center;
        .icon-text {
          display: flex;
          justify-content: center;
          align-items: center;
          .like-text {
            margin-left: 4px;
            font-size: 14px;
            line-height: 17px;
            color: $TXT_LIGHT;
          }
          .like-icon {
            cursor: pointer;
          }
          .comment-icon {
            margin-left: 8px;
            cursor: pointer;
          }
          .titleText {
            padding-bottom: 0px !important;
          }
        }
      }
      .card-right-section {
        display: flex;
        align-items: center;
        .icon-text {
          display: flex;
          justify-content: center;
          align-items: center;
          .right-icon {
            margin-left: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.popover {
  border: none !important;
}
.modalPopUp {
  background: #ffffff;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 16px 16px;

  .modal-icon-text {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    margin-top: 16px;
    cursor: pointer;
    .title {
      font-size: 14px;
      line-height: 17px;
      color: $TXT_LIGHT;
      margin-left: 10px;
    }
    .titleText {
      padding-bottom: 0px !important;
    }
    .icon-hover {
      fill: $TXT_LIGHT;
    }
    &.parent-hover:hover {
      .icon-hover {
        fill: $TXT_BLACK;
      }
      .title {
        color: $TXT_BLACK;
      }
    }
  }
  .modal-more {
    .titleText {
      padding-bottom: 0px !important;
    }
    .title {
      font-size: 14px;
      line-height: 17px;
      color: $TXT_LIGHT;
      cursor: pointer;
    }
    .title-report {
      font-size: 14px;
      line-height: 17px;
      color: $TXT_LIGHT;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
  .on-hover:hover {
    color: $TXT_BLACK;
  }
}

.modalPopUpLike {
  background-color: $TXT_LIGHTBLACK;
  padding: 8px 16px;
  border-radius: 4px;
  .likeHover {
    padding-bottom: 0px;
    color: $TXT_WHITE;
  }
}
