@import "../../assests/scss/variables.scss";

.publish_Page {
  width: 100%;
  display: table;
  position: relative;
  background: $BG_LIGHT;
  overflow: hidden;

  .main_Container {
    width: calc(100% - 96px);
    float: left;

    .publish_Inner {
      width: 100%;
      float: right;
      padding-left: 32px;

      .title_Height {
        padding-top: 40px;

        .tabFilerIcon {
          font-size: $FONT_18;
          color: $ARROW;
          position: absolute;
          right: 15px;
          top: 45px;
          display: none;
        }

        .main_Heading {
          font-size: $FONT_32;
          font-family: $LATO_BOLD;
          font-weight: 600;
          color: $TXT_NAVY;
          padding-bottom: 14px;
        }

        .inner_Text {
          color: $TXT_LIGHTCOLOR;
          font-size: $FONT_16;
          font-family: $LATO_REGULAR;
          font-weight: 400;
          padding-bottom: 32px;
        }
      }

      .tabSearch_section {
        width: 70%;
        float: left;
        position: relative;
        overflow-y: scroll;
        height: 100vh;

        .tab_Search {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          position: relative;
          padding-right: 15px;
          padding-bottom: 40px;

          .tabs_Section {
            width: 100%;
            height: 46px;
            display: flex;

            .preview_Section {
              position: relative;

              &.main_TabEvent {
                .tab-content {
                  width: 100%;
                  border: none;
                }
              }
            }
          }

          .search_Filter {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: -32px;

            .search-filter-child {
              display: flex;
            }

            .filterBtn {
              .filterIcon {
                position: relative;
                margin-left: 15px;
                background: $BG_WHITE;
                width: 117px;
                height: 40px;
                border-radius: 8px;
                border: none;
                display: flex;
                align-items: center;
                border: 1px solid $TXT_LIGHTBLACK;
                justify-content: center;

                &:focus {
                  outline: none;
                }

                i {
                  color: $TXT_LIGHTBLACK;
                  font-size: $FONT_16;

                  span {
                    font-size: $FONT_16;
                    font-family: $LATO_REGULAR;
                  }
                }
              }
            }

            .customDropdown {
              .dropdown {
                .dropdown-menu {
                  transform: translate3d(0, -58.8889px, 0px) !important;
                  top: 100px !important;

                  .dropdownData {
                    background: $BG_WHITE;
                  }
                }
              }
            }
          }
        }
      }

      .social_Section {
        float: left;
        width: 30%;
        background: $BG_OFFLIGHT;
        padding-top: 40px;
        height: 100vh;
        padding: 40px 30px 0 30px;

        .main_Title {
          font-size: 24px;
          color: $TXT_NAVY;
          line-height: 36px;
          padding-bottom: 16px;
          padding-left: 15px;
        }

        .onClose {
          font-size: $FONT_14;
          position: absolute;
          right: 30px;
          top: 50px;
          cursor: pointer;
          display: none;
        }

        .edit_Post {
          display: flex;
          justify-content: flex-end;
          width: 90%;
          margin-top: 40px;

          button {
            width: 184px;
            height: 56px;
          }
        }

        .create_Post {
          display: flex;
          justify-content: center;
          align-items: center;

          .content_Btn {
            display: flex;
            flex-direction: column;
            height: 100vh;
            justify-content: center;
            align-items: center;

            .inner_Txt {
              text-align: center;
              color: $TXT_LIGHT;
              font-size: $FONT_16;
              font-family: $LATO_REGULAR;
            }

            .create_Btn {
              width: 186px;
              margin: 0 auto;

              button {
                height: 56px;
              }
            }
          }
        }
      }
    }
  }

  .message_IconBg {
    position: absolute;
    right: 29%;
    bottom: 15px;
    cursor: pointer;
    background: $BG_ORANGE;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .messageIcon {
      font-size: $FONT_20;
      color: $TXT_WHITE;
    }
  }

  .search_Box {
    width: 0%;
  }

  .preview_Section .nav-tabs {
    margin-bottom: 16px !important;
  }

  .all-tabs {
    .nav-tabs .nav-link.active {
      border: none;
      border-bottom: 2px solid #16056b;
      padding: 0;
      padding-bottom: 15px !important;
      background: transparent !important;
      color: #16056b !important;
      font-weight: 600;
    }

    .nav-tabs .nav-link {
      border-color: transparent;
    }
  }

  #uncontrolled-tab-example-tabpane-Group {
    margin-bottom: 100px !important;
  }

  .showChat {
    &.chat_Box {
      display: table;
      width: 100%;
      margin-bottom: 100px;

      .message_Box {
        margin-bottom: 75px;
      }
    }
  }
}

.paginationClass {
  right: 33% !important;
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .publish_Page {
    .main_Container {
      .publish_Inner {
        .tabSearch_section {
          .tab_Search {
            .search_Filter {
              margin-bottom: 0px;

              .filterBtn {
              }
            }

            .preview_Section {
              &.main_TabEvent {
                .nav-tabs {
                  margin-bottom: 10px !important;

                  .nav-item {
                    margin-right: 24px;
                  }
                }
              }
            }
          }
        }

        .social_Section {
          padding: 40px 10px 0 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .publish_Page {
    .main_Container {
      .publish_Inner {
        .tabSearch_section {
          .tab_Search {
            .search_Filter {
              margin-bottom: 0px;

              .filterBtn {
              }
            }

            .preview_Section {
              &.main_TabEvent {
                .nav-tabs {
                  margin-bottom: 10px !important;
                }
              }
            }
          }
        }

        .social_Section {
          padding: 40px 8px 0 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 991.5px) and (max-width: 1024px) {
  .publish_Page {
    .main_Container {
      .publish_Inner {
        width: 100%;

        .tabFilerIcon {
          display: block !important;
        }

        .tabSearch_section {
          width: 100%;

          .tab_Search {
            .search_Filter {
              margin-bottom: 0px;

              .filterBtn {
                .filterIcon {
                  width: 40px;
                  height: 40px;
                  padding: 0 4px 0 9px;

                  i {
                    span {
                      font-size: 0;
                    }
                  }
                }
              }
            }

            .preview_Section {
              &.main_TabEvent {
                .tab-content {
                  padding-right: 0;
                }

                .nav-tabs {
                  margin-bottom: 10px !important;
                }
              }
            }
          }
        }

        .social_Section {
          display: none;

          &.showTab {
            display: block;
          }
        }

        .social_Section {
          top: 0;
          right: 0;
          z-index: 111;
          width: 475px;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 30px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          position: absolute;
          float: none;
          border-radius: 0 !important;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }

          .onClose {
            display: block;
          }
        }
      }
    }

    .message_IconBg {
      right: 30px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.5px) {
  .publish_Page {
    .main_Container {
      .publish_Inner {
        width: 100%;

        .tabFilerIcon {
          display: block !important;
        }

        .tabSearch_section {
          width: 100%;

          .tab_Search {
            .search_Filter {
              margin-bottom: 0px;

              .filterBtn {
                .filterIcon {
                  width: 40px;
                  height: 40px;
                  padding: 0 4px 0 9px;

                  i {
                    span {
                      font-size: 0;
                    }
                  }
                }
              }
            }

            .preview_Section {
              &.main_TabEvent {
                .tab-content {
                  padding-right: 0;
                }

                .nav-tabs {
                  margin-bottom: 10px !important;
                }
              }
            }
          }
        }

        .social_Section {
          display: none;

          &.showTab {
            display: block;
          }
        }

        .social_Section {
          top: 0;
          right: 0;
          z-index: 111;
          width: 475px;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 30px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          position: absolute;
          float: none;
          border-radius: 0 !important;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }

          .onClose {
            display: block;
          }
        }
      }
    }

    .message_IconBg {
      right: 30px;
    }
  }
}

@media only screen and (min-width: 575.5px) and (max-width: 767px) {
  .publish_Page {
    .main_Container {
      .publish_Inner {
        width: 100%;

        .tabFilerIcon {
          display: block !important;
        }

        .title_Height {
          .inner_Text {
            padding-bottom: 60px;
          }
        }

        .tabSearch_section {
          width: 100%;

          .tab_Search {
            .search_Filter {
              .search-filter-child {
                margin-bottom: -100px;
                justify-content: space-between;
                width: 100%;
              }

              .filterBtn {
                .filterIcon {
                  width: 40px;
                  height: 40px;
                  padding: 0 4px 0 9px;

                  i {
                    span {
                      font-size: 0;
                    }
                  }
                }
              }
            }

            .preview_Section {
              &.main_TabEvent {
                .tab-content {
                }

                .nav-tabs {
                  margin-bottom: 10px !important;
                }
              }
            }
          }

          .tab_Heading {
            margin-top: 80px;
          }
        }

        .social_Section {
          display: none;

          &.showTab {
            display: block;
          }
        }

        .social_Section {
          top: 0;
          right: 0;
          z-index: 1;
          width: 475px;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 30px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          position: absolute;
          float: none;
          border-radius: 0 !important;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }

          .onClose {
            display: block;
          }
        }
      }
    }

    .message_IconBg {
      right: 30px;
    }
  }

  .tab_Section {
    margin-top: 80px;
  }

  ul.mb-3.tabs_Height.nav.nav-tabs {
    overflow-x: scroll !important;
    flex-wrap: nowrap !important;
  }
}

@media only screen and (min-width: 475.5px) and (max-width: 575.5px) {
  .publish_Page {
    .main_Container {
      width: 100%;

      .publish_Inner {
        width: 100%;
        padding-left: 24px;

        .tabFilerIcon {
          display: block !important;
        }

        .title_Height {
          .inner_Text {
            padding-bottom: 50px;
          }
        }

        .tabSearch_section {
          width: 100%;

          .tab_Search {
            .search_Filter {
              .search-filter-child {
                margin-bottom: -100px;
                justify-content: space-between;
                width: 100%;
              }

              .filterBtn {
                .filterIcon {
                  width: 40px;
                  height: 40px;
                  padding: 0 4px 0 9px;

                  i {
                    span {
                      font-size: 0;
                    }
                  }
                }
              }
            }

            .preview_Section {
              &.main_TabEvent {
                .tab-content {
                  padding-right: 0px;
                }

                .nav-tabs {
                  margin-bottom: 10px !important;
                }
              }

              .tab_Heading {
                margin-top: 80px;
              }
            }
          }
        }

        .social_Section {
          display: none;

          &.showTab {
            display: block;
          }
        }

        .social_Section {
          top: 0;
          right: 0;
          z-index: 1;
          // width: 365px;
          width: 100%;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 30px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          position: absolute;
          float: none;
          border-radius: 0 !important;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }

          .onClose {
            display: block;
          }
        }
      }
    }

    .message_IconBg {
      right: 30px;
    }
  }

  .tab_Section {
    margin-top: 80px;
  }

  ul.mb-3.tabs_Height.nav.nav-tabs {
    overflow-x: scroll;
    flex-wrap: nowrap;
  }
}

@media only screen and (min-width: 300px) and (max-width: 475.5px) {
  .publish_Page {
    .main_Container {
      width: 100%;

      .publish_Inner {
        width: 100%;
        padding-left: 24px;

        .tabFilerIcon {
          display: block !important;
        }

        .title_Height {
          .inner_Text {
            padding-bottom: 50px;
          }
        }

        .tabSearch_section {
          width: 100%;
          height: 100vh;

          .tab_Search {
            .search_Filter {
              .search-filter-child {
                margin-bottom: -100px;
                justify-content: space-between;
                width: 100%;
              }

              .search_Box {
                .search_Txt {
                }
              }

              .filterBtn {
                .filterIcon {
                  width: 40px;
                  height: 40px;
                  padding: 0 4px 0 9px;

                  i {
                    span {
                      font-size: 0;
                    }
                  }
                }
              }
            }

            .preview_Section {
              &.main_TabEvent {
                .tab-content {
                  padding-right: 0;
                }

                .nav-tabs {
                  margin-bottom: 10px !important;
                  padding: 0;

                  .nav-link {
                    margin-right: 25px;

                    &.active {
                      padding-bottom: 10px !important;
                    }
                  }
                }
              }

              .tab_Heading {
                margin-top: 80px;
              }
            }
          }
        }

        .social_Section {
          display: none;

          &.showTab {
            display: block;
          }
        }

        .social_Section {
          top: 0;
          right: 0;
          z-index: 1;
          width: 365px;
          box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -webkit-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 5px 0px 24px rgba(0, 0, 0, 0.15);
          border-radius: 0px 30px 30px 0px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          position: absolute;
          float: none;
          border-radius: 0 !important;

          &.showTab {
            transform: translateX(0);
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
          }

          .onClose {
            display: block;
          }
        }
      }
    }

    .message_IconBg {
      right: 30px;
    }
  }

  .tab_Section {
    margin-top: 80px;
  }

  ul.mb-3.tabs_Height.nav.nav-tabs {
    overflow-x: scroll;
    flex-wrap: nowrap;
  }

  .search_Box .search_Txt {
    width: 226px;
  }
}
