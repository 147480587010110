@import '../../../assests/scss/variables.scss';

.profile_Listing {
  .profile_list_inner {
    margin-bottom: 24px;

    display: flex;
    justify-content: space-between;
    border: 1px solid $BG_LIGHTBLUE;
    background: $BG_OFFLIGHT;
    border-radius: 10px;
    .profile_box {
      display: flex;
      padding: 24px 0px 24px 24px;

      .profile_image {
        width: 15%;
      }
      .profile_txt {
        display: block;
        margin-left: 10px;
        width: 175px;
        .userName {
          color: $TXT_NAVY;
          font-size: $FONT_24;
          font-family: $LATO_SEMIBOLD;
          line-height: 36px;
          padding: 0px 0px 5px 10px;
          text-align: left;
          padding-right: 10px;
        }
        .profile_status {
          display: flex;
          .status_title {
            color: $TXT_LIGHTBLACK;
            font-size: $FONT_16;
            font-family: $LATO_REGULAR;
            line-height: 24px;
            padding-left: 10px;
            text-align: left;
            padding-right: 8px;
          }
          .status {
            color: $TXT_GREEN;
            font-size: $FONT_16;
            font-family: $LATO_REGULAR;
            line-height: 24px;
            padding: 0;
            text-align: left;
            padding-right: 10px;
            width: 100%;
          }
        }
      }
    }
    .icon_area {
      display: flex;
      align-items: center;
      padding-right: 24px;
      .status_btn {
        padding-right: 20px;
      }
    }
  }
}

@media only screen and (min-width: 575.5px) and (max-width: 767px) {
  .profile_Listing {
  }
}

@media only screen and (min-width: 475.5px) and (max-width: 575.5px) {
  .profile_Listing {
  }
}

@media only screen and (min-width: 300px) and (max-width: 475.5px) {
  .profile_Listing {
    .profile_list_inner {
      margin-bottom: 16px;
      .profile_box {
        padding: 24px 0px 24px 16px !important;
        .profile_txt {
          .userName {
            font-size: $FONT_16;
            font-family: $LATO_BOLD;
            line-height: 24px;
            padding: 0px 0px 5px 0px;
          }
        }

        .profile_status {
          .status_title {
            font-size: $FONT_14 !important;
            font-family: $LATO_SEMIBOLD !important;
            line-height: 21px !important;
          }
          .status {
            font-size: $FONT_14 !important;
            font-family: $LATO_SEMIBOLD !important;
            line-height: 21px !important;
          }
        }
      }

      .icon_area {
        padding-right: 16px;
        .status_btn {
          padding-right: 16px;
        }
      }
    }
  }
}
