@import "../../../assests/scss/variables.scss";

.customCalender {
  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      top: 0 !important;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      &:after {
        position: absolute;
        font-family: "icomoon" !important;
        content: "\e917";
        font-size: $FONT_20;
        color: $TXT_BLUE;
        top: 6px;
        right: 15px;
        line-height: 45px;
      }

      input {
        width: 100%;
        border-radius: 4px;
        border: 1px solid grey;
        outline: none;
        padding: 14px 16px 8px;
        font-size: 14px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &::-webkit-input-placeholder {
          /* Edge */
          color: $TXT_LIGHTBLUE;
          font-size: $FONT_14;
          font-family: $LATO_SEMIBOLD;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $TXT_LIGHTBLUE;
          font-size: $FONT_14;
          font-family: $LATO_SEMIBOLD;
        }

        &::placeholder {
          color: $TXT_LIGHTBLUE;
          font-size: $FONT_14;
          font-family: $LATO_SEMIBOLD;
        }
      }
    }
  }

  .react-datepicker__tab-loop {
    position: relative;

    .react-datepicker-popper {
      margin-top: 0px;
      width: 100%;

      .react-datepicker {
        width: 100%;
        background: $BG_WHITE;
        box-sizing: border-box;
        -webkit-box-shadow: $BOX_SHADOW;
        -moz-box-shadow: $BOX_SHADOW;
        box-shadow: $BOX_SHADOW;
        border: 1px solid $TXT_NAVY;
        border-radius: 10px;
        padding: 0;

        .react-datepicker__day--outside-month {
          color: grey;
        }

        .react-datepicker__day--selected {
          background: $BG_ORANGE !important;
          color: $TXT_WHITE;
          font-weight: 400;
        }

        .react-datepicker__header {
          background: $BG_WHITE;
          padding: 0;
          border: none;

          .react-datepicker__current-month {
            font-family: $LATO_REGULAR;
          }
        }

        .react-datepicker__month-container {
          width: 100%;

          .react-datepicker__day-names {
            .react-datepicker__day-name {
              width: 33px;
              font-family: $LATO_REGULAR;
              font-size: 14px;
              font-weight: bold;
            }
          }

          .react-datepicker__month {
            margin: 10px 0px;
            background: $BG_WHITE;

            .react-datepicker__week {
              .react-datepicker__day {
                font-family: $LATO_REGULAR;
                width: 33px;
                height: 33px;
                font-family: "latoregular";
                font-size: $FONT_13;
                line-height: 32px;
                color: $TXT_LIGHTBLACK;
                border-radius: 50%;

                &.react-datepicker__day--keyboard-selected {
                  background-color: $BG_ORANGE;
                  color: $TXT_WHITE;
                  font-weight: normal;
                  border-radius: 50%;
                }
              }
            }
          }
        }

        .react-datepicker__triangle {
          display: none;
        }
      }
    }
  }

  .react-datepicker-popper {
    transform: none !important;
    top: 35px !important;

    .react-datepicker__input-container {
      &:after {
        display: none;
      }
    }

    .react-datepicker {
      position: relative;

      .react-datepicker__header {
        .react-datepicker-wrapper {
          width: 40%;
          position: relative;
          margin-right: 15px;

          .react-datepicker__input-container {
            // width: 100%;
          }
        }
      }

      .yearCustom {
        width: auto !important;
        left: -105px;
        top: 30px;
        padding: 5px;

        .react-datepicker__header {
          &.react-datepicker-year-header {
            font-size: 0;
            border: none;
          }
        }

        button {
          &:focus {
            outline: none;
          }

          &.react-datepicker__navigation--previous {
            left: 0;
            top: 3px;
          }

          &.react-datepicker__navigation--next {
            right: 0;
            top: 3px;
          }
        }

        .react-datepicker__year-text {
          &:hover {
            background-color: transparent;
          }
        }
      }

      .monthCustom {
        width: auto !important;
        right: 105px;
        top: 30px;
        padding: 5px;

        button {
          display: none;
        }

        .react-datepicker__header {
          &.react-datepicker-year-header {
            display: none;
          }
        }

        .react-datepicker__month {
          width: 60px;
          margin: 0px !important;
          justify-content: center;
          padding: 4px 0px;
          margin-top: 8px;
          height: 200px;
          overflow: scroll;

          .react-datepicker__month-text {
            width: auto;
            margin: 0px 0px;
            padding: 2px 4px;
            font-weight: normal;
            font-size: $FONT_14;
            line-height: 150%;
            color: $TXT_NAVY;
            font-family: $LATO_REGULAR;
            border-radius: 0px;
            outline: none;
            background: transparent;

            &:hover {
              background-color: transparent;
            }

            &.react-datepicker__month--selected {
              background-color: green;
            }
          }
        }
      }
    }
  }

  .custom-input {
    border: none;
    // background-color: white;
    font-family: $LATO_REGULAR;
    font-size: $FONT_14;
    font-weight: 600;
    color: $TXT_NAVY;
    font-family: $LATO_REGULAR;
    outline: none;
    padding: 8px 7px;
    border-radius: 8px;
    width: 100%;

    .icon-downarrow {
      line-height: 24px;
      font-size: $FONT_10;
    }
  }

  .react-datepicker {
    .react-datepicker__year-wrapper {
      max-width: 65px;
      justify-content: center;
      margin-top: 8px;
      height: 200px;
      overflow: scroll;
      padding: 12px;
    }

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #e4e4e4;
    }

    .react-datepicker__year {
      padding: 4px 0;
      margin: 0px;

      .react-datepicker__year-text {
        width: auto;
        margin: 1px 0;
        padding: 2px;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: $TXT_NAVY;
        font-family: $LATO_REGULAR;
        border-radius: 0px;
        outline: none;

        &.react-datepicker__year-text--keyboard-selected {
          background-color: transparent;
        }

        &.react-datepicker__year-text--selected {
          background-color: green;
        }
      }
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: red !important;
    color: red;
    font-weight: bold;
  }
}

.react-datepicker__time-list {
  // display: none !important;
}
