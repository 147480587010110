@import "../../assests/scss/variables.scss";

.admin_AddBlog {
  .main_Container {
    width: calc(100% - 318px);
    float: right;
    background: $BG_WHITE !important;

    .admin_AddBlog_Scroll {
      position: unset !important;

      .dashboard_Section {
        // // width: calc(100% - 318px);
        // float: right;
        padding-left: 96px;
        background: $BG_WHITE !important;

        .content_Header {
          padding-bottom: 24px;

          .backIcon {
            padding-bottom: 14px;

            .icon_Bg {
              width: 24px;
              height: 24px;
              background: $TXT_LIGHTBLACK;
              border-radius: 12px;

              i {
                color: $BG_WHITE;
                font-size: $FONT_12;
              }
            }
          }

          .page_Title {
            font-size: $FONT_32;
            color: $TXT_NAVY;
            padding-bottom: 0;
            padding-left: 10px;
          }

          .inner_Title {
            color: $LABEL_GREY;
            font-size: $FONT_16;
            padding-bottom: 0;
            padding-left: 35px;
          }

          .w80 {
            width: 80%;
          }
        }

        .right_Button {
          .btn {
            height: 48px;
            border-radius: 8px !important;
          }

          .save {
            background-color: #ffffff !important;
            padding: 5px 46px !important;
            border: 1px solid !important;
            color: #16056b !important;
            width: auto;
            margin-right: 16px;
          }

          .publish {
            padding: 0 !important;
            width: 130px;
          }
        }
      }

      .divider {
        border-bottom: 1px solid $BG_LIGHTBLUE;
      }

      .input_Section {
        width: 70%;
        padding: 32px 174px;
        float: left;

        .box-border {
          .form {
            .firstLine {
              display: flex;
              align-items: baseline;

              .firstTitle {
                width: 65%;
                margin-right: 15px;
              }

              .secondDropdown {
                width: 35%;
                // width: 100%;
                // padding-bottom: 48px;

                .customDropdown {
                  .dropdown {
                    .btn-primary {
                      border: 1px solid $TXT_NAVY !important;
                      padding: 24px 16px !important;
                      color: #16056b !important;
                      border-radius: 15px;
                      // height: 74px;

                      &:after {
                        position: absolute;
                        right: 22px;
                        top: 25px;
                        color: $TXT_BLUE;
                        margin: 10px 0px;
                      }
                    }
                  }
                }

                .btn-primary {
                  color: $TXT_NAVY !important;
                }
              }
            }
          }

          .textField {
            &:first-child() {
              .form-control {
                height: 56px !important;
              }
            }
          }

          textarea {
            height: 194px !important;
          }

          &.marginTop {
            margin-top: 32px;
            padding: 0;

            .textField {
              margin-bottom: 24px !important;
            }

            .inner_Section {
              padding: 40px 42px;

              .textField {
                &:last-child {
                  margin-bottom: 0 !important;
                }
              }
            }
          }

          .bg_Textfield {
            background: $BG_ICONBG;
            padding: 24px 24px;
            display: table;

            a {
              text-decoration: none;
              cursor: pointer;

              .titleText {
                font-size: $FONT_16 !important;
                font-family: $LATO_SEMIBOLD;
                color: $TXT_NAVY;
              }

              .bg_Color {
                background: $TXT_ORANGE;
                width: 48px;
                height: 48px;
                border-radius: 24px;

                i {
                  color: $TXT_WHITE;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }

      .img_Section {
        width: 30%;
        float: left;
        padding: 40px 20px;
        background: $BG_OFFWHITE;
        height: 100vh;

        .titleText {
          font-size: $FONT_24;
          color: $TXT_NAVY;
        }

        .dropdown_Section {
          width: 100%;
          padding-bottom: 48px;

          .customDropdown {
            .dropdown {
              .btn-primary {
                border: 1px solid $TXT_NAVY !important;
                padding: 17px 16px !important;
                color: #16056b !important;

                &:after {
                  position: absolute;
                  right: 22px;
                  top: 25px;
                  color: $TXT_BLUE;
                }
              }
            }
          }

          .btn-primary {
            color: $TXT_NAVY !important;
          }
        }
      }

      .addCard {
        width: 155px;
        float: right;
        height: 48px;
        border-radius: 8px !important;
        background-color: $TXT_NAVY;
        color: $BG_WHITE;
      }

      .closeIcon {
        right: 4px;
        top: 2px;
      }

      .bgColor {
        background: $BG_WHITE;
        width: 27px;
        height: 27px;
      }
    }
  }
}

.publish_Modal {
  .modal-dialog {
    max-width: 938px;

    .modal-content {
      padding: 40px 32px;

      .modal-header {
        padding: 0;
        padding-bottom: 24px;
        border: none;
      }

      .modal-title {
        font-size: $FONT_32;
      }

      .modalInner {
        .modal-body {
          padding: 0;
        }

        .titleText {
          font-size: $FONT_16;
          color: $TXT_NAVY !important;
          font-family: $LATO_REGULAR;
        }

        button {
          width: 303px;

          &.cancelBtn {
            background-color: transparent !important;
            border: 1px solid $TXT_NAVY !important;
            color: $TXT_NAVY !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .admin_AddBlog {
    .main_Container {
      .admin_AddBlog_Scroll {
        .dashboard_Section {
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .admin_AddBlog {
    .main_Container {
      .admin_AddBlog_Scroll {
        .dashboard_Section {
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .admin_AddBlog {
    .main_Container {
      .admin_AddBlog_Scroll {
        .dashboard_Section {
          .input_Section {
            width: 100%;
            padding: 32px 20px;
          }

          .img_Section {
            width: 98%;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .admin_AddBlog {
    .main_Container {
      .admin_AddBlog_Scroll {
        .dashboard_Section {
          .input_Section {
            width: 100%;
            padding: 32px 20px;
          }

          .img_Section {
            width: 98%;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .admin_AddBlog {
    .main_Container {
      .admin_AddBlog_Scroll {
        .dashboard_Section {
          .input_Section {
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .admin_AddBlog {
    .main_Container {
      .admin_AddBlog_Scroll {
        .dashboard_Section {
          width: 100%;

          .page_Title {
            padding-top: 20px;
          }

          .input_Section {
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .admin_AddBlog {
    .main_Container {
      .admin_AddBlog_Scroll {
        .dashboard_Section {
          width: 100%;
          padding: 10px;

          .page_Title {
            padding-top: 20px;
          }

          .input_Section {
          }
        }
      }
    }
  }
}
