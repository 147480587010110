@import "../../assests/scss/variables.scss";

.DropdownGroupScreen {
  .maincontainer {
    background-color: $BG_LIGHT;
    margin-top: -16px;
    width: 20%;
    border-radius: 10px;
    position: absolute;
    z-index: 1;
    .link_section {
      margin-top: 16px;
      margin-left: 16px;
      line-height: 32px;
      .nav-tabs .nav-item.show .nav-link,
      .nav-tabs .nav-link.active {
        color: $TXT_NAVY !important;
        background-color: #f7f7fc !important;
        border-color: #dee2e6 #dee2e6 #fff;
        border-bottom: 1px solid $TXT_NAVY;
      }
      .nav-link {
        border: none;
        color: $TXT_NAVY !important;
      }
    }
    .main_box {
      height: 300px;
      padding: 0px;
      .inner_box {
        .content {
          display: flex;
          justify-content: space-between;
          .content_inner {
            display: flex;
            justify-content: space-between;
            .profile_Img {
              padding: 24px 8px 16px 24px;
            }
            .profile_name {
              font-size: $FONT_14;
              font-family: $LATO_REGULAR;
              font-weight: normal;
              font-style: normal;
              line-height: 21px;
              color: #14142b;
              padding: 26px 0px 19px 15px;
            }
          }
          .checkboxMain {
            margin: 25px 16px 0px 0px;
          }
        }
        .box_border {
          border: 1px solid $TXT_BLUELIGHT;
          margin: 0px 16px -12px 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .DropdownGroupScreen {
    .maincontainer {
      width: 25%;
      .main_box {
        .inner_box {
          .content {
            .checkboxMain {
              margin: 28px 16px 0px 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .DropdownGroupScreen {
    .maincontainer {
      width: 30%;
      .main_box {
        .inner_box {
          .content {
            .checkboxMain {
              margin: 28px 16px 0px 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .DropdownGroupScreen {
    .maincontainer {
      width: 40%;
      .main_box {
        .inner_box {
          .content {
            .checkboxMain {
              margin: 28px 16px 0px 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .DropdownGroupScreen {
    .maincontainer {
      width: 45%;
      .main_box {
        .inner_box {
          .content {
            .checkboxMain {
              margin: 28px 16px 0px 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .DropdownGroupScreen {
    .maincontainer {
      width: 50%;
      .main_box {
        .inner_box {
          .content {
            .checkboxMain {
              margin: 28px 16px 0px 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .DropdownGroupScreen {
    .maincontainer {
      width: 55%;
      .main_box {
        .inner_box {
          .content {
            .checkboxMain {
              margin: 28px 16px 0px 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .DropdownGroupScreen {
    .maincontainer {
      width: 80%;
      .main_box {
        .inner_box {
          .content {
            .checkboxMain {
              margin: 28px 16px 0px 0px;
            }
          }
        }
      }
    }
  }
}
