@import "../../assests/scss/variables.scss";

.blogfour_Page {
  .blogScroll {
    height: calc(100vh - 150px) !important;
    height: 100vh !important;

    .first_Title {
      margin-top: 100px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $FONT_48;
      color: $TXT_NAVY;
      padding: 0px 500px;
      text-align: center;
      line-height: 64px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        background: $TXT_ORANGE;
        height: 4px;
        bottom: -15px;
        width: 50px;
      }
    }

    .center_Section {
      margin-top: 250px;
      display: flex;
      justify-content: center;
      align-items: center;

      .inner_Section {
        margin-top: -176px;
        max-width: 1256px;
        width: 100%;

        .inner_Text {
          font-family: $LATO_REGULAR;
          font-size: $FONT_24;
          margin: 0;
          text-align: justify;
          color: $TXT_DARKBLACK;
          font-weight: 600;
          line-height: 36px;
          padding-bottom: 32px;
          letter-spacing: 1px;

          &:last-child {
            padding-bottom: 0;
          }
        }

        p::first-letter {
          text-transform: uppercase;
          font-size: $FONT_48;
          font-family: $LATO_BOLD;
        }

        .inner_Img {
          img {
            width: 100%;
            padding-bottom: 34px;
          }
        }
      }
    }
    .blog-feature-four {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
    }

    .image_Section {
      padding: 0 40px;
      padding-bottom: 200px !important;

      .titleText {
        display: flex;
        justify-content: center;
        font-size: $FONT_48;
        font-family: $LATO_BOLD;
        color: $TXT_NAVY;
        padding-bottom: 80px;
        padding-top: 40px;
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .blogfour_Page {
    .blogScroll {
      .first_Title {
        padding: 0px 300px;
      }

      .center_Section {
        .inner_Section {
          max-width: 1000px;
        }
      }

      .image_Section {
        padding: 0px 60px;
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .blogfour_Page {
    .blogScroll {
      .first_Title {
        padding: 0px 200px;
      }

      .center_Section {
        .inner_Section {
          max-width: 750px;
        }
      }

      .image_Section {
        padding: 0px 60px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .blogfour_Page {
    .blogScroll {
      .first_Title {
        padding: 0px 90px;
      }

      .center_Section {
        .inner_Section {
          max-width: 900px;
        }
      }

      .image_Section {
        padding: 0 60px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blogfour_Page {
    .blogScroll {
      .first_Title {
        padding: 0px 60px;
      }

      .center_Section {
        .inner_Section {
          max-width: 730px;
        }
      }

      .image_Section {
        .slider_Section {
          .slick-prev {
            left: 16px;
          }

          .slick-next {
            right: 18px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blogfour_Page {
    .blogScroll {
      .first_Title {
        margin-top: 50px;
        padding: 0px 60px;
        font-size: $FONT_36;
      }

      .center_Section {
        .inner_Section {
          max-width: 530px;

          .inner_Text {
            font-size: $FONT_22;
          }
        }
      }

      .image_Section {
        padding: 0 10px;
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .blogfour_Page {
    .blogScroll {
      .first_Title {
        margin-top: 50px;
        padding: 0px 30px;
        font-size: $FONT_36;
      }

      .center_Section {
        .inner_Section {
          max-width: 450px;

          .inner_Text {
            font-size: $FONT_22;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .blogfour_Page {
    .blogScroll {
      .first_Title {
        margin-top: 0;
        padding: 0px 30px;
        font-size: $FONT_24;
        top: 80px;
        line-height: 50px;
      }

      .center_Section {
        .inner_Section {
          max-width: 377px;
          margin-top: -174px;
          padding: 45px 10px 45px 0px;

          .inner_Text {
            font-size: $FONT_22;
          }
        }
      }

      .image_Section {
        padding: 0 24px;

        .slider_Section {
          .slick-prev {
            width: 30px;
            height: 30px;
            left: -10px;
          }

          .slick-next {
            width: 30px;
            height: 30px;
            right: -10px;
          }
        }
      }
    }
  }
}
