@import "../../assests/scss/variables.scss";

.failed_Page {
  width: 100%;
  display: table;
  position: relative;
  background: $BG_LIGHT;
  overflow: hidden;

  .main_Container {
    width: calc(100% - 96px);
    float: left;

    .schedule_Inner {
      width: 100%;

      float: right;
      padding-left: 32px;

      .title_Height {
        padding-top: 40px;
        padding-bottom: 60px;

        .main_Heading {
          font-size: $FONT_32;
          font-family: $LATO_BOLD;
          font-weight: 600;
          color: $TXT_NAVY;
          padding-bottom: 0;
        }
      }

      .tabSearch_section {
        .parent {
          display: flex;
          justify-content: space-between;
          .left {
            width: 100%;
            .tabs-wrapper {
              width: 100% !important;
              margin-top: 48px;
              display: flex;
              flex-wrap: wrap !important;
              // justify-content: space-around !important;
              align-items: center;
              .card {
                margin-bottom: 16px !important;
                margin-right: 16px !important;
              }
            }
            .nav-tabs .nav-item.show .nav-link,
            .nav-tabs .nav-link.active {
              color: $TXT_NAVY;
              background-color: #fff;
              border-bottom: 2px solid $BG_NAVY;
            }
            .nav-tabs .nav-item.show .nav-link,
            .nav-tabs .nav-link {
              border-color: transparent;
              font-weight: bold;
              color: $TXT_LIGHT;
              padding: 0 !important;
              margin-right: 20px;
              padding-bottom: 10px !important;
            }
            .nav {
              margin-bottom: -24px;
            }
            .nav-tabs {
              // border-bottom: none;
            }
          }
          .right {
            .search-filter-main {
              position: absolute;
              right: 0;
              top: 120px;
              display: flex;
              .filterIcon {
                position: relative;
                margin-right: 15px;
                background: $BG_WHITE;
                width: 117px;
                // height: 40px;
                padding: 12px 12px;
                border-radius: 8px;
                border: none;
                display: flex;
                align-items: center;
                border: 1px solid $TXT_LIGHTBLACK;
                justify-content: center;

                &:focus {
                  outline: none;
                }

                i {
                  color: $TXT_LIGHTBLACK;
                  font-size: $FONT_16;

                  span {
                    font-size: $FONT_16;
                    font-family: $LATO_REGULAR;
                  }
                }
              }
              .filter {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .failed_Page {
    .main_Container {
      .schedule_Inner {
        .tabSearch_section {
          .title_Height {
            .main_Heading {
            }
          }
        }
        .parent {
          .left {
            .tabs-wrapper {
            }
            .nav {
            }
          }
          .right {
            .search-filter-main {
              .search_Box .search_Txt {
                width: 200px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 991.5px) and (max-width: 1024px) {
  .failed_Page {
    .main_Container {
      .schedule_Inner {
        .tabSearch_section {
          .title_Height {
            .main_Heading {
            }
          }
        }
        .parent {
          .left {
            .tabs-wrapper {
            }
            .nav {
            }
          }
          .right {
            .search-filter-main {
              .search_Box .search_Txt {
                width: 160px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.5px) {
  .failed_Page {
    .main_Container {
      .schedule_Inner {
        .tabSearch_section {
          .title_Height {
            .main_Heading {
            }
          }
        }
        .parent {
          .left {
            .tabs-wrapper {
            }
            .nav {
            }
          }
          .right {
            .search-filter-main {
              top: 90px !important;
              .search_Box .search_Txt {
                // width: 160px !important;
              }
              .filterIcon {
                // width: 54px !important;
              }
              .filterIcon i span {
                // display: none;
              }
            }
            .filter {
              // margin-left: 8px !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
  .failed_Page {
    .main_Container {
      .schedule_Inner {
        .tabSearch_section {
          .title_Height {
            .main_Heading {
            }
          }
        }
        .parent {
          .left {
            .tabs-wrapper {
              margin-top: 110px !important;
            }
            .nav {
              flex-wrap: nowrap;
              overflow-y: scroll;
            }
          }
          .right {
            .search-filter-main {
              // right: -32px !important;
              display: flex;
              justify-content: space-between;
              top: 194px !important;
              width: 100%;
              .search {
                margin-left: 32px;
              }
              .filterIcon {
                width: 54px !important;
              }
              .filter i span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 475.5px) and (max-width: 574px) {
  .failed_Page {
    .main_Container {
      width: 100%;
      .schedule_Inner {
        .tabSearch_section {
          .title_Height {
            .main_Heading {
            }
          }
        }
        .parent {
          .left {
            .tabs-wrapper {
              margin-top: 110px !important;
            }
            .nav {
              flex-wrap: nowrap;
              overflow-y: scroll;
            }
          }
          .right {
            .search-filter-main {
              // right: -32px !important;
              display: flex;
              justify-content: space-between;
              top: 194px !important;
              width: 100%;
              .search {
                margin-left: 32px;
              }
              .filterIcon {
                width: 54px !important;
              }
              .filter i span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475.5px) {
  .failed_Page {
    .main_Container {
      width: 100%;
      .schedule_Inner {
        .tabSearch_section {
          .title_Height {
            .main_Heading {
            }
          }
        }
        .parent {
          .left {
            .tabs-wrapper {
              margin-top: 110px !important;
            }
            .nav {
              flex-wrap: nowrap;
              overflow-y: scroll;
            }
          }
          .right {
            .search-filter-main {
              // right: -32px !important;
              display: flex;
              justify-content: space-between;
              top: 194px !important;
              width: 100%;
              .search {
                margin-left: 32px;
              }
              .filterIcon {
                width: 54px !important;
              }
              .filter i span {
                display: none;
              }
            }
            .search_Box .search_Txt {
              width: 200px;
            }
          }
        }
      }
    }
  }
}
