@import "../../../assests/scss/variables.scss";
.accordion_profile {
  .container_toggle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 24px;
    cursor: pointer;
    .profile_txt {
      margin-left: 8px;
      .userName {
        font-size: $FONT_24;
        color: $TXT_NAVY !important;
        padding-bottom: 0px;
        line-height: 150%;
      }
    }
    .profile_box {
      display: flex;
      align-items: center;
    }
  }
  .option_toggle {
    padding-bottom: 24px;
    .profile_container {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      .link_text {
        font-size: $FONT_16;
        color: $LABEL_GREY !important;
        padding-bottom: 0px;
        line-height: 150%;
        font-family: $LATO_SEMIBOLD;
      }
    }
  }

  .accordionContent {
    height: 0;
    opacity: 0;
    display: flex;
    overflow: hidden;
    transition: all 600ms ease-in-out;
  }
}

@media only screen and (min-width: 575.5px) and (max-width: 767px) {
}

@media only screen and (min-width: 475.5px) and (max-width: 575.5px) {
}

@media only screen and (min-width: 300px) and (max-width: 475.5px) {
  .accordion_profile {
    .container_toggle {
      margin-bottom: 16px;
      margin-top: 16px;
      .profile_txt {
        .userName {
          font-size: $FONT_16;
          font-family: $LATO_SEMIBOLD;
        }
      }
    }
    .option_toggle {
      padding-bottom: 0px;
      .profile_container {
        padding-bottom: 16px;
        .link_text {
          font-size: $FONT_14;
          padding-bottom: 0px;
          font-family: $LATO_REGULAR;
        }
      }
    }
  }
}
