@import "../../assests/scss/variables.scss";

.headerLogin {
  width: 100%;
  padding: 11.5px 0;
  -webkit-box-shadow: $BOX_SHADOWHEADER;
  -moz-box-shadow: $BOX_SHADOWHEADER;
  box-shadow: $BOX_SHADOWHEADER;
  //added
  position: fixed;
  background: #ffffff;
  z-index: 1;

  .navbar {
    padding: 0 80px 0 40px;

    .container {
      padding: 0;

      .navbar-brand {
        padding: 0;
        color: $TXT_NAVY;
        font-size: $FONT_24;
        font-family: $LATO_SEMIBOLD;

        span {
          color: $TXT_ORANGE;
        }
      }

      .navbar-collapse {
        justify-content: flex-end;

        .menuItem {
          .button_Link {
            display: flex;
            width: 100%;

            .nav-link {
              button {
                height: 37px;
                color: $TXT_NAVY;
                font-family: $LATO_SEMIBOLD;
                background: transparent !important;
                border: 1px solid $TXT_NAVY !important;
                padding: 0 !important;
                font-size: $FONT_14;
                width: 106px;
                border-radius: 100px !important;

                &.signUp {
                  background: $TXT_NAVY !important;
                  color: $TXT_WHITE;
                }
              }
            }
          }

          .nav-link {
            padding: 0;
            margin-left: 32px;
            color: $TXT_LIGHTBLACK;
            font-size: $FONT_14;
            align-items: center;
            display: flex;

            &:last-child {
              margin-left: 24px;
            }
          }
        }
      }
    }
  }
}

.demo_Modal {
  .modal-dialog {
    max-width: 100%;
    margin: 0 50px;
  }

  .modal-header {
    padding: 0;
    border: none;
    position: absolute;
    right: 30px;
    top: 40px;
    z-index: 1;

    .closeBtn {
      i {
        font-size: $FONT_30;
      }
    }
  }

  .modal-content {
    .modalInner {
      .modal-body {
        padding: 33px 24px 24px 77px;

        .demo_Modalpopup {
          .main_Section {
            .left_Section {
              width: 40%;
              border-right: 1px solid $BORDERLIGHT;

              .titleText {
                font-size: $FONT_48;
                color: $TXT_BLACK;
                padding-bottom: 0;
              }

              .main_Top {
                width: 598px;
                padding-top: 80px;
              }

              .timeZone {
                font-size: $FONT_24;
                color: $TXT_DARKGREY;
                font-family: $LATO_REGULAR;
              }

              .time {
                font-size: $FONT_24;
                color: $TXT_DARKGREY;
                font-family: $LATO_REGULAR;
                padding-left: 8px;
              }
            }

            .right_Section {
              width: 60%;
              padding-left: 56px;
              padding-top: 100px;

              .titleText {
                font-size: $FONT_32;
                color: $TXT_BLACK;
                padding-bottom: 73px;
              }
            }
          }
        }
      }
    }
  }
}

.time_Confirm_Modal {
  .modal-dialog {
    max-width: 750px;

    .modal-content {
      border-radius: 50px;

      .modal-header {
        border: none !important;
        padding-bottom: 0;
        padding: 2rem 3rem 1em 3rem;
      }
    }

    .modalInner {
      .modal-body {
        padding: 33px 122px 103px 108px !important;

        .conf_Modalpopup {
          .msg {
            padding-bottom: 5px;
            font-size: $FONT_24;
            color: $TXT_DARKGREY;
            font-family: $LATO_REGULAR;
          }

          .dateTime {
            padding-bottom: 0;
            font-size: $FONT_24;
            font-family: $LATO_REGULAR;
            text-align: center;
            color: $TXT_LIGHTNAVY;
          }

          .footer_Modal {
            padding-top: 44px;

            button {
              width: 218px;
              height: 82px;
              border-radius: 40px !important;
              background: $BG_GREEN !important;

              &:nth-child(2) {
                margin-left: 20px;
                background: $BG_WHITE !important;
                color: $TXT_GREEN;
                -webkit-box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
                  0px 0.5px 2px rgba(96, 97, 112, 0.16);
                box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
                  0px 0.5px 2px rgba(96, 97, 112, 0.16);
              }
            }
          }
        }
      }
    }
  }
}

.contact_Modal {
  .modal-dialog {
    max-width: 750px;

    .modal-content {
      border-radius: 50px;

      .modal-header {
        border: none !important;
        padding-bottom: 0;
        padding: 2rem 3rem 1em 3rem;
      }
    }

    .modalInner {
      .modal-body {
        padding: 24px 30px;
        display: block;

        .form_Modalpopup {
          .contact_Form {
            .textField {
              margin-bottom: 20px;

              .form-control {
                border-radius: 0;
                border: none;
                border-bottom: 1px solid $TXT_BLACK !important;
                background: $BG_GREY;

                &::-webkit-input-placeholder {
                  color: $BG_DARKGREY;
                }

                &::-moz-placeholder {
                  color: $BG_DARKGREY;
                }

                &:-ms-input-placeholder {
                  color: $BG_DARKGREY;
                }

                &:-moz-placeholder {
                  color: $BG_DARKGREY;
                }
              }
            }
          }

          .footer_Modal {
            padding-top: 44px;
            padding-bottom: 70px;

            button {
              width: 174px;
              height: 74px;
              border-radius: 40px !important;
              background: $BG_GREEN !important;

              &:nth-child(2) {
                margin-left: 20px;
                background: $BG_WHITE !important;
                color: $TXT_GREEN;
                -webkit-box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
                  0px 0.5px 2px rgba(96, 97, 112, 0.16);
                box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
                  0px 0.5px 2px rgba(96, 97, 112, 0.16);
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .headerLogin {
    .navbar {
      padding: 0 80px 0 5px;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 768px) {
  .headerLogin {
    padding: 25.5px 0;

    .navbar {
      padding: 0;
      display: block;
      padding: 0 20px;

      &.navbar-expand {
        .navbar-collapse {
          display: block;
        }
      }

      .container {
        .navbar-toggler {
          display: flex !important;
          float: left;
          padding: 0px;
          border: none;
          outline: none;
          margin-right: 0;
          width: 33px;
          height: 33px;
          justify-content: center;
          align-items: center;

          &::before {
            position: relative;
            content: "\e916";
            font-family: "icomoon" !important;
            color: $TXT_LIGHTBLACK;
            font-size: $FONT_16;
            top: 0;
            left: 20px;
          }

          &.collapsed {
            margin-right: 0;

            &::before {
              position: relative;
              content: "\e950";
              font-family: "icomoon" !important;
              color: $TXT_LIGHTBLACK;
              font-size: $FONT_16;
              top: 0;
              left: 20px;
            }
          }

          .navbar-toggler-icon {
            background-image: none;
          }
        }

        .responsive_title {
          display: block;
        }

        .navbar-brand {
          float: left;
        }

        .navbar-collapse {
          position: absolute;
          top: 62px;
          height: calc(100vh - 58px);
          height: -webkit-calc(100vh - 58px);
          height: -ms-calc(100vh - 58px);
          height: -moz-calc(100vh - 58px);
          height: -o-calc(100vh - 58px);
          background: $BG_WHITE;
          right: 0;
          z-index: 1;
          padding: 0;
          width: 360px;
          transform: translateX(100%);
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          -ms-transform: translateX(100%);
          -o-transform: translateX(100%);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -ms-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          -webkit-box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.08);
          -moz-box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.08);
          -ms-box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.08);
          -o-box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.08);
          display: block !important;

          &.collapsing {
            height: 100vh !important;
          }

          &.show {
            transform: translateX(0%);
            -webkit-transform: translateX(0%);
            -moz-transform: translateX(0%);
            -ms-transform: translateX(0%);
            -o-transform: translateX(0%);
            transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
          }

          .navbar-nav {
            flex-direction: column !important;
            align-items: flex-start;

            &.menuItem {
              .button_Link {
                .nav-link {
                  padding: 24px 0;

                  &:last-child {
                    padding-right: 24px;
                  }
                }
              }

              .nav-link {
                width: 100%;
                margin: 0;
                padding: 16px 0;
                border-bottom: 1px solid $TXT_BLUELIGHT;
                justify-content: flex-end;
                padding-right: 24px;

                &:last-child {
                  margin: 0;
                }

                button {
                  border-radius: 8px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
