@import "../../assests/scss/variables.scss";

.socialmedia_Popup {
  height: 75vh;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  .main_Container {
    margin: auto;
    font-family: Lato;
    font-family: normal;
    line-height: 150%;
    width: auto;
    height: auto;
    background: $TXT_OFFWHITE;
    border-radius: 10px;
    padding: 32px 32px 32px 32px;

    .top_Column {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .top_Heading {
        font-size: $FONT_32;
        color: $SOLID_BLACK;
        font-family: $LATO_SEMIBOLD !important;
      }

      i {
        cursor: pointer;
      }
    }

    .choose_Page {
      .choose_Text {
        font-size: $FONT_16;
        color: $SOLID_BLACK;
        margin-top: 2px;
        font-family: $LATO_REGULAR !important;
      }
    }

    .category_Select {
      font-size: $FONT_24;
      font-family: $LATO_SEMIBOLD !important;
      padding-bottom: 8px;
      border-bottom: 1px solid #d9dbe9;

      .pages {
        margin-left: 3rem;
      }

      margin-bottom: 1rem;
    }

    .content_Box {
      background-color: $BG_LIGHT;
      padding-top: 16px;

      .search_Box {
        background-color: $BG_LIGHT;
        border-radius: 10px;
        display: flex;
        width: 94.25%;
        margin: auto;
        // margin-left:16px;
        //  align-items: baseline;
        border: 1px solid $BG_LIGHTBLUE;

        .icon_Search {
          padding: 15px 11.9px 16.39px 11.49px;

          .icon-search {
            top: 30px;
          }
        }

        .search_Here {
          margin-left: 13px;

          .form1 {
            .textField {
              margin-bottom: 0px;
            }

            .form-control {
              font-family: "latoregular";
              font-size: 14px;
              border: none;
              background-color: $BG_LIGHT;
              padding: 13.5px 8px 13.5px 0px;
              color: $TXT_LIGHT;
            }
          }
        }
      }

      .line {
        border-bottom: 1px solid $TXT_BLUELIGHT;
        padding-top: 24px;
      }

      .group_Class {
        padding: 0px 25px 0px 24px;

        .group {
          display: flex;
          justify-content: space-between;
          padding-top: 16px;
          align-items: baseline;
          border-bottom: 1px solid $TXT_BLUELIGHT;

          .photo_Name {
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            img.profile {
              padding-bottom: 8px;
            }

            .name {
              margin-left: 15px;
              font-size: $FONT_14;
              padding-bottom: 10px;
              color: $TXT_LIGHTBLACK;
              font-family: $LATO_REGULAR !important;
            }
          }

          .check {
            margin-bottom: 13px;
          }
        }
      }
    }

    .try_Reconnect {
      display: flex;
      padding-top: 16px;

      .text_Dont {
        font-size: $FONT_16;
        color: $TXT_LIGHTGREY;
        font-family: $LATO_REGULAR !important;
      }

      .text_Reconnect {
        font-size: $FONT_16;
        color: $TXT_NAVY;
        font-family: $LATO_REGULAR !important;
        cursor: pointer;
      }
    }

    .button_Area {
      display: flex;
      justify-content: right;
      padding-top: 70px;

      .cancel_Button {
        border: 1px solid $TXT_NAVY;
        border-radius: 10px;
        width: 30%;

        //    padding:16px 77px;
        button.submitBtn.btn.btn-primary {
          background-color: $TXT_OFFWHITE !important;
          color: $TXT_NAVY;
        }
      }

      .add_Button {
        //    padding:16px 68px;
        margin-left: 16px;
        width: 30%;

        button.submitBtn.btn.btn-primary {
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1600px) {
  .socialmedia_Popup {
    height: 64vh;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .socialmedia_Popup {
    height: 64vh;

    .main_Container {
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .socialmedia_Popup {
    height: 64vh;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .socialmedia_Popup {
    height: 64vh;

    .main_Container {
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .socialmedia_Popup {
    height: 64vh;

    .main_Container {
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .socialmedia_Popup {
    height: 64vh;

    .main_Container {
      // width: 88.33% !important;
      // margin: 81px 24px !important;
      padding: 22px 24px 40px 24px;

      .top_Heading {
        font-size: $FONT_24;
      }

      .try_Reconnect {
        display: inline;

        .text_Dont {
          padding-top: 16px !important;
          padding-bottom: 0px !important;
        }
      }

      .button_Area {
        padding: 22px 24px 0px 24px;
        justify-content: center;

        .cancel_Button {
          width: 50%;

          button.submitBtn.btn.btn-primary {
            background-color: $TXT_OFFWHITE !important;
            color: $TXT_NAVY;
          }
        }
      }

      .add_Button {
        //    padding:16px 68px;
        width: 50% !important;
        margin-left: 13px;
      }
    }
  }
}

@media only screen and (min-width: 476px) and (max-width: 575px) {
  .socialmedia_Popup {
    height: 64vh;

    .main_Container {
      // width: 88.33% !important;
      // margin: 81px 24px !important;
      padding: 22px 24px 40px 24px;

      .top_Heading {
        font-size: $FONT_24;
      }

      .try_Reconnect {
        display: inline;

        .text_Dont {
          padding-top: 16px !important;
          padding-bottom: 0px !important;
        }
      }

      .button_Area {
        padding: 22px 24px 0px 24px;
        justify-content: center;

        .cancel_Button {
          width: 50%;

          button.submitBtn.btn.btn-primary {
            background-color: $TXT_OFFWHITE !important;
            color: $TXT_NAVY;
          }
        }
      }

      .add_Button {
        //    padding:16px 68px;
        width: 50% !important;
        margin-left: 13px;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 475px) {
  .socialmedia_Popup {
    height: 64vh;

    .main_Container {
      // width: 88.33% !important;
      // margin: 81px 24px;
      // padding: 22px 24px 40px 24px;

      .top_Heading {
        font-size: $FONT_24;
      }

      .try_Reconnect {
        display: inline;

        .text_Dont {
          padding-top: 16px !important;
          padding-bottom: 0px !important;
        }
      }

      .button_Area {
        padding: 22px 24px 0px 24px;
        justify-content: center;

        .cancel_Button {
          width: 50%;

          button.submitBtn.btn.btn-primary {
            background-color: $TXT_OFFWHITE !important;
            color: $TXT_NAVY;
          }
        }
      }

      .add_Button {
        //    padding:16px 68px;
        width: 50% !important;
        margin-left: 13px;
      }
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 299px) {
  .socialmedia_Popup {
    display: none;
  }
}
