.ToastBlock {
  background-color: #f7f7f7;
  position: absolute;
  z-index: 2;
  top: 20px;
  right: 20px;
  display: flex;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border-left: 16px solid;

  .innerToast {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    width: 18rem;
    padding: 12px 18px;

    .iconWrapper {
      .icon-close {
        border-radius: 50%;
        padding: 4px;
        background-color: #ff0000;
        color: white;
      }

      .icon-tick {
        border-radius: 50%;
        padding: 4px;
        background-color: #0fd9a2;
        color: white;
      }
    }

    .head {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 150%;
      color: #16056b;
      margin-bottom: 2px;
    }

    .message {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #4e4b66;
      margin: 0px;
    }
  }
}

.success {
  border-color: #0fd9a2;
}

.error {
  border-color: #ff0000;
}
